import React, { useState } from "react";
import { membersData, partnersData } from "../data";
import { Button } from "../../../shared/ui/Button";
import { Link } from "react-router-dom";

import partner1 from "../../../assets/images/partners1.png";
import partner2 from "../../../assets/images/GSG_LOGO_RGB_Lilac-Navy.png";

export default function MembersPertners() {
  const [showLogo, setShowLogo] = useState("members");
  return (
    <div className="container mx-auto py-20 px-8 md:px-16">
      <h2 className="text-center text-4xl pb-12">
        Our <span className="font-bold">Members</span> And{" "}
        <span className="font-bold">Partners</span>
      </h2>
      <div className="max-w-5xl mx-auto flex text-2xl border-b-2 border-primary">
        <div
          className={`flex-1 text-center  p-4 cursor-pointer ${
            showLogo === "members" ? "bg-green-200" : ""
          }  hover:bg-green-100`}
          onClick={() => setShowLogo("members")}
        >
          <p>Our Members</p>
        </div>
        <div
          className={`flex-1 text-center p-4 cursor-pointer ${
            showLogo === "partners" ? "bg-green-200" : ""
          }  hover:bg-green-100`}
          onClick={() => setShowLogo("partners")}
        >
          <p>Network Partners</p>
        </div>
      </div>

      {showLogo === "members" && (
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 justify-center gap-4 items-center py-12">
          {membersData?.map((memberImg) => (
            <div className="p-6" key={memberImg?.id}>
              <img src={memberImg?.img} alt="member img" />
            </div>
          ))}
        </div>
      )}

      {showLogo === "partners" && (
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 justify-center gap-4 items-center py-12">
          <div className="col-start-3 p-6">
            <img src={partner1} alt="member img" className="w-[110px]" />
          </div>
          <div className="col-start-4 col-end-6 p-6">
            <img src={partner2} alt="member img" className="w-[150px]" />
          </div>
        </div>
      )}

      {showLogo === "partners" ? (
        <div className="text-center">
          <Link to="/">
            <Button>View All</Button>
          </Link>
        </div>
      ) : (
        <div className="text-center">
          <Link to="/our-people">
            <Button>View All</Button>
          </Link>
        </div>
      )}
    </div>
  );
}
