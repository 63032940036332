import React from "react";
import { Link } from "react-router-dom";
import news9 from "../../../../assets/images/news9.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function FatherOfImpactInvestmentDetials() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news9}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          Sir Ronald Cohen the Chairman of Global Steering Group for Impact
          Investment and widely described as the father of Impact Investment
          applauded the efforts of Bangladesh in advancing the Impact movement
          in the country.
        </p>
        <div>
          <iframe
            width="100%"
            height="515"
            src="https://www.youtube.com/embed/ru93PSuYnEM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <p className="py-10">
          During his speech at the launch event of the Bangladesh Impact
          Investment Strategy and Action Plan (BIISAP), Sir Ronald Cohen
          acknowledged <span className="font-bold">Build Bangladesh</span> in
          bringing together the government support along with commitment from
          regulators, private sector, financial intermediaries, NGOs, and
          development partners to create real momentum in Bangladesh and to play
          the leadership role in Asia-Pacific.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
