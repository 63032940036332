import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function KeyPolicySecond() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>2015-16</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Law/Circular/Guideline
                        </div>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Date of Publication
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Implementer
                        </div>
                        <div className='col-span-4 border-r border-black flex justify-center items-center p-2'>
                            Brief Description of Provision
                        </div>
                        <div className='col-span-2 flex justify-center items-center p-2'>
                            Implications for Impact Investing in Bangladesh to be considered
                        </div>
                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-11 text-center border-b border-black bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.bb.org.bd/mediaroom/circulars/dos/nov242015dos02e.pdf" target='_blank' className='font-bold text-primary underline  decoration-1'>
                                Circular on Investment in Special Purpose Vehicle,
                                Alternative Investment Fund or similar fund/funds by the
                                scheduled banks
                            </a>
                        </div>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                            November 25, 2015
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            Department of Off-Site Supervision, Bangladesh Bank
                        </div>
                        <div className='col-span-4 border-r border-black flex items-center text-left p-2'>
                            Scheduled banks willing to invest in non-listed special purpose funds (Special Purpose Vehicle, AIF registered with BSEC) are  allowed to invest up to BDT 2 billion (USD 23.53 million) in such funds.
                        </div>
                        <div className='col-span-2 flex items-center text-left p-2'>
                            Opportunity for further growth of impact funds and impact enterprises
                        </div>
                    </div>
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.bb.org.bd/mediaroom/circulars/fid/mar302016dfim04e.pdf" target='_blank' className='font-bold text-primary underline  decoration-1'>
                                Circular Regarding Investment in Non-listed Special
                                Purpose Funds by NBFIs
                            </a>
                        </div>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                            March 30, 2016
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            Department of Financial Institutions and Markets, Bangladesh Bank
                        </div>
                        <div className='col-span-4 border-r border-black flex items-center text-left p-2'>
                            <div>
                                <div className='p-2'>
                                    NBFIs willing to invest in non-listed special purpose fund/funds (Special Purpose Vehicle, AIF registered with BSEC) are allowed to do so following specific guidelines –
                                    <ul>
                                        <li>The aggregate investment in such funds made by any FI shall not exceed 50% of its paid-up capital;</li>
                                        <li>The investment in such a single fund made by any FI shall not exceed 10 percent of its paid-up capital or 20% of that particular fund, whichever is lower.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2  flex items-center text-left p-2'>
                            Opportunity for further growth of impact funds and impact enterprises
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
