import React from 'react';
import { Button } from '../../shared/ui/Button';
import { Link } from "react-router-dom";

export default function Admin() {
    return (
        <div className='min-h-screen w-full flex flex-col justify-center items-center'>
            <div className='flex gap-4'>
                <Link to="/admin/join-form-table">
                    <Button>Join Form Table</Button>
                </Link>
                <Link to="/admin/contact-table">
                    <Button>Contact Table</Button>
                </Link>
            </div>
        </div>
    )
}
