import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ImpactAuditDetails from "./component/ImpactAuditDetails";

export default function ImpactAudit() {
  return (
    <Layout>
      <NewsHeader>Impact Auditing</NewsHeader>
      <ImpactAuditDetails />
    </Layout>
  );
}
