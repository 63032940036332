import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

import Slider from "react-slick";
import { newsData } from "../../NewsEvents/Components/AllNews";

export default function LatestNews() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="bg-primary text-white py-20 -z-10 px-8">
      <div className="max-w-6xl mx-auto ">
        {
          <Slider {...settings}>
            {newsData?.slice(0, 12)?.map((news) => (
              <div className="flex flex-col md:flex-row items-center gap-6 fl">
                <div className="flex-1">
                  <img
                    src={news?.image}
                    className="rounded-md w-full"
                    alt="impact img"
                  />
                </div>
                <div className="flex-1">
                  <h2 className="text-xl md:text-4xl font-bold pb-4">
                    {news?.title}
                  </h2>
                  <p className="pb-6">{news?.description}</p>
                  <Link to={news?.link}>
                    <button className="bg-white text-primary px-4 py-2 rounded-md shadow-btn btn">
                      Read more
                      <FaAngleRight className="inline btn_arrow" />
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        }
      </div>
      {/* <div >
        <img src={impactarches} className='absolute top-0 left-0 right-0 w-full h-[100px]' />
    </div> */}
    </div>
  );
}
