import React from "react";
import { Link } from "react-router-dom";
import news20 from "../../../../assets/images/news20.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function NationalImpactNovemberDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news20}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Every Movement looks difficult; Every Revolution sounds impossible,
          until it happens!
        </p>
        <p className="pb-6">
          Impact Investment Movement in Bangladesh and the global Revolution for
          Impact Investment is going through the same trajectory!
        </p>
        <p className="pb-6">
          Bangladesh is actually progressed quite reasonably after getting its
          position in the global revolution as the 19th member country of the
          Global Steering Group (GSG) for Impact Investment in 2018. This was
          due to the formation of the National Advisory Board for Impact
          Investment in Bangladesh – The NAB.
        </p>
        <p className="pb-6">
          The Movement here actually started before that, when the Bangladesh
          Securities and Exchange Commission launched the Alternative Investment
          Rules to securitise Impact Fund, Venture Capital, and Private Equity
          in 2015. Followed by the first international impact investment
          conference and workshops in 2016 and 2017 by Build Bangladesh, the
          first Impact Enterprise funded by the Impress Group to commence the
          Impact Investment ball rolling on the ground. First private sector
          enterprise to have a vision to actively support people centric
          investment. With this context, focus and background, the NAB as part
          of the GSG was established five years ago in 2018. We are now ready to
          declare 10 November as the National Impact Investment Day – first for
          any country in the world! Over last five years, we have achieved few
          critical milestones and getting ready for next five years.
        </p>

        <h1 className="font-bold text-xl">Achievements</h1>

        <ol className="pl-20 pb-2 flex flex-col gap-4">
          <li>Leading the Impact Investment Movement in Bangladesh.</li>
          <li>
            Established the NAB with diversified member (18 members)
            composition, having key government agencies, regulators, development
            partners, diplomatic missions, private sectors, financial
            institution, NGOs, and business pick body.
          </li>
          <li>
            But over the journey, we also lost some of our members whose
            contributions are invaluable. We would like to remember our second
            Chair Monowar Ahmed, former Secretary of ERD, Architect Mubasher
            Hossain, former President of the Institute of Architects, and Dr
            Swapan Kumar Bala, former Commissioner of the BSEC, who are no
            longer with us but their inspiring spirit is still driving us to
            move on.
          </li>
          <li>
            We must also mention the cooperation and courageous inspiration we
            had received from our first Chair former Secretary of ERD Mr Kazi
            Shafiqul Azam. Mr Sirazul Islam, our Third Chair, his contribution
            in supporting the launch of the national strategy was unparallel and
            our current Chair Mr Lokman Hosain Miah, he is leading and
            supporting us immensely to ensure a sustainable impact investment
            movement in Bangladesh.
          </li>
          <li>
            Became the 19th member country of the Global Steering Group (GSG)
            for Impact Investment in 2018. Which now has 41 member countries.
          </li>
          <li>
            First country to translate ‘On Impact’ written by Sir Roland Cohen
            into Bangla for wider appreciation of the concept of impact
            investment in Bangladesh.
          </li>
          <li>
            Developed and published the Policy Landscape of Impact Investment
            Study in Bangladesh in 2021.
          </li>
          <li>
            Developed and launched the Bangladesh Impact Investment Strategy and
            Action Plan (BIISAP) in 2022. Followed by the Implementation Action
            Plans in 2023.
          </li>
          <li>Registered NAB Trust in 2023.</li>
          <li>
            Hosted the GSG Chief Executive Officer in Bangladesh as the keynote
            speaker at the international summit – Impact InveSTAR in 2023.
          </li>
          <li>
            Acknowledged the contributions of Impact Investment leaders in
            Bangladesh for the first time in 2023.
          </li>
          <li>
            Proactively presented the Population Weighted AUM agenda to the
            annual GSG Summit in 2023 to incorporate this as part of the tipping
            point for GSG.
          </li>
          <li>
            Gathering of brilliant minds and passionate people to support the
            impact investment movement in Bangladesh – at today’s Impact
            InveSTART! Start of a new era for mainstreaming Impact Economy in
            Bangladesh.
          </li>
          <li>
            We also acknowledge the courage and leadership offered by the
            Imparess Group particularly one of their founding Directors Mr Reaz
            Ahmed Khan to formulate Build Bangladesh, the first impact
            enterprise of the country to lead the impact investment movement was
            visionary.
          </li>
          <li>
            And finally, on 10th November 2016, former Finance Minister of
            Bangladesh Government, late Abul Maal Abdul Muhith launched the
            concept of impact investment publicly in Bangladesh. To frame that
            day as an historic milestone in our investment ecosystem calendar,
            we would like to announce 10th November as the annual National
            Impact Investment Day for Bangladesh, first in any country in the
            world.
          </li>
          <li>
            The National Impact Investment Day is not another day, it is a day
            like no other!
          </li>
        </ol>

        <ul className="pl-20 pb-10 ">
          <li>
            Day to celebrate outcomes of impact regulations, transparency,
            instruments and scale up.{" "}
          </li>
          <li>
            Day for impact awareness, accountabilities while incorporating all
            stakeholders: investors, investees, intermediaries, and market
            builders.{" "}
          </li>
          <li>
            Day to acknowledge positive contributions of the impact investment
            movement leaders.
          </li>
          <li>
            Day to share local and global learnings of impact investment
            outcomes through media, public outlets, universities, seminars,
            webinars, summits.
          </li>
          <li>
            Day to inspire future investors, innovators, local and global
            partners.
          </li>
          <li>
            Day to look through the “Build Bangladesh Star Lense” targeting:
            inclusivity, gender equality, climate change, digital innovation,
            and intergenerational wellbeing.
          </li>
          <li>
            Day of best practiced National Day, envy to the world – Bangladesh
            is the first country to launch a National Impact Investment Day.
          </li>
          <li>Congratulations NAB Bangladesh!</li>
        </ul>

        <h1 className="font-bold text-xl">Challenges </h1>

        <ol className="pl-20 pb-2 flex flex-col gap-4">
          <li>
            To engage and influence more private sector players and development
            partners.{" "}
          </li>
          <li>
            Share demonstrated examples of impact investment instruments.{" "}
          </li>
          <li>
            Influence impact transparency in association with Asia-pacific NABs
            and the ISSB.{" "}
          </li>
          <li>
            Influence untapped market as opportunities for impact investment.{" "}
          </li>
          <li>Awareness and advocacy to support people centric investment. </li>
        </ol>

        <h1 className="font-bold text-xl py-6">
          What is the significance of NAB?{" "}
        </h1>
        <p className="pb-6">
          Most of us are yet to deliver an ‘unicorn’ as per the
          traditional/western definition. But, we (the emerging market in Asia)
          never had an opportunity to influence these definitions from a global
          platform, but now we have a voice through GSG and the Impact
          Investment movement, which is about transforming people’s lives, not
          just economies. As we are aware that around 60% of the world
          population (2022) lives in Asia and the GSG, all Asian NABs, NAB Task
          Forces and the local Impact Investment movements, talks about peoples’
          wellbeing, hence, our next tipping point should reflect the number of
          people we change lives along with the number of dollars in our
          revenue. It makes us think, going forward, what if a unicorn business
          was in fact recognised for the one ‘billion’ people it may serve and
          influence, rather than its billion-dollar turnover? It is time to
          dictate our mindset to add people and planet centric investment and
          NAB Bangladesh can do it! 
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
