import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import TimeTravellersDetails from "./component/TimeTravellersDetails";

export default function TimeTravellers() {
  return (
    <Layout>
      <NewsHeader>Are they time travellers from the future?</NewsHeader>
      <TimeTravellersDetails />
    </Layout>
  );
}
