import React from "react";
import PageHeading from "../../../shared/Components/PageHeading";
// import newsandevents from "../../../assets/images/newsandevents.jpg"
import News_Events_Background from "../../../assets/images/News_Events_Background.png";

export default function NewsEventsHeaders() {
  return (
    <PageHeading bgImage={News_Events_Background}>
      <h2 className="font-bold text-4xl text-white py-10">NAB Bangladesh</h2>
    </PageHeading>
  );
}
