import React from "react";
import { FaBraille } from "react-icons/fa";
import investmentimg1 from "../../../assets/images/investmentimg1.png";
import investmentimg2 from "../../../assets/images/investmentimg2.png";
import investmentimg3 from "../../../assets/images/investmentimg3.png";
import investmentimg4 from "../../../assets/images/investmentimg4.png";

export default function ImpactDetails() {
  return (
    <div className="container mx-auto pb-20 px-8 md:px-16">
      <div className="max-w-6xl mx-auto text-center  pb-12">
        <p className="text-[16px]">
          The Government of Bangladesh defined ‘Impact Fund’ under the
          Alternative Investment Rules 2015 as follows: Impact fund means an
          alternative investment fund, which invests in equity and equity linked
          instruments of such companies, organisations, and funds, which are
          engaged in activities with the intention to generate a measurable and
          beneficial social or environmental impact in addition to financial
          returns, as justified with international recognised criteria.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-20">
        <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary flex flex-col items-center">
          <div className="h-[100px]">
            <img src={investmentimg1} className="w-full h-full text-center" />
          </div>
          <h2 className="text-xl font-bold pt-8 pb-2">Intentionality</h2>
          <p>An intention to generate social and environmental impact</p>
        </div>
        <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center">
          <div className="h-[100px]">
            <img src={investmentimg2} className="w-full h-full text-center" />
          </div>
          <h2 className="text-xl font-bold pt-8 pb-2">Return Expectation</h2>
          <p>
            The expected rate of return that can be below the average market
            level or align with the market yields
          </p>
        </div>
        <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center">
          <div className="h-[100px]">
            <img src={investmentimg3} className="w-full h-full text-center" />
          </div>
          <h2 className="text-xl font-bold pt-8 pb-2">Variety</h2>
          <p>
            The financial instruments used and of the forms of intervention
            range from debt to pure equity
          </p>
        </div>
        <div className="bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center">
          <div className="h-[100px]">
            <img src={investmentimg4} className="w-full h-full text-center" />
          </div>
          <h2 className="text-xl font-bold pt-8 pb-2">Measurability</h2>
          <p>
            Data driven impact measurement is essential for ensuring
            transparency and accountability
          </p>
        </div>
      </div>
    </div>
  );
}
