import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news127.jpg";
import news123_creator from "../../../../assets/images/news127_creator.png";
import tika_contribution1 from "../../../../assets/images/tika_contribution1.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function TurkiyesJourneyDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The journey of the Impact Investing Advisory Board (EYDK) is one of
          vision, collaboration, and innovation, marking the beginning of
          Türkiye’s significant engagement with impact investing. In a
          relatively short time, EYDK has evolved into a crucial player in
          shaping Türkiye’s impact economy and positioning the country as a
          gateway for impact investments into the broader region. This
          transformation began in 2019, amidst a growing global awareness of the
          need for more sustainable and inclusive investment practices.
        </p>

        <p className="pb-6">
          The story of EYDK began in April 2019, when the Ministry of Foreign
          Affairs of the Republic of Türkiye and the United Nations Development
          Programme (UNDP) Türkiye office took the first steps toward examining
          Türkiye’s potential in impact investment. Together, they commissioned
          a report that would lay the groundwork for understanding the country’s
          readiness to embrace this new form of investment. The report, titled
          “Impact Investment Ecosystem in Türkiye,” was prepared by the UNDP
          İstanbul International Center for Private Sector in Development
          (IICPSD) in November 2019, with the support of the Presidency of the
          Republic of Türkiye Investment Office.
        </p>

        <p className="pb-6">
          This initial publication served as a breakthrough. It introduced
          impact investment as a new business model, mapping out the
          stakeholders and outlining Türkiye’s potential to become an
          impact-driven economy. More than just a report, it symbolized a
          broader shift in thinking—one that aimed to align the country’s
          economic goals with social and environmental responsibility, and to
          direct capital toward ventures that would foster measurable positive
          impact.
        </p>

        <p className="pb-6">
          As these discussions progressed, the need for a more structured and
          strategic approach became evident. The idea of forming a national
          advisory board to coordinate efforts, raise awareness, and build
          capacity in impact investment in Türkiye started to gain traction.
          This body would act as a bridge between Türkiye and the international
          community, advocating for impact investment as a driver of sustainable
          development.
        </p>

        <p className="pb-6">
          In April 2020, as this vision began to take shape, Etkiyap—the Impact
          Investment Platform—was established. Etkiyap played a catalytic role
          in building momentum for impact investment in Türkiye. Through
          publications, events, and collaborations with both national and
          international stakeholders, Etkiyap helped raise awareness and create
          a space where conversations around impact investment could thrive. It
          was instrumental in initiating Türkiye’s impact investment journey.
        </p>
        <p className="pb-6">
          The next milestone came in November 2020, when the groundwork for the
          Impact Investment Advisory Board (EYDK) was laid. EYDK’s formation was
          a collaborative effort, drawing together key partners such as the
          Presidency of the Republic of Türkiye Investment Office, Etkiyap,
          Türkiye Development and Investment Bank (TKYB), UNDP IICPSD, and UNDP
          Türkiye. The inaugural meeting of the Task Force solidified the
          decision to establish EYDK, with Etkiyap coordinating the process and
          ensuring that it met the criteria of the GSG Impact, headquartered in
          London.
        </p>
        <p className="pb-6">
          Throughout 2020, Etkiyap facilitated conversations with the GSG Impact
          and other member countries, shaping a strategy for EYDK’s official
          launch. By February 2021, the strategy document, legal status,
          operational plan, communication framework, and budget were finalized
          and approved by the Task Force. In March 2021, after extensive
          stakeholder consultations, the list of invited institutions was
          completed, and on April 28, 2021, EYDK was officially established.
        </p>
        <p className="pb-6">
          From the moment of its establishment, EYDK took bold steps to fulfill
          its mandate. It engaged stakeholders across the ecosystem, compiled
          inclusive and data-driven reports, and aligned its efforts with
          Türkiye’s strategic priorities. Over the first two years, EYDK made
          significant progress in policy development, market expansion, and
          creating a common language for impact investment. By focusing on
          research and fostering dialogue, EYDK not only supported the growth of
          impact investment in Türkiye but also paved the way for global
          engagement.
        </p>
        <p className="pb-6">
          In March 2022, EYDK achieved a major milestone when it joined the GSG
          Impact after completing a thorough due diligence process. This
          membership established EYDK as a key player in the global impact
          investment community, enabling it to connect Türkiye with
          international developments in the field. EYDK became a crucial bridge,
          sharing best practices from Türkiye with the world and, in turn,
          attracting international capital to support local impact initiatives.
        </p>
        <p className="pb-6">
          The next chapter in EYDK’s journey came in 2023, when it proudly
          hosted the GSG Leadership Summit in Istanbul. This event was not only
          a testament to Türkiye’s growing role in the global impact investment
          ecosystem but also a significant opportunity to highlight Türkiye’s
          potential as a hub for impact investment. Positioned at the crossroads
          of the Middle East, North Africa, Eastern Europe, and Central Asia,
          Türkiye is uniquely situated to channel impact investments into these
          regions, and EYDK took the lead in showcasing this potential to the
          world.
        </p>
        <p className="pb-6">
          By 2023, EYDK had already witnessed key milestones in Türkiye’s
          transition to an impact-focused economy. The establishment of impact
          funds, the issuance of Türkiye’s first social impact bond, and the
          launch of impact-oriented fund of funds were signs of a promising
          future. These achievements underscored the progress that could be made
          when diverse stakeholders come together with a shared vision of
          creating measurable social and environmental impact.
        </p>
        <p className="pb-6">
          In November 2023, EYDK entered a new phase of its journey by
          transitioning into a more formal structure as the “EYDK Association.”
          This marked the end of its incubation period and the beginning of its
          institutionalization. With a stronger organizational foundation, EYDK
          continues to work toward creating an enabling environment for impact
          investment in Türkiye. Its mission is clear: to foster an inclusive,
          innovative, and impact-focused economy that generates tangible social
          and environmental benefits.
        </p>
        <p className="pb-6">
          EYDK’s story is one of swift progress and ambitious goals. In just a
          few years, it has laid the foundation for Türkiye’s impact investment
          ecosystem, engaging national and international partners and
          positioning the country as a gateway for regional impact investment.
          As EYDK continues to grow, its mission and vision signal a promising
          future for impact investment in Türkiye, where sustainable and
          inclusive investments become a mainstream choice.
        </p>
        <p className="pb-6">
          Through collaboration and innovation, EYDK aims to strengthen
          Türkiye’s role in the global impact economy, ensuring that the
          country’s transition to an impact-focused economy benefits not only
          its people but also the wider region.
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Şafak Müderrisgil</p>
            <p>Chair</p>
            <p>EYDK</p>
            <p>Impact Investing Advisory Board</p>
            <p> GSG National Partner, Türkiye</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
