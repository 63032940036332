import React from 'react'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import KeyPolicyFirst from './KeyPolicyFirst'
import KeyPolicySecond from './KeyPolicySecond'
import KeyPolicyThird from './KeyPolicyThird'

export default function KeyPolicies() {
    return (
        <div className='flex flex-col gap-10'>
            <KeyPolicyFirst />
            <KeyPolicySecond />
            <KeyPolicyThird/>
        </div>
    )
}
