import React from "react";
import nabLogo from "../../../assets/images/NAB_color_logo.png";
import GSG from "../../../assets/images/GSG_LOGO_RGB_Lilac-Navy.png";
import { Button } from "../../../shared/ui/Button";
import { Link } from "react-router-dom";

export default function WhoWeAre() {
  return (
    <div className="max-w-5xl mx-auto py-16 px-8">
      <div className="flex flex-col md:flex-row gap-8 mb-16 items-center">
        <div className="flex-1">
          <img src={nabLogo} className="max-w-[300px]" alt="Nab Bangladesh" />
        </div>
        <div className="flex-1">
          <h2 className="text-2xl font-bold pb-4">Who we are?</h2>
          <p className="pb-6">
            The National Advisory Board (NAB) acts as the advisory committee to
            guide the development of Impact Investment in Bangladesh. It sets
            the strategic direction and fosters collaborative relationships. It
            also attempts to resolve all issues raised within the Impact
            Investment Ecosystem including the reporting process. It is one of
            the GSG National Partners forming the GSG Impact Partnership, a
            global community committed to delivering positive impact at scale
            around the world.
          </p>
          <Link to="/nab-bangladesh">
            <Button>Read more</Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-8 mb-16 items-center">
        <div className="flex-1">
          <img src={GSG} className="max-w-[300px]" alt="GSG Logo" />
        </div>
        <div className="flex-1">
          <p className="pb-6">
            In October 2018, NAB Bangladesh was awarded membership as the GSG
            National Partner from GSG Impact during its annual summit in New
            Delhi. Bangladesh became the 19th member of the GSG Impact
            Partnership connected to its global network of GSG National
            Partners.
          </p>
          <Link to="https://gsgii.org/" target="_blank">
            <Button>Read more</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
