import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import MizohuThinksDetails from "./components/MizohuThinksDetails";

export default function MizohuThinks() {
  return (
    <Layout>
      <NewsHeader>Mizuho Thinks in Impact</NewsHeader>
      <MizohuThinksDetails />
    </Layout>
  );
}
