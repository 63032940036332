import React, { useState } from 'react'
import AllNews from './AllNews';
import AllEvents from './AllEvents';

export default function NewsAndEvents() {
    let [whichPortion, setWhichPortion] = useState("News");
    return (
        <div className='py-20 bg-[#FDFAF6] px-4'>
            <div className='max-w-3xl mx-auto'>
                <div className='grid grid-cols-2 items-center border-b border-black '>
                    <div className={`border p-6 text-xl text-center font-bold rounded-t-md cursor-pointer ${whichPortion === "News" ?  "bg-white  border-[#16A085] border-b-4" : ""}`} onClick={() => setWhichPortion("News")}>
                        News
                    </div>
                    <div className={`border p-6 text-xl text-center font-bold rounded-t-md cursor-pointer ${whichPortion === "Events" ?  "bg-white  border-[#16A085] border-b-4" : ""}`} onClick={() => setWhichPortion("Events")}>
                        Events
                    </div>
                </div>
            </div>

            {
                whichPortion === "News" && <AllNews />
            }
            {
                whichPortion === "Events" && <AllEvents />
            }
        </div>
    )
}
