import React from "react";
import heroVideo from "../../../assets/videos/hero_video.mp4";

export default function Hero() {
  return (
    <section className="min-h-[calc(100vh_-_143px)] bg-red-200 relative flex items-center justify-center mb-12 overflow-hidden -z-10">
      <div className="relative z-30 p-5 text-2xl md:text-5xl text-white text-center font-bold">
        <h1 className="pb-2">National Advisory Board for</h1>
        <h1 className="pb-2">Impact Investment</h1>
        <h1>in Bangladesh</h1>
      </div>
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 z-10 w-auto min-w-full min-h-full max-w-none "
      >
        <source src={heroVideo} type="video/mp4" />
        <source src={heroVideo} type="video/webm" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute z-20 w-auto min-w-full min-h-full max-w-none bg-primary opacity-60"></div>
    </section>
  );
}
