import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function PrivateSector() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Private Sector</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-7  border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-2 border-r border-b border-black flex items-center text-left p-2'>
                            Roots of Impact (ROI)
                        </div>
                        <div className='col-span-7 border-b border-black flex items-center text-left p-2'>
                            <div>
                                <a href="https://www.sie-b.org/" target='_blank' className=' text-primary underline  decoration-1'>
                                    Biniyog Briddhi:
                                </a>
                                Strives to improve the financial, social and environmental performance of impact enterprises by supporting them to master investment readiness and impact management. Brings together investors, incubators, accelerators, and support organizations.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <p className='center'>Private Sector</p>
                        </div>
                        <div className='col-span-2 border-r border-b border-black border-black flex items-center text-left p-2'>
                            Legal Circle
                        </div>
                        <div className='col-span-7 border-b border-black border-black flex items-center text-left p-2'>
                            <div>
                                Through a network of experienced legal professionals, the <span><a href="https://legalcirclebd.com/" target='_blank' className=' text-primary underline  decoration-1'>
                                    Legal Circle
                                </a> </span>
                                provides specialized and tailored legal solutions to ventures and represents Bangladeshi companies in domestic and international legal matters etc. The organization has provided legal support to Startup Bangladesh Limited and Biniyog Briddhi Programme. The Legal Circle’s rich and relevant experience in investment due-diligence could be leveraged in drafting impact investing due-diligence guideline for Bangladesh.
                            </div>

                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-2 border-r  border-black border-black flex items-center text-left p-2'>
                            LightCastle Partners (LCP)
                        </div>
                        <div className='col-span-7  border-black border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://www.lightcastlebd.com/news/smartcap-3rd-batch-roadshow-program/#method-grow-business" target='_blank' className=' text-primary underline  decoration-1'>
                                    SmartCap:
                                </a> </span>
                                Accelerator for SMEs that lack financial accessibility and business know-how despite having growth opportunities. It weaves business pedagogy, market connections, financing, & technology together.  <span><a href="https://www.lightcastlebd.com/acceleration/projects/third-cohort-of-acdi-voca-unnoty-accelerator-program-completed/" target='_blank' className=' text-primary underline  decoration-1'>
                                Unnoty:
                                </a> </span> In partnership with ACDI/VOCA, Unnoty provides business development support via accelerator programs and investment readiness training to women agri-entrepreneurs, including product matrix, business model canvas, financial governance and management, branding & sales, and modern Ag-Tech.
                            </div>
                        </div>

                    </div>






                </div>
            </div>
        </div>
    )
}
