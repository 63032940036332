import React from 'react'
import Layout from '../../shared/Layout/Layout'
import LearningHubHeader from './Components/LearningHubHeader'
import LearningData from './Components/LearningData'

export default function LearningHub() {
  return (
    <Layout>
        <LearningHubHeader />
        <LearningData />
    </Layout>
  )
}
