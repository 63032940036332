import React from 'react'
import PageHeading from '../../../shared/Components/PageHeading'
import nabBangladeshBg from "../../../assets/images/NAB-Bangladesh_bg.jpg"

export default function NabBangladeshHeader() {
    return (
        <PageHeading bgImage={nabBangladeshBg}>
            <h2 className='font-bold text-4xl text-white py-10'>NAB Bangladesh</h2>
        </PageHeading>
    )
}
