import React from 'react'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import Regulators from './Regulators'
import Association from './Association'
import Accelerators from './Accelerators'
import DevelopmentPartners from './DevelopmentPartners'
import PrivateSector from './PrivateSector'
import Academia from './Academia'
import ChamberOfCommerce from './ChamberOfCommerce'


export default function KeyEcosystems() {
  return (
    <div className='flex flex-col gap-10'>
            <Regulators/>
            <DevelopmentPartners/>
            <Association/>
            <Accelerators/>
            <PrivateSector/>
            <Academia/>
            <ChamberOfCommerce/>
           
        </div>
  )
}
