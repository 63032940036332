import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ImpactInvestartDetails from "./components/ImpactInvestartDetails";

export default function ImpactInvestart() {
  return (
    <Layout>
      <NewsHeader>
        Impact InveSTART And Bridging The GAP ‘First Cut’ – Findings
      </NewsHeader>
      <ImpactInvestartDetails />
    </Layout>
  );
}
