import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function ChamberOfCommerce() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Chamber Of Commerce</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-7 border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <p className='bottom'>Chambers of Commerce</p>
                        </div>
                        <div className='col-span-2 border-r border-b border-black flex items-center text-left p-2'>
                            Federation of Bangladesh Chambers of Commerce and Industry (FBCCI)
                        </div>
                        <div className='col-span-7 border-b border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://fbcci.org/" target='_blank' className=' text-primary underline  decoration-1'>
                                    FBCCI
                                </a> </span>
                                serves as the apex trade organization in the country and provides consultative and advisory support to the private sector. The chamber lobbies to safeguard the rights and interests of private sector players.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-2 border-r border-black border-black flex items-center text-left p-2'>
                            Metro Chamber of Commerce and Industries (MCCI)
                        </div>
                        <div className='col-span-7  border-black border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://mccibd.org/" target='_blank' className=' text-primary underline  decoration-1'>
                                    MCCI
                                </a> </span>
                                engages public sector corporations and local and multinational companies and provides services in the areas of taxation, import-export, tariff and non-tariff measures, investment, WTO matters and other national and international economic and commercial concerns. The chamber not only maintains working relations with development partners such as serves as World Bank Group, Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ), ADB, Japan External Trade Organization (JETRO), JICA, the Asia Foundation, etc. but also forms advisory panels and committees under various ministries of the government of Bangladesh.
                            </div>
                        </div>

                    </div>







                </div>
            </div>
        </div>
    )
}
