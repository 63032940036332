import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import LaunchImpactInvestmentDetails from "./component/LaunchImpactInvestmentDetails";

export default function LaunchImpactInvestment() {
  return (
    <Layout>
      <NewsHeader>Launch of Impact Investment 10 Nov 2016</NewsHeader>
      <LaunchImpactInvestmentDetails />
    </Layout>
  );
}
