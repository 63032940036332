import React from 'react'
import Layout from '../../shared/Layout/Layout'
import JoinNabBdHeader from './Components/JoinNabBdHeader'
import JoinNabBdForm from './Components/JoinNabBdForm'

export default function JoinNabBangladesh() {
  return (
    <Layout>
        <JoinNabBdHeader />
        <JoinNabBdForm />
    </Layout>
  )
}
