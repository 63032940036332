import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import BridgingGapDetails from "./component/BridgingGapDetails";

export default function BridgingGap() {
  return (
    <Layout>
      <NewsHeader>Bridging The Gap</NewsHeader>
      <BridgingGapDetails />
    </Layout>
  );
}
