import React, { useState } from "react";
import nabColorLogo from "../../assets/images/NAB_color_logo.png";
import nabColorLogoCropped from "../../assets/images/NAB-color-logo-cropped.png";
import GSG_LOGO from "../../assets/images/GSG_LOGO_PARTNERSHIP_RGB_Lilac_Navy.png";
import {
  FaTwitter,
  FaAngleDown,
  FaServer,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false);
  return (
    <div className="bg-white shadow-md md:sticky top-0 left-0 right-0 z-50">
      <div className="container mx-auto bg-white py-8">
        <div className="flex  gap-2 items-center justify-between px-16">
          <div>
            <Link to="/">
              <div className="flex gap-6 items-center">
                <img
                  src={nabColorLogo}
                  className="hidden md:flex w-[140px] md:h-auto"
                  alt="Nab Bangladesh"
                />
                <img
                  src={nabColorLogoCropped}
                  className="w-[60px] h-auto md:hidden"
                  alt="Nab Bangladesh"
                />
                <img src={GSG_LOGO} className="w-[140px] h-auto" alt="GSG" />
              </div>
            </Link>
          </div>

          <FaServer
            className="absolute top-[45px] right-[15px] w-8 h-8 md:hidden cursor-pointer"
            onClick={() => setShowNavbar(!showNavbar)}
          />

          <FaServer
            className="absolute top-[45px] right-[15px] w-8 h-8 md:hidden cursor-pointer"
            onClick={() => setShowNavbar(true)}
          />
          <div
            className={`${
              showNavbar ? "" : "hidden"
            } absolute bg-white border-t-2 border-primary md:border-none p-4 top-[80px] right-0 px-20 md:px-0 md:flex flex-col md:flex-row gap-6 md:self-start md:relative md:top-0 `}
          >
            <div className="relative">
              <p class=" peer font-semibold text-[12px] flex items-center gap-1 cursor-pointer">
                About
                <FaAngleDown />
              </p>
              <div
                className={`z-10 hidden  peer-hover:flex hover:flex  absolute top-[20px] left-0 bg-white divide-y divide-gray-100  shadow w-56 p-4 border-t-4 border-primary`}
              >
                <div>
                  <Link to="/impact-inveseting">
                    <p className="text-[12px] hover:bg-gray-100 p-2 cursor-pointer">
                      Impact Investment
                    </p>
                  </Link>
                  <Link to="/nab-bangladesh">
                    <p className="text-[12px] hover:bg-gray-100 p-2 cursor-pointer">
                      NAB Bangladesh
                    </p>
                  </Link>
                  <Link to="/impact-investing-in-bangladesh">
                    <p className="text-[12px] hover:bg-gray-100 p-2 cursor-pointer">
                      Impact Investment in Bangladesh
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="relative">
              <p class=" peer font-semibold text-[12px] flex items-center gap-1 cursor-pointer">
                Membership
                <FaAngleDown />
              </p>
              <div
                className={`z-10 hidden  peer-hover:flex hover:flex  absolute top-[20px] left-0 bg-white divide-y divide-gray-100  shadow w-56 p-4 border-t-4 border-primary`}
              >
                <div>
                  <Link to="/join-nab-bangladesh">
                    <p className="text-[12px] hover:bg-gray-100 p-2 cursor-pointer w-full">
                      Join NAB Bangladesh
                    </p>
                  </Link>
                  <Link to="/our-people">
                    <p className="text-[12px] hover:bg-gray-100 p-2 cursor-pointer">
                      Our People
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="relative">
              <p class=" peer font-semibold text-[12px] flex items-center gap-1 cursor-pointer">
                Resources
                <FaAngleDown />
              </p>
              <div
                className={`z-10 hidden  peer-hover:flex hover:flex  absolute top-[20px] left-0 bg-white divide-y divide-gray-100  shadow w-56 p-4 border-t-4 border-primary`}
              >
                <div>
                  <Link to="/learning-hub">
                    <p className="text-[12px] hover:bg-gray-100 p-2 cursor-pointer">
                      Learning Hub
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <Link to="/news-events">
              <p className="font-semibold text-[12px]">News & Events</p>
            </Link>
            <Link to="/contact-us">
              <p className="font-semibold text-[12px]">Contact us</p>
            </Link>
          </div>

          <div className="hidden md:flex gap-2 items-center ">
            <a
              href="https://www.facebook.com/nabbangladesh/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-[#3B5998] p-2 rounded-full cursor-pointer">
                <FaFacebookF className="w-4 h-4 fill-[#fff]" />
              </div>
            </a>

            <a
              href="https://www.linkedin.com/company/nab-bangladesh/about/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-[#007BB6] p-2 rounded-full cursor-pointer">
                <FaLinkedinIn className="w-4 h-4 fill-[#fff]" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
