import React from "react";
import { Link } from "react-router-dom";
import news7 from "../../../../assets/images/news7.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function PartnershipDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news7}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          National Advisory Board (NAB) for Impact Investment in Bangladesh
          recently collaborated with Impact Investing Advisory Board of Türkiye
          (EYDK) for “Venture Meetings” Project. It is a virtual, periodical
          series of convening meetings of Turkish and global VCs and thematic
          startups to exchange ideas and form new partnerships. 
        </p>

        <p className="pb-10">
          The CEO of NAB Bangladesh, Architect Farhadur Reza, and the Executive
          Director of Build Bangladesh, Mr. Erad Kawsar, joined the second
          convening to share the impact stories from Bangladesh. Additionally,
          Bhalo, an impact enterprise that serves as a marketplace for linking
          small farmers with high-quality input providers, was nominated by the
          NAB Bangladesh to present its business case to the global audience.
          The event resulted in a possible collaboration and partnership between
          Bhalo and AgroCasco, an insurtech from Türkiye that provides
          best-in-class parametric insurance products in the agriculture
          industry
        </p>
        <p className="pb-10">
          This second meeting was attended by a number of distinguished guests,
          including Ambassador Mustafa Osman Turan, Turkish Ambassador to
          Bangladesh and Ms Şafak Müderrisgil, Chair of the Turkish NAB.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
