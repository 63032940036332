import React from "react";
import { nabTrust } from "../Data";

export default function NabTrust({ boardRef, buttonTrigger }) {
  return (
    <div ref={buttonTrigger === "trust" ? boardRef : null}>
      <h1 className="text-center py-20 font-bold text-3xl">NAB Trust</h1>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-16 px-8 md:px-16">
        {nabTrust?.map((boardMember) => (
          <div className="bg-white  flex flex-col justify-between  shadow-card">
            <div className="p-2">
              <img
                src={boardMember?.image}
                className="w-full h-[320px] object-contain"
                title={boardMember?.name}
              />
            </div>
            <div className="min-h-[100px] px-4 text-center">
              <h2 className="font-bold text-xl">
                {boardMember?.name ? boardMember?.name : " "}
              </h2>
              <h2>{boardMember?.extraInfo ? boardMember?.extraInfo : " "}</h2>
              <h2>
                {boardMember?.designation ? boardMember?.designation : " "}
              </h2>
            </div>
            <div className="bg-primary text-center text-xs text-white min-h-[70px] flex justify-center items-center">
              {boardMember?.from}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
