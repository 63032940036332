import React from "react";
import NewsHeader from "../../../shared/Components/NewsHeader";
import Layout from "../../../shared/Layout/Layout";
import ShowingTheSeedsDetails from "./components/ShowingTheSeedsDetails";

export default function ShowingTheSeeds() {
  return (
    <Layout>
      <NewsHeader>
        Sowing the Seeds of Impact: My Journey in the Bangladeshi Impact
        Investment Ecosystem
      </NewsHeader>
      <ShowingTheSeedsDetails />
    </Layout>
  );
}
