import React from "react";
import { Link } from "react-router-dom";
import nii2024 from "../../../../assets/images/nii2024.jpg";
import news29_details1 from "../../../../assets/images/news29_details1.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function NationalImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={nii2024}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The Bangladesh impact investment ecosystem is increasingly gaining
          enthusiasm and commitment from across the stakeholder groups. This
          momentum of the impact investing sector is aligned with the growing
          wave of impact investing trend dispersing globally. The Bangladesh
          impact sector has witnessed noteworthy milestones since 2015; however,
          strides have to be made to realise the vision for impact investment.
          Impact Investment movement in Bangladesh is actually progressed quite
          reasonably after getting its position in the global revolution as the
          19th member country of the Global Steering Group (GSG){" "}
          <a
            href="https://gsgii.org/"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            (https://gsgii.org/)
          </a>{" "}
          for Impact Investment in 2018. This was also possible due to the
          formation of the National Advisory Board for Impact Investment in
          Bangladesh – The NAB, in 2018{" "}
          <a
            href="https://www.nabbangladesh.org/"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            (https://www.nabbangladesh.org/)
          </a>
          .
        </p>
        <p className="pb-6">
          The movement to uplift Impact Investment as one of the mainstream
          investment instruments, required to have a comprehensive understanding
          of the current global and local investment ecosystem. There is also a
          need to address the current local challenges and way forward to
          inspire more stakeholders to complement impact investment. Hence, the
          National Advisory Board (NAB) for Impact Investment in Bangladesh is
          hosting the first National Impact Investment Day – Impact InvesTIME in
          November 2024.
        </p>
        <p className="pb-6">
          On 10th November 2016, former Finance Minister of Bangladesh
          Government, late Abul Maal Abdul Muhith launched the concept of impact
          investment publicly in Bangladesh. To frame that day as an historic
          milestone in our investment ecosystem calendar, the NAB has declared
          10th November as the National Impact Investment Day. This will also
          inspire the momentum of the Bangladesh Impact Investment Strategy and
          Action Plan (BIISAP) implementation and influence more impact
          investment stakeholders to join the movement. The Bangladesh
          Government Department’s like the Bangladesh Investment Development
          Authority (BIDA), Bangladesh Securities and Exchange Commission
          (BSEC), Startup Bangladesh, multilateral development partners like
          UNDP, JICA, Swiss Development Cooperation, Turkish International
          Cooperation Agency, Truvalu, World Vision, BRAC, Sajida Foundation,
          Turkish National Advisory Board, and the Global Steering Group (GSG)
          for Impact Investment have agreed to be partner to this event in
          principle.
        </p>
        <p className="pb-6">
          One of the recent milestones achieved to complement the BIISAP
          implementation plan, was to host Mr Cliff Prior, CEO of the GSG as the
          keynote speaker at the Impact InveSTAR in Bangladesh in June 2023.
          Impact InveSTAR was designed to acknowledge and appreciate works of
          the impact investment ecosystem builders, the ‘STARS’ shining on the
          impact investment sky over Bangladesh. In addition, the Bangladesh
          NAB, at the GSG Summit in October 2023, has presented the concept of
          Population Weighted Asset Under Management (AUM) to be considered
          along with the next tipping point of raising/allocating 10% of all AUM
          as impact investment. This was followed by the Impact InveSTART, as
          part of the Bangladesh Impact Investment Strategy and Action Plan
          (BIISAP) implementation. The one and half day long programme has been
          successfully implemented as an international impact investment summit
          on the first day and an interactive workshop with the private sector
          peak bodies and relevant stakeholders on the second day in partnership
          between NAB Bangladesh and the BSEC.
        </p>
        <p className="pb-6">
          The focus for both the days was to highlight the Impact Investment[1]
          progress in Bangladesh and guide the way forward in line with key
          investment instruments such as Macro Investment[2], Capital
          Markets[3], Outcome Based Finance[4], Social Impact Bond[5], Blended
          Finance[6], Catalytic Funding[7], Micro Impact Investment[8] etc. The
          discussion sessions were designed to promote opportunities in
          Bangladesh for sustainable investment in partnership with local and
          global stakeholders. The summit and the workshop also explored
          opportunities to influence untapped sources of funds to reinforce the
          impact investment market in Bangladesh.
        </p>
        <p className="pb-6">
          As part of the natural progression, the NAB has now planned to invite
          local and global experts on mainstream investment instruments to
          inspire the ongoing impact investment movement in Bangladesh. The
          proposed Impact InvesTIME will be a festive gatherings of investment
          experts and impact entrepreneurs to guide the way forward to identify
          Bangladesh as the key impact investment destination for 21st century
          and beyond.
        </p>
        <p className="font-bold underline pb-6">Brief</p>
        <p className=" pb-6">
          As part of its ongoing commitment to lead the Impact Investment
          Ecosystem building movement in Bangladesh since 2018, the NAB
          Bangladesh is celebrating the global first National Impact Investment
          Day in Bangladesh –{" "}
          <span className="font-bold">Impact InvesTIME</span>.
        </p>
        <p className=" pb-6">
          The NAB Bangladesh will approach the Finance Minister, Minister for
          Planning, Minister for Women Affairs, Private Sector and Business
          Advisor to the Prime Minister, Governor of Bangladesh Bank and senior
          executives from multilateral development partners, diplomatic
          missions, financial institutions, and business peak bodies to attend
          the event. Special attention will be given to attract impact
          entrepreneurs from across Bangladesh, the Asia-Pacific region and
          relevant expatriate Bangladeshis from across the globe to attend the
          day.
        </p>
        <p className=" pb-6">
          In addition, NAB will approach the GSG Chair and President to attend
          this global first National Impact Investment Day along with NAB Senior
          Executives from other countries from across the globe with particular
          focus on the Asia-Pacific NAB network.
        </p>
        <p className=" pb-6">
          The celebration will continue over three days between Friday 8th
          November and Sunday 10th November 2024. In addition to the key theme
          of ‘Mainstreaming Impact Investment’, the National Impact Investment
          Day will,
        </p>
        <ul className="pl-20 pb-10">
          <li>
            celebrate outcomes of impact regulations, transparency, instruments
            and scale up.
          </li>
          <li>
            share the number of people, demographic cohorts, and the environment
            received benefits from this Impact Investment movement.
          </li>
          <li>
            Demonstrate impact accountabilities incorporating all stakeholders:
            investors, investees, intermediaries, and market builders.
          </li>
          <li>
            acknowledge positive contributions of the impact investment movement
            leaders.
          </li>
          <li>
            share local and global learnings of impact investment outcomes
            through media, public outlets, universities, seminars, webinars,
            summits.
          </li>
          <li>
            inspire future investors, innovators, local and global partners.
          </li>
          <li>
            look through the Build Bangladesh “Star Lense”: inclusivity, gender
            equality, climate change, digital innovation, and intergenerational
            wellbeing.
          </li>
        </ul>
        <p className="pb-6">
          In addition, Monday 11th November the National Advisory Board (NAB)
          for Impact investment in Bangladesh will organise lecture sessions at
          few Universities School of Business to share Impact InvesTIME. Some of
          our prospective overseas guest have expressed interest to share their
          views at these lectures. We will also organise few one-to-one meetings
          between interested overseas guests/investors and relevant local
          regulators, peak private bodies, impact entrepreneurs, financial
          institutions including alternative asset managers as required. Prior,
          during and after the celebration, comprehensive exposure to
          electronic, print, and social media will be a strong component for the
          National Day to remember and reinforcing awareness.
        </p>
        <p className="pb-6">Come join us, its Impact InvesTIME!</p>

        <ul className="pl-20 py-10">
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref1"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [1]
            </a>{" "}
            Impact investing is an investment that seeks to generate financial
            returns while also creating a positive social or environmental
            impact.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref2"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [2]
            </a>{" "}
            Macro investing or “global-macro investing” refers to investing
            based on global economic patterns, including but not limited to
            which reserve assets have performed and are predicted to perform the
            best in the face of economic chaos.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref3"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [3]
            </a>{" "}
            Capital markets are financial markets that bring buyers and sellers
            together to trade stocks, bonds, currencies, and other financial
            assets.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref4"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [4]
            </a>{" "}
            Outcome Based Finance, the money is invested through a service
            provider for the period of service provision while the outcome payer
            repays when the agreed-upon outcomes are delivered.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref5"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [5]
            </a>{" "}
            A social impact bond (SIB) is a contract with the public sector or
            governing authority, whereby it pays for better social outcomes in
            certain areas and passes on part of the savings achieved to
            investors.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref6"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [6]
            </a>{" "}
            Blended finance is the strategic use of development finance for the
            mobilisation of additional finance towards sustainable development
            in developing countries. It attracts commercial capital towards
            projects that contribute to sustainable development, while providing
            financial returns to investors. This innovative approach helps
            enlarge the total amount of resources available to developing
            countries, complementing their own investments and ODA inflows to
            fill their SDG financing gap, and support the implementation of the
            Paris Agreement.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref7"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [7]
            </a>{" "}
            Catalytic funding is the provision of resources to an investment
            fund or vehicle designed to mitigate risk and improve the fund’s
            overall viability to attract new investment for development goals.
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/28/impact-investime-national-impact-investment-day-2024/#_ftnref8"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [8]
            </a>{" "}
            Micro Impact Investment is designed to empower recipients with small
            investment to transform investee to investors through ongoing
            engagement and literacy programme to ensure social and financial
            security.
          </li>
        </ul>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
