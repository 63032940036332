import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function Association() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Association</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-8 border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-11 text-center border-black bg-[#FAFAFA] '>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                            Association
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            Bangladesh Women Chamber of Commerce and Industries (BWCCI)
                        </div>
                        <div className='col-span-8 border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://bwcci-bd.org/" target='_blank' className=' text-primary underline  decoration-1'>
                                    BWCCI  
                                </a> </span>
                                 is a non-profit, non-political organization that works towards achieving women’s economic and social empowerment. Through a stream of activities, BWCCI aims to increase women’s participation in the private sector and to create a business environment that is conducive for women entrepreneurs. The organization actively lobbies for protecting the existing opportunities and expand the range of support offered to women entrepreneurs across the country.
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}
