import React from 'react'
import PageHeading from '../../../shared/Components/PageHeading'
import JoinNABBangladesh_bg from "../../../assets/images/Join-NAB-Bangladesh_bg.jpg";

export default function JoinNabBdHeader() {
    return (
        <PageHeading bgImage={JoinNABBangladesh_bg}>
            <h2 className='font-bold text-4xl text-white py-10'>Join NAB Bangladesh</h2>
        </PageHeading>
    )
}
