import React from "react";
import { Link } from "react-router-dom";
import news28 from "../../../../assets/images/news28.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function FirstFundRaisingDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news28}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Build Bangladesh, being the lead in promoting impact investment in
          Bangladesh since 2016 launched its first Impact Investment fund
          raising initiative in 2017 partnering with UNDP. The partnership was
          made official through signing of an MoU between Build Bangladesh and
          UNDP and by launching the ‘Build Bangladesh SDG Impact Investment’
          initiative. The Impact Investment fund raising initiative aimed to
          bring together the public and private sector to address the financing
          gaps for achieving the Sustainable Development Goals (SDGs). It also
          aimed to help facilitate the transition from grant-only development
          projects, to blended finance models that can leverage capital markets.
          To date it has raised around US$15 million (around 126 crore taka)
          from private sector investors, individual investors, high net worth
          individual and philanthropists. The key investment has been raised to
          establish the Impact Investment ecosystem in Bangladesh, encouraging
          micro investment among the lower income people, supporting expansion
          of effective healthcare services including health tech and finally to
          ensure intergenerational wellbeing. Build Bangladesh is currently
          raising US$3.5 million impact investment to fund its first affordable
          housing project for lower-middle income group in Bangladesh. The
          partnership between Build Bangladesh and UNDP allowed the investments
          to uniquely designed for Bangladesh with an efficient monitoring and
          impact measuring structure in place with positive social and
          environmental impact while generating financial returns.
        </p>
        <div className="py-4">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/cEGC82h3w18?si=uv1E2kpKbxWcyfeu"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="aspect-video"
          ></iframe>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
