import React from "react";
import { Link } from "react-router-dom";
import hnb from "../../../../assets/images/hnb.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function HnbAndLiinDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={hnb}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          National Advisory Board for Impact Investing in Sri Lanka (NABIISL),
          operating under the auspices of Lanka Impact Investing Network (LIIN),
          has joined forces with Hatton National Bank (HNB), a leading banking
          institution committed to fostering economic growth.
        </p>
        <p className="pb-6">
          The collaboration aims to identify and support Small and Medium-sized
          Enterprises (SMEs) for equity investments through the Social
          Enterprise Fund (SEF), marking a significant milestone in the
          development of Sri Lanka’s impact investing ecosystem.
        </p>
        <p className="pb-6">
          Sri Lanka’s pioneering impact investment firm, LIIN, is dedicated to
          directing private capital towards addressing critical social and
          environmental issues in the country. The collaboration with HNB aligns
          with LIIN’s mission and underscores the commitment to building a more
          dynamic impact investing landscape.
        </p>
        <p className="pb-6">
          The establishment of NABIISL, in partnership with the Global Steering
          Group for Impact Investing (GSG), further reinforces Sri Lanka’s
          position as a destination for responsible investments. GSG’s presence
          in the country unlocks avenues for sustainable development and
          inclusive growth, appealing to both local and foreign investors.
        </p>
        <p className="pb-6">
          The primary objective of NABIISL is to catalyse the growth of Impact
          Enterprises in Sri Lanka through financial support and the operation
          of the SEF. Initiated in 2018, SEF, in collaboration with Capital
          Alliance Group and the United Nations Development Program (UNDP), is
          dedicated to providing capital to Impact Enterprises, enabling them to
          generate substantial social and environmental impact.
        </p>
        <p className="pb-6">
          HNB, a key player in Sri Lanka’s banking sector, has a significant
          client base of SMEs seeking financial services and opportunities for
          growth.
        </p>
        <p className="pb-6">
          LIIN and Social Enterprise Lanka presented ‘Ath Pavura’, a ground
          breaking television show created with HNB as the title sponsor. This
          initiative empowers social entrepreneurs in Sri Lanka, providing them
          with a national platform to showcase their businesses and attract
          investments from local investors.
        </p>
        <p className="pb-6">
          The partnership between HNB and LIIN is strategically positioned to
          identify SME clients within HNB’s network prepared for equity
          investments through the SEF. The Social Enterprise Fund focuses on
          supporting micro, small, and medium-level social entrepreneurs in the
          acceleration stage of their business life cycle.
        </p>
        <p className="pb-6">
          HNB Deputy General Manager of SME and Micro Finance Rajive Dissanayke
          said: “HNB is committed to supporting the growth of SMEs, and through
          this collaboration, we can contribute to creating a more sustainable
          and impactful business environment in Sri Lanka.”
        </p>
        <p className="pb-6">
          In its initial phase, NABIISL, acting as a knowledge hub, will provide
          essential awareness sessions, specialised expertise, personalised
          guidance, and steadfast support to HNB throughout the process of
          identifying, nurturing, and preparing SME clients for potential equity
          investments.
        </p>
        <p className="pb-6">
          This collaboration signifies a significant step towards enhancing the
          impact investing landscape in Sri Lanka, contributing to the nation’s
          sustainable development goals and attracting both local and foreign
          investors to actively participate in responsible investments.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
