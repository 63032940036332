import React from "react";
import publication1 from "../../../assets/images/publications1.jpg";
import publication2 from "../../../assets/images/publications2.png";
import NAB_Report_2023_Cover from "../../../assets/images/NAB_Report_2023_Cover.jpg";
import InveSTAR_PUB_Cover from "../../../assets/images/InveSTAR_PUB_Cover.jpg";
import Impact_InveStory_Cover from "../../../assets/images/Impact_InveStory_Cover.jpg";
import Cover_Impact_InveSTART_2023 from "../../../assets/images/Cover_Impact_InveSTART_2023.jpg";
import Connecting_Creativity_Outcome_Report_Cover from "../../../assets/images/Connecting_Creativity_Outcome_Report_Cover.jpg";
import { Button } from "../../../shared/ui/Button";

export default function LearningData() {
  return (
    <div className="py-20 max-w-6xl mx-auto px-8 md:px-16">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 pb-20">
        <div className="md:col-span-1 text-center">
          <img
            src={Cover_Impact_InveSTART_2023}
            alt="Publication 1"
            className="mb-8"
          />
          <a
            href="https://drive.google.com/file/d/1T2Uknhv63_pieUoXCRtKTvzE7GnvPgHn/view"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">Impact InveSTART</h2>
          <p className="text-[14px]">
            On 10th November 2016, the former Finance Minister of Bangladesh
            Government, late Abul Maal Abdul Muhit launched the concept of
            impact investment publicly in Bangladesh. To frame that day as a
            historic milestone, the NAB trust announced 10th November as the
            annual Impact Investment Day for Bangladesh as part of the Impact
            InveSTART International Summit.
          </p>
          <p className="text-[14px]">
            This Publication explores the proceedings of the Impact InveSTART
            International Summit, organised by the NAB Bangladesh on November
            2023. The Impact InveSTART Summit was a festive gathering of
            investment experts and impact entrepreneurs to guide the way forward
            in identifying Bangladesh as one of the key investment destinations
            for 21st century and beyond. This was the goal for Impact InveSTART!
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 pb-20">
        <div className="md:col-span-1 text-center">
          <img
            src={NAB_Report_2023_Cover}
            alt="Publication 1"
            className="mb-8"
          />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/NAB%20Report%202023.pdf?alt=media&token=cd9fe8d4-2ff1-4441-a6c5-24c7fd58a779"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">NAB Annual Report 2023</h2>
          <p className="text-[14px]">
            This report presented by the NAB Trust, summarizes the key
            achievements of NAB Bangladesh and updates on the implementation of
            the Bangladesh Impact Investment Strategy and Action Plan (BIISAP).
            It delves into the key challenges faced by NAB Bangladesh, through
            the Impact Investment Ecosystem Building Movement. The report also
            discusses the opportunities and the way forward for a prosperous
            Impact Investment Ecosystem.{" "}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 pb-20">
        <div className="md:col-span-1 text-center">
          <img src={InveSTAR_PUB_Cover} alt="Publication 1" className="mb-8" />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/Impact%20InveSTAR%201.pdf?alt=media&token=4ba34d95-cb05-4787-89c2-587b87cd7a97"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">Impact InveSTAR</h2>
          <p className="text-[14px]">
            This Publication explores the proceedings of Impact InveSTAR
            international summit, organised by the NAB Bangladesh on June 2023.
            In this event, NAB Bangladesh expressed gratitude to organizations
            and individuals, integral to the Impact Investment Ecosystem
            Building Movement in Bangladesh. Referred to as Impact InveSTARs,
            the pioneers have been the driving force since day zero. The Stars
            were acknowledged across five pivotal categories of Government,
            Regulators, Investors, Investees, Intermediaries, Market Builders,
            and Global InveSTAR.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 pb-20">
        <div className="md:col-span-1 text-center">
          <img
            src={Impact_InveStory_Cover}
            alt="Publication 1"
            className="mb-8"
          />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/Impact%20InveStory.pdf?alt=media&token=e664ce46-1b3b-49d4-ac50-6b661017e91c"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">Impact InveSTORY</h2>
          <p className="text-[14px]">
            This Publication explores the events of Impact InveSTORY hosted by
            the NAB Bangladesh on August of 2022. This international conference
            marked the official launch of the Bangladesh Impact Investment
            Strategy and Action Plan (BIISAP), a visionary document outlining
            key strategies to propel Impact Investment in Bangladesh. Throughout
            the event, NAB also expressed its gratitude to organizations and
            individuals vital to the Impact Investment Ecosystem Building
            Movement in Bangladesh, recognized as Impact InveSTORYtellers. The
            conference spotlighted their impactful stories while unveiling the
            BIISAP's implementation plan.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 pb-20">
        <div className="md:col-span-1 text-center">
          <img
            src={Connecting_Creativity_Outcome_Report_Cover}
            alt="Publication 1"
            className="mb-8"
          />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/Connecting%20Creativity%20Outcome%20Report%202022%20(1).pdf?alt=media&token=9f4be17b-0416-49d1-a66b-3ea3f4f8582b"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">
            Connecting Creativity 2022 Outcome Report
          </h2>
          <p className="text-[14px]">
            This report delves into the insights and outcomes of the "Connecting
            Creativity 2022" workshop series, a collaborative initiative by NAB
            Bangladesh and the Embassy of Switzerland. This series of four
            physical workshops, conducted from 19th to 24th July 2022, served as
            a dynamic platform to reaffirm the findings of the Bangladesh Impact
            Investment Strategy and Action Plan (BIISAP). Each workshop brought
            together stakeholders with specific roles in the Impact Investment
            Ecosystem, ensuring diverse perspectives and addressing crucial
            questions.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16  pb-20">
        <div className="md:col-span-1 text-center">
          <img src={publication2} alt="Publication 1" className="mb-8" />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/BIISAP.pdf?alt=media&token=b0558423-962a-495f-b8d1-26132687345c"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">
            Bangladesh Impact Investment Strategy and Action Plan:
          </h2>
          <p className="text-[14px]">
            The key focus of the BIISAP is to promote increased understanding
            and advocacy while providing more structured pathways of scaling up
            the impact investing ecosystem in Bangladesh. The BIISAP identifies
            key areas of intervention needed to unlock the full potential of
            impact investing in Bangladesh, while also outlining the potential
            roles of key players and stakeholders in implementing the range of
            recommended actions and strategies. The goal is to support increased
            social and environmental development by further supporting the
            creation of an inclusive impact investing ecosystem in Bangladesh.
          </p>
          <p>
            The BIISAP builds on the 2020 study by NAB Bangladesh –{" "}
            <span className="font-bold">
              Policy Landscape Analysis: Impact Investment in Bangladesh.
            </span>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 ">
        <div className="md:col-span-1 text-center">
          <img src={publication1} alt="Publication 1" className="mb-8" />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/Policy-Landscape-Analysis_Impact-Investment-in-Bangladesh.pdf?alt=media&token=3f2805f5-4fb1-4cef-bb45-07050698cfc6"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download</Button>
          </a>
        </div>
        <div className="md:col-span-2">
          <h2 className="font-bold text-3xl mb-8">
            Policy Landscape Analysis <br />
            IMPACT INVESTMENT IN BANGLADESH
          </h2>
          <p className="text-[14px]">
            This report analyses the current policy landscape in regards to the
            impact investment in Bangladesh, complemented by a series of primary
            interviews with three key stakeholder groups (impact entrepreneurs,
            impact investors and ecosystem builders). It identifies the
            strengths, opportunities, weaknesses and threats inherent in the
            ecosystem (SWOT) to devise a blueprint for the development of the
            proposed Bangladesh Impact Investment Strategies and Action Plan
            (BIISAP).
          </p>
        </div>
      </div>
    </div>
  );
}
