// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Timestamp, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyByB8CwZ8BpGky5TVESmyH7UttGPLrZf5E",
  authDomain: "nab-bangladesh-webapplication.firebaseapp.com",
  projectId: "nab-bangladesh-webapplication",
  storageBucket: "nab-bangladesh-webapplication.appspot.com",
  messagingSenderId: "327080591812",
  appId: "1:327080591812:web:ebab8a0f40eabbab15a491"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const timeStamp = Timestamp;