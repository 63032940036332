import React, { useEffect, useState } from "react";
import Layout from "../../shared/Layout/Layout";
import ImpactDetails from "./Components/ImpactDetails";
import ImpactTopbar from "./Components/ImpactTopbar";
import ImpactInvest from "./Components/ImpactInvest";
import WhyImpactInvesting from "./Components/WhyImpactInvesting";
import { useLocation } from "react-router-dom";
import AboutImpact from "./Components/AboutImpact";

export default function ImpactInvesting() {
  return (
    <Layout>
      <ImpactTopbar />
      <AboutImpact />
      <ImpactDetails />
      <ImpactInvest />
      <WhyImpactInvesting />
    </Layout>
  );
}
