import React, { useState } from 'react'
import Layout from '../../shared/Layout/Layout'
import IIBHeader from './Components/IIBHeader'
import AccordionHeader from './Components/AccordionHeader'
import AccordionHeaderBasedComponents from './Components/AccordionHeaderBasedComponents'

export default function ImpactInvestingBangladesh() {
  let [impactName, setImpactName] = useState("Key Policies");
  return (
    <Layout>
      <IIBHeader />
      <AccordionHeader  impactName={impactName} setImpactName={setImpactName} />
      <AccordionHeaderBasedComponents impactName={impactName} />
    </Layout>
  )
}
