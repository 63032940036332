import React from "react";
import news1 from "../../../assets/images/news11.jpg";
import news2 from "../../../assets/images/news12.jpg";
import { Link } from "react-router-dom";

export default function BisapDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <img src={news1} alt="" className="w-full h-auto rounded-md shadow-xl" />

      <div className="pt-20">
        <p className="pb-10">
          Bangladesh the confidence to develop and publish the Bangladesh Impact
          Investment Strategy and Action Plan (BIISAP) in 2022 and host the
          international conference on Impact Investment – “Impact InveStory”.
        </p>

        <p className="pb-10">
          “Impact InveStory” was a daylong conference highlighting the
          implementation plan of the BIISAP while sharing impact investment
          stories from across the globe and from local champions of course. The
          Minister for Planning Muhammad Abdul Mannan MP, was the chief guest
          and launched the national strategy along with the NAB Bangladesh Chair
          Md Sirazul Islam. He strongly acknowledged the inspiration and
          leadership role played by the Hon. Prime Minister Sheikh Hasina in
          supporting the concept of Impact Investment, who launched the first
          Impact Investment campaign for Bangladesh at a side event in New York
          at the United Nations General Assembly meeting in 2017. In their
          speeches, Mr Salman F Rahman, the Private and Investment Adviser to
          the Hon. Prime Minister, Professor Shibli Rubayat-Ul-Islam, Chairman
          of BSEC, along with the senior representation from the Bangladesh Bank
          emphasized the proactive actions taken by the Bangladesh Government to
          enable both local and global impact investors to invest in Bangladesh.
          The Impact InveStory conference also had other distinguished speaker’s
          like the Jonathan Wong from UNESCAP, Ambassador Nathalie Chuard from
          Switzerland, Ambassador Mustafa Osman Turan from Turkiye and also Ms
          Safak Muderrisgil, president of the Turkiye NAB.
        </p>

        <p className="pb-10">
          The NAB Chair acknowledged the contributions made by the Embassy of
          Switzerland from day one of this movement, not only as a member of the
          National Advisory Board but also as the key sponsor to develop this
          National Strategy and Action Plan. Expressing Switzerland’s support to
          ensure an effective implementation of BIISAP, Ambassador Chuard said
          that her country is implementing the B-Briddhi programme that assists
          high-potential impact enterprises in securing funding and scaling
          their operations. Sir Ronald Cohen and Cliff Prior from the Global
          Steering Group (GSG) for Impact Investment praised the dynamic
          progress of the Impact Investment Ecosystem building activities in
          Bangladesh. Mr Asif Saleh, Executive Director from BRAC also
          highlighted the contributions made by the private sector including the
          not-for-profit sectors to facilitate the Impact investment movements
          in Bangladesh.
        </p>

        <p className="pb-10">
          The BIISAP is based on research and feedback from stakeholders across
          the Bangladesh impact investing ecosystem. Fifteen in-depth interviews
          and workshop consisting of 35 stakeholders were conducted, reflecting
          the views of impact entrepreneurs, impact investors, impact
          accelerators and incubators, policymakers, and ecosystem
          intermediaries. The BIISAP has identified nine (9) strategic
          recommendations under five (5) priority intervention areas as the
          starting points for the impact investment ecosystem building process
        </p>

        <Link to="/gsg-global-impact-summit-2021">
          <p className="text-primary font-bold cursor-pointer">
            &#8592; Prev: GSG Global Impact Summit 2021 – Speakers from
            Bangladesh
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/gsg-global-impact-summit-2021">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news2} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                GSG Global Impact Summit 2021 – Speakers from Bangladesh
              </h2>
              <p className="text-primary pb-2">Dec 27, 2021</p>
              <p>
                The 2021 GSG Global Impact Summit is all set to take place
                virtually from 6-8 October, 2021. The GSG annual summit is...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
