import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import StrongGrowthDetails from "./components/StrongGrowthDetails";

export default function StrongGrowth() {
  return (
    <Layout>
      <NewsHeader>
        The plan to break systemic inertia and deliver impact transparency
      </NewsHeader>
      <StrongGrowthDetails />
    </Layout>
  );
}
