import React from "react";
import { Link } from "react-router-dom";

import news4 from "../../../../assets/images/news4.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function OnImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news4}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          In 2018, Sir Ronald Cohen, Chair of the Global Steering Group (GSG)
          for Impact Investment authored and published a guide to steer the
          global Impact Investment Movement –{" "}
          <span className="font-bold">On Impact</span>.
        </p>

        <p className="pb-10">
          On Impact had eight determinations to ensure the way forward suitable
          for all impact fighters to proceed with their goals and objectives. At
          the launch of On Impact at the GSG annual summit in Delhi India, Sir
          Ronald requested all member countries to share this document among
          their stakeholders as guiding principles to support the development of
          the Impact Investment Ecosystem in their own countries.
        </p>

        <p className="pb-10">
          The National Advisory Board (NAB) for Impact Investment in Bangladesh
          took the direction very seriously and valued the content of On Impact.
          Bangladesh became the first GSG member country to translate On Impact
          in Bangla to facilitate a wider understanding of the Impact Investment
          movement and its significance. The Bangla version also helped the
          Impact Investment stakeholders to have a much clearer picture of what
          type of world we want and how impact investment can play instrumental
          role in delivering that to us in Bangladesh. It also clarified the
          changing nature of investment between the 19th century and 21st
          century.
        </p>

        <p className="pb-10">
          Investment was guided by return only in the 19th century, while 20th
          century introduced risk along with return. Finally, 21st century
          realised the value of impact for any investment and designed impact
          investment to include{" "}
          <span className="font-bold">return, risk, and impact</span>.
        </p>

        <div>
          <iframe
            width="100%"
            height="515"
            src="https://www.youtube.com/embed/SY3ibrBNcYU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <p className="pb-10">
          This is the message, Sir Ronald Cohen shared at the launch of the
          Bangla On Impact at the GSG Annual Summit in Buenos Aires, Argentina
          in 2019. He also highly appreciated the proactiveness of the NAB
          Bangladesh to lead the way forward by translating ON Impact in our own
          language to generate wider awareness in Bangladesh.
        </p>
        <div className="text-center pb-10">
          <a
            href="https://buildbangladesh.com.bd/wp-content/uploads/2023/03/On-Impact-by-Sir-Ronald-Cohen_Bengali-Version.pdf"
            target="_blank"
          >
            <button className="bg-primary text-white px-6 py-2 rounded-md">
              DOWNLOAD THE BOOK
            </button>
          </a>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
