import React from "react";
import { Link } from "react-router-dom";
import news23 from "../../../../assets/images/news23.jpg";
import news23_details1 from "../../../../assets/images/news23_details1.webp";
import news23_details5 from "../../../../assets/images/news23_details5.webp";
import news23_details7 from "../../../../assets/images/news23_details7.webp";
import news23_details2 from "../../../../assets/images/news23_details2.jpg";
import news23_details3 from "../../../../assets/images/news23_details3.jpeg";
import news23_details4 from "../../../../assets/images/news23_details4.jpg";
import news23_details6 from "../../../../assets/images/news23_details6.jpg";
import news23_details8 from "../../../../assets/images/news23_details8.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function ConnectingCreativityDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news23}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The National Advisory Board (NAB) for Impact Investment in Bangladesh,
          in collaboration with the Embassy of Switzerland and Build Bangladesh,
          embarked on a transformative journey to develop the Bangladesh Impact
          Investment Strategy and Action Plan (BIISAP), a groundbreaking roadmap
          that navigates the journey from the early phases of impact investing
          to the maturity. BIISAP not only emphasizes the importance of
          increased understanding and advocacy but also provides structured
          pathways for scaling up Bangladesh’s impact investing ecosystem.
        </p>
        <p className="pb-6">
          Prior to the formal launch of BIISAP, NAB Bangladesh hosted a series
          of workshops, named ‘Connecting Creativity 2022.’ These workshops,
          conducted in partnership with the Embassy of Switzerland and BRAC, and
          supported by Build Bangladesh and LightCastle Partners, served as a
          platform for various stakeholders to come together, share insights,
          and refine the strategies outlined in the action plan. Over four days,
          we witnessed the power of collaboration and innovation as the
          investees, investors, intermediaries, and regulators joined hands to
          shape the future of impact investing in Bangladesh. Every session had
          a common theme on the nine recommendations of the BIISAP and the ways
          to implement those over next few years.
        </p>

        <div className="flex gap-20 justify-center items-center">
          <div className="text-center  w-full">
            <img
              src={news23_details1}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
          <div className="text-center  w-full">
            <img
              src={news23_details2}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>
        <h1 className="font-bold text-xl pt-8">
          Day 1: Engaging Investees – Pioneers of Impact
        </h1>
        <p className="pb-6">
          On 19th July 2022, the first day of the workshop gathered promising
          startups from various sectors, all eager to contribute to Bangladesh’s
          vibrant impact investing ecosystem. Participants discussed the current
          gaps in the country’s impact investment landscape and the way forward
          for collaboration. The workshop began with opening remarks from
          Arastoo Khan, Member Secretary at NAB Bangladesh and the day ended
          with valuable insights shared by Bijon Islam, CEO of LightCastle
          Partners.
        </p>

        <div className="flex gap-20 justify-center items-center">
          <div className="text-center  w-full">
            <img
              src={news23_details3}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
          <div className="text-center  w-full">
            <img
              src={news23_details4}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>

        <h1 className="font-bold text-xl pt-8">
          Day 2: Empowering Intermediaries – Bridging the Gap
        </h1>
        <p className="pb-6">
          On 20th July 2022, day two was dedicated to intermediaries within the
          ecosystem. Stakeholders stressed on how Bangladesh can create a
          prosperous impact investing landscape. Discussions revolved around
          identifying core intervention areas and fostering multi-stakeholder
          collaboration. The workshop began with opening remarks from Arastoo
          Khan, Member Secretary at NAB Bangladesh and concluded with the key
          highlights from the brainstorming session by Erad Kawsar, Executive
          Director of Build Bangladesh.
        </p>
        <h1 className="font-bold text-xl pt-8">Key Highlights:</h1>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            Emphasis on understanding gaps in the emerging impact investing
            landscape.{" "}
          </li>
          <li>Identification of core intervention areas. </li>
          <li>
            Addressed the way forward for deeper collaboration across the
            ecosystem including the intermediaries.{" "}
          </li>
        </ul>
        <div className="flex gap-20 justify-center items-center">
          <div className="text-center  w-full">
            <img
              src={news23_details5}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
          <div className="text-center  w-full">
            <img
              src={news23_details6}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>

        <h1 className="font-bold text-xl pt-8">
          Day 3: Engaging Investors – Agents of Change
        </h1>
        <p className="pb-6">
          Investors took center stage on day three, held on July 21, 2022,
          sharing their insights on building a robust impact investing ecosystem
          in Bangladesh. Participants reflected on the existing gaps and the
          imperative of multi-stakeholder cooperation. The workshop began with
          opening remarks from Arastoo Khan, Member Secretary at NAB. Asif
          Saleh, Executive Director of BRAC, facilitated the dialogue between
          participants. Bijon Islam, Co-Founder & CEO at LightCastle Partners,
          concluded the workshop by summarizing the key highlights from the
          brainstorming session.
        </p>
        <h1 className="font-bold text-xl pt-8">Key Highlights:</h1>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            A focus on understanding gaps in the impact investing landscape.
          </li>
          <li>
            Identification of core intervention areas including regulatory
            reform to encourage more investors.{" "}
          </li>
          <li>Strong emphasis on multi-stakeholder collaboration.</li>
        </ul>

        <div className="flex gap-20 justify-center items-center">
          <div className="text-center  w-full">
            <img
              src={news23_details7}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
          <div className="text-center  w-full">
            <img
              src={news23_details8}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>

        <h1 className="font-bold text-xl pt-8">
          Day 4: Empowering Regulators – Shaping Impact Policy
        </h1>
        <p className="pb-6">
          On July 24, 2022, final day of the workshop series engaged regulators
          in shaping the future of impact investing in Bangladesh. Participants
          discussed policy interventions, recognizing their vital role in the
          ecosystem’s growth. The workshop began with opening remarks from
          Arastoo Khan, Member Secretary at NAB, and concluded with the key
          highlights from the brainstorming session by Erad Kawsar, Executive
          Director of Build Bangladesh.
        </p>
        <h1 className="font-bold text-xl pt-8">Key Highlights:</h1>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            Discussions on gaps and way forward to reinforce the impact
            investing regulatory framework.
          </li>
          <li>Identification of key policy intervention areas.</li>
          <li>The imperative for multi-stakeholder collaboration.</li>
        </ul>
        <p className="pb-6">
          Through ‘Connecting Creativity 2022,’ Bangladesh took a significant
          step towards fostering a more sustainable, socially responsible, and
          economically viable future while maintaining the investors financial
          gain. These workshops not only identified challenges but also
          demonstrated the commitment of diverse stakeholders to work together
          to overcome the barriers hindering the development of the impact
          investment ecosystem building process in Bangladesh.
        </p>
        <p className="pb-6">
          As we move forward, it’s clear that the spirit of collaboration and
          innovation ignited during these workshops which will continue to guide
          Bangladesh’s journey toward a thriving impact investing ecosystem,
          where social and environmental impact go hand in hand with financial
          return. Together, we will build a better, more sustainable future for
          all.
        </p>
        <p className="pb-6">
          The National Advisory Board (NAB) for Impact Investment in Bangladesh,
          in collaboration with various partners, embarked on a transformative
          journey to develop the Bangladesh Impact Investment Strategy and
          Action Plan (BIISAP), a groundbreaking roadmap that navigates the
          journey from the early phases of impact investing to the maturity.
          BIISAP not only emphasizes the importance of increased understanding
          and advocacy but also provides structured pathways for scaling up
          Bangladesh’s impact investing ecosystem.
        </p>
        <p className="pb-6">
          Prior to the formal launch of BIISAP, NAB Bangladesh hosted a series
          of workshops, named ‘Connecting Creativity 2022.’ These workshops,
          conducted in partnership with the Embassy of Switzerland and BRAC, and
          supported by Build Bangladesh and LightCastle Partners, served as a
          platform for various stakeholders to come together, share insights,
          and refine the strategies outlined in the action plan. Over four days,
          we witnessed the power of collaboration and innovation as the
          investees, investors, intermediaries, and regulators joined hands to
          shape the future of impact investing in Bangladesh.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
