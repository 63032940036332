import React from "react";
import news151 from "../../../../assets/images/news151.png";
import news123_creator from "../../../../assets/images/news126_creator.png";
import Picture_1 from "../../../../assets/images/Picture_1.png";
import Picture_2 from "../../../../assets/images/Picture_2.png";
import Picture_3 from "../../../../assets/images/Picture_3.png";
import Picture_4 from "../../../../assets/images/Picture_4.png";
import tika_contribution1 from "../../../../assets/images/tika_contribution1.jpg";
import news11 from "../../../../assets/images/news11.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FaCircleArrowRight, FaCircleArrowLeft } from "react-icons/fa6";

let sliderImages = [Picture_1, Picture_2, Picture_3, Picture_4];

export default function SouthAsiaConveingDetails() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <FaCircleArrowRight />,
    // prevArrow: <FaCircleArrowLeft />,
  };
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news151}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          ANDE South Asia Convening is an initiative to bring this ecosystem of
          entrepreneur support organizations, in South Asia, together to
          initiate discussion to promote impact investment and catalyze
          cross-border collaboration to further economic growth through
          entrepreneurship and strengthen the SGB ecosystem.
        </p>

        <p className="pb-6">
          So far, two editions of the Convening have been organized successfully
          by the South Asia chapter.{" "}
          <span className="font-bold">
            South Asia Convening 2024: Big Ideas for Small Business from
            starting from 3 – 5 December 2024 Virtually
          </span>{" "}
          builds on the ANDE Global Annual Conference 2024 theme “Big Ideas for
          Small Business.” Hosted by the ANDE South Asia chapter in partnership
          with NAB Bangladesh, over the three days, key discussions will focus
          on:
        </p>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            <span className="font-bold">
              Regional Ecosystem Building for SGBs,
            </span>{" "}
            examining how regional cooperation, infrastructure development, and
            digital transformation can drive economic growth, reduce poverty,
            and improve market access;
          </li>
          <li>
            <span className="font-bold">
              Population-Weighted Asset Under Management (AUM),
            </span>{" "}
            exploring its definition, scope, SWOT analysis, methods, and
            regional comparisons; and
          </li>
          <li>
            <span className="font-bold">Impact InvesTREND day,</span> which will
            highlight the role of impact investing for sustainable development
            in Bangladesh, with sessions covering regulatory frameworks,
            transparency, and investment tools.
          </li>
        </ul>

        <p className="pb-6">
          Co-hosted with{" "}
          <a
            href="https://www.nabbangladesh.org/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            NAB Bangladesh
          </a>{" "}
          and partnered with
          <a
            href="https://www.betterstories.limited/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            BetterStories Limited
          </a>
          {","}
          <a
            href="https://www.linkedin.com/company/safalpartners/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            SAFAL Partners,
          </a>
          <a
            href="https://pthventurestudio.com/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            PTH Venture Studio,
          </a>
          <a
            href="https://nventures.sg/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            nVentures,
          </a>
          <a
            href="https://wusc.ca/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            World University Service of Canada
          </a>
          (WUSC – EUMC), and
          <a
            href="https://www.icimod.org/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            ICIMOD,
          </a>
          ANDE’s three-day gathering will explore pivotal themes shaping the
          region’s entrepreneurial landscape and offer an unparalleled platform
          to:
        </p>
        <ul className="pl-20 pb-10 pt-4">
          <li>
            Engage with sector experts and thought leaders from South Asia.
          </li>
          <li>
            Explore opportunities and challenges of impact investing in
            Bangladesh.
          </li>
          <li>
            Gaining insights into groundbreaking small business development
            strategies.
          </li>
          <li>
            Building impactful partnerships that drive sustainable growth.
          </li>
        </ul>
        <h2 className="py-6 font-bold">
          What to Expect at South Asia Convening 2024?
        </h2>

        <div className="max-w-6xl mx-auto py-20 blog px-8">
          {
            <Slider {...settings}>
              {sliderImages?.map((image) => (
                <div className="flex flex-col md:flex-row items-center gap-6 fl">
                  <div className="flex-1">
                    <img
                      src={image}
                      className="rounded-md w-full"
                      alt="impact img"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          }
        </div>

        <h2 className="font-bold">December 3</h2>
        <h2 className="font-bold pb-6">
          Regional Ecosystem Building for SGBs I
        </h2>

        <p className="pb-6">
          Uncover how strategic regional cooperation, infrastructure
          development, and digital innovation can catalyze economic growth,
          broaden market reach, and alleviate poverty across South Asia.
        </p>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            Climate-Resilient Businesses: The Power of Incubation in Mountainous
            Regions – 11:00 am – 12:15 pm IST <br />
            Speakers:
            <a
              href="https://www.linkedin.com/in/anu-joshi-shrestha-9882b3b0/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Anu Joshi Shrestha,ICIMOD Nepal;
            </a>
            <a
              href="https://www.linkedin.com/in/padmakshi-rana-5b24b927/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Padmakshi Rana, Impact Hub Nepal;
            </a>
            <a
              href="https://www.linkedin.com/in/eradkawsar/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Erad Kawsar, Build Bangladesh;
            </a>
            <a
              href="https://www.linkedin.com/in/adnan-faisal-a9021554/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Adnan Faisal, Founder & CEO, ScaleX;
            </a>
            <a
              href="https://www.linkedin.com/in/gjvantveen/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Gerrit Jan van ‘t Veen, World StartUp
            </a>
          </li>
          <li>
            The Venture Studio Debate: Is South Asia Ready? – 3:00 – 4:00 pm IST
            <br />
            Speakers:{" "}
            <a
              href="https://www.linkedin.com/in/chalindaabeykoon/?originalSubdomain=sg"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Chalinda Abeykoon, nVentures;
            </a>
            <a
              href="https://www.linkedin.com/in/ggmathews/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Gigi Mathews, Enviu;
            </a>
            <a
              href="https://www.linkedin.com/in/omarabedin/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Omar Abedin, PTH Venture Studio;
            </a>
            <a
              href="https://www.linkedin.com/in/minhazanwar/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Minhaz Anwar, BetterStories
            </a>
          </li>
          <li>
            Future-Proofing SMEs: The Power of Digital Infrastructure – 6:00 –
            7:15 pm IST
            <br />
            Speakers:{" "}
            <a
              href="https://www.linkedin.com/in/santona-malakar/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Santona Malakar, SAFAL Partners;
            </a>
            <a
              href="https://www.linkedin.com/in/abhishekmodi513/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Abhishek Modi, Sattva;
            </a>
            <a
              href="https://www.linkedin.com/in/radiptandukar/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Radip Tandukar, GeoKrishi;
            </a>
            <a
              href="https://www.linkedin.com/in/gihanmendis/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Gihan Mendis, EngenuityAi
            </a>
          </li>
        </ul>
        <h2 className="font-bold pt-6">December 4</h2>
        <h2 className="font-bold pb-6">
          Regional Ecosystem Building for SGBs II
        </h2>

        <p className="pb-6">
          Engage in a detailed exploration of a novel financial metric,
          understanding its definition, strategic applications, and strengths
          and weaknesses through regional comparisons.
        </p>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            Growing Women-Led Ventures: From Micro to Mighty – 3:00 – 4:15 pm
            IST
            <br />
            Speakers:
            <a
              href="https://www.linkedin.com/in/minhazanwar/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Minhaz Anwar, BetterStories, Bangladesh;
            </a>
            <a
              href="https://www.linkedin.com/in/anaghakamath/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Anagha Kamath, Mann Deshi Foundation, India;
            </a>
            <a
              href="https://www.linkedin.com/in/tariqul-islam-tariq-phd-57307a34/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Tariqul Islam, Max Foundation, Bangladesh;
            </a>
            <a
              href="https://www.linkedin.com/in/dilipbhandari/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Dilip Bhandari, Heifer International, Nepal
            </a>
          </li>
          <li>
            Population-Weighted Asset Under Management – 6:00 – 7:15 pm IST
            <br />
            Speakers:
            <a
              href="https://www.linkedin.com/in/farhad-reza-82b206105/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Farhad Reza, NAB Bangladesh
            </a>
          </li>
        </ul>
        <h2 className="font-bold pt-6">December 5</h2>
        <h2 className="font-bold pb-6">Impact InvesTREND Day</h2>

        <p className="pb-6">
          Dive into discussions on how impact investing is advancing sustainable
          development in Bangladesh. Expect sessions covering regulatory
          landscapes, investment transparency, and practical tools for
          maximizing impact.
        </p>

        <ul className="pl-20 pb-10 pt-4">
          <li>
            <span className="font-bold">Impact Investment Instruments: Way Forward and Demonstrated Examples</span>
            – 11:30 am – 1:00 pm BST / 11:00 am – 12:30 pm IST
            <br />
            Speakers:{" "}
            <a
              href="https://www.linkedin.com/in/mubarizsiddiqui/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Mubariz Siddiqui, Carbon Law;
            </a>
            <a
              href="https://unituscapital.com/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Simmi Sareen, Unitus Capital
            </a>
          </li>
          <li>
            <span className="font-bold">
              Impact Transparency and Scale-Up: Reinforcing Impact Audits,
              Measurements, Management, and Reporting
            </span>{" "}
            – 3:30 – 5:00 pm BST / 3:00 – 4:30 pm IST
          </li>
          <li>
            Regulatory Frameworks: Supporting Investors, Investees,
            Intermediaries, and Untapped Markets – 6:00 – 7:30 pm BST / 5:30 –
            7:00 pm IST <br />
            Speakers:
            <a
              href="https://www.linkedin.com/in/anita-ghazi/"
              target="_blank"
              className="text-blue-500"
              rel="noopener noreferrer"
            >
              Anita Ghazi Rahman, The Legal Circle
            </a>
          </li>
          <li>
            <span className="font-bold">
              Closed-Door Roundtable: Summary and Way Forward
            </span>{" "}
            – 7:30 – 8:15 pm BST / 7:00 – 7:45 pm IST
          </li>
        </ul>

        <p className="pb-6">
          Join the ANDE South Asia Convening virtually with free registration
          for three days of meaningful dialogue, innovative ideas, and
          actionable insights that will help shape the future of
          entrepreneurship in South Asia. For more information and registration
          details, visit{" "}
          <a
            href="https://andeglobal.org/event/south-asia-convening-2024-big-ideas-for-small-business/"
            target="_blank"
            className="text-blue-500"
            rel="noopener noreferrer"
          >
            ANDE South Asia Convening 2024.
          </a>
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
