import React from "react";
import AMeansToEndDetails from "./components/AMeansToEndDetails";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";

export default function AMeansToEnd() {
  return (
    <Layout>
      <NewsHeader>Impact Investing – A Means to an End</NewsHeader>
      <AMeansToEndDetails />
    </Layout>
  );
}
