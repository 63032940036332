import React from "react";

import news6 from "../../../../assets/images/news6.jpg";
import news11 from "../../../../assets/images/news11.jpg";
import { Link } from "react-router-dom";

export default function NabTrustDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news6}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          The newly established National Advisory Board (NAB) Trust for Impact
          Investment in Bangladesh held its first Trustees meeting on 23
          February 2023 at the Build Bangladesh Board Room. The event marked the
          first activity of the Trust as a legal entity and primarily focused on
          deciding the critical points related to the successful operation of
          the Trust. Trustees recommended to introduce a new membership
          structure for the NABG, the BIISAP Implementation Action Plans, and
          discussed on the functional requirements of the Trust in terms of
          financial and human resources. Resolutions derived from the Trustees’
          meeting were presented to the National Advisory Board of Governance
          (NABG) for impact investment in Bangladesh in its 6th meeting for
          approval.
        </p>

        <p className="pb-2 underline  decoration-1">
          Current National Advisory Board (NAB) for Impact Investment in
          Bangladesh governance structure:
        </p>
        <p className="pb-10">
          In 2018, when NAB Bangladesh became the 19the member country of the
          GSG, it always had a plan to do a two-tier governance structure. One
          will perform the advisory role and the other one will do the
          operational function as a legal entity. This structure allows NAB
          Bangladesh to share the operational budget and functions among
          members. Since 2017 till to date Build Bangladesh has been bearing all
          the expenses for the NAB functions including leading the operational
          process to build the Impact Investment Ecosystem in Bangladesh
          including the initial GSG membership fee (which has been abolished
          since 2019). The two tiers NAB Bangladesh are:
        </p>
        <p className="pb-10">
          <span className="font-bold">Tier one:</span> The NAB Bangladesh with
          its current Members has transitioned into the National Advisory Board
          of Governance <span className="font-bold">(NABG)</span> for Impact
          investment in Bangladesh, Chaired by the Executive Chairman of the
          Bangladesh Investment Development Authority. Arastoo Khan has been the
          Member Secretary of NAB Bangladesh since 2018 and continuing the role
          for NABG as well. Architect Farhadur Reza has been the CEO of NAB
          Bangladesh and now continuing as the CEO for NABG. NABG is an Advisory
          Board and instructed the NAB Secretariat (Build Bangladesh) to
          establish a legal entity to manage its day-to-day operation due to the
          increased activities in the Impact Investment Ecosystem in Bangladesh,
          particularly since the launch of the Bangladesh Impact Investment
          Strategy and Action Plan (BIISAP) and its Implementation Action Plans.
          NABG has no legal status.
        </p>
        <p className="pb-10">
          <span className="font-bold">Tier two:</span> Newly established legal
          entity – the <span className="font-bold">NAB Trust</span>. Arastoo
          Khan is the Author/Chairperson of the Trust with six other members
          (five of the seven Trustees are NABG Members). The Trust is an
          independent legal entity but accountable and reports back to the NABG.
        </p>
        <p className="pb-10">
          The NABG, at their February 2023 meeting endorsed the BIISAP
          Implementation Action Plans and tasked NAB Trust to lead the
          implementation of BIISAP over next few years.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
