import React from "react";
import { Link } from "react-router-dom";
import news33 from "../../../../assets/images/news33.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function ConnectingCreativityDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news33}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The National Advisory Board (NAB) for Impact Investment in Bangladesh
          in partnership with Embassy of Switzerland in Bangladesh, BRAC, Build
          Bangladesh, LightCastle Partners, and Channel I, hosted the
          ‘Connecting Creativity 2022’ – four physical workshops between 19 and
          24 of July to reconfirm the nine recommendations, alongside their
          identified priorities and implementation plans, as proposed in the
          ‘Bangladesh Impact Investment Strategy and Action Plan (BIISAP)’. Each
          workshop involved stakeholders – investees, intermediaries, investors,
          regulators, and development partners, depending on their role within
          the Impact Investment Ecosystem in Bangladesh. The outcomes of the
          workshops were presented at the BIISAP launch on 02 August 2022 as
          achievable directions, identified roles and responsibilities to
          implement BIISAP recommendations by 2030.
        </p>
        <p className="pb-6">
          The Connecting Creativity 2022 workshops guided the way forward by
          primarily addressing the following questions:
        </p>

        <ul className="pl-20 py-10">
          <li>
            <span className="font-bold">HOW</span> are we going to implement the
            strategy and action plans?
          </li>
          <li>
            <span className="font-bold">WHO</span> are going to lead us across
            the Impact Investment Ecosystem streams?
          </li>
          <li>
            <span className="font-bold">WHAT</span> is the effective governance
            to monitor, evaluate, and review?
          </li>
        </ul>
        <p className="pb-6">
          Moderated by Erad Kawsar, Country Director at YGAP Bangladesh, the
          first workshop with investees on 19 July, engaged the demand-side
          players in the impact investment landscape. Impact entrepreneurs
          across diverse sectors within the startup landscape from healthcare,
          education, renewable energy and service sector and e-commerce attended
          this workshop.
        </p>
        <p className="pb-6">
          Intermediaries Workshop on 20 July, engaged the ecosystem builders,
          representatives from accelerators and incubators as they play a vital
          role within the impact investment landscape in providing capacity
          building support and building an investment ready pipeline of impact
          enterprises. The workshop was moderated by Bijon Islam, Co-Founder &
          CEO of LightCastle Partners.
        </p>
        <p className="pb-6">
          Investors Workshop engaged the supply-side players on 21 July and was
          moderated by Asif Saleh, the executive director of BRAC. Investors
          play an incontestable role in the ecosystem in ensuring that impact
          entrepreneurs and enterprises have access to capital and the right
          financial instruments, to scale their operations. The workshop
          explored the challenges for investors in the ecosystem and map out
          potential interventions.
        </p>
        <p className="pb-6">
          The workshop with regulators and development partners was on 24 July
          to understand challenges and map out interventions that will enable
          them to play a pivotal role in shaping the regulatory environment, for
          a vibrant impact investing economy. Arastoo Khan – the Member
          Secretary of NAB Bangladesh moderated this final workshop.
        </p>
        <p className="pb-6">
          <span className="font-bold">
            Bangladesh Strategy and Action Plan (BIISAP)
          </span>{" "}
          is the guiding document that sketches out the roadmap for creating a
          conducive environment for impact investment to gain momentum and
          thrive in Bangladesh. Based on research and feedback from stakeholders
          across the Bangladesh impact investing landscape, the BIISAP reflects
          the views of investees, intermediaries, investors, regulators, and
          development partners. It has identified nine (9) strategic
          recommendations under five (5) priority intervention areas as the
          starting points for impact investment ecosystem building process and
          eventually taking the leap from a nascent to an emerging stage.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
