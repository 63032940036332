import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ImpactInvestarDetails from "./Component/ImpactInvestarDetails";

export default function ImpactInvestar() {
  return (
    <Layout>
      <NewsHeader>
        “… the Impact InveSTAR’s of Bangladesh will be envy to many countries…”
      </NewsHeader>
      <ImpactInvestarDetails />
    </Layout>
  );
}
