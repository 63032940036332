import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';

export default function AdminLogin() {
    var main_credentials = JSON.parse(localStorage.getItem('nab_credentials'));
    let [loginCredentails, setLoginCredentials] = useState({
        email: "",
        password: ""
    })

    console.log("main_credentials", main_credentials)
    const [user, setUser] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isUser(loginCredentails)) {
            localStorage.setItem('nabAdminloggedIn', 'true');
            setUser(true);
        }
    }

    const isUser = (data) => {
        console.log("data", data)
        if (main_credentials[0].email === data.email && main_credentials[0].pass === data.password) return true;
        else return false;
    }
    return (
        user ? <Navigate to='/admin' /> : <div className='min-h-screen w-full flex flex-col justify-center items-center'>
            <div className='bg-primary text-white p-8 rounded-md'>
                <h2 className='text-2xl font-bold' >Admin login credential</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        <p>Admin Email</p>
                        <input type="text" onChange={e => setLoginCredentials({ ...loginCredentails, email: e.target.value })} className='w-full rounded-sm text-black' />
                    </label>
                    <label>
                        <p>Admin password</p>
                        <input type="password" onChange={e => setLoginCredentials({ ...loginCredentails, password: e.target.value })} className='w-full rounded-sm text-black' />
                    </label>
                    <div className='pt-4 rounded-sm'>
                        <input type="Submit" value="Login" className='px-4 py-2 rounded-md bg-white text-black border-none focus:outline-none text-xl ' />
                    </div>
                </form>
            </div>
        </div>
    )
}
