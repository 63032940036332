import React from "react";
import news1 from "../../../assets/images/news1.jpg";
import news2 from "../../../assets/images/news2.jpg";
import news3 from "../../../assets/images/news3.jpg";
import news4 from "../../../assets/images/news4.jpg";
import news5 from "../../../assets/images/news5.jpg";
import news6 from "../../../assets/images/news6.jpg";
import news7 from "../../../assets/images/news7.jpg";
import news8 from "../../../assets/images/news8.png";
import news9 from "../../../assets/images/news9.jpg";
import news10 from "../../../assets/images/news10.jpg";
import news11 from "../../../assets/images/news11.jpg";
import news12 from "../../../assets/images/news12.jpg";
import news13 from "../../../assets/images/news13.png";
import news14 from "../../../assets/images/news14.jpg";
import news15 from "../../../assets/images/news15.png";
import news16 from "../../../assets/images/news16.png";
import news17 from "../../../assets/images/news17.png";
import news18 from "../../../assets/images/news18.png";
import news19 from "../../../assets/images/news19.jpg";
import news20 from "../../../assets/images/news20.png";
import news21 from "../../../assets/images/news21.webp";
import news22 from "../../../assets/images/news22.jpg";
import news23 from "../../../assets/images/news23.jpg";
import news24 from "../../../assets/images/news24.jpg";
import news27 from "../../../assets/images/news27.jpg";
import news28 from "../../../assets/images/news28.jpg";
import news29 from "../../../assets/images/news29.jpg";
import news30 from "../../../assets/images/news30.jpg";
import news31 from "../../../assets/images/news31.jpg";
import news32 from "../../../assets/images/news32.jpg";
import news33 from "../../../assets/images/news33.jpg";
import news25 from "../../../assets/images/news25.jpg";
import news26 from "../../../assets/images/news26.jpg";
import newBlog121 from "../../../assets/images/newBlog121.png";
import news122 from "../../../assets/images/news122.jpg";
import new123 from "../../../assets/images/new123.png";
import news124 from "../../../assets/images/news124.jpg";
import news125 from "../../../assets/images/news125.jpg";
import news126 from "../../../assets/images/news126.jpg";
import news127 from "../../../assets/images/news127.jpg";
import news128 from "../../../assets/images/news128.png";
import news129 from "../../../assets/images/news129.jpg";
import news151 from "../../../assets/images/news151.png";
import navigating_impact from "../../../assets/images/navigating_impact.jpg";
import nii2024 from "../../../assets/images/nii2024.jpg";
import plan_break from "../../../assets/images/plan_break.jpg";
import strong_growth from "../../../assets/images/strong_growth.jpg";
import hnb from "../../../assets/images/hnb.jpg";
import trillion_dollar_economy from "../../../assets/images/trillion_dollar_economy.jpg";

import { Link } from "react-router-dom";

export default function AllNews() {
  return (
    <div className="max-w-5xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16 pt-20">
        {newsData?.map((news) => (
          <div className="bg-white shadow-card rounded-md overflow-hidden">
            <div className="h-[160px] overflow-hidden">
              <img
                src={news?.image}
                alt={news?.title}
                className="w-full h-auto"
              />
            </div>
            <div className="p-6">
              <h1 className="text-xl text-primary pb-2">{news?.title}</h1>
              <p className="text-gray-500 pb-2">{news?.date}</p>
              <p className="text-gray-500">{news?.description}</p>
              <Link to={news?.link}>
                <p className="text-primary">read more</p>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export const newsData = [
  {
    id: "151",
    title:
      "ANDE South Asia Convening 2024 Co-hosted by NAB Bangladesh",
    image: news151,
    description:
      "ANDE South Asia Convening is an initiative to bring this ecosystem of entrepreneur support organizations, ...",
    date: "",
    link: "/ande-south-asia-convening-2024",
  },
  {
    id: "121",
    title:
      "Sowing the Seeds of Impact: My Journey in the Bangladeshi Impact Investment Ecosystem",
    image: newBlog121,
    description:
      "My journey into the world of impact investing in Bangladesh began in 2018, fueled by a belief that ...",
    date: "",
    link: "/sowing-the-seeds-of-impact",
  },
  {
    id: "122",
    title: "Impact Journey of BetterStories",
    image: news122,
    description:
      "Before BetterStories, I had worked across the international development and private sectors, engaging in ...",
    date: "",
    link: "/impact-journey-of-betterstories",
  },
  {
    id: "123",
    title: "Building Impact: Our Journey in Australia's Impact Investment",
    image: new123,
    description:
      "Hi, I’m David Hetherington, CEO of Impact Investing Australia. We’re the national peak body for  ...",
    date: "",
    link: "/building-impact/our-journey-in-australias-impact-investment",
  },
  {
    id: "124",
    title: "Mizuho Thinks in Impact",
    image: news124,
    description:
      "Mizuho believes that impact, both positive and negative, must be a key consideration of all  ...",
    date: "",
    link: "/mizuho-thinks-in-impact",
  },
  {
    id: "125",
    title: "Impact Investing – A Means to an End",
    image: news125,
    description:
      "As with many things, my journey in impact investing started more by coincidence than anything else  ...",
    date: "",
    link: "/impact-investing/a-means-to-an-end",
  },
  {
    id: "126",
    title: "Strengthening Ties: TİKA’s Impactful Contributions in Bangladesh",
    image: news126,
    description:
      "The Dhaka Program Coordination Office, which coordinates our Agency's projects for Bangladesh, ...",
    date: "",
    link: "/tikas-impactful-contributions",
  },
  {
    id: "127",
    title: "Shaping the Future: Türkiye’s Journey into Impact Investing",
    image: news127,
    description:
      "The journey of the Impact Investing Advisory Board (EYDK) is one of vision, collaboration, and  ...",
    date: "",
    link: "/turkiyes-journey-into-impact-investing",
  },
  {
    id: "128",
    title: "NAB Trust: New Leaders for Impact Investment",
    image: news128,
    description:
      "NAB Bangladesh is registered as a Trust under the Trusts Act of 1882. This Trust gives the works ...",
    date: "",
    link: "/nab-trust/new-leaders-for-impact-investment",
  },
  {
    id: "129",
    title: "Why Higher Commodity Prices Hurt Smallholder Farmers — and What Can Be Done to Help",
    image: news129,
    description:
      "By investing in SMEs in developing countries, we aim to help them enhance their businesses for ...",
    date: "",
    link: "/why-higher-commodity-prices",
  },
  {
    id: "101",
    title: "Navigating Impact Investment in Bangladesh",
    image: navigating_impact,
    description:
      "The global impact investment industry, totaling USD 715 billion, has grown in depth and sophistication  ...",
    date: "",
    link: "/investment/navigating-impact-investment-in-Bangladesh",
  },
  {
    id: "102",
    title: "Impact InvesTIME: National Impact Investment Day 2024",
    image: nii2024,
    description:
      "The Bangladesh impact investment ecosystem is increasingly gaining enthusiasm and commitment from across the ...",
    date: "",
    link: "/impact-investime:national-impact-investment-day-2024",
  },
  {
    id: "103",
    title: "The plan to break systemic inertia and deliver impact transparency",
    image: plan_break,
    description:
      "Welcome to this Special Edition of Smarter Impact, where you can read through the detailed conversation ...",
    date: "",
    link: "/the-plan-to-break-systemic-inertia-and-deliver-impact-transparency",
  },
  {
    id: "104",
    title: "“A Strong Base for Growth” – EYDK Biennial Report",
    image: strong_growth,
    description:
      "The report includes the founding story of the Impact Investing Advisory Board and offers a summary of ...",
    date: "",
    link: "/a-strong-base-for-growth",
  },
  {
    id: "105",
    title: "HNB and LIIN partner to create access to private equity for SMEs",
    image: hnb,
    description:
      "National Advisory Board for Impact Investing in Sri Lanka (NABIISL), operating under the auspices of ...",
    date: "",
    link: "/hnb-and-liin-partner",
  },
  {
    id: "106",
    title: "Bangladesh: Trillion Dollar Economy!",
    image: trillion_dollar_economy,
    description:
      "Bangladesh has been named as the fastest growing economy in the Asia-Pacific economies out of 32 countries according  ...",
    date: "",
    link: "/bangladesh:trillion-dollar-economy",
  },
  {
    id: "13",
    title: "Impact InveSTART and Bridging the GAP ‘first cut’ – Findings",
    image: news13,
    description:
      "The Impact InveSTART and Bridging the Gap hosted by the National Advisory Board (NAB) for Impact Investment ...",
    date: "December 3, 2023",
    link: "/impact-investart-and-bridging",
  },
  {
    id: "14",
    title: "Bridging the gap",
    image: news14,
    description:
      "Worldwide Impact Investment is gaining momentum and popularity in attracting private sector capital to fulfill SDG commitments. ...",
    date: "December 3, 2023",
    link: "/bridging-the-gap",
  },
  {
    id: "15",
    title: "Impact Auditing",
    image: news15,
    description:
      "Forget pitch decks, coffee meet and fancy offices, the hottest startups of today aren’t just disrupting industries, they’re saving the ...",
    date: "December 3, 2023",
    link: "/impact-auditing",
  },
  {
    id: "16",
    title: "Empowering Impact: Insights from the NAB Bangladesh Conference",
    image: news16,
    description:
      "he fifth annual conference of the National Advisory Board for Impact Investment in Bangladesh took place ...",
    date: "December 3, 2023",
    link: "/insights-from-the-nab-bangladesh-conference",
  },
  {
    id: "17",
    title: "FACTSTATS_ESC VS Impact Investment",
    image: news17,
    description:
      "ESG refers to the infusion of funds in companies that meet ethical considerations of environmental, social, ...",
    date: "December 3, 2023",
    link: "/factstats_esc-vs-impact-investment",
  },
  {
    id: "18",
    title: "National Impact Investment Day 10th November",
    image: news18,
    description:
      "On 10th November 2016, former Finance Minister of Bangladesh Government, late Abul Maal Abdul Muhith launched ...",
    date: "December 3, 2023",
    link: "/national-impact-investment-day",
  },
  {
    id: "19",
    title:
      "Fostering Sustainable Development Through Impact Investment In Bangladesh",
    image: news19,
    description:
      "Introduction: In the vibrant landscape of Bangladesh’s growing economy, the recent Impact Investment Summit marked a significant  ...",
    date: "December 3, 2023",
    link: "/fostering-sustainable-development",
  },
  {
    id: "20",
    title: "10 November: National Impact Investment Day",
    image: news20,
    description:
      "Every Movement looks difficult; Every Revolution sounds impossible, until it happens!  ...",
    date: "October 31, 2023",
    link: "/10-november:national-impact-investment-day",
  },
  {
    id: "21",
    title: "GSG Global Impact Summit 2023 – Innovate. Scale. Impact",
    image: news21,
    description:
      "What an amazing two days we have shared together! More than 1000 passionate impact investment activist had a festive  ...",
    date: "October 30, 2023",
    link: "/gsg-global-impact-summit-2023",
  },
  {
    id: "22",
    title: "Are they time travellers from the future?",
    image: news22,
    description:
      "“It is exciting for me to see that Bangladesh is very actively participating as part of the global impact investment movement ...",
    date: "September 26, 2023",
    link: "/are-they-time-travellers-from-the-future",
  },
  {
    id: "23",
    title: "‘Connecting Creativity’ 2022",
    image: news23,
    description:
      "“It is exciting for me to see that Bangladesh is very actively participating as part of the global impact investment movement ...",
    date: "September 26, 2023",
    link: "/connecting-creativity",
  },
  {
    id: "24",
    title:
      "GSG Global Impact Summit 2023 | Innovate. Scale. Impact | 2-3 October, Málaga, Spain",
    image: news24,
    description:
      "We are excited to announce that the GSG Global Impact Summit 2023 will take place on 2-3 October 2023 ...",
    date: "August 23, 2023",
    link: "/gsg-global-impact-summit",
  },
  {
    id: "25",
    title: "Impact InveSTAR and Impact Invest-ism!",
    image: news25,
    description:
      "On June 2023, the NAB Trust have acknowledged and shared their respect to the ‘STARS’ shining on the Impact Investment ...",
    date: "August 22, 2023",
    link: "/impact-investar-and-impact-invest-ism",
  },
  {
    id: "26",
    title:
      "“We need population weighted AUM” – NAB Bangladesh | GSG GLM @ Istanbul, Türkiye",
    image: news26,
    description:
      "Istanbul, Türkiye, turned into an impact investment leaders ‘Mecca’ between May 30th to 1st June. It was impact ...",
    date: "July 3, 2023",
    link: "/we-need-population-weighted-aum",
  },

  {
    id: "01",
    title:
      "“… the Impact InveSTAR’s of Bangladesh will be envy to many countries…”",
    image: news1,
    description:
      "It was Impact Investment festivity in Bangladesh between 23-25 June 2023. Mr. Cliff Prior, CEO of...",
    date: "July 3, 2023",
    link: "/the-impact-investars-of-bangladesh",
  },
  {
    id: "02",
    title: "Balance priorities for early wins in BIISAP implementation",
    image: news2,
    description:
      "Cliff Prior, CEO of the Global Steering Group for Impact Investment, spoke at the launch of the...",
    date: "May 8, 2023",
    link: "/balance-priorities-for-early-wins-in-biisap-implementation",
  },
  {
    id: "27",
    title:
      "Formation Of National Advisory Board For Impact Investment In Bangladesh",
    image: news27,
    description:
      "In 2018, the National Advisory Board (NAB) for Impact Investment in Bangladesh was formed with the aim of creating ...",
    date: "May 8, 2023",
    link: "/formation-of-national-advisory",
  },
  {
    id: "03",
    title: "IFIC Bank joins NABG",
    image: news3,
    description:
      "The National Advisory Board of Governance (NABG) for Impact Investment in Bangladesh has welcomed...",
    date: "April 3, 2023",
    link: "/ific-bank-joins-nabg",
  },
  {
    id: "28",
    title: "First Impact Fund raising initiative in Bangladesh",
    image: news28,
    description:
      "Build Bangladesh, being the lead in promoting impact investment in Bangladesh since 2016 launched its first Impact Investment ...",
    date: "April 3, 2023",
    link: "/first-impact-fund-raising-initiative-in-bangladesh",
  },
  {
    id: "04",
    title: "On Impact",
    image: news4,
    description:
      "In 2018, Sir Ronald Cohen, Chair of the Global Steering Group (GSG) for Impact Investment authored...",
    date: "March 6, 2023",
    link: "/on-impact",
  },
  {
    id: "29",
    title: "Navigating Impact Investment In Bangladesh",
    image: news29,
    description:
      "The global impact investment industry, totaling USD 715 billion, has grown in depth and sophistication over the last decade ...",
    date: "March 6, 2023",
    link: "/navigating-impact-investment-in-bangladesh",
  },
  {
    id: "05",
    title: "Discussion, Decision, Determination – NABG Meeting",
    image: news5,
    description:
      "The first meeting of the National Advisory Board (NAB) for Impact investment in Bangladesh was...",
    date: "March 6, 2023",
    link: "/discussion-decision-determination-nabg-meeting",
  },
  {
    id: "06",
    title: "NAB Trust: new leader for impact investment",
    image: news6,
    description:
      "The newly established National Advisory Board (NAB) Trust for Impact Investment in Bangladesh held...",
    date: "March 6, 2023",
    link: "/nab-trust-new-leader-for-impact-investment",
  },
  {
    id: "30",
    title: "First International Impact Investment Workshop In Bangladesh",
    image: news30,
    description:
      "On March 21, 2017, Build Bangladesh hosted the first international impact investment workshop – Bringing Impact Investors Together  ...",
    date: "January 31, 2023",
    link: "/first-international-impact-investment-workshop-in-bangladesh",
  },
  {
    id: "07",
    title: "Partnership between NAB Bangladesh and Türkiye NAB",
    image: news7,
    description:
      "National Advisory Board (NAB) for Impact Investment in Bangladesh recently collaborated with Impact...",
    date: "January 31, 2023",
    link: "/partnership-between-nab-bangladesh-and-turkiye-nab",
  },
  {
    id: "08",
    title: "NAB Trust",
    image: news8,
    description:
      "NAB Bangladesh has been registered as a Trust under the Trusts Act of 1882. This Trust will give the...",
    date: "January 31, 2023",
    link: "/nab-trust",
  },
  {
    id: "09",
    title: `"Father of Impact Investment" Applauds Bangladesh’s Progress`,
    image: news9,
    description:
      "Sir Ronald Cohen the Chairman of Global Steering Group for Impact Investment and widely described as...",
    date: "December 4, 2022",
    link: "/father-of-impact-investment",
  },
  {
    id: "31",
    title: `Launch of Impact Investment 10 Nov 2016`,
    image: news31,
    description:
      "November 10, 2016 marked a historic day for the investment ecosystem in Bangladesh. The launch of Build Bangladesh, ...",
    date: "November 30, 2022",
    link: "/launch-of-impact-investment",
  },

  {
    id: "11",
    title: "NAB Bangladesh launches BIISAP",
    image: news11,
    description:
      "The ‘seeding fertile ground’ for impact investment in Bangladesh gave the National Advisory Board...",
    date: "Sep 17, 2022",
    link: "/nab-bangladesh-launches-biisap",
  },
  {
    id: "32",
    title: "Bangladesh Impact Investment Strategy And Action Plan (BIISAP)",
    image: news32,
    description:
      "Today is a historic day for Impact InveStory, story of impact investment ecosystem of a country – the happy Shonar Bangla ...",
    date: "September 19, 2022",
    link: "/bangladesh-impact-investment-strategy-and-action-plan",
  },
  {
    id: "33",
    title: "“Connecting Creativity 2022” for Bangladesh",
    image: news33,
    description:
      "The National Advisory Board (NAB) for Impact Investment in Bangladesh in partnership with Embassy of Switzerland in Bangladesh ...",
    date: "October 28, 2022",
    link: "/connecting-creativity-2022",
  },
  {
    id: "10",
    title: "New Wave of NAB Bangladesh",
    image: news10,
    description:
      "The Member Secretary of NAB Bangladesh – Mr. Arastoo Khan, and the Build Bangladesh (Secretariat of...",
    date: "September 4, 2022",
    link: "/new-wave-of-nab-bangladesh",
  },
  {
    id: "12",
    title: "GSG Global Impact Summit 2021 – Speakers from Bangladesh",
    image: news12,
    description:
      "The 2021 GSG Global Impact Summit is all set to take place virtually from 6-8 October, 2021. The...",
    date: "Dec 27, 2021",
    link: "/gsg-global-impact-summit-2021",
  },
];
