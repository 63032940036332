import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news124.jpg";
import news123_creator from "../../../../assets/images/news124_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function MizohuThinksDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Mizuho believes that impact, both positive and negative, must be a key
          consideration of all aspects of our business. We aspire to be a
          critical player for the sustainable future by working with our
          esteemed clients to generate clearly planned and intended impact.
        </p>
        <p className="pb-6">
          In its aim to realize a sustainable future, in our medium-term
          management plan that started in fiscal 2023, we have established
          impact as a fundamental theme that is key to realizing a sustainable
          society and economy, and we have started our efforts.
        </p>
        <p className="pb-6">
          In order to further promote such initiatives, Mizuho published its
          Impact Business Compass report in May 2024. This report incorporates
          Mizuho's policies regarding impact and the impact business's potential
          and related policy initiatives.
        </p>
        <p className="pb-6">
          Last month, we also formed the first collaboration agreement in Japan
          between with the United Nations Development Programme and a financial
          institution to collaborate in expanding initiatives in the impact
          business aiming to both find solutions to social problems and increase
          economic and corporate value in Asia, including Japan, and indeed the
          world.
        </p>
        <p className="pb-6">
          Impact Business Compass is available here:
          <a
            href="https://www.mizuhogroup.com/binaries/content/assets/pdf/mizuhoglobal/sustainability/overview/report/compass.pdf(PDF/5,029KB)"
            target="_blank"
            className="text-blue-500"
          >
            https://www.mizuhogroup.com/binaries/content/assets/pdf/mizuhoglobal/sustainability/overview/report/compass.pdf(PDF/5,029KB)
          </a>
        </p>
        <p className="pb-6">
          First collaboration agreement in Japan with the United Nations
          Development Programme{" "}
          <a
            href="https://www.mizuhogroup.com/news_release/20241010release_eng.html"
            target="_blank"
            className="text-blue-500"
          >
            https://www.mizuhogroup.com/news_release/20241010release_eng.html
          </a>
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Kotaro Sueyoshi</p>
            <p>Joint General Manager, Sustainable Business</p>
            <p>Mizuho Financial Group</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
