import React from "react";
import { Link } from "react-router-dom";
import news3 from "../../../../assets/images/news3.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function IficDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news3}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          The National Advisory Board of Governance (NABG) for Impact Investment
          in Bangladesh has welcomed the Managing Director & CEO of IFIC Bank
          Limited, Mr. Shah A Sarwar as one of its newest Members. The Chair of
          NABG Bangladesh, along with all the Members, unanimously welcomed the
          membership position for the IFIC Bank at the 6th NABG meeting, held on
          27 February 2023.
        </p>

        <p className="pb-10">
          Mr. Arastoo Khan, the Member Secretary of NABG and Chairman of NAB
          Trust, expressed his enthusiasm for the inclusion of Mr. Sarwar as he
          is the first representative from the financial sector to become a
          Member of the NABG. He believes that the vast experience of Mr. Sarwar
          in financial sector will play a critical role in NABG’s strategic
          planning to promote impact investment in Bangladesh while achieving
          the recommendations proposed in the Bangladesh Impact Investment
          Strategy and Action Plan (BIISAP).
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
