import React from "react";
import news2 from "../../../../assets/images/news2.jpg";
import news11 from "../../../../assets/images/news11.jpg";
import { Link } from "react-router-dom";

export default function BalancePrioritiesDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news2}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          Cliff Prior, CEO of the Global Steering Group for Impact Investment,
          spoke at the launch of the Bangladesh Impact Investment Strategy and
          Action Plan (BIISAP). During his speech, Prior emphasized the
          importance of balancing priorities in implementing BIISAP to achieve
          early wins while building up the capacity of the ecosystem over time.
          He suggested focusing on interventions that can generate early results
          while also strengthening groups, such as amplifying Bangladesh’s story
          to the world, promoting collaboration among ecosystem builders,
          engaging the private sector in impact funds, developing more
          intermediaries, and creating standards for measuring impact.
        </p>

        <p className="pb-10">
          Prior recognized the work of the National Advisory Board for Impact
          Investment in Bangladesh (NAB) in building capacity for creating an
          active impact investment market with strong government support,
          incentives, and active regulators. However, he also highlighted
          challenges that need to be strategically addressed with BIISAP as the
          guiding document to build confidence in the market, bring in new
          supporters, and rethink the approach to achieving net zero and the
          SDGs by 2030.
        </p>

        <p className="pb-10">
          In closing, Prior ensured GSG’s support to Bangladesh in taking impact
          investment to the next level by sharing all the global knowledge and
          insights into the big changes in global finance. He congratulated and
          applauded all the stakeholders for launching such an ambitious and
          detailed impact investment strategy and action plan for Bangladesh.
        </p>

        <p className="mb-10 border-l-4 px-10 py-6 border-gray-200">
          <div></div>
          <p className="text-2xl">Cliff Prior</p>
          <p className="text-2xl">
            Chief Executive Officer, The Global Steering Group (GSG) for Impact
            Investment
          </p>
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
