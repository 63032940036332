import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import GsgGlobalSummitDetails from "./component/GsgGlobalSummitDetails";

export default function GsgGlobalSummit() {
  return (
    <Layout>
      <NewsHeader>
        GSG Global Impact Summit 2023 – Innovate. Scale. Impact
      </NewsHeader>
      <GsgGlobalSummitDetails />
    </Layout>
  );
}
