import React from "react";
import { Link } from "react-router-dom";
import news22 from "../../../../assets/images/news22.jpg";
import news22_details1 from "../../../../assets/images/news22_details1.jpg";
import news22_details2 from "../../../../assets/images/news22_details2.jpg";
import news22_details3 from "../../../../assets/images/news22_details3.jpg";
import news22_details4 from "../../../../assets/images/news22_details4.jpg";
import news22_details5 from "../../../../assets/images/news22_details5.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function TimeTravellersDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news22}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          “It is exciting for me to see that Bangladesh is very actively
          participating as part of the global impact investment movement.” –{" "}
          <span className="font-bold">Professor Shibli Rubayat-Ul-Islam</span>
        </p>

        <div className="flex gap-10 justify-between">
          <p className="mb-10 border-l-4 px-10 py-6 border-gray-200">
            <div></div>
            <p className="text-2xl">Professor Shibli Rubayat-Ul-Islam </p>
            <p className="text-2xl">Chairman,</p>
            <p className="text-2xl font-bold">
              Bangladesh Securities and Exchange Commission
            </p>
            <p className="text-2xl font-bold">(BSEC)</p>
          </p>
          <div className="text-center">
            <img
              src={news22_details1}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>

        <p className="pb-6">Thanks, and a very good afternoon.</p>
        <p className="pb-6">
          First, I would like to thank the Honorable NAB Chair, my respected
          Siraz Bhai as I call him and just now our Honorable ChiefGuest Mr.
          Abul Mannan, Minister for Planning, who has left. Distinguished
          guests, Excellencies, Deputy Governor and otherfriends, colleagues and
          journalists, a very good afternoon and again Assalamualaikum.
        </p>
        <p className="pb-6">
          Yes, it is a very historic moment for all of us working towards
          promoting Bangladesh to continue its growth as one of the fastest
          growing countries in Southeast Asia. Despite all the things happening
          around the world, you can see that the inflation rate that we have
          kept around 7 is above 9 and 10 in the developed world. That means the
          management of supply and this logistics and other things, we have made
          proper arrangement. The leadership of our country did so well that
          either we have our own arrangement, own source of supplies or the way
          we are arranging, it kept the rate of inflation much lower than many
          countries. Our workers have also recently leaving for different
          countries to work for them. So, remittance growth is there, and it is
          expected that it will grow further.
        </p>
        <p className="pb-6">
          When it comes to impact investment, I feel very proud to confirm our
          early entry to this 21st century investment ecosystem. It is exciting
          for me to see that Bangladesh is very actively participating as part
          of the global impact investment movement. It’s something that really
          will help our country or society because by the system, we will be
          able to bring the private sector in contributing environmental and
          social issues. Our Bangladesh Securities and Exchange Commission,
          through its alternative investment rules made in 2015, first inspired
          the traditional investment market to think of alternatives. All the
          time and everywhere we are saying not to be dependent on the
          government’s sources. Yes, that is one of our sources, but the way
          Bangladesh is growing, we need alternative finance and if you do not
          have alternative finance and other sources, the pace that we need
          Bangladesh to grow will never be achieved. So, this is a very good
          arrangement that my very respected Arastoo Khan and his colleagues
          have made. Bangladesh now has a National Advisory Board for Impact
          Investment. It was established in 2018 and supported by BSEC. We have
          supported NAB in 2018 by having a member position.
        </p>
        <p className="pb-6">
          When I was the Dean of the Business School in University of Dhaka, I
          first organized one competition among the students. And I found that
          they are so interested in having these startups, especially those
          relates to Environment and Society. The new generation has a lot of
          inspiration, a lot of quality, a lot of their own innovative ideas
          that need support. Yes, there will be a lot of fallbacks, but the ones
          which will succeed and will be successful, will make huge contribution
          in national development, economic development, and especially
          employment generation. As a finance professor or as I know a little
          bit of economics, now, Bangladesh should only focus on employment and
          employment generation will be the number one solution to take this
          country forward because automatically when you generate employment, it
          creates an industry or something that is moving production oriented
          trading. Automatically, the money cycle, the economic cycle will start
          moving very, very fast. And that is how the government will also
          generate higher income, higher revenue, the banks and other industries
          will also earn more money. So, to me, we have to find scopes and
          opportunities to generate employment.
        </p>
        <p className="pb-6">
          I appreciate very much the initiative taken today because this is also
          so part of this solution. Our Honorable Planning Minister was here.
          They are making wonderful planning for Bangladesh. Their MDG goal, SDG
          goal, the Delta Plan, 2041 Plan, everything is actually happening. It
          is very encouraging because the way the planning ministry is planning
          for future, it is actually achievable. And that’s what we hope by
          2030. These achievements that we hope, and we dream that will be
          achieved, will take Bangladesh to a new height. Bangladesh will have a
          lot of respect if we really make it happen. And for that initiatives
          like these are very much appreciable. I believe the uniqueness and
          strengths of the Bangladesh NAB worked so well that in 2020 they have
          developed and published the Policy Landscape Analysis of Impact
          Investment in Bangladesh. An excellent beginning of the movement with
          strong recommendation that the country needs a National Strategy and
          Action Plan to guide the development of impact investment ecosystem,
          complementing the Bangladesh government’s five-year plan, SDG
          financing gaps, as well as the unexpected challenges generated from
          COVID-19 and now today, due to the Ukraine war. Bangladesh NAB
          developed and published the first ever Impact Investment Strategy and
          Action Plan in 2022. Today I am very proud to be a part of this
          history, making launch of such a milestone document. I am proud not
          only for NAB Bangladesh but for all of us who are promoting Bangladesh
          as the best destination for investment in the 21st century and beyond.
        </p>
        <p className="pb-6">
          Bangladesh Securities and Exchange Commission between last few years
          provided four license for the venture capital funds which are already
          active, amounting to 220 crores. We have also provided approval and
          helped them generating startup funds. And also, we are encouraging
          angel investment, and recently the size of the private equity fund is
          660 crores. In total, between last few years we were part of
          mobilizing 880 crore taka in private equity and venture capital funds.
          Other than these, we also gave permission for environmental impact
          license, and I hope they’re working.
        </p>
        <p className="pb-6">
          I hope, angel investment and impact investment will get a lot from
          many countries including Bangladesh. And regarding environmental
          issues we are heavily working on blue bonds, green bonds, and these
          are so encouraging that the big corporate groups are now coming. Pran
          Group recently took a green bond from us and they are trying to make
          their factories green. Beximco took a solar power project funding from
          us through Sukuk bond. We have hugely financed BRAC and two other NGOs
          for their microcrdit program. If i correctly remember, one NGO
          received financing of 1200 crore, other 800 crore and another one 1400
          crore. So, NGOs are no more looking for external funds. They’re
          generating their funds from the local sources. And they’re providing
          microcredit to help the society and we’re helping them through the
          bonds nowadays. So, I see a lot of possibilities that are happening
          around us. And programs like these arranged by BIDA and NAB authority
          is really time worthy. I congratulate the Chair of NAB Bangladesh, Md
          Sirazul Islam, an excellent personality, and the Member Secretary of
          NAB, Mr Arastoo Khan, who led the journey to establish the impact
          investment ecosystem in Bangladesh. And I’m really feeling honored and
          privileged standing in front of you, speaking in front of you, and I
          thank the organizers for inviting me.
        </p>
        <p className="pb-6">Thank you very much</p>

        <div className="flex gap-10 justify-between">
          <p className="mb-10 border-l-4 px-10 py-6 border-gray-200">
            <div></div>
            <p className="text-2xl">Asif Saleh </p>
            <p className="text-2xl">Executive Director,</p>
            <p className="text-2xl font-bold">BRAC</p>
          </p>
          <div className="text-center">
            <img
              src={news22_details2}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>
        <p className="pb-6">
          “….market failure led us to start an initiative because the private
          sector was clearly not coming in and the public sector was also not
          delivering.” – <span className="font-bold">Asif Saleh</span>
        </p>
        <p className="pb-6">
          I think that last presentation kind of showed also what I wanted to
          say. I want to kind of start from where you (Safak) finished that all
          investments in future should become an impact investment. That’s our
          aspiration. But just to today’s story, I will highlight the social
          enterprise story of BRAC, the journey, just to give an example of what
          is possible in terms of the fact that doing good and good business
          does not have to be mutually exclusive, right? So, telling that story
          and just to share some of the kind of going path forward, what do we
          and how do we see this happening? That’s the idea.
        </p>
        <p className="pb-6">
          So, first of all, is BRAC a poverty fighting organization? Let’s be
          all clear about that first. But what we believe in is that poverty is
          not a one-dimensional issue, people are poor, because they’re
          powerless or is it because of external situations? Lack of access to
          education, access to market, social structure, network, it can be
          various things. So, you have to attack it from all fronts. And hence,
          BRAC’s intervention is also multi-dimensional. But we do believe that
          if you want to have a very sustainable impact and impact at large and
          at scale, you have to link it with market, and hence some of our
          initiatives on social enterprises started. So, let’s hear from our
          founder- Sir Fazle Hasan Abed on how he looked at it with a short
          video clip.
        </p>
        <p className="pb-6 font-bold">
          “I’ve never thought that you can do good only by nonprofit activities,
          you can do good also by doing business. So, let me give you a couple
          of examples. One is that I remember, in the early nineties we were
          lending money to about thirty-forty thousand women and they were
          engaged in vegetable gardening. They will grow okra, they will grow
          brinjal and all kinds of things and put it in a plot. About half an
          acre or something, they will take as lease and then sell the
          vegetables. We found the recovery rate was 96 percent. I looked at the
          data on vegetable cultivators and found everyone else’s recovery rate
          is 98-99 percent. Then why for them (women cultivators) it is only
          ninety six percent, so their productivity must be low. So, I sent out
          a group of agronomists to find out their problem. They quickly found
          out that seeds were the main problem. They couldn’t find high quality
          vegetable seeds. So, I thought why not go to vegetable seed business
          because there were no high-quality vegetable seeds available in the
          market. So, BRAC then went into vegetable seed business. So, BARC now
          has become the second largest seed producer in the country.” – Sir
          Fazle Hasan Abed
        </p>
        <p className="pb-6">
          This is just an example, where how market failure led us to start an
          initiative because the private sector was clearly not coming in and
          the public sector was also not delivering. So, our social enterprise,
          sort of the ones that we have started over the years worked in three
          critical areas.
        </p>
        <p className="pb-6">
          One was linking rural producers who did not have access to bigger
          markets other than their villages. How can we link the entire chain
          and create that? Next, promoting livelihood was a big idea that in a
          rural economy you wanted to create newer kinds of economic activity so
          that people could generate more income. And third, was the underserved
          sectors where there was not enough interest, can we create newer kind
          of opportunities? And you know, starting with artificial insemination,
          to creating poultry, there are lots of examples like that where BRAC
          was the first one to come in, and then later the private sector when
          they saw that there are profits to be made, they came in after that.
        </p>
        <p className="pb-6">
          So, Aarong started with the notion that we will buy the product from
          them regardless of whether we can sell it or not. That’s number one
          and number two was we will pay them immediately as we were buying the
          products. So, the risk is ours. And number three was that to better
          the marketability of the products, we will provide them with better
          quality input and also help them with access to better designs that
          the urban consumers actually like. Hence, Aarong started and then that
          led to expansion over the years.
        </p>
        <p className="pb-6">
          There are a lot of people who have seen Aarong’s stores, but they
          don’t know that there is a very complicated supply chain behind that
          which has centers, in very remote areas. The centers where Artisans
          actually produce these products. So, we have almost about 20,000
          artisans who are connected to 15 main centers, we call them Ayesha
          Abed foundation. It’s a separate Foundation, which trains the artisans
          and at the same time generates the products from them. And there are
          about 700 sub centers. Where these sub centers will be created is
          predominantly decided based on where the needs are the most, where the
          poverty is the most, where their talents are the most, but they are
          not getting access to market. It’s not based on how we can minimize
          our costs. If that was the motivation then all the centers will be
          near Dhaka, but majority of these centers are in more remote places.
        </p>
        <p className="pb-6">
          So, this is just to give you an example that in this age of machine
          produced delivery, in order for us to run a social enterprise which is
          predominantly motivated by social good, you actually have to do a lot
          of work behind the scenes, right? And which can easily be avoided if
          we were just predominantly motivated by profit maximization. So, all
          these costs would not have been kicked in. I’m not going to go through
          this but right now about 65,000 artisans are connected to BRAC’s
          Aarong and they are generating their livelihood. Not only do we help
          them with income generation, but we also give them other integrated
          services like health security, financial inclusion and other things.
          So, it’s a combined holistic intervention as well. And just to show
          that, doing all of these also didn’t mean that we were sort of a
          subsidized loss-making entity. It has generated steady profit for
          Aarong and that meant steady growth. Only sort of dropped you see is
          after 2019 when we first broke the 1000 Crore taka revenue barrier.
          Then COVID started so there was a drop and then slowly it’s back up
          again in 2021 onwards. Again, it’s a very profitable initiative that
          not only reinvest and growing but at the same time for BRAC which does
          a lot of grant making/subsidized work, Aarong’s profit comes in and
          pays for some of those.
        </p>
        <p className="pb-6">
          So there are many social enterprises like that. So, when we say from
          milk to silk, we’ve got it covered. So, sericulture, Aarong Dairy, the
          seeds you heard about, fisheries, artificial insemination, cold
          storage, nursery, all these started with the notion that rural economy
          needs new kind of intervention and they need market linkages.
        </p>
        <p className="pb-6">
          There are social investments that we have made which are separate
          entities outside BRAC, like BRAC bank which started with the
          motivation of to support the missing middle, the SME banks, which was
          not financed by traditional banks at that time. So, in 2001 BRAC bank
          started with funding from IFC and others and also BRAC’s own funding.
          It has now become one of the most profitable banks and at the same
          time still serving its largest portfolio which is the SME portfolio.
          So again, doing good and good business does not have to be mutually
          exclusive. And you all know about one of BRAC bank subsidiaries which
          is Bkash, which has now become the biggest mobile financial service
          provider in the world.
        </p>
        <p className="pb-6">
          So, these are some of the other initiatives that we have started. But
          going forward, obviously, how do we look at this? Again, not
          necessarily BRAC will incubate a lot of these new initiatives, we see
          that there are other good players who can come in, who need financing
          and who can leverage BRAC’s work. So, we have started an Impact Fund
          partnering with Osiris group which is a professional fund manager. And
          now from there, we have started funding newer initiatives and also, we
          fund our own initiatives. Just to give you an example, we have
          recently funded two Dotcom startups- one is an online retail store,
          Shajgoj and another one is an online travel agency, Go Zayan. These
          are all doing really well. And then Bhumijo is an organization that
          makes female friendly public toilets across the country and its seed
          funding also came from BRAC.
        </p>
        <p className="pb-6">
          At the end, I’ll show just a short two-minute video on Aarong. But
          before I show the video, I just wanted to say a few reflection points.
          First of all, not all development financing can happen through impact
          investment, Right? So, there are still a lot of ultra- poor in this
          country. There is free education that has to happen. There is
          humanitarian support that has to happen. It cannot happen when we
          expect returns in these areas, investment and subsidies has to be
          provided. Number two is that this example perhaps shows that what can
          be possible at large scale. It is not only an employment generation
          tool, but a huge amount of social good is possible. I don’t know of
          any sort of (other than microfinance) social enterprises at this scale
          around the world that has happened. So of course, we did not coin the
          word social enterprise 45 years ago when Aarong started, but it is
          very much a social enterprise.
        </p>
        <p className="pb-6">Thank you.</p>

        <div className="flex gap-10 justify-between">
          <p className="mb-10 border-l-4 px-10 py-6 border-gray-200">
            <div></div>
            <p className="text-2xl">Sir Ronald Cohen </p>
            <p className="text-2xl">Chair,</p>
            <p className="text-2xl font-bold">
              Global Steering Group (GSG) for Impact Investment
            </p>
          </p>
          <div className="text-center">
            <img
              src={news22_details3}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>

        <p className="pb-6">
          “For all of us who have been fortunate enough to make capital, we
          can’t take it with us in any case, so let’s act responsibly with it.
          Let’s put something back in.” –{" "}
          <span className="font-bold">Sir Ronald Cohen</span>
        </p>
        <p className="pb-6">
          Good morning, everyone. It’s a pleasure for me to join you today from
          France.
        </p>
        <p className="pb-6">
          This is a historic moment for Impact Investment, all of the efforts
          that you have been making in Bangladesh and the efforts the GSG has
          been making. I met with Farhad at my home in 2016 in London and since
          then it led us to historic points.
        </p>
        <p className="pb-6">
          When Bangladesh had become the member of GSG, it was the 19th country.
          Today we are 35 countries with 50 taskforces in another 15 countries
          on their way to becoming part of the organization. And your progress
          in Bangladesh has been very impressive. You have managed to bring
          together the government with the PM for all the support since 2017.
          Business, the social sectors, organizations in the world of finance
          and the related areas to create real momentum in Bangladesh and to
          play a leadership role in South Asia.
        </p>
        <p className="pb-6">
          For many years Impact has been a peripheral subject, both within the
          world of business and within the world of government but things are
          changing now across the world, and we are finding that there is hardly
          a company whose boardroom is not talking about impact and impact
          measurements today. We are finding government and official bodies in
          the form of the SECs for the United States which regulate all
          financial activities there. In the form of the EU or commission which
          is pushing the impact agenda in Europe and then, in the form of the
          organization responsible for accounting outside of the united states
          across the whole world, we are finding everywhere an emphasis now of
          brining transparency to the impact that companies create and we are
          beginning to see the tools that we have invented like social impact
          bond which we invented in 2010 began to get much great attraction in
          the financial market than we could have expected in this time frame.
        </p>
        <p className="pb-6">
          Last year a trillion dollars was raised across the world in
          sustainability impact bonds and loans where the rate of interest of
          the borrower falls if certain environmental or social objectives are
          achieved. And bringing pay for success into the main lending markets
          is a fantastic step forward for our movement. Because every company
          today has the option to embrace impact or try to avoid it. And we see
          the thoughts of leaders embracing it, going beyond the short-term
          considerations of performance, to say if we want to have a thriving
          company then we need to look beyond this year to the decade ahead.
          And, the decade ahead sees changes in consumer values which lead to
          consumers adopting the products of the companies that do good, talent
          joining these companies and investors flocking to these companies. So,
          we are in the process of changing now the drivers of our whole
          economies across the world. Changing them from profit to learn to
          profit and measurable impact.
        </p>
        <p className="pb-6">
          Now I am personally very proud that on impact- the guide to the impact
          revolution which was launched in India at the summit in 2017, was
          first translated into a foreign language when it was translated into
          Bangla. That makes me feel that our ideas are spreading in Bangladesh
          because of the leadership that all of you are showing. And the work
          that we have been doing with UN ESCAP, with British Council and the
          Embassy of Switzerland to publish policy landscape analysis of impact
          investment in Bangladesh is a major milestone for our movement as well
          as for your country. This is a first time that we have looked in
          detail at the impact investment ecosystem in Bangladesh and identified
          the existing tracks, and challenges and opportunities and threats to
          support our movement as part of the global effort. An excellent
          beginning with strong recommendations for National Strategy and Action
          Plan, which now has to be a major objective.
        </p>
        <p className="pb-6">
          I believe that every country will soon be following your lead in
          pushing for such a plan. Because negative impacts through the
          perpetuation of poverty, differences in remuneration, lack of action
          on social issues that plague countries, lack of education which
          inhibits social mobility, homelessness which is characteristics of so
          many countries both in the emerging and in the developed world, issues
          around health. All of these issues require money from the government,
          that the government does not have in its coffers. So, our approach of
          bringing the private capital markets to achieve the priorities that
          the government sets in the social and environmental areas has become a
          necessity now. So, we share your enthusiasm for pushing at government
          level and bringing change top-down, while also pushing bottom-up to
          create initiative on the ground that improves our lives and our
          planet.
        </p>
        <p className="pb-6">
          So, I leave you with my best wishes for your continuous success. I
          look forward to continuing to support your efforts and visiting you in
          person before too long. Thank you and goodbye.
        </p>

        <div className="flex gap-10 justify-between">
          <p className="mb-10 border-l-4 px-10 py-6 border-gray-200">
            <div></div>
            <p className="text-2xl">Şafak Müderrisgil</p>
            <p className="text-2xl">President,</p>
            <p className="text-2xl font-bold">Turkiye NAB (EYDK)</p>
          </p>
          <div className="text-center">
            <img
              src={news22_details4}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>
        <p className="pb-6">
          “In a nascent market, capacity building and raising awareness among
          all sectors count as a crucial and ongoing process in order to form a
          well-functioning ecosystem” –{" "}
          <span className="font-bold">Şafak Müderrisgil </span>
        </p>
        <p className="pb-6">Good afternoon to you all.</p>
        <p className="pb-6">
          Chair of NAB Bangladesh, Excellencies, CEO of NAB Bangladesh and
          distinguished guests, it’s my pleasure and honor to be here today, to
          represent Turkiye at this very important day, that we are to observe –
          the launch of Bangladesh Impact Investment Strategy and Action Plan.
        </p>
        <p className="pb-6">
          I have brought heartfelt greetings from Turkiye as the Turkish
          counterparts of the global impact investing movement and I would like
          to express our support and admiration to our colleagues in Bangladesh
          to make it happen, many congratulations. NAB Bangladesh was founded in
          August 2018 and became a member to GSG in October 2018, way before the
          Turkish NAB’s interference to the global impact investing system. So,
          we have followed NAB Bangladesh very closely from the day we started
          our journey to establish EYDK as the Turkish NAB. When we approached
          NAB Bangladesh, to listen to their journey to become a NAB, and their
          vision and strategy, we all were received very well, and it was
          informed very thoroughly by our counterparts. Therefore, I would like
          to take this opportunity to thank Mr. Farhad Reza, the CEO, and his
          team for their openness and kindness in sharing their knowledge and
          experience. I would also like to stress on GSG’s crucial role here, as
          a leader organization to establish and develop the global impact
          investing ecosystem that brings us all together from the finance,
          business, and philanthropy sectors. It is GSG’s success to bring us
          together as leaders for impact investing on country level and motivate
          them to come together and work together as we are doing today.
        </p>
        <p className="pb-6">
          In the impact space, a multi-stakeholder approach is very important.
          Impact investing as a noble concept requires all parties from sectors
          to step in to catalyze impact investment and entrepreneurship to
          benefit people and the planet. In that respect, having the government
          agencies on board is a very important starting point as policy making
          and regulations consist of the backbone of establishing a national
          ecosystem for impact investing in a country. Therefore, it is an honor
          to see the support of the government officials here today, as well as
          the private sector leaders and the global supporters at this
          groundbreaking event.
        </p>
        <p className="pb-6">
          As many of the other national advisory boards in the world, EYDK aims
          to create an enabling environment to facilitate the growth of impact
          investing and to establish a well-functioning ecosystem in Turkiye.
          Considering the global challenges, it is our vision to make impact
          investing mainstream, as a sustainable and inclusive investment choice
          for a better and more sustainable future.
        </p>
        <p className="pb-6">
          Firstly, when you are talking about establishing an ecosystem for
          impact investing, mobilizing all sectors from government agencies to
          private sector players and non-profit actors’ matter, which counts as
          one of the main strategies and as a primary strategy it is really very
          important.
        </p>
        <p className="pb-6">
          Secondly, in a nascent market, capacity building and raising awareness
          among all sectors count as a crucial and ongoing process in order to
          form a well-functioning ecosystem.
        </p>
        <p className="pb-6">
          And thirdly, creating a common language for impact investing,
          knowledge sharing and developing a clear understanding of impact
          measurement and management are also among our strategies. Turkiye’s
          journey with impact investment dates back to 2019 when the stakeholder
          mapping and preliminary analysis of an impact investing ecosystem was
          conducted by UNDP in Turkiye. This study was negotiated and started by
          the Ministry of Foreign Affairs at that time and then funded and
          launched by the investment office of the presidency.
        </p>
        <p className="pb-6">
          Therefore, we can say that the government played a crucial role to
          point out the need of exploring potential for an impact economy in
          Turkiye. And I am happy to see Ambassador Mustofa Osman Turan here as
          in those days in 2019, he was in Ankara, he was one of the key people
          who was negotiating the necessity of running this analysis in Turkiye
          and it’s a great opportunity and a coincidence to see him here today
          as the Ambassador to Dhaka.
        </p>
        <p className="pb-6">
          The first report done by UNDP led the way to initiate discussions and
          activities among the key stakeholders and eventually a task force for
          impact investment was formed in November 2020. Right after this
          report, another, a second report was produced by UNDP which is the SDG
          investor map, launched in March 2021 and it played an important role
          to introduce the sector readiness and relevant potential investment
          areas for SDGs and impact investments in Turkiye. This was and still
          is a great resource for private sector investors in Turkiye. These two
          reports are the starting point and very important as a reference to
          start our journey.
        </p>
        <p className="pb-6">
          We then produced a white paper on impact measurement and management,
          and we launched it together with UNDP in December 2021 and produced
          our second report right after called ‘Unity is Strength’ that reflects
          the findings of our research conference held with our members and key
          stakeholders in order to design our teams and content of our working
          groups. These two reports of EYDK helped us to design our strategy on
          an evidence-based approach. Our founding members are from the two UNDP
          offices, the investment office of the Presidency, IT Valley,
          Development and Investment bank of Turkiye and Etkiyap -the first
          impact investing platform of Turkiye. As I mentioned, the first two
          UNDP reports were kind of our reference documents and the opportunity
          areas emerged from those reports. From the first report, we could see
          the refugee livelihoods, women’s empowerment, renewable energy, health
          & tech, and financial inclusion are the areas that has a potential in
          Turkiye. The SDG map of Turkiye gives us nine sectors and 27
          investment areas as reference points. These sectors are technology and
          communications, transportation, renewable and alternative energy,
          education, healthcare, food and beverages, consumer goods,
          infrastructure, and finance.
        </p>
        <p className="pb-6">
          Government and policymaking consist of the backbone of forming an
          impact investing ecosystem. Turkiye is growingly adopting an SDG based
          approach in this is policymaking. And, as EYDK we prioritize aligning
          with the national agenda. Therefore, the 11th development plan,
          mid-term program, FDI strategy and economic reform action plan and
          Green Deal action plan are all our major government documents that we
          align with.
        </p>
        <p className="pb-6">
          Finally, I will keep on promoting that in the future, all investment
          should be Impact Investment. I would like to again congratulate NAB
          Bangladesh for the BIISAP launch and express my gratitude and respect
          to you all.
        </p>

        <div className="flex gap-10 justify-between">
          <p className="mb-10 border-l-4 px-10 py-6 border-gray-200">
            <div></div>
            <p className="text-2xl">Architect Farhadur Reza </p>
            <p className="text-2xl">President, Build Bangladesh</p>
            <p className="text-2xl font-bold">CEO, NAB Bangladesh</p>
          </p>
          <div className="text-center">
            <img
              src={news22_details5}
              alt=""
              className="w-full h-auto rounded-md shadow-xl object-contain"
            />
          </div>
        </div>

        <p className="pb-6">
          “There has never been a greater challenge and opportunity for
          investors to be innovative and creative to complement social,
          economic, and environmental reforms while ensuring financial gain to
          win against the negative impacts of COVID-19 and the war in Europe.” –{" "}
          <span className="font-bold">Farhad Reza</span>
        </p>
        <p className="pb-6">
          Good afternoon, it is now my responsibility to share the Impact
          InveStory of the National Advisory Board for Impact Investment in
          Bangladesh and the Bangladesh Impact Investment Strategy and Action
          Plan including some backgrounds.
        </p>
        <p className="pb-6">
          Today is a historic day for Impact InveStory, story of impact
          investment ecosystem of a country – the happy Shonar Bangla, dream of
          the father of the Nation Bangabandhu Sheikh Mujibur Rahman. Not an
          easy journey though! We fought colonialism, war of independence,
          famine, disaster, needed extra money for food, clothing, housing,
          education, health, agriculture – the overall wellbeing for the people
          in this region.
        </p>
        <p className="pb-6">
          Our region started with a different ‘model’ of banking. The early
          banking system was introduced by the Afghan traders popularly known as
          Kabuliwalas¹. Muslim businessmen from Kabul, Afghanistan came to India
          and started money lending business in exchange of interest sometime in
          1312 A.D. During the period of British rule, the merchants established
          the first modern bank headquartered in Dhaka, the Dacca Bank,
          established in 1846. It was purchased by Bank of Bengal in 1862. Bank
          of Bengal opened branches in Sirajganj and Chittagong in 1873, and in
          Chandpur in 1900.
        </p>
        <p className="pb-6">
          People gradually started to save, women started to transfer their
          savings from non-secured steal trunk, pillow covers, bamboo poles
          inside the home to more formal arrangements through Banks. Still long
          way to go though!
        </p>
        <p className="pb-6">
          Since the birth of Bangladesh as an independent country in 1971, its
          very first private sector bank was AB Bank Limited. This bank was
          incorporated on 31st December 1981 and started its effective operation
          from 12th April 1982.
        </p>
        <p className="pb-6">
          Globally, in relation to the financial/banking industry, the Jews in
          Jerusalem introduced a kind of banking in the form of money lending
          before the birth of Christ. The word ‘bank’ was probably derived from
          the word ‘bench‘ as during ancient time Jews used to do money –
          lending business sitting on long benches.
        </p>
        <p className="pb-6">
          First modern banking was introduced in 1668 in Stockholm, which opened
          up a new era of banking activities throughout the European Mainland.
          The industrial revolution needed new financial instruments. New
          changes were introduced with the emergence of the gold standard, stock
          markets and insurance.
        </p>
        <p className="pb-6">
          First stock exchange was established in London (1698). Philadelphia
          Stock Exchange was one of the first stock brokerage houses. People
          started to invest in companies that are efficient and produce profits,
          leading to overall economic growth through competition.
        </p>
        <p className="pb-6">
          The World War required war-making states to mobilize and sustain the
          financial resources. What made war finance during the conflict so
          special is that this challenge had never been confronted in a world
          economy as large, deeply interconnected, and sophisticated as that
          which existed in 1914.
        </p>
        <p className="pb-6">
          Financial institutions gradually transformed over last centuries into
          different streams to adapt micro finance, social business, private
          equity, venture capital, family office, trust, grants, charity,
          not-for-profit, philanthropy etc.
        </p>
        <p className="pb-6">
          The question is how COVID-19 and the war in Europe will influence to
          change rest of 2022 and future socio-economic indicators, investments
          and eventually the overall financial ecosystem of Bangladesh. The
          impacts of these disasters are pushing us to rethink and re-evaluate
          our work, and what it might mean for us and the next generation
          investors/investees to design for a Bangladesh that will probably not
          be the same! What will be our new perception and goals when working
          with the existing financial instruments or designing new investment
          instruments to do well and do good at the same time?
        </p>
        <p className="pb-6">
          There has never been a greater challenge and opportunity for investors
          to be innovative and creative to complement social, economic, and
          environmental reforms while ensuring financial gain to win against the
          negative impacts of COVID-19 and the war in Europe. We have moved
          forward from profit to risk, risk to impact! From only profit, to
          profit, people, and planet! We realised, people do not want charity,
          they want a chance.
        </p>
        <p className="pb-6">
          In 2007, Rockefeller Foundation introduced the concept of Impact
          Investment. We got familiar with ESG complying businesses, SDG since
          2015 along with the SDG financing gap. According to the 2017 SDG
          Financing Strategy, Bangladesh requires an additional $928 billion to
          implement the Sustainable Development Goals by 2030, with the private
          sector accounting for 42 per cent.
        </p>
        <p className="pb-6">
          Can Impact Investing complement us in this difficult journey? We
          believe it can.
        </p>
        <p className="pb-6">
          Bangladesh became part of the global movement of Impact Investment and
          became the 19th member country of the Global Steering Group of Impact
          Investment in 2018. Bangladesh Government visioned this even earlier,
          Bangladesh Securities and Exchange Commission released the Alternative
          Investment Rules 2015 to securities – private equity, venture capital
          and Impact Fund in 2015. Bangladesh Bank extended its support for
          Impact Investment – linking Impact Investment with Green Finance and
          also tax incentives announced by the National Board of Revenue are
          very encouraging for the ecosystem players. Private sector like the
          Impress Group with their Impact Investment stream Build Bangladesh
          came forward and organized the first international conference on
          Impact Investment in November 2016 followed by the first international
          workshop on impact investment in 2017.
        </p>
        <p className="pb-6">
          It has been a genuine inspiration for impact investment activists like
          us, when the Hon. Prime Minister Sheikh Hasina supported the concept
          of Impact Investment, by launching the first Impact Investment
          campaign for Bangladesh at a side event in New York at the United
          Nations General Assembly meeting in 2017. Again in 2017 UN passed a
          resolution including Impact Investment for the first time, and
          Bangladesh played significant role to make it happen.
        </p>
        <p className="pb-6">
          The leadership demanded a planned development of the ecosystem,
          required a structure, required an advisory board rather than running
          with series of projects with just a badge of impact investment, may
          result into impact washing. Hence, the creation of the National
          Advisory Board (NAB) for Impact Investment in Bangladesh in 2018 with
          the strong support and recommendation from the than Finance Minister
          late Abdul Muhith. The first Chair was the than Secretary of ERD,
          second chair was also the following Secretary of ERD. Currently, we
          are extremely lucky to have Md Sirazul Islam the Executive Chairman of
          BIDA as the Chair of NAB Bangladesh.
        </p>
        <p className="pb-6">
          NAB Bangladesh has membership from other key government departments
          including Bangladesh Bank, BSEC, PPPA, DSE. It also has members from
          Save the Children, UNDP, Embassy of Switzerland, ADB, FCDO, BRAC,
          BWCCI, IAB, Legal Circle and IFC plus JICA are part of the NAB as
          observers. Build Bangladesh works as NAB’s Secretariat. NAB
          Bangladesh, in partnership with the UNESCAP, Embassy of Switzerland,
          British Council Dhaka and Build Bangladesh first produced the Policy
          Landscape Analysis for Impact Investment in Bangladesh in 2020.
          Followed by the Bangladesh Impact Investment Strategy and Action Plan
          (BIISAP) in 2022, supported by the Embassy of Switzerland and Build
          Bangladesh.
        </p>
        <p className="pb-6">
          To ensure, the implementation plans are discussed thoroughly and
          actioned appropriately, Build Bangladesh and Light Castle Partners
          jointly hosted 4 stakeholders workshop supported by the NAB
          Bangladesh, Embassy of Switzerland and BRAC in July 2022. These
          workshops included participants from the groups of investees,
          investors, intermediaries, regulators, and development partners.
        </p>
        <p className="pb-6">
          Today, we are launching the National Strategy. This is the first
          strategy to guide the implementation of Impact Investment from a
          national perspective, developed through an inclusive consultative
          process. This will be reviewed in 2025 as in 2026, the country will be
          graduated from its LDC status and the whole document will be updated
          in 2030. I must acknowledge the comprehensive works that has been
          undertaken by Light Castle Partners and Build Bangladesh to bring this
          document together has been exemplary.
        </p>
        <p className="pb-6">
          You all have a copy of the Strategy. The nine recommendations from the
          strategy are grouped under 5 priority intervention areas:
        </p>

        <ol className="pb-10 pl-20 flex flex-col gap-4">
          <li>Positioning and Identity </li>
          <li>Collaborations and Inclusivity </li>
          <li>Education and Training </li>
          <li>Alternative Financing Instruments and Tools </li>
          <li>Incentives and Policy Follow-through </li>
        </ol>
        <p className="pb-6">
          The growth of impact investment in such a supportive ecosystem is the
          best investment instrument to work as the ‘antidote’ against the
          possible forthcoming negative impacts. According to the Global Impact
          Investment Network (GIIN), impact investing has attracted increasing
          interest among investors who are allocating more capital to impact
          investments year-over-year. It has identified the overall global
          investment is more than $250 trillion and projecting the prospects for
          capturing at least one percent of this investments, which is around
          $2.5 trillion, for global impact investment.
        </p>
        <p className="pb-6">
          With enabling ecosystem and ingredients, few private sectors have
          already delivered projects and programmes at the grass root level
          across Bangladesh, which revealed the effectiveness of impact
          investment as an effective tool. It is now time for reinforcing impact
          investments in Bangladesh through ongoing/new instruments like the
          Impact Entrepreneurs Incubator/Accelerator Programme (ygap
          Bangladesh), Outcomes Based Funding (e.g. Social Impact Bond and
          Development Impact Bond) (BRAC).
        </p>
        <p className="pb-6">
          Corporate Social Impact investment (CSII) and Micro Impact Investment
          (MI2) (Build Bangladesh and yGap), Impact Based Open Ended Mutual
          Funds (ICL), initiatives like Binyog Briddhi (Embassy of Switzerland,
          RoI, LCP) to prepare the next generation investors and investees with
          enough outcome focused energy including the works done by the IFC,
          UNDP, JICA, FCDO, USAID, DFAT, and ADB.
        </p>
        <p className="pb-6">
          Impact investment is becoming an influential instrument to bring
          different mixtures of people, ideas, and resources to challenge
          difficult social issues while allowing positive solutions to achieve
          social and environmental good. Impact investment are now being used to
          finance initiatives such as aged care, health, housing, education,
          infrastructure, water and sanitation, financial inclusion, sustainable
          agriculture, and development of the services sector. The National
          Advisory Board (NAB) for Impact Investment in Bangladesh will deliver
          education/awareness programme focusing Impact Investment ecosystem
          players. Impact Investment is the best instrument to achieve long-term
          goals ensuring 21st century way forward for Bangladesh while
          responding to the need for people, planet, and prosperity.
        </p>
        <p className="pb-6">
          Finally, I would like to conclude my Impact InveStory by sharing my
          true feelings:
        </p>
        <p className="pb-6 font-bold text-xl italic">
          “Tomorrow is never promised, today is too short, so, do not just
          dream, make some legacies.”
        </p>
        <p className="pb-6 ">Thank you.</p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
