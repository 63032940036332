import React, { useEffect, useState } from 'react'
import { Button, GreenOutlineButton } from '../../../shared/ui/Button'
import { useFirestore } from '../../../hooks/useFirestore';

export default function JoinNabBdFormRight() {
    let initialObj = {
        firstName: "",
        lastName: "",
        title: "",
        organization: "",
        email: "",
        industry: "",
        address: "",
        postalCode: "",
        city: "",
        phone: "",
        category: "",
        reference: "",
        referenceName: "",
        referenceAddress: "",
        referenceEmail: "",
        referenceDesignation: "",
    }
    let [formObj, setFormObj] = useState({ ...initialObj });
    let [success, setSuccess] = useState("");

    const { addDocument, response } = useFirestore("JoinForm");

    console.log("response", response);

    useEffect(() => {
        if (response.success) {
            setFormObj({ ...initialObj });
            setSuccess("Form Submited Successfuly.");
        }
    }, [response.success])

    const handleSubmit = e => {
        e.preventDefault();
        setSuccess("");
        addDocument(formObj);
    }
    return (
        <div>
            <form className='bg-[#EEEEEE] p-4 rounded-md' onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='First Name' value={formObj?.firstName} onChange={e => setFormObj({ ...formObj, firstName: e.target.value })} />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Last Name' value={formObj?.lastName} onChange={e => setFormObj({ ...formObj, lastName: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Title' value={formObj?.title} onChange={e => setFormObj({ ...formObj, title: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Organization' value={formObj?.organization} onChange={e => setFormObj({ ...formObj, organization: e.target.value })} required />
                    <input type="email" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Email' value={formObj?.email} onChange={e => setFormObj({ ...formObj, email: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Industry' value={formObj?.industry} onChange={e => setFormObj({ ...formObj, industry: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Address' value={formObj?.address} onChange={e => setFormObj({ ...formObj, address: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Postal Code' value={formObj?.postalCode} onChange={e => setFormObj({ ...formObj, postalCode: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='City' value={formObj?.city} onChange={e => setFormObj({ ...formObj, city: e.target.value })} required />
                    <input type="pone" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Phone' value={formObj?.phone} onChange={e => setFormObj({ ...formObj, phone: e.target.value })} required />
                    <select className='col-span-2 p-[12px] rounded-md focus:outline-none text-gray-400' value={formObj?.category} onChange={e => setFormObj({ ...formObj, category: e.target.value })} required>
                        <option value="">I represent the category (Choose One)</option>
                        <option value="Investees (Startup, SMEs, Social Enterprise, etc.)">Investees (Startup, SMEs, Social Enterprise, etc.)</option>
                        <option value="Intermediaries (Business Incubation Service Provider, Financial Institution, Bank, Assest Management Film, etc.)">Intermediaries (Business Incubation Service Provider, Financial Institution, Bank, Assest Management Film, etc.)</option>
                        <option value="Investor (Angle Investors Network, VC Firm, High Net Worth Individual, Private Sector, etc.)">Investor (Angle Investors Network, VC Firm, High Net Worth Individual, Private Sector, etc.)</option>
                        <option value="Regulators, Development Partners, NGOs">Regulators, Development Partners, NGOs</option>
                    </select>
                    <input type="text" className='col-span-2 p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Reference(if applicable)' value={formObj?.reference} onChange={e => setFormObj({ ...formObj, reference: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Reference Name' value={formObj?.referenceName} onChange={e => setFormObj({ ...formObj, referenceName: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Reference Address' value={formObj?.referenceAddress} onChange={e => setFormObj({ ...formObj, referenceAddress: e.target.value })} required />
                    <input type="email" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Reference Email' value={formObj?.referenceEmail} onChange={e => setFormObj({ ...formObj, referenceEmail: e.target.value })} required />
                    <input type="text" className='p-[12px] rounded-md focus:outline-none text-gray-400 placeholder:font-thin' placeholder='Reference Designation' value={formObj?.referenceDesignation} onChange={e => setFormObj({ ...formObj, referenceDesignation: e.target.value })} required />
                </div>
                <p className='py-6'>I have read and unsdertood the statutes of the assocation and agree to be invoiced the membership and service fees as applivable</p>
                {success && <p className='text-primary font-bold'>{success}</p>}
                <div className='text-right'>
                    <GreenOutlineButton className="outline">Submit</GreenOutlineButton>
                </div>
            </form>
        </div>
    )
}
