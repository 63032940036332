import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import TurkiyesJourneyDetails from "./components/TurkiyesJourneyDetails";

export default function TurkiyesJourney() {
  return (
    <Layout>
      <NewsHeader>
        Shaping the Future: Türkiye’s Journey into Impact Investing
      </NewsHeader>
      <TurkiyesJourneyDetails />
    </Layout>
  );
}
