import React from "react";
import { Link } from "react-router-dom";
import news21 from "../../../../assets/images/news21.webp";
import news21_details1 from "../../../../assets/images/news21_details1.png";
import news21_details3 from "../../../../assets/images/news21_details3.png";
import news21_details4 from "../../../../assets/images/news21_details4.png";
import news21_details5 from "../../../../assets/images/news21_details5.jpg";
import news21_details6 from "../../../../assets/images/news21_details6.jpg";
import news21_details7 from "../../../../assets/images/news21_details7.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function GsgGlobalSummitDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news21}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Congratulations to Sri Lanka, Malaysia, Peru, Belgium, and Norway!
        </p>
        <p className="pb-6">
          What an amazing two days we have shared together! More than 1000
          passionate impact investment activist had a festive time in Malaga,
          Spain at the GSG global summit on 2nd and 3rd October 2023. Bangladesh
          NAB was also among the key participants.
        </p>
        <p className="pb-6">
          The key outcome this year was to include five more new countries into
          the GSG Family. These are Sri Lanka, Malaysia, Peru, Belgium, and
          Norway. Congratulations to these new NABs and best wishes from the
          Bangladesh NAB.
        </p>
        <p className="pb-6">
          The GSG Global Impact Summit is the premier global event of the impact
          movement, bringing together 1000+ practitioners and leaders from the
          worlds of finance, business, policy-making, and civil society from
          around the globe. Addressing three themes, we celebrated achievements
          and identified strategies and actions designed to deliver positive
          impact at scale in the future:
        </p>
        <ul className="pl-20 py-10">
          <li>
            Mobilising Capital for People and Planet, where it is most needed
          </li>
          <li>Achieving full transparency in Measuring Impacts</li>
          <li>Building Impact Economies</li>
        </ul>
        <p className="pb-6">
          Impact leaders from around the globe, participants from all pillars of
          the impact ecosystem, big/small business, government, investors – some
          as asset owners, entrepreneurs, policymakers, regulators, advisors,
          market builders joined the two days of mind-blowing sessions.
        </p>
        <p className="pb-6">
          Farhad Reza, as the CEO of the Bangladesh NAB presented the paper on
          Population Weighted Asset Under Management to be considered as part of
          the next tipping point for the GSG. This topic was agreed by the
          Asia-Pacific NABs and Task Forces at the GSG Leadership Meeting in May
          this year and Bangladesh NAB was nominated to lead this agenda. The
          Asia-Pacific Regional session on the 3rd October was the forum for
          Bangladesh NAB to share this critical dimension of impact investment
          which was highly appreciated and supported by the 100+ audiences. The
          GSG Asia-Pacific group will forward this agenda to be mainstreamed
          over next few months.
        </p>
        <p className="pb-6">
          The GSG now covers 41+ countries through its 41 National and Regional
          Advisory Boards working to catalyse impact investment in their
          markets.
        </p>

        <div className="text-center w-full">
          <img
            src={news21_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-10 pt-6">
          As the impact investing movement continues to gain traction, the
          impact community does not speak with a unified voice about the impact
          opportunity. This reduces awareness, creates confusion, and slows our
          momentum. Having better and more consistent ways of telling our story
          will give added momentum for impact investing to truly go mainstream.
          This is essential in order to bring about transformative impact for
          people and planet and to achieve the UN Sustainable Development Goals.
          Market building organisations, such as the GSG’s National Advisory
          Boards, often struggle to convince financial institutions and
          governments around the world to put their full support behind impact
          investing. This Annual Summit addresses the concerns and opportunities
          we’ve uncovered amongst these communities.[1]
        </p>
        <p className="pb-10 pt-6">
          Well done GSG! The next year GSG Leadership Meeting (GLM) will be held
          in Costa Rica – hope to see you all again to share synergies,
          challenges, opportunities, and achievements.
        </p>

        <div className="flex gap-2 items-center">
          <div className="relative w-full">
            <img src={news21_details3} alt="" className="w-full" />
            <div className="absolute bottom-0 text-white font-bold text-center bg-black/30 w-full text-[12px] ">
              <p>
                Sir Ronald Cohen, President GSG, speaking at the GSG Summit 2023
              </p>
            </div>
          </div>
          <div className="relative w-full">
            <img src={news21_details4} alt="" className="w-full" />
            <div className="absolute bottom-0 text-white font-bold text-center bg-black/30 w-full text-[12px] ">
              <p className="pb-2">Asia-Pacific Forum at the GSG Summit 2023</p>
              <p className="pb-2">from left: Jian Li Yew (Malaysian NAB),</p>
              <p className="pb-2">
                Farhad Reza (Bangladesh NAB), Marta Perez Cuso (UN ESCAP),
              </p>
              <p>Chul Moon (Korean NAB), Ken Shibusawa (Japan NAB)</p>
            </div>
          </div>
        </div>
        <div className="flex gap-2 ">
          <div className="relative w-full">
            <img
              src={news21_details5}
              alt=""
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 text-white font-bold text-center bg-black/30 w-full text-[12px] ">
              <p className="pb-2">
                The ‘Graffiti wall’ at the Summit was painted
              </p>
              <p className="pb-2">with Bangladeshi Flag by Sarzeena Maodud</p>
              <p>from Bangladesh</p>
            </div>
          </div>
          <div className="relative w-full">
            <img
              src={news21_details6}
              alt=""
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 text-white font-bold text-center bg-black/30 w-full text-[12px] ">
              <p className="pb-2">
                Farhad Reza speaking at Asia-Pacific Forum, GSG Summit 2023,
                Moderated{" "}
              </p>
              <p className="pb-2">by the Jian Li Yew, Malaysian NAB</p>
            </div>
          </div>
          <div className="relative w-full">
            <img
              src={news21_details7}
              alt=""
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 text-white font-bold text-center bg-black/30 w-full text-[12px] ">
              <p className="pb-2">Sir Ronald Cohen, President GSG and</p>
              <p className="pb-2">Farhad Reza, President Build Bangladesh</p>
            </div>
          </div>
        </div>

        <ul className="pl-20 py-10">
          <li>
            <a
              href="https://gsgii.org/impact-narrative/"
              target="_blank"
              rel="noreferrer"
            >
              https://gsgii.org/impact-narrative/
            </a>
          </li>
        </ul>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
