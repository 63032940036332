import React from 'react'
import PageHeading from '../../../shared/Components/PageHeading'
import ImpactInvestment_bn_header from "../../../assets/images/Impact-Investment_bn_header.jpg";

export default function IIBHeader() {
    return (
        <PageHeading bgImage={ImpactInvestment_bn_header}>
            <h2 className='font-bold text-2xl md:text-4xl text-white py-10 text-center'>Impact Investing in Bangladesh</h2>
        </PageHeading>
    )
}
