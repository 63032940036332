import React from "react";
import Layout from "../../shared/Layout/Layout";
import NabBangladeshHeader from "./Components/NabBangladeshHeader";
import NavBangladeshMission from "./Components/NavBangladeshMission";
import NabBangladeshWhatWeDo from "./Components/NabBangladeshWhatWeDo";
import GSGImpactPartnership from "./Components/GSGImpactPartnership";

export default function NabBangladesh() {
  return (
    <Layout>
      <NabBangladeshHeader />
      <NavBangladeshMission />
      <NabBangladeshWhatWeDo />
      <GSGImpactPartnership />
    </Layout>
  );
}
