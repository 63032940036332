import React from 'react'
import { useCollection } from '../../hooks/useCollection';

export default function AdminJoinFormContactTable() {
    const { document } = useCollection("contactForm")
    return (
        <div>
            <section className="bg-white shadow-xl rounded-md m-4 py-6 px-4 overflow-hidden lg:py-8">
                <h1 className='font-bold text-xl text-center py-2'>All Contact form</h1>
                <div className='border-b border-b-gray-200'></div>
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4 mx-4">
                        <div className="max-w-full overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="text-left text-dark font-bold">
                                        <th
                                            className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Full Name
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Email
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Phone Number
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Subject
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Message
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        document?.map(contactForm => (
                                            <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <p>{contactForm?.firstName + " " + contactForm?.lastName}</p>
                                                </td>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <p>{contactForm?.email}</p>
                                                </td>

                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <p>{contactForm?.phoneNumber}</p>
                                                </td>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <p>{contactForm?.subject}</p>
                                                </td>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <p>{contactForm?.message}</p>
                                                </td>


                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
