import React from "react";
import { Link } from "react-router-dom";
import news8 from "../../../../assets/images/news8.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function ActualNabTrustDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news8}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          NAB Bangladesh has been registered as a Trust under the Trusts Act of
          1882. This Trust will give the works and initiatives of NAB more
          accountability, continuity, and sustainability. The Trust as a
          not-for-profit entity will now allow NAB to influence the BIISAP
          implementation plan by incorporating more supportive partners
          including relevant industry experts to drive the next step of agendas
          forward. The Trust consists of seven members including Mr Arastoo Khan
          as the Chairperson, Ms Sultana Afroz as the Vice Chairperson,
          Architect Farhadur Reza as the Managing Trustee, Mr Niaz Rahim as the
          Treasurer and Ms Anita Ghazi Rahman as the Secretary of the Trust. Mr
          Asif Saleh and Ms Sangita Ahmed are the members of the Trust.
        </p>

        <p className="pb-10">
          With the formation of the NAB Trust, the current National Advisory
          Board (NAB) for Impact Investment in Bangladesh will be called
          National Advisory Board of Governance (NABG). The NAB Trust will
          establish, set up, run, maintain, assist, finance, and form the NABG
          to whom the Trustees will report quarterly.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
