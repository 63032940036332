import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/new123.png";
import news123_creator from "../../../../assets/images/news123_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function AustraliaImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Building Impact: Our Journey in Australia's Impact Investment Building
          Impact: Our Journey in Australia's Impact Investment Hi, I’m David
          Hetherington, CEO of Impact Investing Australia. We’re the national
          peak body for impact investing and Australia’s National Partner for
          GSG Impact. Our role is to build the market for impact investing here
          in Australia, above all by increasing the flow of capital to impact.
        </p>
        <p className="pb-6">
          As with many people, my journey to impact investment has been a
          roundabout one. I accidentally stumbled upon my first ‘real’ job in
          London while backpacking. I worked for an incredibly entrepreneurial
          company that organised investment conferences around the world, having
          started these in Eastern Europe after the fall of communism. Once
          you’d proved yourself, you were given your own P&L and encouraged to
          find your own conference opportunity. My first one was power
          generation in North Africa. It was the dawn of the internet and a
          hugely exciting time.
        </p>
        <p className="pb-6">
          After returning home to Australia, I took a role with a global
          strategy consulting firm. I loved consulting. I had five wonderful
          years working all over the world – from Bavaria to Botswana. I loved
          the people and was part of great teams on projects in sectors ranging
          from aviation to diamonds. I always knew, though, that I was most
          interested in social justice and was starting to think about a career
          change to scratch that itch. So I took myself off to LSE and did an
          MPA in public and economic policy. I did a placement at the OECD and
          then got a research role at IPPR, one of the UK’s leading think tanks,
          working on housing and disability. This was super stimulating –
          working on the big social problems facing the UK.
        </p>
        <p>
          The only problem was that I wanted to tackle these problems in my own
          country, so I went home and with a few like-minded people, I
          co-founded a progressive policy think tank in Australia, Per Capita.
          We quickly built relationships with Australia’s federal government and
          helped shape a range of economic and social policy debates.
        </p>
        <p className="py-6">
          It was at Per Capita that I first encountered impact investing. When
          Australia’s largest private childcare provider collapsed in 2009, we
          supported the policy response that led to the establishment of an
          impact-funded non-profit provider, Goodstart Early Learning, with a
          capital raise of more than A$150 million led by Social Ventures
          Australia. It was a rapid-fire introduction to the world of impact.
        </p>
        <p className="pb-6">
          Later on at Per Capita, I led another impact investing project
          alongside Richard Brandweiner, then the chief investment officer of a
          large Australian pension fund. We developed a policy framework that
          contributed to the establishment of the National Housing Finance
          Investment Corporation, a government facility for social and
          affordable housing bonds.
        </p>
        <p className="">
          After a decade addressing systems change at Per Capita, I decided I
          wanted to gain more direct experience in combating disadvantage. I
          took a role as CEO of the Public Education Foundation, a grassroots
          charity that supports disadvantaged children in public schools. At the
          Foundation, we quadrupled the scholarship support and expanded the
          model to include laptops, mentoring and tutoring alongside cash
          assistance.
        </p>
        <p className="pb-6">
          After six years at the Foundation, I had a chance encounter with my
          former collaborator Richard Brandweiner, who by this point was Chair
          of Impact Investing Australia. Richard mentioned that the IIA Board
          was looking for some strategic advice and asked if I’d be interested
          in helping out.
        </p>
        <p className="pb-6">
          I found both the organisation and the ecosystem to offer great
          potential. One thing led to another, and I subsequently took on the
          CEO role at IIA in December 2022. My task was to build engagement with
          private investors looking to embrace impact investing, while pursuing
          the government with the Social Impact Investment Taskforce
          recommendations.
        </p>
        <p className="">
          Almost two years in, we have made progress on both fronts and more.
          We’ve recently launched the inaugural Endowments for Impact Challenge,
          a competition to identify and showcase best-in-class impact financial
          advice, and to support the participating trusts and foundations in
          managing a (combined) A$170 million investment pool. The Australian
          Government has launched a A$100 million Outcomes Fund, and expanded
          its regional impact fund, Australian Development Investments, to A$250
          million.
        </p>
        <p className="pb-6">
          It's been a great journey so far. I’ve met lots of exceptional,
          good-hearted people and have a treasure trove of stories to tell. But
          I’ve no doubt the best is still to come!
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">David Hetherington</p>
            <p>CEO</p>
            <p>Impact Investing Australia</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
