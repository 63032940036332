import React from "react";
import { Link } from "react-router-dom";
import news24 from "../../../../assets/images/news24.jpg";
import new24_details1 from "../../../../assets/images/new24_details1.jpg";
import new24_details2 from "../../../../assets/images/new24_details2.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function GsgSummitDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news24}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          We are excited to announce that the{" "}
          <a
            href="https://gsgii.org/"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            GSG
          </a>{" "}
          Global Impact Summit 2023 will take place on 2-3 October 2023, in
          Malaga, Spain in partnership with the SpainNAB. The{" "}
          <a
            href="https://www.nabbangladesh.org/"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            National Advisory Board
          </a>{" "}
          (NAB) for Impact Investment in Bangladesh is an integral part of this
          Summit. Multiple representatives will represent NAB Bangladesh to
          share the Bangladesh Impact Investment stories.
        </p>
        <p className="pb-6">
          The GSG covers 36 countries through its 30 National and Regional
          Advisory Boards working to catalyse impact investment in their
          markets.
        </p>

        <div className="text-center  w-full">
          <img
            src={new24_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          The GSG Global Impact Summit is the premier global event of the impact
          movement, bringing together 1000+ practitioners and leaders from the
          worlds of finance, business, policy-making, and civil society from
          around the globe. Addressing three themes, the Summit will celebrate
          achievements and identify strategies and actions designed to deliver
          positive impact at scale in the future including:
        </p>

        <ul className="pl-20 pb-10">
          <li>
            Mobilising capital for people and planet, where it is most needed
          </li>
          <li>Achieving full transparency in measuring impacts</li>
          <li>Building impact economies</li>
        </ul>

        <p className="pb-6">
          As the impact investing movement continues to gain traction, the
          impact community does not speak with a unified voice about the impact
          opportunity. This reduces awareness, creates confusion, and slows our
          momentum. Having better and more consistent ways of telling our story
          will give added momentum for impact investing to truly go mainstream.
          This is essential in order to bring about transformative impact for
          people and planet and to achieve the UN Sustainable Development Goals.
          Market building organisations, such as the GSG’s National Advisory
          Boards, often struggle to convince financial institutions and
          governments around the world to put their full support behind impact
          investing. This Annual Summit addresses the concerns and opportunities
          we’ve uncovered amongst these communities.[1]
        </p>
        <p className="pb-6">
          It is expected that the Impact leaders from around the globe, big and
          small businesses, government, investors, asset owners, entrepreneurs,
          policymakers, regulators, advisors, and market builders will attend
          the Summit. All participants will have access to inspirational,
          thought leading global speakers. Two days of learning, sharing
          information and networking. Masterclasses on Informal Settlements,
          Valuing and Accounting for Impact, Outcomes-based Social Investment.
          An Arts & Culture programme taking place both within the Summit venue
          and throughout the city of Malaga.
        </p>

        <div className="text-center  w-full">
          <img
            src={new24_details2}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-10">
          Malaga is a city of innovation in culture, technology, and impact that
          attracts entrepreneurs and investors from all over the world. Famed
          for its art and culture, in 2023 Malaga celebrates the work of Pablo
          Picasso, half a century since his passing.
        </p>
        <p className="pb-6">
          Anyone can register to this Summit following the link:{" "}
          <a
            href="https://gsgii.org/gsg-global-impact-summit-2023/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://gsgii.org/gsg-global-impact-summit-2023/
          </a>
        </p>
        <p className="pb-6">
          The Summit will be held at The Trade Fairs & Convention Centre of
          Malaga (FYCMA), Spain
        </p>
        <p className="pb-6">
          <a
            href="https://gsgii.org/impact-narrative/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            [1] https://gsgii.org/impact-narrative/
          </a>
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
