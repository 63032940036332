import React from "react";
import { Link } from "react-router-dom";
import news25 from "../../../../assets/images/news25.jpg";
import news25_details1 from "../../../../assets/images/news25_details1.jpg";
import news25_details2 from "../../../../assets/images/news25_details2.jpg";
import news25_details3 from "../../../../assets/images/news25_details3.jpg";
import news25_details4 from "../../../../assets/images/news25_details4.jpg";
import news25_details5 from "../../../../assets/images/news25_details5.jpg";
import news25_details6 from "../../../../assets/images/news25_details6.jpg";
import news25_details7 from "../../../../assets/images/news25_details7.jpg";
import news25_details8 from "../../../../assets/images/news25_details8.jpg";
import news25_details9 from "../../../../assets/images/news25_details9.jpg";
import news25_details10 from "../../../../assets/images/news25_details10.jpg";
import news25_details11 from "../../../../assets/images/news25_details11.jpg";
import news25_details12 from "../../../../assets/images/news25_details12.jpg";
import news25_details13 from "../../../../assets/images/news25_details13.jpg";
import news25_details14 from "../../../../assets/images/news25_details14.jpg";
import news25_details15 from "../../../../assets/images/news25_details15.jpg";
import news25_details16 from "../../../../assets/images/news25_details16.jpg";
import news25_details17 from "../../../../assets/images/news25_details17.jpg";
import news25_details18 from "../../../../assets/images/news25_details18.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function InvesterismDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news25}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          On June 2023, the NAB Trust have acknowledged and shared their respect
          to the ‘STARS’ shining on the Impact Investment sky over Bangladesh.
          This was an opportunity to show NAB’s appreciation for the
          organisations and people, who have been with the Impact Investment
          Ecosystem Building Movement of Bangladesh from day zero – the Impact
          InveSTAR. A movement to promote Impact Invest-ism. An ‘ism’, centred
          around entrepreneurial movement as the next revolution for an
          inclusive world led by the impact entrepreneurs. NAB Trust wanted to
          take the opportunity to recognise these Impact InveSTARs, while Mr.
          Cliff Prior, the CEO of the Global Steering Group (GSG) for Impact
          Investment was in Bangladesh. The Stars were grouped under five
          categories: Government and Regulators; Investors; Investees;
          Intermediaries and Market Builders. And one special category for our
          Global InveSTAR.
        </p>

        <h1 className="font-bold">
          Impact InveSTAR – Group 1: Government/Regulators
        </h1>
        <p className="pb-6">
          The government including the regulators were recognised because of
          their support to establish the Impact Investment Ecosystem in
          Bangladesh since 2015. The Bangladesh Investment Development Authority
          (BIDA), Bangladesh Securities and Exchange Commission (BSEC) and the
          Bangladesh Bank were recognised for their unique role to support
          Impact Investment in Bangladesh. The NAB Trust crests were shared by
          Cliff Prior, CEO of the GSG.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Lokman Hossain Miah, Executive Chairman, BIDA and also the Chair
          of the National Advisory Board Governance (NABG) for Impact Investment
          in Bangladesh has accepted the crest for the Bangladesh Investment
          Development Authority (BIDA). BIDA was created on September 1, 2016,
          with a mandate to provide diversified promotional and facilitating
          services with a view to accelerating the industrial development of the
          country. BIDA’s functions can be broadly categorized as investment
          promotion, investment facilitation and policy advocacy. BIDA’s vision
          is to become a world class investment promotion agency for achieving
          economic progress. Its mission is to attracting domestic and foreign
          investment in the private sector, providing improved services,
          effective coordination and creating an investment-friendly
          environment.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details2}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          On behalf of the Bangladesh Securities and Exchange Commission (BSEC),
          Dr Shaikh Shamsuddin Ahmed, Commissioner, BSEC and also a member of
          the NABG accepted the crest. The BSEC was established on 8th June,
          1993 as the regulator of the country’s capital market under the
          provision of Bangladesh Securities and Exchange Commission Act 1993.
          The purpose of the Commission is to protect the interest of investors
          in securities, develop the securities market and make rules for
          matters connected therewith or ancillary thereto. The Commission
          consists of the Chairman and four Commissioners who are appointed for
          full time by the Government. The Chairman acts as the Chief Executive
          of the Commission. The Commission is a statutory body and attached to
          the Ministry of Finance. BSEC is an ‘A’ category member of
          International Organization of Securities Commissions (IOSCO) since 22
          December 2013.
        </p>
        <p className="pb-6">
          The next recipient of the crest was the Bangladesh Bank. The central
          bank and apex regulatory body for the country’s monetary and financial
          system, which was established in Dhaka as a body corporate vide the
          Bangladesh Bank Order, 1972 (P.O. No. 127 of 1972) with effect from
          16th December 1971. Its vision is to develop continually as a
          forward-looking central bank with competent and committed
          professionals of high ethical standards, conducting monetary
          management and financial sector supervision to maintain price
          stability and financial system robustness, supporting rapid broad
          based inclusive economic growth, employment generation and poverty
          eradication in Bangladesh. Its missions are to: Formulating Monetary
          And Credit Policies; Managing Currency Issue And Regulating Payment
          System; Managing Foreign Exchange Reserves And Regulating The Foreign
          Exchange Market; Regulating And Supervising Banks And Financial
          Institutions, And Advising The Government On Interactions And Impacts
          Of Fiscal, Monetary And Other Economic Policies.
        </p>
        <h1 className="font-bold underline">
          Impact InveSTAR – Group 2: Investors
        </h1>
        <p className="pb-6">
          The impact investors were recognised because of their decision to
          invest in Bangladesh and encourage other local and global investors to
          follow their path while establishing Bangladesh as the mature
          destination for impact investment. The NAB Trust crests were shared by
          Mr Lokman Hossain Miah, Executive Chairman of BIDA and the Chair of
          NABG.
        </p>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details3}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Martin Holtmann, Country Manager of the International Finance
          Corporation (IFC) of the World Bank Group and a member of the NABG has
          received the crest. IFC is the largest global development institution
          focused on the private sector in developing countries. By encouraging
          growth, IFC advances economic development, creates jobs and improves
          the lives of people. IFC helps to boost sustainable economic growth in
          South Asia including Bangladesh by supporting infrastructure building
          and improvements to logistics, expanding the use of renewable energy,
          promoting cleaner production, creating opportunities for smaller
          businesses, and facilitating public-private partnerships. IFC also
          promotes inclusive growth by improving infrastructure and financial
          services, and by supporting the expansion of smaller businesses. Its
          strategic investments and advisory interventions across India,
          Bangladesh, Sri Lanka, Nepal, Bhutan and the Maldives, to promote
          inclusive growth, help address climate change impacts, and encourage
          global and regional integration.
        </p>
        <p className="pb-6">
          Next on the line of recognition was the British International
          Investment (BII). In Bangladesh, BII will continue to build on their
          74-year experience as an impact investor to support private-sector
          growth and leverage both its partnerships and deep-rooted knowledge of
          markets across South Asia to help boost economic opportunities and
          transform lives. Bangladesh is an important market for them, where
          they have been investing since the eighties. BII currently have a
          portfolio of over US $270 million in Bangladesh, supporting 11
          businesses and two funds, across a variety of sectors including
          financial services, infrastructure, manufacturing, and food
          processing.
        </p>
        <p className="pb-6">
          It was time to recognise the Turkish Cooperation and Coordination
          Agency (TIKA) for their support to the first Outcome Based Finance
          Impact Investment project in Bangladesh – the Prokriti o Pani (PoP)
          project initiated by Build Bangladesh. TİKA works with an approach
          that involves sharing Turkey’s development experience with other
          countries and relies on an understanding of “cooperation partnership”.
          At the root of this understanding, there is compatible, sustainable,
          and multilateral cooperation, and the projects have a one hundred
          percent success rate, thanks to actors that take on suitable roles.
        </p>

        <div className="flex gap-6 items-center">
          <div className="w-full">
            <img src={news25_details4} alt="" className="w-full" />
          </div>
          <div className="w-full">
            <p>
              Next the credit went to the millennials, the Associate Director of
              the Impress Group, Mr. Tanjeem Ahmed Khan received the NAB crest
              as the major supporter and investor to initiate the wider Impact
              Investment Movement in Bangladesh. Impress Group is the pioneer
              and the first corporate sector to establish its own impact
              enterprise (Build Bangladesh) in Bangladesh in 2016 to build the
              Impact Investment Ecosystem as part of the global movement.
              Impress Group with their progressive vision and mission to promote
              Bangladesh as the seeding fertile ground for impact investment is
              still working towards transforming impact investment as the
              mainstream investment both locally and globally.
            </p>
            <h1 className="font-bold underline">
              Impact InveSTAR – Group 3: Intermediaries{" "}
            </h1>
          </div>
        </div>

        <p className="pb-6">
          Intermediaries are a lesser known but critical group in the ecosystem
          that facilitate connections between relevant actors. Operating as the
          go-between for key actors, intermediaries are uniquely placed to
          unlock the potential of impact investment. These intermediaries were
          recognised because of their active support to reinforce the impact
          investment ecosystem building process in Bangladesh. The NAB Trust
          crests were shared by Mr Martin Holtmann, Country Manager of IFC and a
          member of the NABG.
        </p>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details5}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Ms Sangita Ahmed, Senior Vice President of the Bangladesh Women
          Chamber of Commerce and Industries (BWCCI) and also a member of the
          NABG has received the crest. BWCCI is the first chamber of commerce in
          the country exclusively working on women’s economic and social
          empowerment. It is a non-profit, non-political organization
          established in June 2001 with an enlightened aim to encourage and
          strengthen women’s participation in the private sector as
          entrepreneurs through promoting a women friendly business environment.
          BWCCI is a strong community voice, lobbying for micro to macro women
          entrepreneurs to assist their growth and to improve their social and
          economic prospects. The members of BWCCI are from the entire range of
          socio-economic spectrum.
        </p>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details6}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          The Managing Director of the Startup Bangladesh Limited, Mr Sami Ahmed
          received the NAB Trust crest. Startup Bangladesh Limited is the
          flagship venture capital fund of ICT Division. Under the visionary
          guidance of Honorable Prime Minister Sheikh Hasina, Startup Bangladesh
          – the first and only venture capital fund sponsored by the government
          of the people’s republic of Bangladesh started its journey on March
          2020 with an allocated capital of BDT 500 crores. This effort will
          enable the nation to innovate faster, create new jobs, develop
          technical skills, and realize the vision of Digital Bangladesh. It is
          committed to developing Bangladesh’s venture capital ecosystem by
          providing support to early-stage tech-based companies in the form of
          capital, financial guidance, and operational guidance. The company
          also intends to provide superior returns over a medium to long term by
          investing in high growth and social impact companies, with good
          management and corporate governance through equality and equity linked
          instruments.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details7}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Ms Nauriin Ahmed, Associate, of The Legal Circle received the next
          crest for its pioneering role played as a legal intermediary since
          2016-17. The funding Director Barrister Anita Ghazi Rahman has been
          involved in the Impact Investment Movement from day zero and currently
          serving as the Secretary of the NAB Trust. The Legal Circle is a firm
          of barristers, advocates, and legal consultants specializing in
          representing clients in Bangladesh on both domestic and international
          legal matters. Bringing together a team of experienced lawyers,
          several of whom have previously worked at leading law firms in
          Bangladesh and internationally. They aim to provide approachable, yet
          client-focused, solutions-oriented legal services tailored to the
          individual needs of their clients. It is committed to providing the
          highest level of legal services and achieving cost-effective legal
          results for its clients by being flexible and highly responsive in
          approach.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details8}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Ms Nilanjana Rahman, Deputy Head-Finance & Operations of the Impress
          Capital Limited (ICL) received the next NAB Trust crest of
          appreciation. ICL continuing to bring innovation with partnership of
          clients in the wealth management arena, was incorporated as a private
          limited company on June 11, 2014. ICL contributes to average people’s
          lives by providing a set of diversified asset management solutions
          through an in-depth market analysis with a passion for money
          management. ICL believes that standalone quantitative do not have the
          sustainability, on an absolute term, behind quantitative, qualitative
          should work. ICL has been part of Build Bangladesh since 2016 and
          joined the movement to establish the Impact Investment Ecosystem in
          Bangladesh. It is the first asset manager to register an impact fund
          along with its three operational mutual funds while launching a
          shariah based mutual fund soon.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details9}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Mahmudul Hasan, Programme Officer and Youth Coordinator, UNDP
          Bangladesh, received the next crest for their tireless efforts to
          serve the young impact entrepreneurs over the years through their
          unique programme the Youth Co:Lab. Youth Co:Lab Bangladesh leverages
          social innovation and entrepreneurship to create a new generation of
          Bangladeshi leaders and empowers them to achieve the Sustainable
          Development Goals (SDGs). The programme positions young people front
          and centre in order to solve the region’s most pressing challenges.
          Co-created in 2017 by the United Nations Development Programme (UNDP)
          and the Citi Foundation, Youth Co:Lab aims to establish a common
          agenda for countries in the Asia-Pacific region to empower and invest
          in youth so that they can accelerate the implementation of the
          Sustainable Development Goals (SDGs) through leadership, social
          innovation, and entrepreneurship.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details10}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Erad Kawsar, Executive Director, Build Bangladesh has received the
          NAB Trust Crest on behalf of ygap Bangladesh. ygap is an international
          organisation that supports early-stage social impact ventures with
          locally led solutions to local problems. It believes in the power of
          entrepreneurship as an effective way to make sustainable and equitable
          change in the world. Its work is fully informed by the communities
          they serve and their own entrepreneurial drive. They believe that
          accelerate and support the growth of impact ventures led by local
          entrepreneurs who deeply understand the unique challenges of their
          communities and are best placed to develop solutions, and they create
          enabling environments for ventures to succeed in the ecosystems in
          which they operate. ygap Bangladesh programmes are co-created and run
          by expert teams who are local to Bangladesh, who deeply and easily
          understand local need and context. They support entrepreneurs who have
          high potential for success but are usually the least resourced.
        </p>

        <h1 className="font-bold underline">
          Impact InveSTAR – Group 4: Market Builder
        </h1>
        <p className="pb-6">
          The market builders help impact investing scale locally and globally
          through specialised advocacy, consulting, investment funds, awareness,
          and education. They usually invest in and supports enterprises that
          deliver financial as well as social and/or environmental returns as
          part of the Impact Investment Movement. The NAB Trust crests were
          shared by Ms Sangita Ahmed, Senior Vice President, BWCCI and Member of
          the NAB Bangladesh Trust.
        </p>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details11}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Ichiguchi Tomohide, Chief Representative, JICA Bangladesh and a
          member of the NABG received the crest. JICA as a top bilateral donor
          in Bangladesh, aiming to support the people in Bangladesh, is focusing
          on two pillars, namely: (a) accelerating sustainable economic growth
          and (b) overcoming poverty. As the support for economic growth, JICA
          Bangladesh continues to cooperate in infrastructure development such
          as transportation and electricity, and also provide support for
          revitalizing the private sector. In order to overcome various social
          vulnerabilities to overcome poverty, it provides support in the field
          of basic education, health care, rural development, governance, and
          disaster countermeasures nationwide. Recently, JICA has reinforced its
          attention to impact investment and considering this as the way forward
          to build the impact market here in Bangladesh.
        </p>
        <p className="pb-6">
          Next crest went to the{" "}
          <span className="font-bold">
            Swiss Agency for Development and Cooperation (SDC)
          </span>{" "}
          as part of the Swiss Embassy in Bangladesh. In Bangladesh, SDC works
          with its partners to promote sustainable economic and social
          development while extending its leadership support to build the Impact
          Investment Ecosystem. The SDC has been very active with the Impact
          Investment Movement in Bangladesh since 2018 as a member of the NABG,
          which culminated by its support to the development of the Bangladesh
          Impact Investment Strategy and Action Plan (BIISAP) in 2022. It works
          to improve market and working conditions and promote the rights and
          participation of minorities, women, and young people to pave the way
          for a prosperous, just, and inclusive society through Biniyog Briddhi
          is exemplary to facilitate impact investment in Bangladesh.
        </p>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details12}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Sarder Asaduzzaman, Assistant Resident Representative, UNDP
          Bangladesh has received the appreciation crest from NAB Trust. UNDP
          works in about 170 countries and territories, helping to eradicate
          poverty, reduce inequalities and exclusion, and build resilience so
          countries can sustain progress. As the UN’s development agency, UNDP
          plays a critical role in helping Bangladesh to achieve the Sustainable
          Development Goals. Since 2018, the Resident Representative of UNDP
          Bangladesh being a member of the NABG Bangladesh, UNDP Bangladesh has
          been proactively leading the Impact Investment Movement in Bangladesh
          in partnership with local and global players.
        </p>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details13}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          On behalf of BRAC, Mr. KAM Morshed, Senior Director on BRAC has
          received the crest. The Executive Director of BRAC, Mr Asif Saleh
          being a member of the NAB Trust is a great demonstration and
          commitment of BRAC to build the impact investment market in
          Bangladesh. As part of its invest strategy, BRAC invests in socially
          responsible companies that assist in its mission to empower people and
          communities in situations of poverty, illiteracy, disease, and social
          injustice. It networks of seven investments help it to strive towards
          the goal of self-sustainability. The investment strategy reflects its
          belief that investing to generate financial returns and lasting social
          and environmental impact are not only compatible, but also mutually
          reinforcing objectives. BRAC is exploring to launch the first Social
          Impact Bond (SIB) in Bangladesh soon.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news25_details14}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Mr. Arastoo Khan, Chair of the Advisory Board, Build Bangladesh, and
          the Chair of the NAB Bangladesh Trust kindly accepted the crest on
          behalf of Build Bangladesh. Build Bangladesh, the first impact
          enterprise, leading the impact investment stream of the Impress Group,
          one of the largest private sector conglomerates in Bangladesh. Its
          work focuses strongly to ensure sustainable investment, impact
          investment under the wider umbrella of ESG principles. Build
          Bangladesh with its asset management, alternative investment,
          foundation, and impact enterprise streams is leading the Impact
          Investment Movement in Bangladesh. In November 2016, Build Bangladesh
          hosted the first ever international conference on Impact Investment in
          Bangladesh followed by the first International Workshop on Impact
          Investment on March 2017. Build Bangladesh also facilitated the
          establishment of the National Advisory Board Governance (NABG) and NAB
          Trust for Impact Investment in Bangladesh (2018), and the membership
          of the Global Steering Group (GSG) for Impact Investment.
        </p>

        <h1 className="font-bold underline pb-4">
          Impact InveSTAR – Group 5: Investee
        </h1>

        <div className="flex gap-4">
          <div className="w-full">
            <img src={news25_details15} alt="" className="w-full" />
          </div>
          <div className="w-full">
            <img src={news25_details16} alt="" className="w-full" />
          </div>
          <div className="w-full">
            <img src={news25_details17} alt="" className="w-full" />
          </div>
        </div>
        <p className="pb-6">
          A number of Impact Entrepreneurs were recognised for their
          contributions as part of the Impact Investment Ecosystem building in
          Bangladesh. Mr Ichiguchi Tomohide, Chief Representative of JICA
          Bangladesh and a member of the NABG has kindly shared the crests with
          the young entrepreneurs. The investees were, Bhalo Social Enterprise;
          Apon Wellbeing Limited; iFarmer Limited; Drishti Technologies Limited;
          and GARBAGEMAN Limited.{" "}
        </p>
        <h1 className="font-bold underline pb-4">
          Impact InveSTAR – Group 6: Global Star
        </h1>

        <div className="text-center w-full pb-6">
          <img
            src={news25_details18}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Finally, on behalf of the NAB Trust, Mr Arastoo Khan, Chair of the NAB
          Trust has extended his appreciation to Mr Cliff Prio, CEO of the GSG
          to acknowledge its global leadership. The Global Steering Group (GSG)
          for Impact investment is a global organisation founded on the belief
          that investment done well can benefit all people and the planet. It
          wants societal and environmental impact to be at the heart of
          investment and business decisions. The GSG is an independent
          organisation catalysing impact investment and impact entrepreneurship.
          The GSG was established in August 2015 as the successor to and
          incorporating the work of the Social Impact Investment Taskforce
          established under the UK´s 2013 presidency of the G8. GSG is promoting
          that Impact investment optimises risk, return and impact to benefit
          people and the planet. It does so by setting specific social and
          environmental objectives alongside financial ones and measuring their
          achievement. Bangladesh is the 19th member country of the GSG.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
