import React from 'react'
import Layout from '../../shared/Layout/Layout'
import NewsEventsHeaders from './Components/NewsEventsHeaders'
import NewsAndEvents from './Components/NewsAndEvents'

export default function NewsEvents() {
    return (
        <Layout>
            <NewsEventsHeaders />
            <NewsAndEvents />
        </Layout>
    )
}
