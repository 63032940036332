import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import HnbAndLiinDetails from "./components/HnbAndLiinDetails";

export default function HnbAndLiin() {
  return (
    <Layout>
      <NewsHeader>Navigating Impact Investment In Bangladesh</NewsHeader>
      <HnbAndLiinDetails />
    </Layout>
  );
}
