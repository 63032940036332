import React from "react";

export default function AboutImpact() {
  return (
    <div className="container mx-auto pt-20 px-8 md:px-16">
      <div className="max-w-6xl mx-auto text-center  pb-12">
        <h2 className="text-2xl md:text-4xl pb-10">About Impact</h2>
        <p className="text-[16px] pb-4">
          <span className="text-primary font-bold">Impact</span> is how we
          describe the interconnected social and environmental outcomes for all
          people and our planet, on the journey towards a more equitable and
          sustainable future.
        </p>
        <p className="text-[16px] pb-4">
          An <span className="text-primary font-bold">impact economy</span> is
          where all investment, business, consumption, and government decisions
          are taken with impact at its core. The transition to impact economies
          is crucial to address urgent global challenges.
        </p>
        <p className="text-[16px] pb-4">
          <span className="text-primary font-bold">Impact investments</span> aim
          to deliver solutions at scale to our world’s biggest issues,
          optimising risk, return and impact to benefit people and the planet.
          They do so by setting specific social and environmental objectives
          alongside financial ones, and measuring their achievement. For more
          information see ({" "}
          <a
            href="www.gsgimpact.org"
            target="_blank"
            className="text-primary font-bold"
          >
            www.gsgimpact.org
          </a>{" "}
          )
        </p>
      </div>
    </div>
  );
}
