import React from "react";
import { publicationsData } from "../data";
import { Button } from "../../../shared/ui/Button";
import { Link } from "react-router-dom";
import { FaAngleRight, FaArrowRight } from "react-icons/fa";

export default function Publications() {
  return (
    <div className="bg-[#EFEFEF] py-16 px-8">
      <div className="max-w-5xl mx-auto ">
        <h2 className="text-center text-4xl font-bold pb-8">NAB Publication</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {publicationsData?.map((publication) => (
            <div key={publication?.id} className="text-center">
              <div className="max-h-[500px] flex justify-center">
                <img
                  src={publication?.image}
                  alt={publication?.title}
                  className="rounded-lg object-contain"
                />
              </div>
              <p className="py-8 font-medium">{publication?.title}</p>
              <a href={publication?.pdf} target="_blank">
                <Button>Download</Button>
              </a>
            </div>
          ))}
        </div>
        <div className="text-center">
          <Link to="/learning-hub">
            <p className="inline-block text-primary font-semibold text-center text-xl pt-16 btn">
              Click to view all our Research Publications{" "}
              <FaArrowRight className="inline btn_arrow w-4 h-4" />
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
