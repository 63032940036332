import React from "react";
import { Link } from "react-router-dom";
import news27 from "../../../../assets/images/news129.jpg";
import news129_pic1 from "../../../../assets/images/news129_pic1.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function HigherCommodityDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news27}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          By investing in SMEs in developing countries, we aim to help them
          enhance their businesses for the benefit of smallholders — proving
          they are credible to the local banking sector along the way.
          Ultimately, our core purpose is to tilt the economic balance back
          toward smallholders; so they can make a decent living in normal times
          and survive inevitable price shocks.
        </p>
        <p className="pb-6">
          Price volatility is the enemy of the smallholder farmer. That applies
          when prices rise dramatically just as much as when they fall.
        </p>
        <p className="pb-6">
          Current spikes in commodity prices and{" "}
          <a
            href="https://sustainablebrands.com/read/supply-chain/without-access-to-ukrainian-sunflower-oil-iceland-foods-forced-to-put-practicality-over-sustainability"
            target="_blank"
            className="text-blue-500"
          >
            {" "}
            supply chain disruptions
          </a>{" "}
          caused by the conflict in Ukraine are exacerbating disparities in
          world food systems that have already been severely tested by the
          pandemic. And smallholders are being hit the hardest.
        </p>
        <p className="pb-6">
          According to the World Bank, there are roughly{" "}
          <a
            href="https://www.worldbank.org/en/news/feature/2016/02/25/a-year-in-the-lives-of-smallholder-farming-families"
            target="_blank"
            className="text-blue-500"
          >
            {" "}
            500 million smallholder households
          </a>{" "}
          globally, accounting for almost two billion people —{" "}
          <a
            href="https://www.worldbank.org/en/topic/agriculture/overview"
            target="_blank"
            className="text-blue-500"
          >
            {" "}
            with 65 percent of poor working adults
          </a>{" "}
          earning their living through agriculture. Many live in countries
          caught in the{" "}
          <a
            href="https://unctad.org/news/more-100-countries-depend-commodity-exports"
            target="_blank"
            className="text-blue-500"
          >
            {" "}
            commodities trap
          </a>
          , which means 60 percent or more of their total merchandise exports
          are commodities. This makes them particularly vulnerable to commodity
          price instability.
        </p>
        <p className="pb-6">
          It may seem counterintuitive that commodity price rises would harm
          commodity producers. But the smallholder farmer growing and selling
          maize, vanilla or{" "}
          <a
            href="https://sustainablebrands.com/read/supply-chain/driving-home-the-truth-about-cocoa-on-world-chocolate-day"
            target="_blank"
            className="text-blue-500"
          >
            cocoa
          </a>{" "}
          on a hectare or two of land is in a very different position from a
          multinational commodities business. They are lucky if they achieve a
          two-digit return on their investment, and high prices are making that
          even more difficult.
        </p>
        <p className="pb-6">
          There are a number of reasons why this is happening. The price of a
          farmer’s crop may have risen, but so have the inputs — such as
          nitrogen fertiliser and fuel — that enable them to farm it. In fact,
          according to the UN’s Food and Agriculture Organization (FAO),{" "}
          <a
            href="https://openknowledge.fao.org/server/api/core/bitstreams/8082fa5a-7f01-4962-8267-d61a8663ecdd/content"
            target="_blank"
            className="text-blue-500"
          >
            input inflation has outstripped output inflation
          </a>{" "}
          by 6 percent to 2 percent in the past 12 months. With the pay-off for
          investment uncertain — Will prices still be high when the crops are
          ready to harvest months down the line? — there’s little incentive for
          farmers to commit to growing the food that is vital for them and their
          communities.
        </p>
        <p className="pb-6">
          Smallholder farmers are also consumers of the products their crops are
          used to make. In the poorest countries, where the poorest smallholders
          are,{" "}
          <a
            href="https://openknowledge.fao.org/server/api/core/bitstreams/32709b4d-ed41-4b1e-9d37-91786824cb9e/content"
            target="_blank"
            className="text-blue-500"
          >
            households spend 60-80 percent of their income on food
          </a>{" "}
          compared to around 10 percent in rich nations — which means they are
          disproportionately impacted when prices in the shops rise. Any extra
          they can charge for their produce is soon eaten up by the money they
          are now spending to feed their families.
        </p>
        <p className="pb-6">
          Whether they can actually charge extra is another point of concern.
          Many live and work in hard-to-reach areas and have little access to
          competitive markets. They are at the end of the value chain; and their
          selling price is determined by a market system which is beyond their
          control.
        </p>
        <p className="pb-6">
          In other words, they are price takers, with limited power to bargain
          that price upwards. They cannot raise them in the same way a retailer
          or brand can when their costs grow. And, inevitably in this scenario,
          the amount they’re paid rarely rises as quickly as it falls.
        </p>
        <p className="pb-6">
          Where there are local enterprises that seek to{" "}
          <a
            href="https://sustainablebrands.com/read/supply-chain/goodsam-foods-out-to-scale-regenerative-farmer-driven-model-for-sustainable-snacks"
            target="_blank"
            className="text-blue-500"
          >
            pay smallholders a fairer share
          </a>{" "}
          , they too are being squeezed from several directions. Not only are
          they contending with higher input prices, they’re unable to pass on
          costs to local consumers who have little ability to pay more. In
          addition, supply chain issues such as sea container shortages and
          delays are forcing them to wait longer to receive the value of their
          exports. In turn, they do not have the cashflow to reinvest in
          smallholder produce.
        </p>
        <p className="pb-6">
          On the ground, the consequences of these multiple challenges are
          stark. Without stable incomes, smallholder families are less likely to
          afford school fees for their children — which leads to an increase in
          harmful practices such as child marriage; and for many, it means
          hunger for them and their communities. As always, poverty causes
          social and political instability — which exacerbates poverty even
          further. This is not the time to look away and assume all commodity
          producers are winning.
        </p>
        <p className="pb-6">
          In the short term,{" "}
          <a
            href="https://sustainablebrands.com/read/marketing-comms/1-8m-fairtrade-farmers-to-world-leaders-keep-your-climate-promises"
            target="_blank"
            className="text-blue-500"
          >
            governments and institutions must step up
          </a>
          and provide financial support and subsidies for smallholders and local
          agribusinesses to weather this storm. Large multinational companies
          must also make sure they are a paying a fair market rate to
          smallholders for their crops. At the Common Fund for Commodities
          <a
            href="https://www.common-fund.org/"
            target="_blank"
            className="text-blue-500"
          >
            (CFC)
          </a>
          , we are always looking for ways to help viable businesses survive
          during a crisis — as we did by{" "}
          <a
            href="https://sustainablebrands.com/read/supply-chain/it-s-time-to-listen-what-rural-agricultural-communities-need-during-covid-19"
            target="_blank"
            className="text-blue-500"
          >
            providing liquidity
          </a>{" "}
          to several of our partners when COVID-19 was at its height.
        </p>
        <p className="pb-6">
          But long-term resilience to price volatility requires deeper change.
          Small and medium-sized enterprises (SMEs) that work to benefit
          smallholders need financial backing and a fair playing field to
          profitably expand in existing and new markets. But these businesses
          are often viewed as too risky by local lenders, which means they
          struggle to access the working capital they need to drive growth.
        </p>
        <p className="pb-6">
          By investing in SMEs in developing countries, we aim to help them
          enhance their businesses for the benefit of smallholders — proving
          they are credible to the local banking sector along the way. This
          might involve building factories that add value to products; creating
          brands that encourage farmers to{" "}
          <a
            href="https://sustainablebrands.com/read/finance-investment/40-acre-co-op-sowing-seeds-for-financially-sustainable-future-for-black-farmers"
            target="_blank"
            className="text-blue-500"
          >
            diversify their crops
          </a>{" "}
          , so they are not over-reliant on a single harvest; promoting
          <a
            href="https://sustainablebrands.com/read/supply-chain/roots-of-change-how-fairtrade-is-unlocking-climate-resilience-fairer-incomes-for-cocoa-farmers"
            target="_blank"
            className="text-blue-500"
          >
            climate-resilient farming practices
          </a>
          ; and finding innovative ways to boost incomes, such as through{" "}
          <a
            href="https://sustainablebrands.com/read/finance-investment/indigo-ag-credit-issuance-connects-farmers-to-multibillion-dollar-voluntary-carbon-market"
            target="_blank"
            className="text-blue-500"
          >
            carbon credits
          </a>
          .
        </p>

        <div className="text-center w-full mb-8">
          <img
            src={news129_pic1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
      
        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
