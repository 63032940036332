import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import NabTrustDetails from "./Component/NabTrustDetails";

export default function NabTrust() {
  return (
    <Layout>
      <NewsHeader>NAB Trust: new leader for impact investment</NewsHeader>
      <NabTrustDetails />
    </Layout>
  );
}
