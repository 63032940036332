import React from "react";

export default function OurPeopleButtons({ setButtonTrigger }) {
  return (
    <div className="text-center">
      <button
        className="bg-primary text-white px-4 py-2 rounded-sm border border-2 border-green-900"
        onClick={() => setButtonTrigger("board")}
      >
        Board Members
      </button>
      <button
        className="bg-primary text-white px-4 py-2 rounded-sm border border-2 border-green-900"
        onClick={() => setButtonTrigger("trust")}
      >
        NAB Trust
      </button>
      <button
        className="bg-primary text-white px-4 py-2 rounded-sm border border-2 border-green-900"
        onClick={() => setButtonTrigger("secreteriat")}
      >
        NAB Secreteriat
      </button>
    </div>
  );
}
