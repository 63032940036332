import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function KeyPolicySecond() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>2018-19</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Law/Circular/Guideline
                        </div>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Date of Publication
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Implementer
                        </div>
                        <div className='col-span-4 border-r border-black flex justify-center items-center p-2'>
                            Brief Description of Provision
                        </div>
                        <div className='col-span-2 flex justify-center items-center p-2'>
                            Implications for Impact Investing in Bangladesh to be considered
                        </div>
                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-11 text-center border-b border-black bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.bb.org.bd/mediaroom/circulars/feid/jul302018feid02e.pdf" target='_blank' className='font-bold text-primary underline  decoration-1'>
                            Circular on Investment by non-resident investors in Alternative Investment Funds
                            </a>
                        </div>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                        July 30, 2018
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                        Foreign Exchange Investment Department, Bangladesh Bank
                        </div>
                        <div className='col-span-4 border-r border-black flex items-center text-left p-2'>
                        The AIFs must be registered under BSEC to attain foreign investments. Under the existing rules, non-residents are also allowed to make direct investments or portfolio investments in Bangladesh.
                        Foreign investors holding units of AIFs would be allowed to sell or transfer or redeem the units as per rules or directions issued by the BSEC.
                        </div>
                        
                        <div className='col-span-2 flex items-center text-left p-2'>
                            Opportunity for further growth of impact funds and impact enterprises
                        </div>
                    </div>
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.bb.org.bd/mediaroom/circulars/gbcrd/apr042019sfd01.pdf" target='_blank' className='font-bold text-primary underline  decoration-1'>
                            Circular on Considering Investment by Scheduled banks and Financial Institutions in Impact Fund as Green Financing
                            </a>
                        </div>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                        April 04, 2019
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                        Sustainable Financing Department, Bangladesh Bank
                        </div>
                        <div className='col-span-4 border-r border-black flex items-center text-left p-2'>
                            <div>
                                <div className='p-2'>
                                Investment by scheduled banks and FIs in Impact Funds will be considered as Green Financing.
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2  flex items-center text-left p-2'>
                        Banks and FIs will be encouraged to invest in impact funds if they receive preferential treatments by BB
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
