import React from "react";
import { Link } from "react-router-dom";
import news26 from "../../../../assets/images/news26.jpg";
import news26_details1 from "../../../../assets/images/news26_details1.jpg";
import news26_details2 from "../../../../assets/images/news26_details2.jpg";
import news26_details3 from "../../../../assets/images/news26_details3.jpg";
import news26_details4 from "../../../../assets/images/news26_details4.jpg";
import news26_details5 from "../../../../assets/images/news26_details5.jpg";
import news26_details6 from "../../../../assets/images/news26_details6.jpg";
import news26_details7 from "../../../../assets/images/news26_details7.jpg";
import news26_details8 from "../../../../assets/images/news26_details8.jpg";
import news26_details9 from "../../../../assets/images/news26_details9.jpg";
import news26_details10 from "../../../../assets/images/news26_details10.jpg";
import news26_details11 from "../../../../assets/images/news26_details11.jpg";
import news26_details12 from "../../../../assets/images/news26_details12.jpg";
import news11 from "../../../../assets/images/news11.jpg";

const showcase = [
  {
    id: 1,
    img: news26_details1,
    title:
      "Sir Ronald Cohen, President, GSG, and Farhad Reza, President, Build Bangladesh",
  },
  {
    id: 2,
    img: news26_details2,
    title: "Cliff Prior, CEO GSG and Farhad Reza, President, Build Bangladesh",
  },
  {
    id: 3,
    img: news26_details3,
    title: "Farhad Reza, Krisztina Tora (GSG), Tomoya Shiraishi (Japan NAB)",
  },
  {
    id: 4,
    img: news26_details4,
    title: "Few Members of the APAC NABs, Taskforce and GSG",
  },
  {
    id: 5,
    img: news26_details5,
    title: "Day One: GLM Meeting",
  },
  {
    id: 6,
    img: news26_details6,
    title: "Welcome note from Safak Muderrisgil (Chair, Türkiye NAB)",
  },
  {
    id: 7,
    img: news26_details7,
    title: "Welcome note from Sir Ronal Cohen, President GSG",
  },
  {
    id: 8,
    img: news26_details8,
    title: "Ongoing Meeting",
  },
  {
    id: 9,
    img: news26_details9,
    title: "Group tour by the APAC Team",
  },
  {
    id: 10,
    img: news26_details10,
    title: "APAC Breakfast Meeting",
  },
  {
    id: 11,
    img: news26_details11,
    title: "Workshop: APAC Team",
  },
  {
    id: 12,
    img: news26_details12,
    title: "Concluding Speech: Nick Hard, Chair GSG",
  },
];

export default function NeedPopulationDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news26}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Istanbul, Türkiye, turned into an impact investment leaders ‘Mecca’
          between May 30th to 1st June. It was impact Investment annual
          festivity at the Global Leaders Meeting (GLM) organised by the Global
          Steering Group (GSG) for Impact Investment in partnership with the
          Türkiye NAB. This was an ‘invite only’ event among the global impact
          investment leaders representing their National Advisory Board (NAB)
          and Taskforces working towards becoming a NAB. GSG has around 33 NAB
          member countries and expecting to have around 40 countries by end of
          this year. Bangladesh became the 19th member country in 2018.
        </p>
        <p className="pb-6">
          The festivity commenced with a great welcome party on 29th May evening
          before the hard-working sessions over next three days. Over the three
          days, 120+ participants discussed how we can do even more to change
          the financial system and drive impact at scale for people and planet.
          In 2017-18, the GSG first strategic plan aimed at a Tipping Point by
          end 2020 which we collectively achieved: 30 member countries and over
          $300bn USD in impact investing. The GLM, discussed what should be our
          next step? Our next “tipping point”?
        </p>
        <p className="pb-6">
          The Working sessions started with welcome from the Chair of the
          Türkiye NAB Ms Safak Muderrisgil followed by the inspiring speech from
          Sir Ronald Cohen, President of the GSG. Each of the NAB had their 60
          seconds of ‘glory sharing’ time while the Taskforces were in a
          different session lead by the GSG CEO Cliff Prior. It was clear from
          the Asian (particularly Bangladesh) and African (particularly Nigeria)
          NABs that people focused investment should play significant role to
          mainstream impact investment in addition to the tipping points of
          increasing member countries and Asset Under Management (AUM) over next
          few years.
        </p>
        <p className="pb-6">
          The leadership meeting also discussed overarching issues like,
        </p>
        <ul className="pl-20 pb-6">
          <li>Policy, advocacy and impact transparency; </li>
          <li>Mobilising private capital for impact; and </li>
          <li>Frontier challenges and impact economies. </li>
        </ul>
        <p className="pb-6">
          At the regional level, there were few workshops among the regional
          members and Bangladesh NAB played instrumental role to guide the way
          forward for the Asia-Pacific (APAC) NABs and Taskforce leaders. The
          workshop topics included questions like,
        </p>
        <ul className="pl-20 pb-6">
          <li>
            How would you demonstrate the impact of impact investment in your
            region?{" "}
          </li>
          <li>
            How would you demonstrate the impact of your collective work in your
            region?{" "}
          </li>
          <li>
            List some actionable ideas and how you can hold yourself accountable
            to make them a reality?{" "}
          </li>
          <li>
            What do you need to STOP, START and CONTINUE to achieve insights and
            higher ambition (e.g. 10% AUM) into your strategy and action plans?
          </li>
        </ul>

        <p className="pb-6">
          The Asia-Pacific (APAC) NABs and Taskforce leaders were inspired and
          listed number of possibilities to collaborate amongst them. Below are
          few potential regional collaborations that emerged from the
          Asia-Pacific breakfast and regional conversation/workshops, and all
          agreed to follow these up with specific NAB to lead.
        </p>

        <ol className="pl-20  flex flex-col gap-4">
          <li>
            Send an open letter to the International Sustainable Standard Board
            (ISSB) from APAC representatives (lead: Chul Woo Moon – Korea NAB){" "}
          </li>
          <li>
            Share more data re: fund allocation in the region (lead: Masa Uo –
            Japan NAB){" "}
          </li>
          <li>
            Collect and share case stories from each country (lead: All NABs,
            Taskforces and GSG){" "}
          </li>
          <li>
            Create an Asia Impact Fund (lead: Royston Braganza – India NAB){" "}
          </li>
          <li>
            Share knowledge and best practices on wholesale / pension fund
            (leads: David Heatherington – Australia NAB and Masa Uo – Japan NAB){" "}
          </li>
          <li>
            Potential APAC meet-up in Bangkok (leads: Krathing and Sunit –
            Thailand Taskforce){" "}
          </li>
          <li>
            Promote ranking/impact index – population weighted AUM (lead: Farhad
            Reza – Bangladesh NAB){" "}
          </li>
        </ol>

        <p className="pb-6">
          The GLM finished with a positive note from the current GSG Chair Mr
          Nick Hard, former Minister for the UK Government. Sir Ronald
          officially handed over the Chair responsibility to Nick and he will
          continue to lead the global impact investment movement as the GSG
          President.{" "}
        </p>
        <p className="pb-6">
          The whole leadership group ended the meeting hoping to see all again
          at the GSG Annual Conference in Malaga, Spain between 2-3 October
          2023.{" "}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {showcase?.map((image) => (
            <div key={image?.id}>
              <img src={image?.img} alt={image?.title} />
              <p className="text-[10px] text-center">{image?.title}</p>
            </div>
          ))}
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
