import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function DevelopmentPartners() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Development Partners and Organizations</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-3 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-6  border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black flex items-center text-left p-2'>
                            International Finance Corporation (IFC)
                        </div>
                        <div className='col-span-6 border-b border-black flex items-center text-left p-2'>

                            <div>
                                <a href="https://www.ifc.org/wps/wcm/connect/corp_ext_content/ifc_external_corporate_site/home" target='_blank' className=' text-primary underline  decoration-1'>
                                    IFC
                                </a>
                                <br />
                                is the largest global development institution focused on private
                                sector in developing countries. As a member of the World Bank, it
                                advances economic development by encouraging the growth of the
                                private sector.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            United Nations Development Programme (UNDP)
                        </div>
                        <div className='col-span-6 border-b border-black border-black flex items-center text-left p-2'>
                            <div>
                                <p>Implementer of</p>
                                <a href="https://www.uncdf.org/ield/homepage" target='_blank' className=' text-primary underline  decoration-1'>
                                    Inclusive and Equitable Local Development Programme (IELD): Addresses structural impediments that prevent women from
                                    entering the labour market through local public and private
                                    investments, and unlocking domestic capital for women’s economic
                                    empowerment and entrepreneurship.
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            Japan International Cooperation Agency (JICA)
                        </div>
                        <div className='col-span-6 border-b border-black border-black flex items-center text-left p-2'>
                            <div>
                                <a href="https://www.jica.go.jp/english/index.html" target='_blank' className=' text-primary underline  decoration-1'>
                                    JICA
                                </a>
                                <br />
                                is committed to support Bangladesh’s journey towards sustainable
                                growth with equity by providing strategic and financial assistance
                                in these economic areas – power and energy, transport, urban
                                development, education, health, governance, agriculture and
                                disaster.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            Embassy of Switzerland in Bangladesh
                        </div>
                        <div className='col-span-6 border-b border-black border-black flex items-center text-left p-2'>
                            <div>
                                Apart from reflecting Swiss interests in the areas of political,
                                economic and financial affairs, development cooperation and
                                humanitarian aid, the
                                <br />
                                <a href="https://www.eda.admin.ch/dhaka" target='_blank' className=' text-primary underline  decoration-1'>
                                    Embassy of Switzerland
                                    <br />
                                    in Bangladesh also collaborates with Bangladesh to help it achieve
                                    a prosperous and equitable future. The embassy promotes economic
                                    and national self-sufficiency, works to improve conditions of
                                    production and assists in dealing with environmental problems.
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            Development Partners and Organizations
                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            Asian Development Bank (ADB)
                        </div>
                        <div className='col-span-6 border-b border-black border-black flex items-center text-left p-2'>
                            <div>
                                <a href="https://www.adb.org/" target='_blank' className=' text-primary underline  decoration-1'>
                                    ADB
                                </a>
                                <br />
                                catalyses private investments, by offering financial assistance to
                                non-sovereign projects and financial intermediaries. ADB committed
                                to channel USD 583.5 Mn to Bangladesh through its non-sovereign
                                portfolio. Between 2021 and 2023, ADB aims at investing USD 5.9 Bn
                                in 30 projects in Bangladesh. Also, it aims to provide technical
                                assistance and co-financing (USD 52 Mn) to 39 projects in the next
                                three years.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            United Nations Capital Development Fund (UNCDF)
                        </div>
                        <div className='col-span-6  border-b border-black border-black flex items-center text-left p-2'>
                            <div>
                                Implementer of
                                <br />
                                <a href="https://www.uncdf.org/ield/homepage" target='_blank' className=' text-primary underline  decoration-1'>
                                    Inclusive and Equitable Local Development Programme
                                    (IELD): Addresses structural impediments that prevent women from
                                    entering the labour market through local public and private
                                    investments, and unlocking domestic capital for women’s economic
                                    empowerment and entrepreneurship.
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            Development for International Development (DFID)
                        </div>
                        <div className='col-span-6  border-b border-black border-black flex items-center text-left p-2'>

                            <div>
                                <a href="https://www.gov.uk/world/organisations/dfid-bangladesh" target='_blank' className=' text-primary underline  decoration-1'>
                                    Former DFID
                                </a>
                                <br />
                                (now a part of Foreign, Commonwealth and Development Office)
                                played a vital role in uplifting Bangladesh’s economic and social
                                infrastructure by contributing in sectors such as agriculture
                                (e.g., Suchana project), banking and financial services (e.g.,
                                Business Finance for Poor programme), business (e.g., BD
                                Investment Climate Fund), energy and power (e.g., Summit
                                Meghnaghat), disaster relief (Strengthening humanitarian
                                preparedness and response in Bangladesh), education, environment,
                                government and civil society (Strengthening Public Expenditure
                                Management in Bangladesh), health, industry and water sector.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-b border-r border-black flex items-center text-left p-2'>
                            BRAC
                        </div>
                        <div className='col-span-6  border-b border-black border-black flex items-center text-left p-2'>
                            <a href="http://www.brac.net/udp-urban-innovation-challenge" target='_blank' className=' text-primary underline  decoration-1'>
                                Urban Innovation Challenge: Invites to submit innovative and sustainable solutions to urban
                                problems to provide seed funding and mentorship to test the ideas,
                                followed by an opportunity to pitch it to investors to scale it
                                up.
                            </a>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-b border-black border-black flex items-center text-left p-2'>
                            OXFAM
                        </div>
                        <div className='col-span-6 border-b border-black border-black flex items-center text-left p-2'>
                            <a href="https://wee.oxfam.org/EYW" target='_blank' className=' text-primary underline  decoration-1'>
                                Empower Youth for Work (EYW): Introduces business, entrepreneurship, accounting, and green
                                business to youth entrepreneurs. Extensively discusses on how to
                                increase productivity, tactics to raise income, provision of loans
                                and investment readiness.
                            </a>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-3 border-r border-black border-black flex items-center text-left p-2'>
                            Truvalu
                        </div>
                        <div className='col-span-6 border-black flex items-center text-left p-2'>
                            <div>
                                Grow Your Business with
                                <br />
                                <a href="https://truvalu-group.com/" target='_blank' className=' text-primary underline  decoration-1'>
                                    Truvalu: Provides strategic business development services to improve
                                    operations, management, finance, and marketing alongside equity
                                    investments to and building trade relationships with agro-SMEs.
                                </a>
                            </div>
                        </div>

                    </div>





                </div>
            </div>
        </div>
    )
}
