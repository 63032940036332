import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function Accelerators() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Accelerators and Incubators</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-8  border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-11 text-center  border-black bg-[#FAFAFA] '>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                            Accelerators and Incubators
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            YGAP Bangladesh
                        </div>
                        <div className='col-span-8 border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://www.thedailystar.net/star-youth/spark-bangladesh-accelerator-2018-1546642" target='_blank' className=' text-primary underline  decoration-1'>
                                    Social Entrepreneurship Accelerator Program:
                                </a> </span>
                                Works to improve the lives of people living in poverty by accelerating start-up social enterprises and supporting them grow their businesses and make positive changes in their communities. <a href="https://ygap.org/bangladesh/" target='_blank' className=' text-primary underline  decoration-1'>
                                    YGAP Bangladesh
                                </a> Accelerator: Accelerator program for impact entrepreneurs that strengthen their business models and also provide mentorship, marketing support, legal and accounting help, and access to finance. YHER Bangladesh: In October 2018 YGAP launched YHER – an accelerator program dedicated for women led impact/– one of its kind in South Asia. Till May 2020 the enterprises have recorded an average of 510% growth in impact and 262% growth in revenue.
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}
