import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import InvesterismDetails from "./component/InvesterismDetails";

export default function InvesterAndIsm() {
  return (
    <Layout>
      <NewsHeader>Impact InveSTAR and Impact Invest-ism!</NewsHeader>
      <InvesterismDetails />
    </Layout>
  );
}
