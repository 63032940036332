import React from "react";
import { Link } from "react-router-dom";
import news14 from "../../../../assets/images/news14.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function BridgingGapDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news14}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <h1 className="pb-10 text-2xl font-semibold">
          Summarized by Arastoo Khan, Chair, NAB Trust
        </h1>
        <p className="pb-6">
          Worldwide Impact Investment is gaining momentum and popularity in
          attracting private sector capital to fulfill SDG commitments. It has
          become one of the key strategies in pooling private capital on the
          back of climate change, energy transition, and increased ESG
          awareness. In the context of Bangladesh, it is imperative to create
          awareness, educate, and engage private sector stakeholders to
          complement the impact investment movement in the country.
        </p>
        <p className="pb-6">
          With this in view, the National Advisory Board (NAB) for Impact
          Investment in Bangladesh and the Bangladesh Securities and Exchange
          Commission (BSEC) came together to host a roundtable workshop –
          “Bridging the Gap’ on 12 November 2023 at the Securities Commission
          Bhaban.
        </p>
        <p className="pb-6">
          The workshop, a folloup event of the International Impact Investment
          Summit – Impact InveSTART, brought together peak business bodies,
          investors, experts, financial institutions, development partners,
          regulators, and other stakeholders. It aimed to make them aware of the
          impact investment movement and explore ways for the private sector to
          drive this movement on a local scale.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
