import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import NeedPopulationDetails from "./component/NeedPopulationDetails";

export default function NeedPopulation() {
  return (
    <Layout>
      <NewsHeader>
        “We need population weighted AUM” – NAB Bangladesh | GSG GLM @ Istanbul,
        Türkiye
      </NewsHeader>
      <NeedPopulationDetails />
    </Layout>
  );
}
