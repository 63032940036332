import React from "react";
import { Link } from "react-router-dom";
import news13 from "../../../../assets/images/news13.png";
import news13_details1 from "../../../../assets/images/news13_details1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function ImpactInvestartDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news13}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          The <span className="font-bold">Impact InveSTART</span> and{" "}
          <span className="font-bold">Bridging the Gap</span> hosted by the
          National Advisory Board (NAB) for Impact Investment in Bangladesh and
          the Bangladesh Securities and Exchange Commission on 11th and 12th
          November 2023 respectively, to facilitate Impact Investment Ecosystem
          in Bangladesh attracted more than 200 people over the two days. The
          key comments/findings are:
        </p>

        <ul className="pl-20 pb-10">
          <li>
            Position Bangladesh as a leader in the world for impact investment
            through a common voice with same narratives and objective to attract
            impact investments for Bangladesh.
          </li>
          <li>
            10th of November has been declared as the National Impact Investment
            Day for Bangladesh.
          </li>
          <li>
            It is important to emphasise current theory of change backed by
            business plan and data to demonstrate its successful delivery on the
            ground.
          </li>
          <li>
            Involve financial and non-financial organisations to develop
            diversified and more appropriate business cases to attract large
            investors from multilateral organisation, international development
            partners, corporate sectors, philanthropic funding, and expatriates.
          </li>
          <li>
            NAB to continue its awareness, education, and research programmes
            through ongoing communications among stakeholders to transform
            mono-focus businesses to multi-focal investments complementing
            social and environmental benefits while producing sustainable
            financial return.
          </li>
          <li>
            Redefine roles and responsibilities of social enterprises and
            for-profit businesses by negotiating behavioural and cultural
            changes required to do business more effectively to scale up the
            impact economy.
          </li>
          <li>
            Develop measurable criteria and indicators for impact investing
            using global references while ensuring the local applicability and
            sensitivity.
          </li>
          <li>
            Design and implement locally developed and applicable impact
            investment instruments and/or make global instruments appropriate
            and accessible for local needs.
          </li>
          <li>
            Scope for Government to use their regulatory power to encourage and
            promote impact investing, particularly from the financial
            institutions through more supply of equity capital in this sector.
          </li>
          <li>
            Capitalise on the collective strength of 170 million plus
            Bangladeshis as consumers of commodity.
          </li>
          <li>
            Achieve tangible outcomes by working collaboratively across
            government, with corporate and other not-for-profit sectors by
            delivering local solutions to local problems with global standard to
            ensure its competitiveness.
          </li>
        </ul>
        <p className="pb-10">
          While impact investment stakeholders including investors are very
          optimistic for Bangladesh because of its current socio-political
          stability, supportive regulatory framework, GDP growth, and younger
          demographic trends, there is still significant challenges that
          discourages investors from entering the local market. Some of these
          key challenges are:
        </p>

        <ul className="pl-20 pb-10">
          <li>Proper due diligence process and absence of local expertise.</li>
          <li>Structure for investment.</li>
          <li>
            Outcomes monitoring, measurements, and reporting of investment.
          </li>
          <li>Follow up of investments.</li>
          <li>Difficulty in raising funds.</li>
          <li>Difficulty in finding local expertise to manage impact fund.</li>
          <li>Limited availability of local funds.</li>
          <li>Many investors are still setting up overseas fund.</li>
          <li>Fund repatriation difficulties.</li>
          <li>Absence of effective exit strategy.</li>
        </ul>

        <p className="pb-10">
          The NAB Bangladesh is working on the way forward addressing these
          challenges through its ongoing consultations with local and global
          stakeholders. It will present a comprehensive action report to
          celebrate an outcome focused National Impact Investment Day on 10
          November 2024.
        </p>
        <div className="text-center">
          <img
            src={news13_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-10 pt-2">Bridging the GAP, November 12, 2023</p>
        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
