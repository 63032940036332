import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import NewWaveDetails from "./Component/NewWaveDetails";

export default function NewWave() {
  return (
    <Layout>
      <NewsHeader>New Wave of NAB Bangladesh</NewsHeader>
      <NewWaveDetails />
    </Layout>
  );
}
