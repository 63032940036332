import React from "react";
import { Link } from "react-router-dom";
import navigating_impact from "../../../../assets/images/navigating_impact.jpg";
import investment_Picture1 from "../../../../assets/images/investment-Picture1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function InvestImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={navigating_impact}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The global impact investment industry, totaling USD 715 billion, has
          grown in depth and sophistication over the last decade.[1] The latest
          Global Impact Investing Network (GIIN) survey reports that 68% of the
          impact investors around the globe consider that impact investing has
          passed its nascent stage and continues to grow steadily.
        </p>
        <p className="pb-6">
          The Bangladesh impact investment ecosystem is increasingly gaining
          enthusiasm and commitment across stakeholder groups. This momentum of
          the impact investing sector is aligned with the growing wave of impact
          investing trend dispersing globally. The Bangladesh impact sector has
          witnessed noteworthy milestones since 2015; however, strides have to
          be made to realise the vision for impact investment.[2]
        </p>
        <ul className="pl-20 pb-10">
          <li>
            Firstly, a strong positioning and identity needs to be created for
            the Bangladeshi impact investing ecosystem so that the ambiguity
            regarding the definition and measurement of impact could be
            minimized.
          </li>
          <li>
            Secondly, higher level of collaboration has to be established among
            stakeholders to orchestrate impact investing interventions that
            generate inclusive outcomes.
          </li>
          <li>
            Thirdly, impact investing education and trainings could be made more
            available and accessible to the impact enterprises across Bangladesh
            to accelerate capacity development of the entrepreneurs and
            scalability of the enterprises.
          </li>
          <li>
            Fourthly, alternative financing instruments and tools including
            blended financing, procurement policies for impact enterprises, and
            untapped capital utilization could be explored to increase the flow
            of impact capital to the market.
          </li>
          <li>
            Lastly, there should be more incentives and support for the supply
            side (i.e., investors) to explore impact investing. Standardised due
            diligence processes and guidelines, more clearly defined exit
            strategies, and a forward market for selling impact stocks (impact
            exchange) would go a long way to crowding more investors in to the
            impact ecosystem.[3]
          </li>
        </ul>
        <p className="pb-6">
          Impact investment is gaining traction in Bangladesh, with investors
          increasing interest in programmes/projects that generate social and
          environmental benefits alongside financial returns. However, impact
          investing is a complex space that requires careful navigation to
          ensure positive outcomes for all stakeholders involved. In addition to
          the end beneficiaries, there are four critical stakeholders in this
          space: the regulatory authority, investors, investees and
          intermediaries shaping the impact investment landscape.
        </p>

        <p className="pb-6">
          In 2015, the Bangladesh Securities and Exchange Commission (BSEC)
          introduced the BSEC Alternative Investment Rules that paved the path
          of a regulatory framework for impact investment. A circular
          recognising scheduled banks’ investments in alternative investment by
          Bangladesh Bank was released in the same year, marking a significant
          milestone. Between 2016 and 2017, Build Bangladesh[4] hosted the first
          international conference on impact investment in 2016 followed by the
          first international workshop on impact investment in 2017 to initiate
          the movement for establishing the Impact Investment Ecosystem in
          Bangladesh. One of the outcomes from these awareness sessions was an
          active partnership between UNDP and Build Bangladesh to promote impact
          investment in Bangladesh and to influence the United Nations to
          consider impact investment as part of its mainstream support
          mechanism. In 2017, UN member states committed to develop enabling
          environment for Impact Investing through UN resolution, adopted by the
          UN ESCAP, in that regard, Asia Pacific member states including
          Bangladesh played a leadership role globally in really putting this
          agenda on the UN priority list.
        </p>
        <p className="pb-6">
          In 2017, the concept of impact fund was launched by the honorable
          Prime Minister Sheikh Hasina along with Achim Steiner, the
          Administrator for the UNDP, at a side event of the UN General Assembly
          in New York organized by Build Bangladesh and UNDP. The National
          Advisory Board (NAB) for Impact Investment in Bangladesh{" "}
          <a
            href="https://nabbangladesh.org/"
            rel="noreferrer"
            target="_blank"
            className="text-blue-500"
          >
            (https://nabbangladesh.org/)
          </a>{" "}
          was formed in 2018 to provide high-level guidance and shape the impact
          investing ecosystem in Bangladesh. Build Bangladesh took the
          responsibility to provide the secretariat support for NAB Bangladesh
          while played instrumental role for NAB Bangladesh to become the 19th
          member country (2018) of the Global Steering Group (GSG) for Impact
          Investment{" "}
          <a
            href="https://gsgii.org/"
            rel="noreferrer"
            target="_blank"
            className="text-blue-500"
          >
            (https://gsgii.org/)
          </a>
          , an organization catalyzing impact investment to benefit society and
          the planet.
        </p>
        <p className="pb-6">
          In 2019, Bangladesh Bank published a circular linking impact investing
          with green funds. The NAB Bangladesh in partnership with Build
          Bangladesh, the Embassy of Switzerland, British Council and UN ESCAP
          jointly supported the development of the “Policy Landscape Analysis:
          Impact Investing in Bangladesh” and was published in 2020 to provide
          the baseline. Meanwhile, between 2015 and 2020, organisations like IFC
          (Impact Investment), ADB, Build Bangladesh (Micro Impact Investment,
          Corporate Social Impact Investment), SDC (Biniyog Briddhi), BRAC
          (Social Impact Bond), FCDO, Light Castle Partners, Start-up
          Bangladesh, Better Stories, Anchorless, Bangladesh Angel, SBK,
          commenced works to promote and practice impact investment within their
          individual capacity.
        </p>
        <p className="pb-6">
          During the COVID-19 pandemic/lockdown in 2020-21, NAB Bangladesh
          tasked Build Bangladesh to develop a National Strategy to guide the
          impact investment movement as identified in the Policy Landscape
          study. Build Bangladesh in partnership with the Embassy of Switzerland
          developed and delivered the first Bangladesh Impact Investment
          Strategy and Action Plan (BIISAP) in 2022. Prior to the launch of
          BIISAP, in July 2022, NAB Bangladesh hosted four workshops on impact
          investing in partnership with Build Bangladesh, Light Castle Partners,
          the Embassy of Switzerland in Bangladesh, and BRAC, involving major
          stakeholders such as the regulators, development partners, investors,
          investees, and intermediaries. These workshops gave a much clearer way
          forward to develop the BIISAP Implementation Action Plans.
        </p>
        <p className="pb-6">
          The BIISAP was launched by the NAB Bangladesh in partnership with
          BIDA, Build Bangladesh, the Embassy of Switzerland, Roots of Impact
          and Dhaka Stock Exchange in August 2022. The BIISAP was launched by
          the Honorable Minister for Planning Muhammad Abdul Manna MP. The
          BIISAP launch incorporated an international summit on Impact
          Investment – “Impact InveStory”. This summit attracted speakers and
          audiences from a diversified stakeholders including the GSG, other
          NABs, diplomatic missions, multilateral development organisation, key
          government departments, NGO, peak bodies, young impact entrepreneurs
          and intermediaries.
        </p>
        <p className="pb-6">
          In 2022, the NAB Bangladesh tasked Build Bangladesh to establish a
          legal entity for NAB to influence the BIISAP implementation action
          plans while opening up scopes for incorporating more supportive
          partners including relevant industry experts to drive the way forward.
          In January 2023, NAB Bangladesh has registered a not-for-profit Trust
          – the NAB Trust. This Trust will give the works and initiatives of NAB
          more accountability, continuity, and sustainability. With the creation
          of the NAB Trust, the current National Advisory Board (NAB) for Impact
          Investment in Bangladesh will still be operational and be called
          National Advisory Board of Governance (NABG) for Impact Investment in
          Bangladesh. The existing members of NAB Bangladesh including the
          current Chair will continue their roles as incorporated in the
          formation of the NABG. The NAB Trust will provide the secretariat
          support including communication, membership management and finance, to
          the NABG, while the Trustees will report to the NABG quarterly.
        </p>
        <h2 className="font-bold underline">
          Current NAB governance structure:
        </h2>
        <p className="pb-6">
          In 2018, when NAB Bangladesh became the 19th member country of the
          GSG, it always had a plan to do a two-tier governance structure. One
          will perform the advisory role and the other one will do the
          operational function as a legal entity. This structure allows NAB
          Bangladesh to share the operational budget and functions among
          members. Since 2017 till to date Build Bangladesh has been bearing all
          the expenses for the NAB functions including leading the operational
          process to build the Impact Investment Ecosystem in Bangladesh
          including the initial GSG membership fee (which has been abolished
          since 2019). The two tiers NAB Bangladesh are:
        </p>
        <p className="pb-6">
          Tier one: The NAB Bangladesh with its current Members has transitioned
          into the National Advisory Board of Governance (NABG) for Impact
          investment in Bangladesh, Chaired by the Executive Chairman of the
          Bangladesh Investment Development Authority. Arastoo Khan has been the
          Member Secretary of NAB Bangladesh since 2018 and continuing the role
          for NABG as well. Architect Farhadur Reza has been the CEO of NAB
          Bangladesh and now continuing as the CEO for NABG. NABG is an Advisory
          Board and instructed the NAB Secretariat (Build Bangladesh) to
          establish a legal entity to manage its day-to-day operation due to the
          increased activities in the Impact Investment Ecosystem in Bangladesh,
          particularly since the launch of the Bangladesh Impact Investment
          Strategy and Action Plan (BIISAP) and its Implementation Action Plans.
          NABG has no legal status.
        </p>
        <p className="pb-6">
          Tier two: Newly established legal entity – the NAB Trust. Arastoo Khan
          is the Author/Chairperson of the Trust with six other members (five of
          the seven Trustees are NABG Members). The Trust is an independent
          legal entity but accountable and reports back to the NABG.
        </p>
        <p className="pb-6">
          The NABG, at their February 2023 meeting endorsed the BIISAP
          Implementation Action Plans and tasked NAB Trust to lead the
          implementation of BIISAP over next few years.
        </p>
        <p className="pb-6">
          Bangladesh’s impact investment landscape has been steadily progressing
          in recent years, thanks to the efforts of organizations such as BSEC,
          Bangladesh Bank, BIDA, and NAB Bangladesh. However, there is still
          much that can be done to enhance the country’s impact investment
          ecosystem. To maximize the impact investment opportunities in
          Bangladesh, it is crucial to sharpen and amplify the country’s impact
          story to the world. This will help to increase international interest
          in Bangladesh’s impact investment opportunities, attract new
          investors, and expand the market. In addition, promoting collaboration
          among ecosystem builders can help to create a more cohesive impact
          investment landscape, where stakeholders can share knowledge and
          resources to drive growth and impact. Finally, an effective and
          streamlined investment and repatriation system will significantly
          encourage the impact investment process, increase investor confidence,
          and create a more favorable investment environment in Bangladesh. By
          focusing on these key areas, Bangladesh can continue to develop and
          strengthen its impact investment ecosystem, ultimately driving greater
          positive impacts across the country.
        </p>

        <ul className="pl-20 py-10">
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/29/navigating-impact-investment-in-bangladesh-2/#_ftnref1"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [1]
            </a>
            Global Impact Investing Network (GIIN). 2020.{" "}
            <a
              href="https://thegiin.org/research/publication/impinv-survey-2020"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              https://thegiin.org/research/publication/impinv-survey-2020
            </a>
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/29/navigating-impact-investment-in-bangladesh-2/#_ftnref2"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [2]
            </a>
            Impact investments are investments made with the intention to
            generate positive, measurable social and/or environmental impact
            alongside a financial return. BSEC
          </li>
          <li>
            {" "}
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/29/navigating-impact-investment-in-bangladesh-2/#_ftnref3"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [3]
            </a>
            BIISAP 2022
          </li>
          <li>
            <a
              href="https://www.buildbangladesh.com.bd/2024/02/29/navigating-impact-investment-in-bangladesh-2/#_ftnref4"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              [4]
            </a>
            The first Impact Enterprise in Bangladesh under the Impress Group
            with its own legal entities: Asset Management, Social Enterprise and
            Foundation.
          </li>
        </ul>

        <div>
          <img src={investment_Picture1} alt="" />
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
