import React from "react";

export default function NewsHeader({ children }) {
  return (
    <div className="py-20 bg-[#16A085]">
      <div className="max-w-6xl mx-auto">
        <p className="text-5xl font-bold text-center text-white py-8">
          {children}
        </p>
      </div>
    </div>
  );
}
