import React from 'react'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import SupplySidePlayers from './SupplySidePlayers'

export default function KeySupplySides() {
  return (
    <div className='flex flex-col gap-10'>
            <SupplySidePlayers />
            
        </div>
  )
}
