import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ActualNabTrustDetails from "./Components/ActualNabTrustDetails";

export default function ActualNabTrust() {
  return (
    <Layout>
      <NewsHeader>NAB Trust</NewsHeader>
      <ActualNabTrustDetails />
    </Layout>
  );
}
