import React from "react";
import { Button, ButtonYellow } from "../../../shared/ui/Button";
import impactImg from "../../../assets/images/impact.jpg";
import impactarches from "../../../assets/images/impactarches.png";

import { Link } from "react-router-dom";

export default function Impact() {
  return (
    <div className="bg-[#E8A87C] text-white py-20 -z-10 px-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row items-center gap-6">
          <div className="flex-1">
            <h2 className="text-4xl font-bold pb-4">
              Impact Investment in Bangladesh
            </h2>
            <p className="pb-6">
              Bangladesh with its strong, growing, and diverse economy, have
              become the ‘seeding fertile ground’ for Impact Investment. Both
              the Government and the corporate sectors have acknowledged the
              need for an alternative investment strategy including impact
              investment by taking actions accordingly. To allow impact
              investment suppressing the COVID-19 challenges, a supportive
              ecosystem is required, which is being built over the years.
            </p>
            <Link to="/impact-investing-in-bangladesh">
              <ButtonYellow>Read More</ButtonYellow>
            </Link>
          </div>
          <div className="flex-1">
            <img src={impactImg} className="rounded-md" alt="impact img" />
          </div>
        </div>
      </div>
      {/* <div >
            <img src={impactarches} className='absolute top-0 left-0 right-0 w-full h-[100px]' />
        </div> */}
    </div>
  );
}
