import React, { useEffect, useState } from 'react'

export const WindowHeight = () => {
    let [height, setHeight] = useState(0);
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        const winScroll = document?.body?.scrollTop || document?.documentElement?.scrollTop;
        setHeight(winScroll);
    };
    return height
}
