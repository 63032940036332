import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import TrillionEconomyDetails from "./components/TrillionEconomyDetails";

export default function TrillionEconomy() {
  return (
    <Layout>
      <NewsHeader>
        The plan to break systemic inertia and deliver impact transparency
      </NewsHeader>
      <TrillionEconomyDetails />
    </Layout>
  );
}
