import React from 'react'

export default function PageHeading({ children, bgImage }) {
    return (
        <div
            className="bg-white py-[110px] bg-cover bg-center bg-no-repeat flex justify-center items-center w-full"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            {children}
        </div>
    )
}
