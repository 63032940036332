import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import FactstatsVsImpactDetails from "./component/FactstatsVsImpactDetails";

export default function FactstatsVsImpact() {
  return (
    <Layout>
      <NewsHeader>FACTSTATS_ESC VS Impact Investment</NewsHeader>
      <FactstatsVsImpactDetails />
    </Layout>
  );
}
