import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import FatherOfImpactInvestmentDetials from "./Components/FatherOfImpactInvestmentDetials";

export default function FatherOfImpactInvestment() {
  return (
    <Layout>
      <NewsHeader>
        "Father of Impact Investment" Applauds Bangladesh’s Progress
      </NewsHeader>
      <FatherOfImpactInvestmentDetials />
    </Layout>
  );
}
