import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import NationalImpactNovemberDetails from "./component/NationalImpactNovemberDetails";

export default function NationalImpactNovember() {
  return (
    <Layout>
      <NewsHeader>10 November: National Impact Investment Day</NewsHeader>
      <NationalImpactNovemberDetails />
    </Layout>
  );
}
