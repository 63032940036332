import React from 'react'
import Layout from '../../../shared/Layout/Layout'
import NewsHeader from '../../../shared/Components/NewsHeader'
import NewLeaderForImpactDetails from './components/NewLeaderForImpactDetails'

export default function NewLeaderForImpact() {
  return (
    <Layout>
      <NewsHeader>
        Shaping the Future: Türkiye’s Journey into Impact Investing
      </NewsHeader>
      <NewLeaderForImpactDetails />
    </Layout>
  )
}
