import React from 'react'
import PageHeading from '../../../shared/Components/PageHeading'
import Learning_hub_bg from "../../../assets/images/Learning_hub_bg.jpg"

export default function LearningHubHeader() {
    return (
        <PageHeading bgImage={Learning_hub_bg}>
            <h2 className='font-bold text-2xl md:text-4xl text-center text-white py-10'>Research Publications</h2>
        </PageHeading>
    )
}
