import React from 'react'
import KeyPolicies from './KeyPolicies'
import KeyEcosystems from './KeyEcosystems'
import KeyEcosystemIntermediaries from './KeyEcosystemIntermediaries'
import KeySupplySides from './KeySupplySides'

export default function AccordionHeaderBasedComponents({impactName}) {
  return (
    <div className='bg-[#F5F5F5] py-20 px-8 md:px-16'>
        <div className="container mx-auto">
            {
                impactName === "Key Policies" &&  <KeyPolicies />
            }
            {
                impactName === "Key Ecosystem Builders" &&  <KeyEcosystems />
            }
            {
                impactName === "Ecosystem Intermediaries" &&  <KeyEcosystemIntermediaries />
            }
            {
                impactName === "Supply Side Players" &&  <KeySupplySides />
            }
           
        </div>
    </div>
  )
}
