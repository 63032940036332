import React from 'react'
import Layout from '../../../shared/Layout/Layout'
import NewsHeader from '../../../shared/Components/NewsHeader'
import BetterStoriesDetails from './components/BetterStoriesDetails'

export default function BetterStories() {
  return (
    <Layout>
      <NewsHeader>Navigating Impact Investment</NewsHeader>
      <BetterStoriesDetails  />
    </Layout>
  )
}
