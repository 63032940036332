import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import InvestmentStrategyDetails from "./component/InvestmentStrategyDetails";

export default function InvestmentStrategy() {
  return (
    <Layout>
      <NewsHeader>
        Bangladesh Impact Investment Strategy And Action Plan (BIISAP)
      </NewsHeader>
      <InvestmentStrategyDetails />
    </Layout>
  );
}
