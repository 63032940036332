import React from "react";
import { FaAngleRight } from "react-icons/fa";

export function Button({ children, className }) {
  return (
    <button className="bg-primary text-white px-4 py-2 rounded-md shadow-btn btn">
      {children}
      <FaAngleRight className="inline btn_arrow" />
    </button>
  );
}
export function GreenOutlineButton({ children }) {
  return (
    <button className="bg-white border-2 border-primary text-primary px-4 py-2 rounded-md shadow-btn btn">
      {children}
      <FaAngleRight className="inline btn_arrow" />
    </button>
  );
}

export function ButtonYellow({ children }) {
  return (
    <button className="bg-white text-[#E8A87C] px-4 py-2 rounded-md shadow-btn btn">
      {children}
      <FaAngleRight className="inline btn_arrow" />
    </button>
  );
}
