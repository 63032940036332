import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import PartnershipDetails from "./Component/PartnershipDetails";

export default function Partnership() {
  return (
    <Layout>
      <NewsHeader>
        Partnership between NAB Bangladesh and Türkiye NAB
      </NewsHeader>
      <PartnershipDetails />
    </Layout>
  );
}
