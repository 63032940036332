import React from 'react'
import KeyEcosystemIntermediatoryFirst from './KeyEcosystemIntermediatoryFirst'

export default function KeyEcosystemIntermediaries() {
    return (
        <div className='flex flex-col gap-10'>
            <KeyEcosystemIntermediatoryFirst />
        </div>
    )
}
