import React from 'react'
import Layout from '../../shared/Layout/Layout'
import BisapHeader from './Components/BisapHeader'
import BisapDetails from './Components/BisapDetails'

export default function BisapNews() {
  return (
    <Layout>
        <BisapHeader />
        <BisapDetails />
    </Layout>
  )
}
