import React from 'react'
import ImpactInvestment1 from "../../../assets/images/Impact-Investment1.png"
import ImpactInvestment2 from "../../../assets/images/Impact-Investment2.png"
import ImpactInvestment3 from "../../../assets/images/Impact-Investment3.png"

export default function WhyImpactInvesting() {
    return (
        <div className='container mx-auto pb-20 px-8 md:px-16'>
            <div className='max-w-5xl mx-auto text-center pb-8'>
                <h2 className='text-2xl md:text-4xl pb-10'>Why Impact Investing?</h2>
                <p className='font-semibold text-sm'>Impact investing has positioned itself to reshape the relationship between individual wealth and the public good, releasing the potential of private money to make a positive impact on society. In this new world, Impact Investment holds the potential for delivering on the national priorities of:</p>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-20'>
                <div className='bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary flex flex-col  items-center pb-10'>
                    <div className='text-center pb-10'>
                        <img src={ImpactInvestment1} className='w-20 h-20 text-center' />
                    </div>
                    <p>Becoming a middle-income country while ensuring equitable and efficient market systems to break the vicious cycle of poverty for the marginalized and underserved communities</p>
                </div>
                <div className='bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col  items-center'>
                    <div className='text-center pb-10'>
                        <img src={ImpactInvestment2} className='w-20 h-20 text-center' />
                    </div>
                    <p>Driving economic growth through greater private investment, foreign direct investment, entrepreneurship and innovation</p>
                </div>
                <div className='bg-white px-6 py-10 shadow-xl border-b-4 rounded-md text-center shadow-card border-primary  flex flex-col items-center'>
                    <div className='text-center pb-10'>
                        <img src={ImpactInvestment3} className='w-20 h-20 text-center' />
                    </div>
                    <p>Attaining remarkable progress in achieving SDGs by soaring up the recent gap in SDG financing</p>
                </div>
            </div>
        </div>
    )
}
