import React from 'react'
import JoinNabBdFormLeft from './JoinNabBdFormLeft'
import JoinNabBdFormRight from './JoinNabBdFormRight'

export default function JoinNabBdForm() {
    return (
        <div className='container mx-auto py-20 px-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <JoinNabBdFormLeft />
                <JoinNabBdFormRight />
            </div>
        </div>
    )
}
