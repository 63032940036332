import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news126.jpg";
import news123_creator from "../../../../assets/images/news126_creator.png";
import tika_contribution1 from "../../../../assets/images/tika_contribution1.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function TikasContributionDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          The Dhaka Program Coordination Office, which coordinates our Agency's
          projects for Bangladesh, Nepal, Sri Lanka, Maldives, and Bhutan,
          started providing resident services as of June 14, 2014. Our Agency's
          activities and projects for Bangladesh began to be implemented in
          2008, before the opening of our office. TİKA's activities in the
          country focus on responding to the country's development and
          humanitarian aid needs. Projects that concentrate especially in the
          areas of health, education, and humanitarian aid strengthen diplomatic
          and cultural ties between the two countries, as well as contribute to
          regional stability and development.
        </p>

        <p className="pb-6">
          Projects such as health centers built annually in rural areas, Medical
          Equipment Support to the Mother and Child Hospital that opened in
          Sylhet city, and the mobile clinic vehicle that entered service in
          2023 aim to strengthen Bangladesh's health infrastructure and improve
          public health by increasing access to health services.
        </p>

        <p className="pb-6">
          TİKA is carrying out many sustainable development projects in
          Bangladesh. Projects such as the establishment of an E-Commerce
          Facilitation Center for Disadvantaged Entrepreneurs contribute to
          economic life. The greenhouse for the tissue culture laboratory
          established in Bogra city aims to provide healthy seeds and plants to
          women farmers at affordable prices. In the first phase of this
          project, potato, cotton, and banana seedlings will be produced.
          Additionally, the establishment of a Molecular Biology Laboratory at
          the Bangladesh Cotton Development Board is ongoing. These projects aim
          to increase the country's capacity, especially in the agriculture and
          textile sectors.
        </p>

        <p className="pb-6">
          In the field of education, TİKA's activities aim to reach different
          segments of society and provide equal opportunities. Projects such as
          the renovation and equipping of Feni Atatürk High School with
          educational equipment, the establishment of an Educational Kitchen at
          Cox's Bazar City College, and the donation of a school bus to Teknaf
          Moinuddin College can be given as examples of TİKA projects in the
          field of education. Additionally, an innovative digital production
          laboratory was established at the Islamic University of Technology
          (IUT) in Dhaka, the capital of Bangladesh. "Cezeri Lab" provides
          practical study opportunities for electrical, civil, architectural,
          and Computer Science and Engineering students. Moreover, the
          laboratory offers students the opportunity to develop and complete
          joint projects, promoting international cooperation and technological
          innovation.
        </p>

        <p className="pb-6">
          TİKA operates in social and cultural fields in Bangladesh. Within the
          scope of the "Give Me a Chance Project" launched in 2021, girls from
          the north and east of Bangladesh were brought together to play
          football. In collaboration with Build Bangladesh, TİKA initiated the
          "Toy Library" project to contribute to the education of children
          living in rural areas and promote game-based learning. The project
          covers 50 schools in 21 regions across Bangladesh. As part of the
          project, 50 libraries, each equipped with educational toys and sports
          equipment, were established. This has increased opportunities for
          learning through play by providing access to various toys for children
          in rural areas, making a significant contribution to their educational
          lives.
        </p>
        <p className="pb-6">
          TİKA organized a smart farming techniques training program for farmers
          in the Koyra section of the Khulna region of Bangladesh. The
          four-month training was given to a group of 20 people consisting of
          women and youth, and participants were taught smart farming techniques
          such as sack gardening and vertical farming. Additionally, the program
          enabled trainees to develop their leadership and decision-making
          skills. At the end of the training, participants were presented with
          their certificates and provided with seed and fertilizer support.
        </p>

        <div className="text-center w-full">
          <img
            src={tika_contribution1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          In cooperation with Build Bangladesh, TİKA built two water towers in
          the Bandarban region of Bangladesh to combat water scarcity and
          related problems. Each tower has the capacity to produce 500 liters of
          water per week. In the region, people living mostly in villages face
          serious difficulties due to the distance of accessible water sources
          from settlements. Especially women have to carry water daily from
          distant sources to meet the water needs of their homes.
        </p>
        <p className="pb-6">
          Within the framework of humanitarian aid activities, TİKA organizes
          Ramadan programs every year and distributes thousands of food packages
          throughout Bangladesh. TİKA has been conducting a comprehensive aid
          program for Rohingya refugees since 2017. The TİKA Kitchen established
          in refugee camps in the Cox's Bazar region provided hot meals to an
          average of 40,000 people per day in the initial period of refugee
          migration. Today, it continues to serve 1,000 families daily during
          Ramadan months. Moreover, the Multi-Purpose Education and Cultural
          Center built in Camp-16 contributes to the long-term socio-economic
          development of refugees by offering vocational training, cultural
          integration, and social interaction opportunities together. Libraries
          equipped with Burmese books, which are a first in the camps, allow
          refugees to preserve their cultural identities and enable their
          intellectual development.
        </p>
        <p className="pb-6">
          Children's parks established in Camp-16 and Camp-5 contribute to the
          psycho-social development of children, while agricultural projects and
          infrastructure works carried out on Bhasan Char island aim to ensure
          food and life security for the island's residents. The sea ambulance,
          which entered service in 2023, has made a significant improvement in
          refugees' access to health services by facilitating access to
          emergency health services between Bhasan Char island and the mainland.
          Additionally, the steel bridge built in the Kutupalong Refugee Camp
          Expansion Area has improved the infrastructure in the camps.
        </p>
        <h2 className="font-bold">2024 Flood Disaster in Bangladesh</h2>
        <p className="pb-6">
          TİKA has started emergency humanitarian and medical aid in the flood
          area following the flood disaster that occurred as a result of heavy
          rains in Bangladesh. Comprehensive aid packages consisting of basic
          food items, water purification tablets, and hygiene materials were
          distributed to a total of 3,000 families in the cities of Feni,
          Laksmipur, and Noakhali; these distributions were carried out in
          coordination with local administrations, fire departments, and
          non-governmental organizations. The search and rescue team sent from
          Bangladesh after the earthquakes that occurred in Türkiye on February
          6, 2023, participated in the distribution of some of these aid
          packages, showing an example of mutual solidarity.
        </p>
        <p className="pb-6">
          TİKA also sent a Mobile Clinic Vehicle to the region. This mobile
          health unit has provided free health services to approximately 10,000
          disaster victims so far in the Feni and Noakhali regions, with
          volunteer doctors and healthcare workers, including a doctor from
          Türkiye. It has focused especially on disadvantaged groups such as
          children, women, and the elderly, providing treatment and medication
          support against skin diseases and water-borne infections that
          increased after the flood. Additionally, awareness-raising activities
          have been carried out to prevent diseases.
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Şevki Mert Barış</p>
            <p>Coordinator</p>
            <p>Turkish Cooperation and Coordination Agency - TİKA</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
