import React from "react";
import { Link } from "react-router-dom";
import trillion_dollar_economy from "../../../../assets/images/trillion_dollar_economy.jpg";
import trillion1 from "../../../../assets/images/trillion1.png";
import trillion2 from "../../../../assets/images/trillion2.png";
import trillion3 from "../../../../assets/images/trillion3.png";
import trillion4 from "../../../../assets/images/trillion4.png";
import trillion5 from "../../../../assets/images/trillion5.png";
import trillion6 from "../../../../assets/images/trillion6.png";
import trillion7 from "../../../../assets/images/trillion7.png";
import trillion8 from "../../../../assets/images/trillion8.png";
import trillion9 from "../../../../assets/images/trillion9.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function TrillionEconomyDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={trillion_dollar_economy}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Bangladesh has been named as the fastest growing economy in the
          Asia-Pacific economies out of 32 countries according to the latest
          report published by the World Economics (2023). Bangladesh has made
          enormous strides in the past 53 years – from one of the poorest
          nations at birth, it is now one of the fastest-growing economies. Over
          the recent years, Bangladesh has grown faster than few Asian countries
          and is now set to emerge as a trillion-dollar economy powered by an
          ambitious business community. The country’s $416 billion GDP in 2021
          would ascend to $1 trillion by 2030 if it secures a growth rate of 10%
          or by 2040 with an even slowed-down growth rate of 5%. It outpaced its
          major Asian peers including Vietnam, India, Philippines, Thailand, and
          Indonesia with an impressive 6.4% average growth over the 2016-21
          period. On a 10-year gross domestic product (GDP) compound annual
          growth rate (CAGR) basis, Bangladesh ranked as the fastest growing
          economy in the Asia-Pacific region and also the fastest in the
          frontier markets, Bangladesh’s CAGR over the last 10, 5 and 3 years
          was 9.1%, 9.4% and 8.5% respectively.
          <a
            href="https://www.buildbangladesh.com.bd/2024/02/27/bangladesh-trillion-dollar-economy/#_ftn1"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            [1]
          </a>
        </p>
        <p className="pb-6">
          Bangladesh has a strong track record of growth and development, even
          in times of elevated global uncertainty. A robust demographic
          dividend, strong ready-made garment (RMG) exports, resilient
          remittance inflows, and stable macroeconomic conditions have supported
          rapid economic growth over the past two decades. With an average
          annual GDP growth of 6.4% between 2016 and 2021, Bangladesh has
          performed significantly better than the global average of 2.9%. The
          country’s GDP per capita was US$2,800 in 2022—already higher than
          India’s. But it had ups and downs. The current global economic climate
          has created some uncertainties, with liquidity challenges, foreign
          exchange risks, inflation pressure in the short term, but the measures
          taken by the Country should allow Bangladesh to remain on its course
          towards a trillion-dollar economy.
        </p>
        <p className="pb-6">
          Every beginning also has its own beginning! This trend of beginnings
          inspired us to share our Impact InveStory. Our story begun long ago
          with the growth of five sectors: public, private, NGOs, social
          enterprise, and social business. But we are yet to recognise ‘unicorn
          business’ which is serving one ‘billion’ people along highlighting
          one-billion-dollar turnover! It is all about our mindset with money.
          We need a sixth sector and believe, “people do not want charity, they
          want a chance”. This is the foundation of Build Bangladesh as the
          first Impact Enterprise in Bangladesh, leading the Impact Investment
          Movement – the sixth sector. The impacts of Covid-19, the war in
          Europe and the Middle East puts us on the spot to innovate ‘people
          centric’ investment focusing return, risk, and impacts while
          complementing the 8th Five Year Plan for Bangladesh and SDGs. We have
          a much steep terrain to climb than our predecessor innovators managed
          the impacts of industrial revolution, two world wars and post
          independent years for Bangladesh. Our driving aspiration: “we are not
          good, until we all are good”. This edition of the Impact InveStory is
          a humble testimony of our passion and conviction ensuring
          intergenerational wellbeing reinforced with our unconditional love for
          Bangladesh.
        </p>
        <p className="pb-6">
          The question now is, why are we the most competitive model among our
          South Asian Neighbours? Why Bangladesh is the seeding fertile ground
          for Impact Investment? The reasons are:
        </p>

        <ol className="pl-20 py-10">
          <li>
            <span className="font-bold">Solid Optimism:</span> Consumer optimism
            in Bangladesh is high. Even with recent global downturn, 57% of
            Bangladeshis believe the next generation would have a better life –
            as the country is transitioning to a skill-based economy.
          </li>
          <li>
            <span className="font-bold">Rise in Consumption:</span> Bangladesh
            GDP is reliant on household consumption for driving growth – this
            consumer market overtaking UK, Germany and supressing Vietnam and
            Thailand.
          </li>
          <li>
            <span className="font-bold">Young, Growing Workforce: </span>{" "}
            Bangladesh is home of younger population with a median age of 28
            years – Indonesia 31, India 29, Thailand 39, Vietnam 32 with a
            global average of 30 years. 114 million working age citizens.
          </li>
          <li>
            <span className="font-bold">High Economic Resilience: </span>{" "}
            Bangladesh households are financially resilient with high savings
            and low national debts.
          </li>
          <li>
            <span className="font-bold">Digital Momentum: </span> Mobile
            subscription doubled between 2012 and 2021 reaching 177 million,
            while internet subscription grew to 70% over last 10 years. Digital
            finance has touched around 3.5 billion transactions in 2022.
          </li>
          <li>
            <span className="font-bold">Government Shaping the Economy: </span>{" "}
            Government spending quadrupled over last decades from BDT532 billion
            in 2012 to BDT2,254 billion in 2022.
          </li>
          <li>
            <span className="font-bold">Fast Growing Private Sector: </span>{" "}
            Growth of Bangladesh private sector is a miracle. Readymade
            garments, pharmaceuticals, leather, telcos, NGOs, models like
            Corporate Social Impact investment (CSII) along with remittances
            taking the country forward faster – providing safety net for the
            bottom of the pyramid.
          </li>
          <li>
            <span className="font-bold">
              Scaled up and Thriving Gig Economy:{" "}
            </span>{" "}
            With 650,000 freelancers, Bangladesh is world’s second largest
            supplier of online labour. 15% of these global freelancers lives in
            Bangladesh just behind India, which home around 25%.
          </li>
          <li>
            <span className="font-bold">
              The Maturing Impact Investment Ecosystem:{" "}
            </span>{" "}
            The launch of the Bangladesh National Impact investment Strategy and
            Action Plan in 2022 with very strong endorsement by the Government
            to support its implementation is leading the Impact Investment
            Movement in Bangladesh. Supported by private sectors like Build
            Bangladesh, BRAC, diplomatic partners like the Türkiye and Swiss
            Government along with the UNDP.
          </li>
        </ol>

        <p className="pb-6">
          Finally, the last decade has also seen encouraging growth for the
          Bangladesh Start-up Ecosystem with emergence of over 1200 active
          start-ups in the country ensuring a sound and effective supply
          pipeline to enrich the ongoing development trajectory of the private
          sector in Bangladesh. These startups are enjoying access to capital
          and capacity building as the obvious benefit of impact investing in
          Bangladesh. Impact investors are investing in early-stage companies
          that have a positive social or environmental impact while generating
          financial gain to achieve sustainability. Impact investing is a great
          way for startups to access the capital they need to get their business
          off the ground. Hence, Build Bangladesh is leading to mainstreaming
          impact investing.
        </p>
        <p className="pb-6">
          The infographics below demonstrate the rising trends of the targeted
          sectors complementing{" "}
          <span className="font-bold">‘mainstreaming impact investing’</span> in
          Bangladesh!
        </p>
        <p className="pb-6">
          <span className="font-bold">[1]</span> The Trillion Dollar Prize –
          Local Champions Leading the Way, November 2022, Boston Consulting
          Group
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            <img src={trillion1} alt="" />
          </div>
          <div>
            <img src={trillion2} alt="" />
          </div>
          <div>
            <img src={trillion3} alt="" />
          </div>
          <div>
            <img src={trillion4} alt="" />
          </div>
          <div>
            <img src={trillion5} alt="" />
          </div>
          <div>
            <img src={trillion6} alt="" />
          </div>
          <div>
            <img src={trillion7} alt="" />
          </div>
          <div>
            <img src={trillion8} alt="" />
          </div>
          <div>
            <img src={trillion9} alt="" />
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
