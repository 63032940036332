import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import GsgSummitDetails from "./component/GsgSummitDetails";

export default function GsgSummit() {
  return (
    <Layout>
      <NewsHeader>
        GSG Global Impact Summit 2023 | Innovate. Scale. Impact | 2-3 October,
        Málaga, Spain
      </NewsHeader>
      <GsgSummitDetails />
    </Layout>
  );
}
