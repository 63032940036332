import React from "react";
import { Link } from "react-router-dom";
import plan_break from "../../../../assets/images/plan_break.jpg";
import news29_details1 from "../../../../assets/images/news29_details1.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function PlanBreakDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={plan_break}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Welcome to this Special Edition of Smarter Impact, where you can read
          through the detailed conversation I had with The Rt. Hon{" "}
          <a
            href="https://www.linkedin.com/in/righthon-nickhurd/"
            rel="noreferrer"
            target="_blank"
            className="text-blue-500"
          >
            {" "}
            Nick Hurd
          </a>
          , Chair of{" "}
          <a
            href="https://www.linkedin.com/company/gsgimpinv/"
            rel="noreferrer"
            target="_blank"
            className="text-blue-500"
          >
            The Global Steering Group for Impact Investment (GSG).
          </a>
        </p>
        <p className="pb-6 font-bold">Transcript follows</p>

        <p className="pb-6">
          Thank you for joining me for the first edition of Smarter Impact for
          2024, and it’s my great pleasure to be here with the Rt. Hon. Nick
          Hurd – And Mr Hurd, you have a huge bio which we’ll get into in a
          moment.
        </p>
        <p className="pb-6">
          And I do encourage people to click through the various links and
          associated organisations, and you’ll be able to discover thousands of
          people working to create a better world.
        </p>
        <p className="pb-6">
          Though, to begin, I was wondering, Sir What’s the hardest thing you
          do?
        </p>
        <p className="pb-6">
          -For me the hardest thing I do is to prioritise things.
        </p>
        <p className="pb-6">
          I’m very lucky I’m in the flow of lots of opportunity and I can get
          excited about lots of things.
        </p>
        <p className="pb-6">
          And like many people I guess, I find a big challenge is really
          prioritising what is absolutely essential.
        </p>
        <p className="pb-6">
          Of course, I’m leaving on one side, the challenge of trying to be a
          good father to six children.
        </p>
        <p className="pb-6">You know, that’s an altogether different scale.</p>
        <p className="pb-6">– And how old are those kids?</p>
        <p className="pb-6">
          – Well, it’s quite a big spread, six children from two marriages.
        </p>
        <p className="pb-6">
          Four kids that are all well into their late twenties and early
          thirties and making their way in this complicated world.
        </p>
        <p className="pb-6">
          And then a daughter of 11 and a young son of nine.
        </p>
        <p className="pb-6">So quite a spread of parenting challenge.</p>
        <p className="pb-6">
          And when you were talking about prioritising in relation to being the
          hardest thing you do what is it that’s hard about that?
        </p>
        <p className="pb-6">Probably like a number of people watching this.</p>
        <p className="pb-6">
          I get excited by things, historically I’ve found it quite hard to say
          no to things I like.
        </p>
        <p className="pb-6">I like new ideas.</p>
        <p className="pb-6">I like running with things.</p>
        <p className="pb-6">
          I’ve learned over time the merits of trying to do less, better.
        </p>
        <p className="pb-6">
          And with that, the need to kind of really focus on what you enjoy, but
          also what feels most important to you.
        </p>
        <p className="pb-6">
          – And I was wondering, what is it that brings you the most joy?
        </p>
        <p className="pb-6">
          – In a corny way I like things that make an impact.
        </p>
        <p className="pb-6">
          I see myself as a doer, when I was a Minister in Government, I took
          pride in getting things done because I know how hard that is.
        </p>
        <p className="pb-6">And I learned in government, for example that</p>
        <p className="pb-6">
          working out what to do is difficult but probably only 10% of the
          challenge, is actually getting your idea or getting that policy or
          getting that program to harment, and work in the way that you
          originally conceived.
        </p>
        <p className="pb-6">
          So I really like bringing together people to get something impactful
          done that makes a difference that people can be proud of what they did
          together.
        </p>
        <p className="pb-6">I get a lot of buzz out of that.</p>
        <p className="pb-6">
          – And in relation to that, you’ve been and forgive me, because you
          obviously were there for all of this, but for our listeners, you were
          Minister for Civil Society and the Minister of State for Climate
          Change and Industry, Minister for Policing and Fire Service, Minister
          for London, Minister of State.
        </p>
        <p className="pb-6">
          And now you’re the Chair for the Global Steering Group for Impact
          Investing, Chair of the Access Foundation, you founded Big Society
          Capital.
        </p>
        <p className="pb-6">
          You’re an advisor with Pollination and there’s a raft of other
          businesses you’re involved in.
        </p>
        <p className="pb-6">
          And to me that really strikes the stepping between two worlds.
        </p>
        <p className="pb-6">
          I know a lot of people who spend a lot of time in Government or a lot
          of people who spend time in Private Enterprise, and I was wondering
          what are the three things that come to mind, you’d suggest to people
          in Government about working with Private Enterprise?
        </p>
        <p className="pb-6">
          – To be clear these are in sequence they’re not all running in
          parallel.
        </p>
        <p className="pb-6">
          So I spent 14 years in British Parliament and nine years as a
          Government Minister, but that all finished in 2019.
        </p>
        <p className="pb-6">
          So my work on impact now is in the private sector.
        </p>
        <p className="pb-6">
          Your question is a really interesting one, because in a way I’ve got a
          slightly unusual perspective in that I speak government and I speak
          private sector and then I speak civil society and there are different
          languages and it touches on, quite a fundamental point Philip, in that
          my belief system is that, looking at the challenges that we face in
          every country, but also the connected world, both social challenges
          and environmental challenges I felt that at some time we have to get
          off the step of just simply asking “well what’s Government doing about
          it?” or “what’s the role of business?”
        </p>
        <p className="pb-6">
          For me, the future is really about effective collaboration between
          different parts of society what we call our whole of society approach.
        </p>
        <p className="pb-6">
          For that to work, those three legs of the tripod have got to
          understand each other and have got to find some common language.
        </p>
        <p className="pb-6">
          And that in my experience is really hard because Government thinks in
          very different ways from business.
        </p>
        <p className="pb-6">
          And both those think very differently from the way that Civil Society
          mindset is.
        </p>
        <p className="pb-6">
          But there’s real magic, if you can, strike collaborations and what we
          call ‘impact partnerships’ that really allow those elements to work
          together.
        </p>
        <p className="pb-6">
          And we achieved that in the UK in various ways which we can talk
          about.
        </p>
        <p className="pb-6">I think for Government there are two steps.</p>
        <p className="pb-6">
          The first is to realise that you don’t have all the answers, which for
          many governments who want to try to persuade their electorate that,
          they are the solution to everything, is a challenge.
        </p>
        <p className="pb-6">
          But the reality is they don’t have all the answers.
        </p>
        <p className="pb-6">
          There’s never going to be enough public money to solve all the
          problems in Australia or in England nor is there enough innovation or
          ability to innovate.
        </p>
        <p className="pb-6">
          So why not try and collaborate with people who’ve got what you don’t
          have?
        </p>
        <p className="pb-6">
          And for Government, that’s a very big step because they worry about
          accountability, they worry about whether they can trust the people
          that they’re working with.
        </p>
        <p className="pb-6">
          So the first step is to realise you don’t have all the tools or all
          the capability.
        </p>
        <p className="pb-6">
          And then once you understand that, you take a big step and reach out
          you take a big step and reach out and talk to others, but talk to
          others in a spirit of genuine partnership rather than ‘we are
          government, we know best’
        </p>
        <p className="pb-6">
          – We’ve had the tension in Australia of where our government went to
          private sector consulting to fill that gap and essentially an
          information drain of the knowledge of the public sector and
          externalise that to consultants and now trying to drag that back to
          internalise that knowledge again.
        </p>
        <p className="pb-6">
          – yeah, and with a lot of money spent along the way, and nothing
          really changes very much in that.
        </p>
        <p className="pb-6">
          I’ll give you one example in the UK one of the things we engineered
          was a change in the way that government commissions.
        </p>
        <p className="pb-6">
          Now that immediately starts to sound a bit dull, but if you think
          about how much money government spends on our behalf, the results we
          get for it in certain areas, that’s a system ready for change, and
          particularly in areas of social policy that have failed.
        </p>
        <p className="pb-6">So I take an example of the UK children in care.</p>
        <p className="pb-6">The state is a lousy parent.</p>
        <p className="pb-6">
          The cost of a child in care I think is around £40,000 sterling a year
          to the taxpayer.
        </p>
        <p className="pb-6">
          Terrible outcomes, long tail costs associated with failure. You know,
          decades of failure.
        </p>
        <p className="pb-6">
          And faced with that, why wouldn’t you want to try and do something
          different?
        </p>
        <p className="pb-6">
          And what we did with it, is we encouraged some innovation in the way
          the government commissions to work on what we call outcome based
          commission and entering into contracts outcome based commission and
          entering into contracts where actually it was sort of often civil
          society delivery partners who delivered the interventions with working
          capital provided often by the Impact Investment Community within a
          framework of really robust accountability for outcomes, really good
          data sets.
        </p>
        <p className="pb-6">
          And the results are in many cases absolutely fantastic.
        </p>
        <p className="pb-6">
          Homelessness in Manchester transformed through these kind of
          mechanisms.
        </p>
        <p className="pb-6">And it’s not easy. It is not easy.</p>
        <p className="pb-6">
          But it’s really worth it if you push through the difficulty and really
          strike that that partnership between those elements in society where
          everyone brings what they’re good at.
        </p>
        <p className="pb-6">
          – and for people in private enterprise, is this a great time to be
          bringing these sort of ideas and this methodology of service delivery
          to government?
        </p>
        <p className="pb-6">
          – Some governments won’t have anything to do with it because they
          don’t trust the private sector.
        </p>
        <p className="pb-6">
          Often they think they know best But in a world which I hope is
          increasingly transparent in terms of impact and outcomes, I think
          that’s probably unsustainable.
        </p>
        <p className="pb-6">
          There are lots of areas in public policy where government really
          struggles keeping people out of prison and keeping children out of
          care, homelessness, I’m just using three UK examples.
        </p>
        <p className="pb-6">
          There’ll be different ones in Australia where we’re really my view is,
          come on, we’re failing, you know, let’s open the door to trying to
          find new, better solutions because at the end of the day, this isn’t
          about numbers or theory or ideology.
        </p>
        <p className="pb-6">
          It’s really about people who are suffering, and trying to find better
          ways of helping them.
        </p>
        <p className="pb-6">
          And often that requires money, it doesn’t always require government
          money.
        </p>
        <p className="pb-6">
          Particularly in a world which I think is changing in terms of private
          sector attitudes and what the various stakeholders in the private
          sector now increasingly demand of their investments or the businesses
          that they work for or the businesses they do custom with
        </p>
        <p className="pb-6">
          societal attitudes are changing around what we expect of business.
        </p>
        <p className="pb-6">
          And the best business leaders understand that and really want more
          opportunities to demonstrate they’re good citizens and are playing a
          proactive role in trying to help social progress.
        </p>
        <p className="pb-6">
          – I’m interested in this idea of the semantic density behind the word
          impact, there are contributing organisations in the Impact Task Force
          report, which I’ll link to in this conversation for people.
        </p>
        <p className="pb-6">
          We’ve got people like Bridges Outcomes Partnerships, Social Value
          International, The International Foundation for Valuing Impact, the
          Value Balancing Alliance, the Impact Management Platform on and on and
          on.
        </p>
        <p className="pb-6">
          We have this broadening regulatory environment with the Task Force of
          Nature Related Financial Disclosures and in some ways I see it as this
          shift both towards a requirement, a quest for transparency, but then
          on a government level, maybe in response to social license to operate
          or at least the constituents requirements that, we rein in unchecked
          capitalism that might have been externalizing costs onto our social
          and natural environments for quite a while.
        </p>
        <p className="pb-6">
          I have people come to me and say “we want to make an impact.” And I
          ask “so do you mean you want a theory of change and an impact
          management framework, or do you mean you just want to feel good about
          where your money goes and to not pour paint down the drain?
        </p>
        <p className="pb-6">
          Where do you find that conversation of impacts is in sort of the
          hearts and minds of people?
        </p>
        <p className="pb-6">
          Do they do they just want to feel good about what they’re doing?
        </p>
        <p className="pb-6">
          Do they want to take the hard regulatory approach to transparency?
        </p>
        <p className="pb-6">What’s the (balance)?</p>
        <p className="pb-6">
          – There’s obviously a spectrum, and I think if you take one step back,
          which often you need to, it’s really striking how much change that’s
          been over, the last decade, if you like.
        </p>
        <p className="pb-6">
          The obvious proof points of that is the growth of the so-called, ESG
          movement inside the investment management industry, which has become
          the growth story, and so they’ve all, like moths to the flame, headed
          towards it in a way that’s positive because it reflects the kind of
          societal trends and expectations that we’re talking about.
        </p>
        <p className="pb-6">And that’s a shift we should be welcoming.</p>
        <p className="pb-6">
          People are beginning to care more about what their money is doing
          right?
        </p>
        <p className="pb-6">
          That matters in a world where our investable wealth, our private
          savings is worth, $270 trillion, that mindset, that shift really
          matters Inevitably, though, as I’m sure you’ve exposed and discussed
          before, there are charlatans in that movement.
        </p>
        <p className="pb-6">There’s greenwash.</p>
        <p className="pb-6">
          There’s people who are simply doing it because that’s where they think
          the buck is rolling.
        </p>
        <p className="pb-6">And that’s inevitable parts of human nature.</p>
        <p className="pb-6">
          And we’re now at the point where the regulators, in the EU, for
          example, and others are saying, okay, this is an important movement,
          but it’s a movement that’s carrying risk now because of greenwashing
          and and concerns about integrity and therefore, some regulatory
          frameworks and taxonomies have to be built in the interests of
          investors so that we can actually trust what we’re being told by those
          who are managing our savings or want to manage our savings.
        </p>
        <p className="pb-6">
          But within that ESG movement, let’s say it’s $40 billion.
        </p>
        <p className="pb-6">Within that, there’s a spectrum of ambition.</p>
        <p className="pb-6">
          On the far left (my left) you’ve got a cluster of capital that is
          content to do less harm and just wants to persuade investors that,
          okay, if you invest with us, we won’t invest in X, we won’t invest in
          Y.
        </p>
        <p className="pb-6">That’s okay, I think.</p>
        <p className="pb-6">
          My personal view, we’re in a world where doing less harm is not good
          enough and what I’m interested in and the movement that I chair, and
          the broader ecosystem that it’s part of, which is advocating for
          investment for positive impact, for mindset that gets up every morning
          thinking, how can I combine a sensible, adequate financial return with
          a measurable, positive, intentional impact?
        </p>
        <p className="pb-6">
          That money, worth maybe a trillion and a half dollars at this point in
          time, within that spectrum of $40 billion, I’m using big round
          numbers.
        </p>
        <p className="pb-6">That’s what’s got to grow.</p>
        <p className="pb-6">
          we’ve got to push and nudge and accelerate the mind that says it’s no
          longer good enough just to do less harm.
        </p>
        <p className="pb-6">
          We should be encouraging more capital to make a positive impact and be
          prepared to measure it and to be accountable for it and at the same
          time and they are connected you raised the point about external cost.
        </p>
        <p className="pb-6">
          Parts of the reason we’re in the hole we’re in certainly
          environmentally is that companies have not been required to account
          for their impact.
        </p>
        <p className="pb-6">
          Boards are taking decisions with no visibility or insight or
          investigation of the social environmental impact of their investment
          decisions and costs are externalized.
        </p>
        <p className="pb-6">
          And that’s the fundamental thing that has to change, which is why, the
          Impact Task Force that I chair supported by the UK G7 presidency at
          the time, came out very strongly arguing for mandatory accounting for
          impact by companies.
        </p>
        <p className="pb-6">You can’t change what you don’t know.</p>
        <p className="pb-6">
          And we need that impact transparency so that we can, in I hope a not
          too distant future be able to have genuine insight into the impact of
          companies and the investment decisions they take and be able to make
          meaningful comparisons between companies and when that happens,
          behavior will change.
        </p>
        <p className="pb-6">
          The destination for us is to integrate a third lens into investment
          decision making.
        </p>
        <p className="pb-6">
          We obviously look at return, we’ve learnt to look at risk and
          therefore risk adjusted return, the second lens.
        </p>
        <p className="pb-6">The third lens in our view has got to be impact.</p>
        <p className="pb-6">
          Risk, Return and Impact have got to be the three lenses through which
          kind of every investment decision whether that be corporate or
          institutional is taken
        </p>
        <p className="pb-6">
          And on impact transparency, I really can now begin to see the
          movement.
        </p>
        <p className="pb-6">
          You mentioned all the acronyms, Carbon Disclosure (TCFD) and Nature
          Disclosure (TNFD) following very quickly we’ve had the formation of
          the International Sustainability Standards Board (ISSB) people are
          cooperating now to try and work towards a more harmonized framework of
          disclosure requirements.
        </p>
        <p className="pb-6">You really begin to feel that that that moving.</p>
        <p className="pb-6">
          We’ve got pioneers that we’re supporting out there looking at impact
          weighted accounts and to what degree we can put a monetary value on
          impacts and the degree to which this can be integrated into financial
          reporting system.
        </p>
        <p className="pb-6">
          You can see now that the world could look very different in five years
          time in terms of the requirements on companies, in terms of disclosure
          and critically, the information available to us investors and
          stakeholders in companies, whether we’re employees or customers or
          investors.
        </p>
        <p className="pb-6">
          I think the landscape of information available to us is going to be
          transformed, and that’s what we’re working towards.
        </p>
        <p className="pb-6">
          – To me it’s also a question of human resources about the ability of
          people to actually be across this.
        </p>
        <p className="pb-6">
          And earlier you were speaking about the three pillars, of Government,
          Private Enterprise and the social sector and how each have their own
          essentially semantic density and nomenclature and way of thinking and
          Impact in some ways is this fusion of them and this awareness that our
          actions have impact in other areas of society?
        </p>
        <p className="pb-6">And how do we train people to be aware of that?</p>
        <p className="pb-6">
          Is there a point where you get to saturation with your domain
          expertise and look sideways because I’m imagining somebody is
          interning in Government, or interning in private enterprise and
          growing their career, when do they get to the point of having an aha
          moment of going, Oh, hang on, there’s more going on, or is that what
          regulatory requirements are going to bring to the fore?
        </p>
        <p className="pb-6">
          – It’s a really interesting question because in my experience, change
          advocates or change makers often underestimate the human capital
          dimension here.
        </p>
        <p className="pb-6">
          Change requires people we often underestimate the importance of
          building human capability capacity and bringing people with you.
        </p>
        <p className="pb-6">
          Inertia and fear of losing things are powerful human impulses.
        </p>
        <p className="pb-6">
          In my experience, often it comes down to leadership.
        </p>
        <p className="pb-6">
          If you’re leading a government department, which I’ve had the
          opportunity to do, or leading a business which I’ve had the
          opportunity to do you set the framework, you set the culture, you set
          expectation, you create the space in which people hopefully thrive and
          fulfill their potential.
        </p>
        <p className="pb-6">
          I come back to the example that I used a few minutes ago around
          experience of government, of doing things in different ways and
          forging different relationships with outside government, with the
          private sector, the social investment community, I was very helped at
          that time by the fact that the Prime Minister of the UK at the time,
          David Cameron, really set his stall out on this, his message was we
          can’t keep deluding ourselves that governments have got all the
          answers.
        </p>
        <p className="pb-6">
          Surely we forge a much better response to some of the challenges that
          undermine the country, if we could find better ways of getting
          governments and business and civil society working together more
          effectively.
        </p>
        <p className="pb-6">So I had that kind of top cover.</p>
        <p className="pb-6">
          And we set out in the department to try and do things differently.
        </p>
        <p className="pb-6">
          And I tell you what I found Philip was once you’ve created the signal
          of approval and encouragement, some resisted, some were uncomfortable,
          but some people just leapt at it.
        </p>
        <p className="pb-6">
          I learned that inside the public sector, actually, there is an
          entrepreneurial spirit.
        </p>
        <p className="pb-6">And we did that in the UK.</p>
        <p className="pb-6">
          We opened up a number of the public services areas to mutuals.
        </p>
        <p className="pb-6">
          We basically said, look, if you’re running a service to the public and
          you’ve got strong ideas of how it could be done better of how it could
          be done better through a mutual model whereby you’ve got a stake in
          the business, then we’re open to effectively spinning you out and
          supporting you with a contract.
        </p>
        <p className="pb-6">Hundreds of organisations took that up.</p>
        <p className="pb-6">
          So it’s really about the signal from leadership and about what’s okay
          and what’s encouraged.
        </p>
        <p className="pb-6">
          And we got a fantastic response in the UK to that kind of liberation.
        </p>
        <p className="pb-6">
          – A lot of my work begins with stakeholder mapping and figuring out
          the leverage points of the clients I work with who are impact funds,
          and some government departments And I do wonder about the impact
          movement in the sense that I hear a lot of work, for instance, in
          where I am in Australasia for supporting the Global South and reducing
          wealth inequality and generally uplifting humanity.
        </p>
        <p className="pb-6">
          And then that’s coming from people who are arguably well-off who have
          this ability in almost an awareness of enlightened self-interest or a
          hierarchy of needs from Maslow’s perspective to really self-actualize
          as people who are changing the world.
        </p>
        <p className="pb-6">
          And I wonder, as we’re heading into this sort of more, I’m perceiving
          it as separated almost the
        </p>
        <p className="pb-6">
          the breaking apart of globalisation and the sort of strongmen
          leadership of countries.
        </p>
        <p className="pb-6">Is the middle going to come on this journey?</p>
        <p className="pb-6">
          As people are under more pressure, do they have the space in your
          experience or what you’re witnessing to go back to type and want
          betterment for all?
        </p>
        <p className="pb-6">
          – It’s a really good question because, definitely the world has
          changed, the global order and geopolitics has become more fragmented,
          more friction, more competitive.
        </p>
        <p className="pb-6">That’s absolutely true.</p>
        <p className="pb-6">
          On the other hand, if I look at oddly, the existential global
          challenge of climate change, for example, climate change, for example,
          that connective tissue of collaboration and cooperation is alive and
          arguably accelerating in terms of when you look at the our progress in
          terms of reducing the costs of the technologies that are going to be
          key to the future, the flow of investment into those technologies.
        </p>
        <p className="pb-6">
          And at the same time, you’re seeing very strong evidence of I don’t
          like the expression, but the so-called Global South becoming better
          organized, more vocal.
        </p>
        <p className="pb-6">
          The African Union, for example, taking seats up on the G20, expansion
          of the BRIC networks.
        </p>
        <p className="pb-6">
          You can see that the Global South You can see that the Global South is
          getting more organised, more vocal, and the Global North is having to
          respond to that.
        </p>
        <p className="pb-6">
          And obviously, where this all comes together is the huge funding gap,
          the gap between what smart people tell us, what their models tell us
          in terms of what is required in terms of investment flows to help us
          deliver on the Sustainable Development Goals and climate, although
          obviously climate is absolutely integrated into the Sustainable
          Development Goals.
        </p>
        <p className="pb-6">
          And that gap is measured in trillions annually and the gap is
          particularly acute in the so-called emerging economies, that is
          recognised.
        </p>
        <p className="pb-6">
          And when you look at things like the Bridgetown Initiative and you
          look at some of the stuff coming out of the G20 and the G7, you can
          see that there’s political recognition of the need to do something
          about that.
        </p>
        <p className="pb-6">
          And we detect through our networks interests in the private sector,
          there’s institutional investors and corporate leaders in obviously
          investing in emerging economies because, arguably that’s where the
          growth is.
        </p>
        <p className="pb-6">
          But there are complexities and there are barriers and there are
          problems and there are risks that need to be managed and risks that
          need to be mitigated.
        </p>
        <p className="pb-6">
          And again, that’s a whole space that we got into with the Impact Task
          Force where we were asking ourselves, okay, we’ve got this agenda
          around impact transparency.
        </p>
        <p className="pb-6">
          And we’re clearly making progress towards a world in which companies
          will account for their impacts in a meaningful way.
        </p>
        <p className="pb-6">
          But we’ve now at the same time, we’ve also got to look at the supply
          of opportunity to invest, particularly in so-called emerging
          economies, where there are real barriers and real problems.
        </p>
        <p className="pb-6">
          And we’ve got to transform that landscape of supply too many investors
          saying that we’d love to, but it’s too difficult and we’ve got to take
          action in the short term to break down those barriers in order to make
          more appropriate investment opportunities for the big institutional
          money.
        </p>
        <p className="pb-6">
          That is the money that’s sitting on the sidelines at this moment in
          time and needs to be deployed for positive impact in our view of the
          world.
        </p>
        <p className="pb-6">
          – When you hear it’s too difficult, what do you think they’re saying?
        </p>
        <p className="pb-6">
          I think there are real difficulties in terms of, things that are
          material to risk adjusted return assessments, not least foreign
          exchange risk or government risk all manners of real risk that might
          gets in the way of of a pension fund in Australia, that might gets in
          the way of a pension fund in Australia, investing in Nigeria or Ghana
          or Indonesia so there are very real risks.
        </p>
        <p className="pb-6">
          We argue that there are tools to manage and mitigate those risks.
        </p>
        <p className="pb-6">
          And there’s a role for government in that, hopefully temporary.
        </p>
        <p className="pb-6">
          We also believe because I think the evidence tells us that those risks
          may be distorted and magnified in the eyes of investors because there
          is some demonstration effect of investments being made generating,
          decent returns in those environments.
        </p>
        <p className="pb-6">
          We think that a large part of this is mindset and basically system
          inertia, and basically system inertia, which is often the biggest
          barrier to change.
        </p>
        <p className="pb-6">
          So our work is around identifying the real risks, looking at tools
          that can dismantle those barriers and reduce the cost of capital in
          those in those countries.
        </p>
        <p className="pb-6">
          And encouraging the building of demonstration effect over time,
          gradually over time, gradually
        </p>
        <p className="pb-6">
          the position of the risks are too great or the returns are too small
          becomes untenable.
        </p>
        <p className="pb-6">
          And as money begins to flow, copycat culture, which is rampant in the
          investment management industry, will kick in.
        </p>
        <p className="pb-6">– Thank you for answering both sides of that.</p>
        <p className="pb-6">
          I didn’t mean to be flippant because when I asked the question, I came
          up with 20 specific reasons why it’s not happening But then things
          change – we need the money to flow, right?
        </p>
        <p className="pb-6">We need the money to flow.</p>
        <p className="pb-6">
          If we’re serious about the SDGs and climate, if we accept the fact
          that there are, big, important emerging economies that have to
          transition to clean energy, and if we accept our responsibility in
          supporting that
        </p>
        <p className="pb-6">
          and we also are alive, to the huge economic opportunity attached to
          investing in support of these transitions, then why wouldn’t we try to
          put all our brainpower and energy behind thinking, okay, how do we
          make this easier?
        </p>
        <p className="pb-6">How do we make this flow?</p>
        <p className="pb-6">Because it’s urgent, right?</p>
        <p className="pb-6">– Yeah.</p>
        <p className="pb-6">– The clock is ticking.</p>
        <p className="pb-6">
          It’s just gonna be around the corner, and we’re nowhere near where we
          need to be on climate or the SDGs.
        </p>
        <p className="pb-6">So this feels urgent to us.</p>
        <p className="pb-6">
          And that’s why we came together with the task force.
        </p>
        <p className="pb-6">
          That’s what drives the GSG country by country to try and bring some
          energy and urgency to the question.
        </p>
        <p className="pb-6">
          “How do we create a better environment for investment to flow that
          really wants to combine return with positive impact to make a
          difference?” – I feel like you may have just beautifully answered the
          question I was about to ask, which is “Who is the GSG for?”
        </p>
        <p className="pb-6">
          – Well, ultimately it’s for the beneficiaries of the investment.
        </p>
        <p className="pb-6">
          As industry evolved or special interests evolved, they tend to wrap
          fog around them and bristle with acronyms and become impenetrable to
          the outside world and often loose sight of what’s really important.
        </p>
        <p className="pb-6">
          It’s about impact, ultimately about impact on people and improving
          people’s lives.
        </p>
        <p className="pb-6">
          And of course that includes, the physical environments, the natural
          environments in which they in which they live, in which they work.
        </p>
        <p className="pb-6">
          Ultimately it’s about people and planet and the difference that money
          can make to delivering better outcomes and securing a better future,
          and the money’s a tool, right?
        </p>
        <p className="pb-6">
          And the instruments we talk about and get very excited about, they’re
          tools.
        </p>
        <p className="pb-6">
          Ultimately it’s about driving bigger percentages of the wealth that
          we’ve accumulated through extractive economies to drive more of that
          money to deliver better outcomes for people, hopefully in the context
          of an economic model that is much more focused on outcomes and
          regeneration and a better balance between wealth creation and
          sustainability.
        </p>
        <p className="pb-6">– That’s wonderful!</p>
        <p className="pb-6">
          For somebody who’s in finance, I think they’d be pretty crazy if they
          hadn’t heard of the GSG or the GIIN why would I get involved with the
          GSG?
        </p>
        <p className="pb-6">
          I’m sorry to make you pitch here, obviously people can Google the GSG
        </p>
        <p className="pb-6">
          – I’ve just recently taken over Chairmanship of the GSG from Sir
          Ronald Cohen with whom I’ve worked very closely for a number of years
          and is a very close friend under his leadership, from a sort of core
          of eight (countries), which was its origins setup under the G8 by
          David Cameron, now to over 40 countries around the world, and Philip,
          a queue a couple of dozen countries that also want to join the
          network.
        </p>
        <p className="pb-6">
          So something is happening here in terms of the energy out there,
          people who want something different and essentially what we do,
          country by country, is try and build ecosystems that enable more
          impact transparency and more investment to flow, that enable more
          impact transparency and more investment to flow, that’s looking to
          make a positive impact alongside natural return.
        </p>
        <p className="pb-6">
          And so we bring together the demand side, the supply side, investors,
          entrepreneurs, policymakers, intermediaries, regulators.
        </p>
        <p className="pb-6">
          We try and bring them together in what we call the National Advisory
          Board at the local level.
        </p>
        <p className="pb-6">
          And we try and then support them in their work, engaging with
          government, engaging with investment institutions, engaging with
          regulators, try to bring to them wherever they are, whether they’re in
          Ghana or Bangladesh.
        </p>
        <p className="pb-6">
          We try and bring the learnings from around the world so that wherever
          we’re advocating or engaging or convening or influencing or
          structuring new opportunity, we can learn from people who are trying
          to do the same we can learn from people who are trying to do the same
          somewhere else, and we can also give as well.
        </p>
        <p className="pb-6">
          So it’s an extraordinary network of changemakers collaborating at a
          national level within their own countries, but also collaborating
          internationally to learn from each other, to encourage each other, to
          support each other, to inspire each other, to keep pushing for that
          change so that this becomes a truly global movement and country by
          country we create an environment which is creating more opportunity
          for impact investment and flow that is creating more pressure for an
          understanding of impact transparency and of course investment will
          flow between countries.
        </p>
        <p className="pb-6">
          So we have countries that are exporters of capital and we have
          countries in the network that obviously want that capital, as well as
          encouraging their own domestic pools of capital to think about
          allocating more to impact.
        </p>
        <p className="pb-6">
          So it’s a very dynamic network and I think you’re in Malaga where we
          brought everyone together with almost 1000 people, 60 countries.
        </p>
        <p className="pb-6">It’s something rather unique and precious.</p>
        <p className="pb-6">
          Precious is such a resonant word for me My experience of being around
          the GSG for the last few years and being involved is that element of
          asking better questions.
        </p>
        <p className="pb-6">
          Because I consistently see the GSG pose better questions of, “Why
          isn’t it like this?
        </p>
        <p className="pb-6">Could it be like this?</p>
        <p className="pb-6">
          Hey, what if we did it like this?” And that I see come from the
          membership, come from the leaders, come from the investees and the
          stakeholders who are benefited by this process.
        </p>
        <p className="pb-6">
          And Government officials; “Ah, it could be like that.” It’s this
          posing of questions that I find as catalysts for change.
        </p>
        <p className="pb-6">
          – It’s hard to argue against impact transparency.
        </p>
        <p className="pb-6">
          I’ve been a big advocate for transparency as a Government Minister
          it’s a great disinfectant.
        </p>
        <p className="pb-6">It’s pretty powerful discipline.</p>
        <p className="pb-6">It’s almost impossible to argue against.</p>
        <p className="pb-6">– Yeah</p>
        <p className="pb-6">
          “No, we can’t be transparent.” “We can’t give our customers
          information.” “We can’t give shareholders information.” So I think
          it’s a very powerful thing to argue for.
        </p>
        <p className="pb-6">
          It changes behavior, of course, because you’ve suddenly getting boards
          beginning to compare what they’re doing with what their peer group are
          doing.
        </p>
        <p className="pb-6">
          And we’re finding on the Impact Valuation work which is groundbreaking
          and very much out there, exploring methodologies for putting a
          monetary value on impact.
        </p>
        <p className="pb-6">When we start doing</p>
        <p className="pb-6">
          comparisons between companies like Coca Cola and Pepsi and their use
          of water, it’s staggering.
        </p>
        <p className="pb-6">
          It’s absolutely staggering, and I can’t help feeling that once that
          gets into the flow of what is normal in assessing company performance.
        </p>
        <p className="pb-6">
          And you can see it for example, on the Bloomberg Terminal, that’s when
          you get
        </p>
        <p className="pb-6">the real behavior change.</p>
        <p className="pb-6">
          And then people say ‘okay, now we really need to improve our impact.
        </p>
        <p className="pb-6">
          We need to be looking more proactively at our impact.’ Then you need
          to create the supply.
        </p>
        <p className="pb-6">
          You have to have the supply side of opportunity alongside that and
          engagement with governments is increasingly sensible ones realise they
          haven’t got all the answers.
        </p>
        <p className="pb-6">
          Our challenge to ourselves is, country by country,
        </p>
        <p className="pb-6">
          how can we engage with our government, to be a credible part of the
          solution to their priority at the moment, skills development or
          homelessness or improving quality of life in informal settlements,
          whatever it is,
        </p>
        <p className="pb-6">
          our challenge to ourselves is can we work with partners to structure
          investable opportunities that are acceptable to the government whereby
          we can be part of the solution?
        </p>
        <p className="pb-6">And so we push ourselves hard on that.</p>
        <p className="pb-6">
          And Philip, I think the bottom line is what drove me as a sort of
          young minister kind of inspired by this, I couldn’t help feeling that
          if we could work towards a world where if you or I were presented with
          a choice of investment opportunities and investment opportunity A gave
          you a financial return, and investment opportunity B gave you a
          financial return that was acceptable to you but combines with evidence
          of a positive impact.
        </p>
        <p className="pb-6">Which would you choose?</p>
        <p className="pb-6">
          So I want to work towards a world where we have that choice and it’s
          not a pipe dream.
        </p>
        <p className="pb-6">
          In France they changed the law so that the pensions offered to you at
          work, you have a choice of a solidarity pension where a proportion of
          that is invested effectively for social benefits and social purposes
          It’s very, very popular.
        </p>
        <p className="pb-6">It’s a default choice, if you like.</p>
        <p className="pb-6">
          I’d love us all to have that choice because I’m pretty sure that
          presented with that choice, I know where the money will flow.
        </p>
        <p className="pb-6">
          So the challenge is to make sure that choice is a real choice, that
          there’s a sufficient flow of genuine investable opportunity that can
          deliver that impact, but also can deliver a return that’s appropriate
          and acceptable.
        </p>
        <p className="pb-6">
          – Wonderfully said. And everything you’re sharing here obviously has
          deep experience and examples behind it.
        </p>
        <p className="pb-6">
          So for people listening, if they’re inspired by any part of this
          conversation, get involved.
        </p>
        <p className="pb-6">
          Go and ask the question “How do we do that?” – It’s real! It’s real.
        </p>
        <p className="pb-6">It’s emerging, it’s fledgling.</p>
        <p className="pb-6">There’s still not enough demonstration effects.</p>
        <p className="pb-6">Some of it is too small scale, in my view.</p>
        <p className="pb-6">
          But something’s happening, I’m Chairing a network with over 40
          countries.
        </p>
        <p className="pb-6">And it could be more much more than that.</p>
        <p className="pb-6">
          People want to be part of this, people want to make this change within
          their countries and the pace of success will differ in different
          places.
        </p>
        <p className="pb-6">
          within their countries and the pace of success will differ in
          different places.
        </p>
        <p className="pb-6">This is going to happen.</p>
        <p className="pb-6">It has to happen</p>
        <p className="pb-6">
          Just because of the context we’re in which is unsustainable and we’re
          talking about changing arguably the most powerful economic system we
          have, which is the system that allocates our wealth accumulated in
          trillions over many centuries.
        </p>
        <p className="pb-6">
          Obviously, a large proportion of it now being passed on to a younger
          generation with different values that will want different things.
        </p>
        <p className="pb-6">So it’s an incredibly exciting time.</p>
        <p className="pb-6">But this is about system change.</p>
        <p className="pb-6">
          This is about a different way of allocating our wealth to ensure that
          we integrate impact into our decision making alongside risk and
          return.
        </p>
        <p className="pb-6">– That was a wonderful way to wrap this up.</p>
        <p className="pb-6">
          And in the last, if you can, the last 3 minutes, this might be a touch
          of a philosophical question; Speaking to rites of passage and
          tradition, in some ways, I’d suggest these are a little lacking in my
          generation of Western Society as we drifted a little from religious
          orders and things like that.
        </p>
        <p className="pb-6">
          But I believe you’re a Liveryman for the Worshipful Company of
          Grocers, and I was wondering if you share what that brings to your
          perspective on the world.
        </p>
        <p className="pb-6">
          I became what we call a Grocer, it’s a part of these Livery Companies,
          very much an important part of the City of London in the UK.
        </p>
        <p className="pb-6">So there are two dimensions to that.</p>
        <p className="pb-6">One is family.</p>
        <p className="pb-6">
          So my Grandfather was the Master of that livery and for him, it was
          one of the great joys in his life.
        </p>
        <p className="pb-6">I’m connected to him through it.</p>
        <p className="pb-6">
          But it is also a gathering of people where, the common ground is
          companionship, but also about a collective will to make a difference.
        </p>
        <p className="pb-6">
          These Livery Companies are a force for good because they’re generous
          and they encourage people to be generous because they’re generous and
          they encourage people to be generous in a quiet way, not in a flashy
          way.
        </p>
        <p className="pb-6">That’s another dimension to it.</p>
        <p className="pb-6">They demonstrate social responsibility.</p>
        <p className="pb-6">
          I think that value is a really, really important one in this age that
          value of social responsibility.
        </p>
        <p className="pb-6">
          And obviously we’re in a completely different age with social media
          being potentially a force for good, but also a force for fragmentation
          and division as well, we’ll collectively have to work that out.
        </p>
        <p className="pb-6">
          Those quiet little societies, in their quiet, modest way, bring people
          together to develop and demonstrate social responsibility.
        </p>
        <p className="pb-6">And I think that’s valuable.</p>
        <p className="pb-6">– Wonderful.</p>
        <p className="pb-6">Thank you so much for your time.</p>
        <p className="pb-6">I appreciate we’re at the end of it.</p>
        <p className="pb-6">
          So I will encourage people to go look up the Global Steering Group for
          Impact Investment get involved, get amongst it, the world needs us!
        </p>
        <p className="pb-6">
          – Yes, please do!{" "}
          <a href="http://gsgii.org" target="_blank" rel="noreferrer">
            http://gsgii.org
          </a>
        </p>
        <p className="pb-6 font-bold italic">Thanks for reading!</p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
