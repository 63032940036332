import React from "react";

export default function Message() {
  return (
    <div className="max-w-5xl mx-auto py-16 px-8 ">
      <h2 className="text-2xl font-bold clear-left pb-6">
        Message from Mr. Arastoo Khan, Member Secretary of the National Advisory
        Board (NAB) for Impact Investment in Bangladesh
      </h2>
      <p>Dear Impact Investment Stakeholders,</p>
      <p className="">Greetings from NAB Bangladesh!</p>

      <div className="py-4">
        In light of the recent transition to an interim Government in
        Bangladesh, led by a focus on national stability and progress, we at the
        National Advisory Board (NAB) for Impact Investment are optimistic about
        the future. The interim Government devoid of political bias, provides a
        unique opportunity for strengthening institutional frameworks and
        fostering socio-economic growth. We believe that their commitment to a
        transparent and inclusive governance model will help unlock the full
        potential of impact investments in the country.
      </div>
      <div>
        The NAB remains fully aligned with national priorities, and we are eager
        to resume our operations in line with the key recommendations from the
        upcoming Bangladesh White Paper 2024, which will chart a course for
        sustainable development, economic resilience, and social equity. Once
        the White Paper is published, we will calibrate our strategies to align
        with the proposed reforms, especially in building an empowered impact
        investment ecosystem that supports every Bangladeshi. Together, we can
        create a more inclusive and prosperous Bangladesh. Thank you for your
        continued support.
      </div>
      <p className="py-4">
        <p>--</p>
         Yours Sincerely,</p>
      <div className="">
        <p className="font-bold">Arastoo Khan</p>
        <p className="font-bold">Chair, NAB Trust</p>
        <p>Member Secretary, NABG</p>
      </div>
    </div>
  );
}
