import React from "react";
import { Link } from "react-router-dom";
import news10 from "../../../../assets/images/news10.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function NewWaveDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news10}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          The Member Secretary of NAB Bangladesh – Mr. Arastoo Khan, and the
          Build Bangladesh (Secretariat of NAB) team welcomes the Senior
          Secretary, Lokman Hossain Miah as the New Chair of the National
          Advisory Board (NAB) for Impact Investment in Bangladesh. Secretary
          Lokman has joined as the Executive Chairman of Bangladesh Investment
          Development Authority (BIDA), replacing the immediate past chairman Md
          Sirazul Islam on Sept 4, 2022.
        </p>

        <p className="pb-10">
          The new Resident Representative of UNDP Bangladesh, Mr. Stefan Liller,
          also became the latest addition to the list of distinguished members
          of the NAB Bangladesh. Mr. Liller succeeded the outgoing Resident
          Representative, Mr. Sudipto Mukerjee who was also a Member of the
          Advisory Board.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
