import React from "react";
import { Link } from "react-router-dom";
import news19 from "../../../../assets/images/news19.jpg";
import news19_details1 from "../../../../assets/images/news19_details1.jpg";
import news19_details2 from "../../../../assets/images/news19_details2.jpg";
import news19_details3 from "../../../../assets/images/news19_details3.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function FosteringDevelopmentDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news19}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <h1 className="text-xl font-bold pb-4">
          Article by Dr Rahul Mathew, Deputy Director, Resource Development,
          World Vision Bangladesh
        </h1>
        <p className="pb-6">
          Introduction: In the vibrant landscape of Bangladesh’s growing
          economy, the recent Impact Investment Summit marked a significant
          milestone. Organized by the NAB Trust of Bangladesh and Build
          Bangladesh at the Bangladesh Investment Development Authority Hall,
          this event brought together a confluence of ideas, strategies, and
          insights on impact investment. The focus was clear: leveraging
          investment for not just financial gains but also for achieving
          substantial social and environmental impact.
        </p>
        <div className="text-center w-full pb-6">
          <img
            src={news19_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          The Current State of Impact Investment: Bangladesh’s journey in impact
          investment is at a crucial juncture. With a robust youth population
          and a dynamic socio-economic fabric, the country presents fertile
          ground for investments that can yield both social good and financial
          returns. However, this journey is not without its challenges. Limited
          access to capital, especially for small and medium-sized enterprises
          focused on social change, a lack of standardized impact metrics, and
          nascent regulatory frameworks are some of the hurdles in the path of
          this promising sector.
        </p>
        <p className="pb-6">
          Innovations in Financial Instruments: A key theme of the summit was
          the introduction of innovative financial instruments such as social
          impact bonds and development impact bonds. These instruments represent
          a transformative shift from traditional funding mechanisms, with
          returns tied to achieving specific social outcomes. A notable example
          is the Skills Impact Bond in India, where outcomes-based finance has
          successfully driven social change, offering a model that can be
          replicated in Bangladesh.
        </p>
        <p className="pb-6">
          Role of Stakeholders: Impact investment in Bangladesh is a
          multi-stakeholder endeavor. Government bodies, private investors,
          NGOs, and local communities each play a vital role. Outcome funders
          provide the financial incentives for achieving desired results.
          Implementers, be they NGOs or social enterprises, are tasked with
          executing projects. Impact investors bring in the necessary capital.
          The success of these investments hinges on the synergy and alignment
          of goals among these diverse stakeholders.
        </p>
        <p className="pb-6">
          Regulatory Framework and Policy Advocacy: For impact investment to
          thrive, a supportive regulatory environment is essential. The current
          regulatory landscape in Bangladesh, though evolving, needs more
          clarity and flexibility to accommodate innovative financing models.
          Advocacy for policy reforms and more favorable regulations can
          stimulate growth in this sector. Government support, in the form of
          tax incentives and legal recognition of social enterprises, can
          further bolster impact investment.
        </p>
        <p className="pb-6">
          Ensuring Sustainability and Long-term Impact: Sustainability is the
          cornerstone of impact investment. Projects should be designed with a
          long-term view, ensuring enduring benefits. Engaging local
          communities, building local capacities, and addressing systemic issues
          are crucial for the sustainability of impact initiatives. Regular
          monitoring and evaluation play a key role in ensuring these projects
          adapt and continue to be relevant over time.
        </p>
        <div className="text-center  w-full pb-6">
          <img
            src={news19_details2}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          Overcoming Challenges and Setting Future Directions: Navigating the
          challenges of impact investment requires innovative solutions and
          persistent efforts. Building robust ecosystems for impact investment,
          enhancing skills and capacities of local actors, and fostering
          public-private partnerships are key strategies for the future.
          Additionally, promoting a deeper understanding of impact investment
          among potential investors and the general public is crucial for
          creating a market that is ready and receptive to these new forms of
          investment.
        </p>
        <p className="pb-6">
          Conclusion: The Impact Investment Summit has set the stage for a new
          era of investment in Bangladesh, one that aligns economic growth with
          societal and environmental well-being. By embracing innovative
          financing models, fostering collaboration across sectors, and focusing
          on sustainable outcomes, Bangladesh can harness the power of impact
          investment to create a resilient and equitable future. As the country
          continues to grow, the integration of impact investment into its
          development narrative holds the promise of not just economic
          prosperity but a better world for all its citizens.
        </p>
        <div className="text-center max-w-[500px] w-full">
          <img
            src={news19_details3}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pt-2 pb-6">
          Dr Rahul Mathew, Deputy Director, Resource Development, World Vision
          Bangladesh
        </p>
        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
