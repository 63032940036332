import React from "react";
import { Link } from "react-router-dom";
import news30 from "../../../../assets/images/news30.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function FirstWorkshopDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news30}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          On March 21, 2017, Build Bangladesh hosted the first international
          impact investment workshop –{" "}
          <span className="font-bold"> Bringing Impact Investors Together</span>{" "}
          – Shaping the Future of Impact Investment in Bangladesh – as a
          follow-up to the 2016’s inaugural International Summit on Impact
          Investment in Bangladesh. The workshop facilitated dialogues between
          local and global Impact Investment stakeholders and explored
          opportunities for Impact Investment in Bangladesh while complementing
          the SDGs.
        </p>
        <p className="pb-6">
          The consultation workshop was designed around the{" "}
          <span className="font-bold">‘Community Strength Based Model’</span> to
          enable relevant local and global players to come together to share
          each other’s strengths and experiences for guiding the development of
          the overall ecosystem for impact investment in Bangladesh.
        </p>
        <p className="pb-6">
          The workshop sessions and the follow-up meeting resulted in some
          tangible outcomes. These are:
        </p>

        <ul className="pl-20 py-10">
          <li>
            MoU between Build Bangladesh and United Nations Social Impact Fund
            (UNSIF) to launch the first Build Bangladesh-UNSIF Impact Fund for
            Bangladesh.
          </li>
          <li>
            Establishment of National Advisory Board (NAB) for Impact Investment
            in Bangladesh.{" "}
          </li>
          <li>
            Invitation to represent Bangladesh at the 2017 Global Impact Summit
            by Global Steering Group (GSG) for Impact Investment.{" "}
          </li>
          <li>
            Initiation of impact investment ready Affordable Housing project by
            Build Bangladesh.{" "}
          </li>
        </ul>
        <p className="pb-6">
          The workshop was attended by Senior Officials from Bangladesh
          Government and Corporate Sectors. In addition to various multilateral
          organizations and development partners like the World Bank, United
          Nations, European Union, Asian Development Bank, GiZ, IFC, DFID, and
          foreign missions, the session had also attracted national and
          international experts from USA, UK, Hong Kong, China, Sri Lanka,
          Singapore, Thailand, and India. The session was also attended by
          prospective international investors to explore opportunities in
          Bangladesh.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
