import React, { useEffect } from "react";
import Navbar from "../../shared/Components/Navbar";
import Hero from "./Components/Hero";
import heroVideo from "../../assets/videos/hero_video.mp4";
import WhoWeAre from "./Components/WhoWeAre";
import WhatWeDo from "./Components/WhatWeDo";
import Impact from "./Components/Impact";
import MembersPertners from "./Components/MembersPertners";
import Publications from "./Components/Publications";
import Footer from "../../shared/Components/Footer";
import Layout from "../../shared/Layout/Layout";

import nabColorLogo from "../../assets/images/NAB_color_logo.png";
import nabColorLogoCropped from "../../assets/images/NAB-color-logo-cropped.png";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutubeSquare,
  FaAngleDown,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import LatestNews from "./Components/LatestNews";
import Message from "./Components/Message";

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Message />
      <WhoWeAre />
      <WhatWeDo />
      <LatestNews />
      <Impact />
      <MembersPertners />
      <Publications />
    </Layout>
  );
}
