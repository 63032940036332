import React from 'react'
import bg from "../../../assets/images/whatwedo_bg.svg";
import whatwedo1 from "../../../assets/images/whatwedo1.png";
import whatwedo2 from "../../../assets/images/whatwedo2.png";
import whatwedo3 from "../../../assets/images/whatwedo3.png";
import { Button } from '../../../shared/ui/Button';
import {Link } from 'react-router-dom'

export default function WhatWeDo() {
    return (
        <div className='relative py-40 '>
            <img src={bg} className='absolute top-0 left-0 w-full rotate-180 h-[80px]' />
            <div className='max-w-5xl mx-auto'>
                <div className='text-center font-bold mb-8'>
                    <h2 className='text-4xl pb-2'>What do we do?</h2>
                    <p >NAB Bangladesh’s efforts and activities are built on three key pillars:</p>
                </div>

                <div className='flex flex-col md:flex-row justify-between gap-6'>
                    <div className='flex justify-center flex-col items-center basis-1/3'>
                        <img src={whatwedo1} className='w-[120px] h-[120px] contain' alt="What we do 1" />
                        <p className='font-bold mt-4'>Policy Advocacy</p>
                    </div>
                    <div className='flex justify-center flex-col items-center basis-1/3'>
                        <img src={whatwedo2} className='w-[120px] h-[120px] contain' alt="What we do 1" />
                        <p className='font-bold mt-4'>Ecosystem Management</p>
                    </div>
                    <div className='flex justify-center flex-col items-center basis-1/3'>
                        <img src={whatwedo3} className='w-[120px] h-[120px] contain' alt="What we do 1" />
                        <p className='font-bold mt-4'>Research & Publication</p>
                    </div>
                </div>
                <div className='pt-8 text-center'>
                    <Link to="/impact-inveseting/">
                    <Button>Read More</Button>
                    </Link>
                </div>
                
            </div>
           
        </div>
    )
}
