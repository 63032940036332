import React from "react";
import { Link } from "react-router-dom";
import navigating_impact from "../../../../assets/images/news128.png";
import news128_pic1 from "../../../../assets/images/news128_pic1.png";
import news128_pic2 from "../../../../assets/images/news128_pic2.png";
import investment_Picture1 from "../../../../assets/images/investment-Picture1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function NewLeaderForImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={navigating_impact}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          NAB Bangladesh is registered as a Trust under the Trusts Act of 1882.
          This Trust gives the works and initiatives of NAB more accountability,
          continuity, and sustainability. The Trust allows NAB to influence the
          BIISAP implementation plan by incorporating more supportive partners
          including relevant industry experts to drive the next step of agendas
          forward.
        </p>
        <p className="pb-6">
          NAB Bangladesh is registered as a Trust under the Trusts Act of 1882.
          This Trust gives the works and initiatives of NAB more accountability,
          continuity, and sustainability. The Trust, as a not-for-profit entity,
          allows NAB to influence the BIISAP implementation plan by
          incorporating more supportive partners including relevant industry
          experts to drive the next step of agendas forward. With the formation
          of the NAB Trust, the current National Advisory Board (NAB) for Impact
          Investment in Bangladesh is called National Advisory Board of
          Governance (NABG). The NAB Trust establishes, sets up, runs,
          maintains, assists, finances, and forms the NABG to whom the Trustees
          report quarterly.
        </p>

        <div className="text-center w-full">
          <img
            src={news128_pic1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          The Trust consists of seven members including Mr. Arastoo Khan as the
          Chairperson, Ms. Sultana Afroz as the Vice Chairperson, Architect
          Farhadur Reza as the Managing Trustee, Mr. Niaz Rahim as the Treasurer
          and Ms. Anita Ghazi Rahman as the Secretary of the Trust. Mr. Asif
          Saleh and Ms. Sangita Ahmed are the members of the Trust.
        </p>
        <div className="text-center w-full mb-8">
          <img
            src={news128_pic2}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>

       

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
