import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import NationalImpactDetails from "./components/NationalImpactDetails";

export default function NationalImpact() {
  return (
    <Layout>
      <NewsHeader>
        Impact InvesTIME: National Impact Investment Day 2024
      </NewsHeader>
      <NationalImpactDetails />
    </Layout>
  );
}
