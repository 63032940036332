import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news122.jpg";
import news122_creator from "../../../../assets/images/news122_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function BetterStoriesDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Before BetterStories, I had worked across the international
          development and private sectors, engaging in social business,
          philanthropy, and aligning with global agendas like the Millennium
          Development Goals (MDGs) and later the Sustainable Development Goals
          (SDGs). For much of the work that I was engaging in, I was bidding for
          foreign grants/funds to flow to Bangladesh. To persuade fund managers
          to approve our bid, I had to highlight the problems faced in
          Bangladesh so that the impact of the funds seemed large. While doing
          so, I got a deep feeling of guilt: besides all the problems around us,
          I also saw a lot of positives that I wanted to spotlight for the world
          instead. In my work through BetterStories, I get the chance to not
          only have lasting impact, but also showcase the powerful stories of
          entrepreneurs in Bangladesh to the rest of the world, one story at a
          time.
        </p>
        <h2 className="font-bold">Impact as a Philosophy and Vision</h2>
        <p className="pb-6">
          BetterStories was established with the belief that impact is a
          philosophy—it’s not a single outcome but a continuous, intentional
          process that involves knowledge-sharing, capacity building, and
          cultivating a vibrant ecosystem for entrepreneurs. From the start, it
          was clear to me that Bangladesh’s entrepreneurial landscape needed a
          robust, impact-driven support structure to help businesses to thrive.
          Our journey has been driven by this purpose, recognizing that
          meaningful change requires partnership, training, and dedicated
          efforts to bridge existing gaps. With this guiding principle, we’ve
          approached our projects with a focus on inclusive development,
          ensuring that all levels of the socio-economic levels are engaged in
          our initiatives.
        </p>
        <h2 className="font-bold">Our Projects and Initiatives</h2>
        <p className="pb-6">
          Our work at BetterStories has spanned multiple dimensions, but each
          initiative has consistently contributed to one of three pillars:
          Capacity Building, Knowledge Building, and Ecosystem Building.
        </p>
        <p className="pb-6">
          A major focus of BetterStories has been empowering Bangladeshi
          entrepreneurs with the skills needed to build resilient, impact-driven
          businesses. From the early days, we have always believed in building
          our own capacity to meet global standards with the dedication of
          grooming Bangladeshi entrepreneurs to meet the same bar. In 2018, a
          BetterStories team member completed training on an Impact Investment
          Manager program in Bangkok, and we also served as a Global Mentor for
          Santa Clara University’s Entrepreneurship Program 2022, guiding
          entrepreneurs from around the world in sustainable business practices.
        </p>
        <p className="pb-6">
          Between 2020 and 2024, we partnered with Biniyog Briddhi to conduct
          the Investment Readiness and Impact Measurement (IMM) Training
          Programs. Prior to providing these training programs, we were trained
          through Biniyog Briddhi’s Train The Trainer program. Every year, three
          BetterStories team members receive training under this program and
          subsequently support impact enterprises to expand their capacity.
          Parallelly, BetterStories also launched the GetFunded program in 2021
          to equip startups with essential tools and knowledge for effectively
          build capacity on impact measurement and investment readiness. Since
          its inception, three cohorts have successfully completed the program,
          with a fourth cohort currently in development. This helps Bangladeshi
          entrepreneurs to understand and leverage impact measurement—an
          essential component for building sustainable, socially responsible
          businesses. We build our own capacity to the highest standards to
          create an exceptional environment where entrepreneurs can thrive and
          achieve their full potential.
        </p>
        <h2 className="font-bold">Knowledge Building</h2>
        <p className="pb-6">
          BetterStories has always believed that impactful growth is grounded in
          knowledge. In 2011, we conducted a landmark Inclusive Business
          Feasibility Study for the ADB (Asian Development Bank), assessing the
          viability of a $100 million fund for inclusive business in Bangladesh.
          This study played a crucial role in defining inclusive business in
          Bangladesh, advocating for models that include people at every
          level—especially those at the bottom of the economic pyramid. Our
          first major international exposure came from this project. We got an
          invitation to attend an Inclusive Business Forum held at the Asian
          Development Bank (ADB) headquarters in Manila. This event provided a
          unique platform for us to engage with global leaders, including
          Professor Michael Choo from the ADB, who contributed significant
          insights to the forum.
        </p>
        <p className="pb-6">
          In 2016, we conducted the “State of Social Enterprise Survey” for the
          British Council, producing a comprehensive report that has since
          served as a valuable resource for policymakers, investors, and
          entrepreneurs. During the period from 2014 to 2016, we also partnered
          in a series of social enterprise policy dialogues. In 2015, I was
          invited to participate in a fellowship by the Charles Wallace Trust,
          which brought me to London for a month as part of a high-level
          delegation focused on advancing social enterprise initiatives. Our
          commitment to knowledge sharing extended internationally, with
          contributions to a master’s program curriculum in Switzerland in 2018.
          The program continues to educate aspiring impact leaders using our
          insights.
        </p>
        <p className="pb-6">
          These experiences provided invaluable exposure and strengthened our
          capacity to drive impactful change within Bangladesh.These
          contributions underscore our dedication to building a knowledge base
          that others can draw upon, helping to shape the landscape of social
          entrepreneurship and impact investment in Bangladesh and beyond.
        </p>
        <h2 className="font-bold">Ecosystem Building</h2>
        <p className="pb-6">
          One of our most fulfilling endeavours at BetterStories has been
          building a supportive ecosystem for inclusive and impact-driven
          business ventures. From our early partnership with UNDP in their
          Innovation Hub, we contributed to developing frameworks for
          Bangladeshi entrepreneurs, advocating for an impact-focused approach
          to business that remains central to our work today.
        </p>
        <p className="pb-6">
          In 2012 or 2013, I participated in the International Visitor
          Leadership Program (IVLP) in the United States, an experience that
          broadened my global perspective on social impact. During this time, I
          also became one the first external angel investors in an innovative
          social business initiative led by Dr. Muhammad Yunus, associated with
          Grameenphone. Our efforts to foster international collaboration have
          also been significant. Around 2018-2019, we partnered with the
          National University of Singapore on the Asia-Pacific Impact Challenge.
          This collaboration allowed us to exchange strategies and knowledge on
          shared challenges across the Asia-Pacific region, further expanding
          our footprint and influence.
        </p>
        <p className="pb-6">
          In 2018, BetterStories became a member of the Aspen Network of
          Development Entrepreneurs (ANDE), joining a global community that
          supports small and growing businesses in emerging markets. Our work
          with ANDE reflects our commitment to fostering an inclusive
          entrepreneurial ecosystem in Bangladesh. Our impact evaluations, such
          as the 2023 assessment for the Start Fund and our 2016 evaluation of
          the British Council’s International Climate Champions Programme, have
          strengthened our efforts in ensuring that we—and others—achieve
          measurable outcomes that contribute to social and environmental
          sustainability.
        </p>
        <h2 className="py-6 font-bold">A Legacy Forward</h2>
        <div className="py-6">
          Through our focus on capacity building, knowledge sharing, and
          ecosystem support, we are actively contributing to a future where
          Bangladesh’s entrepreneurial landscape is thriving, sustainable, and
          inclusive. Reflecting on our journey, I am immensely proud of the
          impact BetterStories has achieved and am inspired by the potential
          that lies ahead. Our path has been one of collaboration, mentorship,
          and community-building, and I am grateful for the entrepreneurs,
          partners, and mentors who have joined us along the way. With a deep
          commitment to social change, we continue to build a legacy of positive
          impact, one story at a time.
        </div>

        <div className="mb-8">
          <img src={news122_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Minhaz Anwar</p>
            <p>Founder & Chief StoryTeller</p>
            <p>BetterStories Limited</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
