import React from "react";
import { Link } from "react-router-dom";
import newBlog121 from "../../../../assets/images/newBlog121.png";
import new121_creator from "../../../../assets/images/new121_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function ShowingTheSeedsDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={newBlog121}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          My journey into the world of impact investing in Bangladesh began in
          2018, fueled by a belief that business could be a force for good. I
          envisioned a future where investments not only generated financial
          returns but also tackled pressing social and environmental challenges.
        </p>
        <p className="pb-6">
          However, I quickly learned that impact investing isn't a solo
          endeavor. Building a meaningful ecosystem requires a coalition of
          committed, like-minded individuals working toward a shared vision. In
          my journey of impact investment, I found myself among people who
          brought diverse expertise, resilience, and optimism—those who
          believed, like I did, in the potential of impact to drive
          transformative change. Together, we've cultivated a network that
          supports the growth of impact-driven enterprises and champions
          initiatives for social and environmental change.
        </p>
        <p className="pb-6">
          Here’s a glimpse of what we've accomplished so far:
        </p>
        <p>Empowering the Ecosystem:</p>
        <ul className="pl-20 pb-10">
          <li>
            Training the Trainers: We launched impactful training programs,
            equipping ecosystem players with the knowledge and skills to nurture
            and support impact-driven startups.
          </li>
          <li>
            National Leadership: I'm proud to host the secretariat for the
            National Advisory Board of Impact Investment in Bangladesh (NAB
            Bangladesh), a vital platform for collaboration and guidance.
          </li>
          <li>
            Laying the Foundation: As a key contributor to the 2020 Policy
            Landscape Study and the 2022 Bangladesh Impact Investment Strategy &
            Action Plan (BIISAP), I've helped shape the national agenda for
            impact investing.
          </li>
          <li>
            Measuring What Matters: We localized impact mapping and reporting
            frameworks, making it easier for impact startups and investors to
            connect and track progress.
          </li>
          <li>
            Realizing Impact: Together with partners like YGAP Australia, UNDP,
            Citi Foundation, and TIKA, we've reported on the lives of over 1
            million people positively impacted by impact investments. This is
            the largest verified impact data reported in Bangladesh to date – a
            testament to the power of this approach!
          </li>
          <li>
            Pioneering Investment Models: We successfully tested various impact
            investing models, including outcome-based funds, KPI-based funds,
            and micro-impact investments, demonstrating the versatility and
            potential of this field.
          </li>
        </ul>
        <p className="pt-8">Beyond Impact Investing:</p>
        <p>
          My commitment to positive change extends beyond impact investing. I've
          also played a pivotal role in fostering a vibrant startup ecosystem in
          Bangladesh:
        </p>
        <ul className="pl-20 pb-10">
          <li>
            Championing Women Entrepreneurs: We launched "yher," the first and
            only women-centric accelerator program in Bangladesh, empowering
            women to pursue their entrepreneurial dreams.
          </li>
          <li>
            Reaching the Grassroots: We designed and ran the only startup
            incubation program in Bangla, specifically for sub-urban and rural
            founders, ensuring inclusivity and accessibility.
          </li>
          <li>
            {" "}
            Nurturing Social Enterprises: We manage the largest accelerator
            program for social enterprises in Bangladesh, supporting businesses
            dedicated to solving social and environmental challenges.
          </li>
          <li>
            {" "}
            Cultivating Young Innovators: We developed and manage a Student
            Innovators Network across 38 technology universities in Bangladesh,
            fostering the next generation of changemakers.
          </li>
          <li>
            Supporting Innovation: I've served as a judge for the Bangladesh
            Innovation Grant (BIG) and advised and managed four editions of UNDP
            Youth Co:Lab, contributing to a culture of innovation and
            entrepreneurship.
          </li>
        </ul>
        <p className="pt-8">Open Innovation and a Brighter Future:</p>

        <ul className="pl-20 pb-10">
          <li>
            Impactful Partnerships: We created a venture design studio (Needle
            Innovation) with H&M, focusing on ready-made garments startups and
            driving innovation in areas like alternative materials, organic
            dyes, and circular fashion. We mobilized investment for 21 promising
            startups!
          </li>
          <li>
            Addressing Critical Needs: We co-designed and led Young Water
            Solution, an incubation program for wash-centric startups, in
            partnership with Young Water Fellowship, Belgium.
          </li>
          <li>
            Empowering Rural Youth: We co-designed SAMPREETI, a startup
            development program using entrepreneurship to combat violent
            extremism in rural areas. This program, delivered in Bangla, has
            empowered countless young people from low-income backgrounds.
          </li>
          <li>
            Sustainable Solutions: We spearheaded a water tower ecosystem,
            generating 10,000 liters of clean drinking water from air with zero
            energy conversion – a project even featured by the World Economic
            Forum!
          </li>
        </ul>

        <p className="pb-6">
          This is just the beginning. I believe that by working together, we can
          unlock the immense potential of impact investing to create a more
          equitable and sustainable future for Bangladesh. I invite you to join
          me on this journey!
        </p>

        <div className="mb-8">
          <img src={new121_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Erad Kawsar</p>
            <p>Executive Director</p>
            <p>Build Bangladesh</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
