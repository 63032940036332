import React from "react";
import { Link } from "react-router-dom";
import news16 from "../../../../assets/images/news16.png";
import news13_details1 from "../../../../assets/images/news13_details1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function EmpoweringImpactDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news16}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <h1 className="pb-6 text-xl font-bold">
          Article by Laura Brouwer, Manager – Social Finance NL
        </h1>
        <p className="pb-6">
          The fifth annual conference of the National Advisory Board for Impact
          Investment in Bangladesh took place last month in Dhaka. I had the
          pleasure of visiting Bangladesh and attending and contributing to the
          conference, which brought together a wide range of stakeholders to
          speak to the state of impact investment in Bangladesh, the
          opportunities it brings and what is needed to grow the allocation of
          private capital to make a greater impact. As Bangladesh is scheduled
          to graduate from the Least Developed Country (LDC) category in 2026,
          it will affect the levels of Official Development Assistance (ODA)
          being available. Despite an impressive average annual growth rate in
          Gross Domestic Product (GDP), Bangladesh will face challenges in
          funding the solutions to social, economic and governance challenges.
          This will likely be perpetuated by Bangladesh’s high vulnerability to
          climate risks.
        </p>
        <p className="pb-6">
          The conference therefore provided an important opportunity for
          stakeholders to come together around what is needed to attract and
          invest more capital in genuinely impactful ways. From a wide range of
          takeaways, below are three that stood out to me:
        </p>

        <ol className="pl-20 pb-10 flex flex-col gap-4">
          <li>
            Choose an investment vehicle that is fit for purpose: impact
            investments can be made using a wide range of financial instruments,
            including traditional investment instruments, such as private
            equity, debt instruments and bonds. There are also innovative
            finance solutions, including blended finance and outcomes-based
            finance. It is critical to select a fitting investment vehicle,
            based on the specific challenges one is trying to solve, the key
            actors involved in solving that challenge and the enabling
            environment. For example, a Development Impact Bond can help to
            improve the quality of service delivery by steering more towards
            outcomes. Such as this Skills Impact Bond in India. For impact
            entrepreneurs, impact-linked finance can work to help them access
            private investments whilst being rewarded for the impact they are
            creating. B-BRIDDHI in Bangladesh focusses on catalysing funding for
            impact entrepreneurs in this way. For large scale infrastructure
            investments for climate change adaptation and mitigation, green
            bonds can mobilise the required investments. For example, the IFC is
            doing significant work with the Bangladesh Bank on this.
          </li>

          <li>
            Government needs to play a catalytic and enabling role: it was
            inspiring to have many government bodies present at the two-day
            conference, speaking with different stakeholders. The government is
            also taking important steps to create a more enabling environment
            for impact investors. The news that the Bangladesh Securities and
            Exchange Commission (BSEC) is preparing to enact the sustainability
            bonds guidelines is an important and exciting step.
          </li>

          <li>
            Impact measurement and transparency: another important discussion
            point at the conference was the need for more and accessible impact
            measurement and transparency. This is not only the case for
            Bangladesh, but for impact investment ecosystems worldwide.
            Measuring and reporting impact makes impact investment distinct from
            other forms of investment like ESG investing. The Global Steering
            Group on Impact Investment (GSG) is pushing this agenda
            internationally. The Bangladesh government and international finance
            institutions are taking good steps in developing more standardised
            ESG frameworks. Private sector participants at the NAB conference
            called for more dialogue to develop these frameworks with the
            private sector realities in mind.
          </li>
        </ol>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
