import React from 'react'
import Layout from '../../shared/Layout/Layout'
import FormComponents from './Components/FormComponents'

export default function ContactUs() {
  return (
    <Layout>
        <FormComponents />
    </Layout>
  )
}
