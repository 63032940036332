import React from "react";
import nabWhiteLogo from "../../assets/images/NAB_white_logo.png";
import {
  FaFacebook,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitch,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-primary text-white pt-10">
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-20 px-16">
          <div>
            <img
              src={nabWhiteLogo}
              className="w-60 h-auto md:w-auto"
              alt="Nab Bangladesh"
            />
          </div>
          <div className="border-t-2 border-white py-6 px-4 flex flex-col gap-2">
            <Link to="/nab-bangladesh">
              <p>About US</p>
            </Link>
            <Link to="/join-nab-bangladesh">
              <p>Join NAB Bangladesh</p>
            </Link>
            <Link to="/learning-hub">
              <p>Resources</p>
            </Link>
          </div>
          <div className="border-t-2 border-white py-6 px-4 flex flex-col gap-2">
            <Link to="/impact-inveseting">
              <p>Impact Investing</p>
            </Link>
            <Link to="/news-events">
              <p>News & Events</p>
            </Link>
            <Link to="/contact-us">
              <p>Contact Us</p>
            </Link>
          </div>
          <div className="border-t-2 border-white py-6 px-4 flex flex-col gap-2">
            <p>info@nabbangladesh.org</p>
            <p>
              <span>Address:</span>260/B(1st floor), Evergreen Plaza, Tejgaon
              1/A, Dhaka-1208, Bangladesh
            </p>
            <p>Follow us on:</p>
            <div className="flex items-center gap-2">
              <a
                href="https://www.facebook.com/nabbangladesh/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bg-white p-2 rounded-full">
                  <FaFacebookF className="w-4 h-4 fill-primary" />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/nab-bangladesh/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bg-white p-2 rounded-full">
                  <FaLinkedinIn className="w-4 h-4 fill-primary" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#005439] p-6 text-center">
        copyright © {new Date().getFullYear()}, National Advisory Board for
        Impact Investment | All Rights Reserved
      </div>
    </div>
  );
}
