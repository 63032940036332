import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function Academia() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Academia</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-7  border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                            <p className='bottom'>Academia</p>
                        </div>
                        <div className='col-span-2 border-r border-b border-black flex items-center text-left p-2'>
                            University of Dhaka
                        </div>
                        <div className='col-span-7 border-b border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://www.du.ac.bd/" target='_blank' className=' text-primary underline  decoration-1'>
                                    University of Dhaka
                                </a> </span>
                                is the oldest and largest public university in Bangladesh with more than 30,000 active students and a large alumni network spread all across the globe. The university has multiple entrepreneurship and innovation labs. For instance, the Innovation, Creativity, and Entrepreneurship (ICE) Centre is a platform that facilitates the development of business solutions by the students. The organization is working towards establishing an effective national innovation system (NIS) in Bangladesh.
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>

                        </div>
                        <div className='col-span-2 border-r border-black border-black flex items-center text-left p-2'>
                            Daffodil University
                        </div>
                        <div className='col-span-7 border-black border-black flex items-center text-left p-2'>
                            <div>
                                <span><a href="https://daffodilvarsity.edu.bd/" target='_blank' className=' text-primary underline  decoration-1'>
                                    Daffodil University,
                                </a> </span>
                                a private university in Bangladesh, has been fostering entrepreneurship, innovation and social business through a range of programmes. The expertise of its trainers and strategic partnerships with organisations such as Dhaka Chamber of Commerce could be tapped to devise the training curriculum for university-level students.
                            </div>
                        </div>

                    </div>







                </div>
            </div>
        </div>
    )
}
