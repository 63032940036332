import React from "react";
import { Link } from "react-router-dom";
import news32 from "../../../../assets/images/news32.jpg";
import news15_details1 from "../../../../assets/images/news15_details1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function InvestmentStrategyDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news32}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <h1 className="text-xl">Farhadur Reza[1].</h1>
        <p className="pb-6">
          Today is a historic day for Impact InveStory, story of impact
          investment ecosystem of a country – the happy Shonar Bangla, dream of
          the father of the Nation Bangabandhu Sheikh Mujibur Rahman. Not an
          easy journey though! We fought colonialism, war of independence,
          famine, disaster, needed extra money for food, clothing, housing,
          education, health, agriculture – the overall wellbeing for the people
          in this region.
        </p>
        <p className="pb-6">
          Our region started with a different ‘model’ of banking. The early
          banking system was introduced by the Afghan traders popularly known as
          Kabuliwalas[2]. Muslim businessmen from Kabul, Afghanistan came to
          India and started money lending business in exchange of interest
          sometime in 1312 A.D. During the period of British rule, the merchants
          established the first modern bank headquartered in Dhaka, the Dacca
          Bank, established in 1846. It was purchased by Bank of Bengal in 1862.
          Bank of Bengal opened branches in Sirajganj and Chittagong in 1873,
          and in Chandpur in 1900.
        </p>
        <p className="pb-6">
          People gradually started to save, women started to transfer their
          savings from non-secured steal trunk, pillow covers, bamboo poles
          inside the home to more formal arrangements through Banks. Still long
          way to go though!
        </p>
        <p className="pb-6">
          Since the birth of Bangladesh as an independent country in 1971, its
          very first private sector bank was AB Bank Limited. This bank was
          incorporated on 31st December 1981 and started its effective operation
          from 12th April 1982.
        </p>
        <p className="pb-6">
          Globally, in relation to the financial/banking industry, the Jews in
          Jerusalem introduced a kind of banking in the form of money lending
          before the birth of Christ. The word ‘bank’ was probably derived from
          the word ‘bench‘ as during ancient time Jews used to do money –
          lending business sitting on long benches.
        </p>
        <p className="pb-6">
          First modern banking was introduced in 1668 in Stockholm, which opened
          up a new era of banking activities throughout the European Mainland.
          The industrial revolution needed new financial instruments. New
          changes were introduced with the emergence of the gold standard, stock
          markets and insurance.
        </p>
        <p className="pb-6">
          First stock exchange was established in London (1698). Philadelphia
          Stock Exchange was one of the first stock brokerage houses. People
          started to invest in companies that are efficient and produce profits,
          leading to overall economic growth through competition.[3]
        </p>
        <p className="pb-6">
          The World War required war-making states to mobilize and sustain the
          financial resources. What made war finance during the conflict so
          special is that this challenge had never been confronted in a world
          economy as large, deeply interconnected, and sophisticated as that
          which existed in 1914.
        </p>
        <p className="pb-6">
          Financial institutions gradually transformed over last centuries into
          different streams to adapt micro finance, social business, private
          equity, venture capital, family office, trust, grants, charity,
          not-for-profit, philanthropy etc etc.
        </p>
        <p className="pb-6">
          The question is how COVID-19 and the war in Europe will influence to
          change rest of 2022 and future socio-economic indicators, investments
          and eventually the overall financial ecosystem of Bangladesh. The
          impacts of these disasters are pushing us to rethink and re-evaluate
          our work, and what it might mean for us and the next generation
          investors/investees to design for a Bangladesh that will probably not
          be the same! What will be our new perception and goals when working
          with the existing financial instruments or designing new investment
          instruments to do well and do good at the same time?
        </p>
        <p className="pb-6">
          There has never been a greater challenge and opportunity for investors
          to be innovative and creative to complement social, economic, and
          environmental reforms while ensuring financial gain to win against the
          negative impacts of COVID-19 and the war in Europe.
        </p>
        <p className="pb-6">
          We have moved forward from profit to risk, risk to impact! From only
          profit, to profit, people, and planet!
        </p>
        <p className="pb-6">
          We realised,{" "}
          <p className="underline italic">
            ‘people do not want charity, they want a chance’!
          </p>
        </p>
        <p className="pb-6">
          In 2007, Rockefeller Foundation introduced the concept of Impact
          Investment. We got familiar with ESG complying businesses, SDG since
          2015 along with the SDG financing gap. According to the 2017 SDG
          Financing Strategy, Bangladesh requires an additional $928 billion to
          implement the Sustainable Development Goals by 2030, with the private
          sector accounting for 42 per cent.
        </p>
        <p className="pb-6">
          Can Impact Investing complement us in this difficult journey? We
          believe it can.
        </p>
        <p className="pb-6">
          Bangladesh became part of the global movement of Impact Investment and
          became the 19th member country of the Global Steering Group of Impact
          Investment in 2018. Bangladesh Government visioned this even earlier,
          Bangladesh Securities and Exchange Commission released the Alternative
          Investment Rules 2015 to securitise – private equity, venture capital
          and Impact Fund in 2015. Bangladesh Bank extended its support for
          Impact Investment – linking Impact Investment with Green Finance and
          also tax incentives announced by the National Board of Revenue are
          very encouraging for the ecosystem players. Private sector like the
          Impress Group with their Impact Investment stream Build Bangladesh
          came forward and organised the first international conference on
          Impact Investment in November 2016 followed by the first international
          workshop on impact investment in 2017.
        </p>
        <p className="pb-6">
          It was a genuine inspiration for impact investment activists like us
          when the Hon. Prime Minister Sheikh Hasina supported the concept of
          Impact Investment, by launching the first Impact Investment campaign
          for Bangladesh at a side event in New York at the United Nations
          General Assembly meeting in 2017. Again in 2017 UN passed a resolution
          including Impact Investment for the first time, and Bangladesh played
          significant role to make it happen.
        </p>
        <p className="pb-6">
          The leadership demanded a planned development of the ecosystem,
          required a structure, required an advisory board rather than running
          with series of projects with just a badge of impact investment, may
          result into impact washing.
        </p>
        <p className="pb-6">
          Hence, the creation of the National Advisory Board (NAB) for Impact
          Investment in Bangladesh in 2018 with the strong support and
          recommendation from the than Finance Minister late Abdul Muhith. The
          first Chair was the than Secretary of ERD, second chair was also the
          following Secretary of ERD. Currently, we are extremely lucky to have
          Md Sirazul Islam the Executive Chairman of BIDA as the Chair of NAB
          Bangladesh.
        </p>
        <p className="pb-6">
          NAB Bangladesh has membership from other key government departments
          including Bangladesh Bank, BSEC, PPPA, DSE. It also has members from
          Save the Children, UNDP, Embassy of Switzerland, ADB, FCDO, BRAC,
          BWCCI, IAB, Legal Circle and IFC plus JICA are part of the NAB as
          observers. Build Bangladesh works as NAB’s Secretariat.
        </p>
        <p className="pb-6">
          NAB Bangladesh, in partnership with the UNESCAP, Embassy of
          Switzerland, British Council Dhaka and Build Bangladesh first produced
          the Policy Landscape Analysis for Impact Investment in Bangladesh in
          2020. Followed by the Bangladesh Impact Investment Strategy and Action
          Plan (BIISAP) in 2022, supported by the Embassy of Switzerland and
          Build Bangladesh.
        </p>
        <p className="pb-6">
          To ensure, the implementation plans are discussed thoroughly and
          actioned appropriately, Build Bangladesh and Light Castle Partners
          jointly hosted 4 stakeholders workshop supported by the NAB
          Bangladesh, Embassy of Switzerland and BRAC in July 2022. These
          workshops included participants from the groups of investees,
          investors, intermediaries, regulators, and development partners.
        </p>
        <p className="pb-6">
          Today, we are launching the National Strategy. This is the first
          strategy to guide the implementation of Impact Investment from a
          national perspective, developed through an inclusive consultative
          process. This will be reviewed in 2025 as in 2026, the country will be
          graduated from its LDC status and the whole document will be updated
          in 2030. I must acknowledge the comprehensive works that has been
          undertaken by Light Castle Partners and Build Bangladesh to bring this
          document together has been exemplary.
        </p>
        <p className="pb-6">
          You all have a copy of the Strategy. The nine recommendations from the
          strategy are grouped under 5 priority intervention areas:
        </p>

        <ul className="pl-20 py-10">
          <li>Positioning and Identity</li>
          <li>Collaborations and Inclusivity</li>
          <li>Education and Training</li>
          <li>Alternative Financing Instruments and Tools</li>
          <li>Incentives and Policy Follow-through</li>
        </ul>
        <p className="pb-6">
          The growth of impact investment in such a supportive ecosystem is the
          best investment instrument to work as the ‘antidote’ against the
          possible negative impacts. According to the Global Impact Investment
          Network (GIIN), impact investing has attracted increasing interest
          among investors who are allocating more capital to impact investments
          year-over-year. It has identified the overall global investment is
          more than $250 trillion and projecting the prospects for capturing at
          least one percent of this investments, which is around $2.5 trillion,
          for global impact investment.
        </p>
        <p className="pb-6">
          With enabling ecosystem and ingredients, few private sectors have
          already delivered projects and programmes at the grass root level
          across Bangladesh, which revealed the effectiveness of impact
          investment as an effective tool. It is now time for reinforcing impact
          investments in Bangladesh through ongoing/new instruments like the
          Impact Entrepreneurs Incubator/Accelerator Programme (YGAP
          Bangladesh), Outcomes Based Funding (e.g. Social Impact Bond and
          Development Impact Bond) (BRAC), Micro Impact Investment (MI2) (Build
          Bangladesh and YGAP), Impact Based Open Ended Mutual Funds (ICL),
          initiatives like Binyog Briddhi (Embassy of Switzerland, RoI, LCP) to
          prepare the next generation investors and investees with enough
          outcome focused energy including the works done by the IFC, UNDP,
          JICA, FCDO, USAID, DFAT, and ADB.
        </p>
        <p className="pb-6">
          Impact investment is becoming an influential instrument to bring
          different mixtures of people, ideas, and resources to challenge
          difficult social issues while allowing positive solutions to achieve
          social and environmental good. Impact investment are now being used to
          finance initiatives such as aged care, health, housing, education,
          infrastructure, water and sanitation, financial inclusion, sustainable
          agriculture, and development of the services sector. The National
          Advisory Board (NAB) for Impact Investment in Bangladesh will deliver
          education/awareness programme focusing Impact Investment ecosystem
          players. Impact Investment is the best instrument to achieve long-term
          goals ensuring 21st century way forward for Bangladesh while
          responding to the need for people, planet, and prosperity.
        </p>
        <p className="pb-6">
          Finally, I would like to conclude my Impact InveStory with a quotation
          from Victor Hugo.
        </p>

        <p className="mb-10 border-l-4 px-10 py-6 border-gray-200 text-center">
          <div></div>
          <p className="text-2xl">
            “You can resist an invading army; you cannot resist an idea whose
            time has come.”
          </p>
          <p className="text-2xl">— Victor Hugo</p>
        </p>

        <p className="pb-6">Thank you.</p>
        <ol className="flex flex-col gap-4 pl-20 py-10">
          <li>
            Farhad Reza is an Architect-Planner and Impact Activist. Worked for
            the Australian Government, UNDP as the Asia-Pacific Adviser, pioneer
            in leading the Impact InveSTORY movement in Bangladesh as the
            founding President of Build Bangladesh and the CEO of the National
            Advisory Board (NAB) for Impact Investment in Bangladesh.
          </li>
          <li>
            Md. Saiduzzaman Selim, “The banking system of Bangladesh – Prospects
            &Challenges” January, 2015
          </li>
          <li>
            <a
              href="https://prezi.com/kkbchdgzkwxr/new-financial-instruments-in-the-industrial-revolution/"
              target="_blank"
              rel="noreferrer"
            >
              https://prezi.com/kkbchdgzkwxr/new-financial-instruments-in-the-industrial-revolution/
            </a>
          </li>
        </ol>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
