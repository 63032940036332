import React from 'react'
import PageHeading from '../../../shared/Components/PageHeading'
import Impact_investment from "../../../assets/images/Impact-Investment.jpg"

export default function ImpactTopbar() {
    return (
        <PageHeading bgImage={Impact_investment} >
            <div className='max-w-7xl px-8 md:px-20 text-white text-center p-4 mx-4' style={{ backgroundColor: "rgba(0,0,0,.5)" }}>
                <h2 className='font-bold text-2xl md:text-4xl'>
                    About Impact Investing
                </h2>
                <p>Impact Investments are investments that are made with the intention to generate positive,
                    measurable social and environmental impact alongside financial return.</p>
            </div>
        </PageHeading>
    )
}
