import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import AustraliaImpactDetails from "./components/AustraliaImpactDetails";

export default function AustraliasImpact() {
  return (
    <Layout>
      <NewsHeader>
        Building Impact: Our Journey in Australia's Impact Investment
      </NewsHeader>
      <AustraliaImpactDetails />
    </Layout>
  );
}
