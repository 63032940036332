import React from 'react'
import { FaEye, FaRegEdit, FaCopy, FaTrashAlt, FaTimes } from "react-icons/fa";
import { useCollection } from '../../hooks/useCollection';

export default function AdminJoinFormTable() {
    const { document } = useCollection("contactForm")
    return (
        <div>
            <section className="bg-white shadow-xl rounded-md m-4 py-6 px-4 overflow-hidden lg:py-8">
                <h1 className='font-bold text-xl text-center py-2'>All Join form</h1>
                <div className='border-b border-b-gray-200'></div>
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4 mx-4">
                        <div className="max-w-full overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="text-left text-dark font-bold">
                                        <th
                                            className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Full Name
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Professional details
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Personal Details
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Reference
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Category
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Address
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        document?.map(joinForm => (
                                            <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <p>{joinForm?.firstName + " " + joinForm?.lastName}</p>
                                                </td>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                  
                                                    <div><span className='font-bold'>Title: </span><span>{joinForm?.title}</span></div>
                                                    <div><span className='font-bold'>Organization: </span><span>{joinForm?.organization}</span></div>
                                                    <div><span className='font-bold'>Industry: </span><span>{joinForm?.industry}</span></div>
                                                </td>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                    <div><span className='font-bold'>Email: </span><span>{joinForm?.email}</span></div>
                                                    <div><span className='font-bold'>Phone: </span><span>{joinForm?.phone}</span></div>
                                                    <div><span className='font-bold'>Address: </span><span>{joinForm?.address}</span></div>
                                                </td>
                                                <td
                                                    className="py-2 px-2  text-base"
                                                >
                                                   <div><span className='font-bold'>Reference: </span><span>{joinForm?.reference}</span></div>
                                                    <div><span className='font-bold'>Reference Name: </span><span>{joinForm?.referenceName}</span></div>
                                                    <div><span className='font-bold'>Reference Email: </span><span>{joinForm?.referenceEmail}</span></div>
                                                    <div><span className='font-bold'>Reference Address: </span><span>{joinForm?.referenceAddress}</span></div>
                                                    <div><span className='font-bold'>Reference Designation: </span><span>{joinForm?.referenceDesignation}</span></div>
                                                </td>
                                                <td
                                                    className="y-2 px-2  text-base"
                                                >
                                                    {
                                                        joinForm?.category
                                                    }
                                                </td>
                                                <td
                                                    className="y-2 px-2  text-base"
                                                >
                                                   <div><span className='font-bold'>City: </span><span>{joinForm?.city}</span></div>
                                                    <div><span className='font-bold'>Postal Code: </span><span>{joinForm?.postalCode}</span></div>
                                                </td>
                                               

                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
