import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import EmpoweringImpactDetails from "./component/EmpoweringImpactDetails";

export default function EmpoweringImpact() {
  return (
    <Layout>
      <NewsHeader>
        Empowering Impact: Insights from the NAB Bangladesh Conference
      </NewsHeader>
      <EmpoweringImpactDetails />
    </Layout>
  );
}
