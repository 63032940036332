import React from "react";
import Layout from "../../../shared/Layout/Layout";
import BalancePrioritiesHeader from "./Component/BalancePrioritiesHeader";
import BalancePrioritiesDetails from "./Component/BalancePrioritiesDetails";

export default function BalancePriorities() {
  return (
    <Layout>
      <BalancePrioritiesHeader />
      <BalancePrioritiesDetails />
    </Layout>
  );
}
