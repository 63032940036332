import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import IficDetails from "./Component/IficDetails";

export default function Ific() {
  return (
    <Layout>
      <NewsHeader>IFIC Bank joins NABG</NewsHeader>
      <IficDetails />
    </Layout>
  );
}
