import React, { useEffect, useRef, useState } from "react";
import OurPeopleButtons from "./Components/OurPeopleButtons";
import Layout from "../../shared/Layout/Layout";
import BoardMember from "./Components/BoardMember";
import NABSecreteriat from "./Components/NABSecreteriat";
import NabTrust from "./Components/NabTrust";

export default function OurPeople() {
  let [buttonTrigger, setButtonTrigger] = useState("");

  let boardRef = useRef(null);
  let secreteriatRef = useRef(null);
  let trustRef = useRef(null);

  const scrollToBoard = () => {
    boardRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSecreteriatRef = () => {
    secreteriatRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTrustRef = () => {
    trustRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (buttonTrigger === "board") {
      scrollToBoard();
    } else if (buttonTrigger === "secreteriat") {
      scrollToSecreteriatRef();
    } else if (buttonTrigger === "trust") {
      scrollToTrustRef();
    }
  }, [buttonTrigger]);

  return (
    <Layout>
      <div className="container mx-auto py-20 px-4">
        <OurPeopleButtons setButtonTrigger={setButtonTrigger} />
        <BoardMember buttonTrigger={buttonTrigger} boardRef={boardRef} />
        <NabTrust buttonTrigger={buttonTrigger} boardRef={trustRef} />
        <NABSecreteriat
          buttonTrigger={buttonTrigger}
          secreteriatRef={secreteriatRef}
        />
      </div>
    </Layout>
  );
}
