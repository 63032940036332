import React from "react";
import { Link } from "react-router-dom";
import news18 from "../../../../assets/images/news18.png";
import news18_details1 from "../../../../assets/images/news18_details1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function NationalImpactInvestDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news18}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          On 10th November 2016, former Finance Minister of Bangladesh
          Government, late Abul Maal Abdul Muhith launched the concept of impact
          investment publicly in Bangladesh.
        </p>
        <p className="pb-6">
          To frame that day as an historic milestone in our investment ecosystem
          calendar, NAB Bangladesh is proud to declare 10 November as the
          National Impact Investment Day – first country in the world to do so.
        </p>
        <div className="text-center max-w-[500px] mx-auto pb-6">
          <img
            src={news18_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          The National Impact Investment Day is not another day, it is a day
          like no other! A day to:
        </p>

        <ul className="pl-20 pb-10">
          <li>
            celebrate positivity of impact regulations, transparency,
            instruments and scale up initiatives.
          </li>
          <li>
            share the benefits received by people, economy, and environment.
          </li>
          <li>
            demonstrate impact accountabilities for investors, investees,
            intermediaries, and market builders while complementing the SDGs.
          </li>
          <li>acknowledge contributions of our impact leaders.</li>
          <li>
            share local and global learnings on impact investment outcomes
            through media, public outlets, universities, seminars, webinars,
            summits.
          </li>
          <li>
            inspire future investors, innovators, local and global partners.
          </li>
          <li>
            look through the “Build Bangladesh Star Lens” targeting:
            inclusivity, gender equality, climate change, digital innovation,
            and intergenerational wellbeing.
          </li>
          <li>
            showcase the best practiced National Day, envy to the world – as the
            first country to celebrate National Impact Investment Day.
          </li>
        </ul>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
