import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function KeyPolicyFirst() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>2014-15</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-5 text-primary text-center font-bold border-b border-black'>
                        <div className='border-r border-black flex justify-center items-center p-2'>
                            Law/Circular/Guideline
                        </div>
                        <div className='border-r border-black flex justify-center items-center p-2'>
                            Date of Publication
                        </div>
                        <div className='border-r border-black flex justify-center items-center p-2'>
                            Implementer
                        </div>
                        <div className='border-r border-black flex justify-center items-center p-2'>
                            Brief Description of Provision
                        </div>
                        <div className='flex justify-center items-center p-2'>
                            Implications for Impact Investing in Bangladesh to be considered
                        </div>
                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-5 text-center border-b border-black bg-[#FAFAFA] '>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.sec.gov.bd/slaws/BSEC(Alternative_Investment)_Rules,_2015_22.06.2015.pdf" target='_blank' className='font-bold text-primary underline  decoration-1'>
                                Alternative Investment
                                Rules, 2015
                            </a>
                        </div>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            June 22, 2015
                        </div>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            Bangladesh Securities and Exchange Commission (BSEC)
                        </div>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            Recognizes impact fund as an alternative investment fund and sets
                            the definition of an impact fund along with necessary provisions
                            relating to the formulation of an impact fund.
                        </div>
                        <div className='flex items-center text-left'>
                            <div>
                                <div className='border-b border-black  p-2'>
                                    Clearer guidelines on the registration process and management of
                                    impact funds.
                                </div>
                                <div className='border-b border-black p-2'>
                                    Clearer guidelines on the registration process and management of
                                    impact funds.
                                </div>
                                <div className='border-b border-black p-2'>
                                    Clearer guidelines on the registration process and management of
                                    impact funds.
                                </div>
                                <div className=' p-2'>
                                    Clearer guidelines on the registration process and management of
                                    impact funds.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* second Row */}
                    <div className='grid grid-cols-5 text-center  bg-[#FAFAFA]'>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.bb.org.bd/en/index.php" target='_blank' className='font-bold text-primary underline  decoration-1'>
                                Circular on Direct Budgetary CSR Expenditure
                            </a>
                        </div>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            December 22, 2014
                        </div>
                        <div className='border-r border-black flex items-center text-left p-2'>
                            Green Banking and CSR Department, Bangladesh Bank
                        </div>
                        <div className='border-r border-black flex items-center text-left'>
                            <div>
                                <div className='border-b border-black p-2'>
                                    Banks and FIs are mandated to spend on CSR from their net profits
                                    and there is no limit on expenditure*
                                </div>
                                <div className='p-2'>
                                    Banks and FIs are to allocate 30% of their CSR outlay on the
                                    education sector, 20% on healthcare sector; The rest is to be
                                    allocated in
                                    <ul>
                                        <li>emergency disaster relief;</li>
                                        <li>promoting environmentally sustainable output practices and
                                            lifestyles;</li>
                                        <li>promoting artistic, cultural, literary;</li>
                                        <li>building sports and recreational facilities for the
                                            underprivileged;</li>
                                        <li>upgrading facilities and lifesavings equipment in emergency
                                            rescue services (i.e., fire brigades)</li>
                                        <li>improving infrastructure for disadvantaged communities in
                                            remote areas</li>
                                    </ul>
                                    <p>*(Upcoming modification: Banks and FIs will spend 2.5% of their
                                        net profits on CSR activities</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center text-left p-2'>
                            Impact enterprises operating in these mentioned areas can be
                            considered for CSR funding by banks and FIs
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
