import React from 'react'
import Layout from '../../shared/Layout/Layout'
import GsgHeader from './Components/GsgHeader'
import GsgDetails from './Components/GsgDetails'

export default function GsgNews() {
  return (
    <Layout>
        <GsgHeader />
        <GsgDetails />
    </Layout>
  )
}
