import React from 'react'
import collab1 from "../../../assets/images/collab1.png"
import collab2 from "../../../assets/images/collab2.png"
import collab3 from "../../../assets/images/collab3.png"

export default function NabBangladeshWhatWeDo() {
    return (
        <div className='bg-[#F5F5F5] py-20'>
            <div className='max-w-7xl mx-auto px-8 md:px-16 text-center ' >
                <h1 className='font-bold text-3xl'>What We Do?</h1>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-16'>
                    <div className=' px-6 py-10 rounded-md text-center flex flex-col items-center pb-10'>
                        <div className='text-center pb-10'>
                            <img src={collab1} className='w-20 h-20 text-center' />
                        </div>
                        <h2 className='font-semibold text-primary text-xl pb-2'>Policy Advocacy</h2>
                        <p>Collaborate with regulators and policy makers to create a conducive policy environment for stimulating the growth of Impact Investment and legitimizing it as a unique asset class in Bangladesh.</p>
                    </div>
                    <div className=' px-6 py-10 rounded-md text-center flex flex-col items-center pb-10'>
                        <div className='text-center pb-10'>
                            <img src={collab2} className='w-20 h-20 text-center' />
                        </div>
                        <h2 className='font-semibold text-primary text-xl pb-2'>Ecosystem Engagement</h2>
                        <p>Organize capacity building trainings, workshops, roundtables to sensitize the ecosystem stakeholders and promote greater understanding of the demands and necessities of Impact Investment.</p>
                    </div>
                    <div className=' px-6 py-10 rounded-md text-center flex flex-col items-center pb-10'>
                        <div className='text-center pb-10'>
                            <img src={collab3} className='w-20 h-20 text-center' />
                        </div>
                        <h2 className='font-semibold text-primary text-xl pb-2'>Research & Publication</h2>
                        <p>Amplify the advocacy efforts and provide a better understanding of impact investing through data driven research & analysis.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}
