import React from "react";
import { Link } from "react-router-dom";
import news15 from "../../../../assets/images/news15.png";
import news15_details1 from "../../../../assets/images/news15_details1.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function ImpactAuditDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news15}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          Forget pitch decks, coffee meet and fancy offices, the hottest
          startups of today aren’t just disrupting industries, they’re saving
          the planet (and making a buck while they’re at it). This VC funding is
          not a sought-after game anymore ; it’s impact investing on steroids, a
          $715 billion market hurtling towards $1.7 trillion by 2025. But with
          more money chasing impact than a Kardashian chasing clout, measuring
          that impact is becoming the new competitive edge. So ditch the pitches
          and fixing ‘no-way forward’ meeting, folks, it’s time to get on the
          field, focus on making your hands dirty and go granular. We’re talking
          impact audits that are sexier than a pivot, forecast reports and that
          TAM-SAM-SOM siren song with unicorn tears. Because in this brave new
          investment world, saving the world just got quantifiable! Buckle up,
          we’re about to introduce you to the hottest tool in the impact
          investor’s arsenal, and let’s just say, it’s not a slide deck.
        </p>
        <p className="pb-6">
          And for impact investors, the future’s looking SDG (and profitable).
          Drop your unicorn dreams and join the mission-driven flying-dragons,
          because the future belongs to those who measure their success in
          impact, not just IPOs.
        </p>

        <div className="text-center max-w-[500px] w-full">
          <img
            src={news15_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-10 pt-2">
          Erad Kawsar, Executive Director, Build Bangladesh
        </p>
        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
