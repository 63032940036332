import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import SouthAsiaConveingDetails from "./components/SouthAsiaConveingDetails";

export default function SouthAsiaConveing() {
  return (
    <Layout>
      <NewsHeader>
        ANDE South Asia Convening 2024 Co-hosted by NAB Bangladesh
      </NewsHeader>
      <SouthAsiaConveingDetails />
    </Layout>
  );
}
