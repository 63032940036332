import React from 'react'
import impactInvestment from "../../../assets/images/impact-Investment.png"

export default function ImpactInvest() {
    return (
        <div className='container mx-auto px-8 md:px-16'>
            <div className='max-w-5xl mx-auto text-center pb-8'>
                <h2 className='text-2xl md:text-4xl pb-10'>How is <span className='font-bold'>Impact Investing</span> different
                    <br />
                    from other forms of investing?
                </h2>
                <p className='font-semibold text-sm'>Impact Investing lies in the centre of the spectrum ranging <br />
                    from not-for-profit investing or grants to for-profit (commercial/traditional) investing</p>
            </div>

            <div>
                <img src={impactInvestment} alt="impact invest" />
            </div>
        </div>
    )
}
