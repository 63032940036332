import React from "react";
import { Link } from "react-router-dom";
import news122 from "../../../../assets/images/news125.jpg";
import news123_creator from "../../../../assets/images/news125_creator.png";
import news11 from "../../../../assets/images/news11.jpg";

export default function AMeansToEndDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news122}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          As with many things, my journey in impact investing started more by
          coincidence than anything else. It was during my studies in Mechanical
          Engineering that I decided that an internship at an incubator or
          venturing team would be a good opportunity. I landed a role at
          Dialogues Incubator which, much to my own surprise, was the innovation
          arm of ABN AMRO Bank. Hired by Ruben Koekoek, we discussed whether to
          focus on tech innovation with another colleague, or social
          entrepreneurship with him. Ruben hired me and seemed like a good guy,
          so social entrepreneurship it was. The rest is history, as they say. A
          journey in social entrepreneurship and impact investing followed,
          which I’m sharing today through my key lessons but by starting at the
          beginning.
        </p>
        <h2 className="font-bold">
          {" "}
          Bringing the Social Impact Bond to the Netherlands
        </h2>
        <p className="pb-6">
          Shortly after my start, Ruben learned about the launch of the Social
          Impact Bond (SIB) in Peterborough, led by our now colleagues at Social
          Finance UK. We set off to London to learn more from the team, many are
          still leaders in our field (David Hutchison, Jane Newman, Dan
          Miodovnik and Antonio Miguel). They spent the afternoon sharing
          everything they knew. It inspired us to launch the first SIB in the
          Netherlands in 2013. Fast forward to 2018. I was now working at
          Monitor Deloitte, with Ruben still at ABN AMRO. More SIBs had been
          launched since the first project, and since the field of impact bonds
          and impact investing in the Netherlands was still small, most of the
          roads led back to us when people where interested in social impact
          bonds. However, it seemed that people where particularly interested in
          setting up the SIB structure, without taking into consideration
          whether that really would be the best way to finance the desired
          impact. It led us to the realisation that an organisation such as
          Social Finance UK was missing in the Dutch ecosystem. Social Finance
          NL was born.
        </p>
        <h2 className="font-bold">
          Financing impact needs more than just outcomes-based finance
        </h2>
        <p className="pb-6">
          Having been active in the field of impact bonds, or outcomes-based
          finance, for over a dozen years, we’ve learned that not everything can
          or should be financed through this model. There was a time when impact
          bonds were a hot topic in impact investing, but in recent years, the
          enthusiasm has cooled, and the same cycle is observed with other
          impact investment products. This shift makes sense; we all want to
          capitalize on momentum, standardize, and scale successful models.
          However, when financing deep impact, flexibility is essential. Unlike
          traditional business sectors, each phase, topic, and region has its
          own particularities. If the goal is to create real impact rather than
          just generate financial returns, we must adapt and find what really
          works. Someone once described our work as "swimming through the mud";
          when people are struggling, simply diluting the mud won’t help—we need
          to put in the hard work to reach them, keep them afloat, and bring
          them to solid ground.
        </p>
        <h2 className="font-bold">
          Apply insights to the broader field, such as quantifying your impact
        </h2>
        <p className="pb-6">
          While outcomes-based finance is not a solution for every financing
          need, the insights we’ve gained from implementing it are very
          valuable. First, the focus on outcome indicators in every programme is
          key to ensure that people’s needs remain at the core of policy, grant
          programs, and investments. Second, measuring impact and setting
          indicators from the start raises the quality of programmes in both the
          short and long term. Finally, the need to quantify impact and assign a
          value to outcomes has sparked a necessary conversation. Social
          entrepreneurs often generate value beyond traditional business
          metrics, yet this social value is frequently overlooked, which hampers
          innovations and competitiveness. By failing to recognise social
          impact, we mistakeably favour organisations that harm society and the
          environment, while social entrepreneurs struggle to attract investment
          and scale. We owe it to the impact sector and society at large, it’s
          essential to start valuing social outcomes and reward those creating
          positive change.
        </p>
        <h2 className="font-bold">
          Building Ecosystems Locally, Connecting It Globally
        </h2>
        <p className="pb-6">
          Outcomes-based finance can only be successful through strong
          partnerships among all involved stakeholders. This was an early lesson
          and applies to all our work. When Social Finance NL was launched, we
          said we didn’t want to become a large organisation, but rather build a
          large ecosystem of partners around our philosophy. This can still be
          seen through the fact that one of the three key pillars in our team is
          knowledge dissemination. And while we are building our ecosystem
          locally, impact investing is global. Therefore we are proud to work
          hand in hand with the Social Finance Global Network with three other
          partners and over 350 people globally focusing on social change. But
          this has also been a key reason why I was part of the team exploring
          the opportunities for a Netherlands Advisory Board on Impact Investing
          in the Netherlands and support the Dutch chapter as a Board Member,
          building the Dutch ecosystem but connecting to experts all over the
          world, such as in Bangladesh.
        </p>
        <h2 className="font-bold">Work with good people</h2>
        <p className="pb-6">
          Finally, I’m closing off with a cliché, but it is the most important
          aspect of my journey through impact investing. Most of the inflection
          points happened more by chance than by design. I have always felt that
          I’m only a small radar in all of the work I’ve been involved in, being
          incredibly lucky to have worked with some of the smartest and
          inspiring people out there that have led my way. The field of impact
          investing is unique in that it seems to draw people in for life. They
          are intrinsically motivated and much more focused on partnerships than
          competition. It is shown through the fact that many people I met early
          on in my journey, are still key partners today. At Social Finance NL
          we are now with 20 incredible talents, working every day to steer
          financial capital, public and private, towards more impact. Last week
          the GIIN announced that the impact investing market has grown to over
          US$1.5 Trillion. If we can ensure that that reaches the most in need
          and be used as effectively as possible, the positive change we can
          have together can be endless.
        </p>

        <div className="mb-8">
          <img src={news123_creator} alt="" className="max-w-[500px] mx-auto" />
          <div className="flex flex-col gap-2 items-center mt-4">
            <p className="font-bold">Björn Vennema</p>
            <p>Co-Founder & Managing Director</p>
            <p>Social Finance NL</p>
          </div>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
