import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function KeyEcosystemIntermediatoryFirst() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Ecosystem Intermediaries</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-10 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-8 border-black flex justify-center items-center p-2'>
                            Description of Activities/Web Links
                        </div>

                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-10 text-center border-black bg-[#FAFAFA] '>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                            <a href="https://www.sec.gov.bd/slaws/BSEC(Alternative_Investment)_Rules,_2015_22.06.2015.pdf" target='_blank' className='font-bold text-primary '>
                                ---
                            </a>
                        </div>
                        <div className='col-span-1 border-r border-black flex items-center text-left p-2'>
                            Build Bangladesh
                        </div>
                        <div className='col-span-8  border-black flex items-center text-left p-2'>
                            <div>
                                <a href="http://www.buildbangladesh.org.bd/event-biit.php" target='_blank' className=' text-primary underline  decoration-1' >
                                    <span>Bringing Impact Investors Together:</span></a> An international workshop on Impact Investment to provide information, intelligence and decision-making tools for investors. It explores opportunities to attract local and global impact investments, identify sectoral priorities, and discuss potential to establish an Impact Investment Exchange.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
