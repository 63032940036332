import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import FosteringDevelopmentDetails from "./component/FosteringDevelopmentDetails";

export default function FosteringDevelopment() {
  return (
    <Layout>
      <NewsHeader>
        Fostering Sustainable Development through Impact Investment in
        Bangladesh
      </NewsHeader>
      <FosteringDevelopmentDetails />
    </Layout>
  );
}
