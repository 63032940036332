import React from "react";

export default function JoinNabBdFormLeft() {
  return (
    <div>
      <h2 className="text-3xl pb-8">
        Become a distinguished member of NAB Bangladesh and create your own
        Impact InveStory!
      </h2>
      <p className="pb-8">
        NAB Bangladesh is a high-level advisory board recognised as a GSG
        National Partner with members from Government, Regulators, MDFIs, NGOs,
        Development Partners, Peak Business Associations, Legal Firms and
        Academics to shape of the Bangladesh Impact Investing Industry.
      </p>
      <p>
        If you want to be a part of this cause and get access to all the
        opportunities that can only be accessed under the ‘collective’ umbrella
        of NAB Bangladesh, please fill-up the online form! The NAB Bangladesh
        Secretariat will communicate with you.
      </p>
    </div>
  );
}
