import React from "react";
import { Link } from "react-router-dom";
import strong_growth from "../../../../assets/images/strong_growth.jpg";
import news29_details1 from "../../../../assets/images/news29_details1.jpg";
import news11 from "../../../../assets/images/news11.jpg";
import { Button } from "../../../../shared/ui/Button";

export default function StrongGrowthDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={strong_growth}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          “A Strong Base for Growth” – EYDK Biennial Report (May 2021 – May
          2023) Published!
        </p>
        <p className="pb-6">
          The report includes the founding story of the Impact Investing
          Advisory Board and offers a summary of its activities between May 2021
          and May 2023. Additionally, it provides a general overview of the
          impact investment journey in Türkiye, addressing the key aspects such
          as Türkiye’s impact investment potential and EYDK’s vision for impact
          measurement and management.
        </p>
        <div className="mx-auto">
          <a
            href="https://buildbangladeshbd-my.sharepoint.com/personal/aninda_buildbangladesh_org_bd/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Faninda%5Fbuildbangladesh%5Forg%5Fbd%2FDocuments%2FBB%20Publications%2FEYDK%2DFaaliyet%2DRaporu%2DENG%2D2%2D1%2Epdf&parent=%2Fpersonal%2Faninda%5Fbuildbangladesh%5Forg%5Fbd%2FDocuments%2FBB%20Publications&ga=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Read the report</Button>
          </a>
        </div>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
