import React from 'react'
import Layout from '../../../shared/Layout/Layout'
import NewsHeader from '../../../shared/Components/NewsHeader'
import TikasContributionDetails from './components/TikasContributionDetails'

export default function TikasContribution() {
  return (
    <Layout>
      <NewsHeader>Strengthening Ties: TİKA’s Impactful Contributions in Bangladesh</NewsHeader>
      <TikasContributionDetails />
    </Layout>
  )
}
