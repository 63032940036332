import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import NabMeetingDetails from "./Component/NabMeetingDetails";

export default function NabMeeting() {
  return (
    <Layout>
      <NewsHeader>
        Discussion, Decision, Determination – NABG Meeting
      </NewsHeader>
      <NabMeetingDetails />
    </Layout>
  );
}
