import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import OnImpactDetails from "./Component/OnImpactDetails";

export default function OnImpact() {
  return (
    <Layout>
      <NewsHeader>On Impact</NewsHeader>
      <OnImpactDetails />
    </Layout>
  );
}
