import React from 'react'

export default function AccordionHeader({  impactName, setImpactName }) {
    return (
        <div className='container mx-auto pt-20 px-8 md:px-16'>
            <div className='grid grid-cols-2 md:grid-cols-4'>
                {
                    HeaderData?.map(header => (
                        <div key={header.id} className={`${impactName === header?.title ? "bg-[#F5F5F5] text-primary font-bold underline decoration-2" : ""} border p-6 rounded-md cursor-pointer`} onClick={() => setImpactName(header?.title)}>
                            <p className='md:font-semibold text-sm text-center'>
                                {header?.title}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const HeaderData = [
    {
        id: "01",
        title: "Key Policies"
    },
    {
        id: "02",
        title: "Key Ecosystem Builders"
    },
    {
        id: "03",
        title: "Ecosystem Intermediaries"
    },
    {
        id: "04",
        title: "Supply Side Players"
    },
]
