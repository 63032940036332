import GSG_LOGO from "../../../assets/images/GSG_LOGO_PARTNERSHIP_RGB_Lilac_Navy.png";

export default function GSGImpactPartnership() {
  return (
    <div className="container mx-auto pt-20 px-8 md:px-16">
      <div className="max-w-6xl mx-auto text-center  pb-12">
        <h2 className="text-2xl md:text-4xl pb-10">
          The GSG Impact Partnership
        </h2>
        <img src={GSG_LOGO} className="w-[240px] h-auto mx-auto" alt="GSG" />
        <p className="text-[16px] pb-4 pt-10">
          GSG Impact Partnership, is a global community committed to delivering
          positive impact at scale around the world. The National Advisory Board
          (NAB) of Impact Investment in Bangladesh is one of the GSG National
          Partners forming the GSG Impact Partnership. The mission of GSG Impact
          Partnership is to accelerate meaningful impact transparency and a
          transformative shift of resources to deliver positive impact on the
          SDGs and climate goals.
        </p>
        <p className="text-[16px] pb-4">
          Collectively, GSG Impact Partnership wants impact to be a core factor
          in every investment, business, and government spending decision. We
          firmly believe that, with greater transparency, government, finance,
          and business can be a catalyst for transformation, and private capital
          can be a powerful tool for delivering public good. We use our
          influence to make this happen.
        </p>
        <p className="text-[16px] pb-4">
          What makes GSG Impact Partnership unique is the global network and the
          symbiotic relationship between GSG Impact and the other GSG National
          Partners, who address opportunities in their countries and beyond,
          driving forward global solutions. They are the heart of GSG Impact
          Partnership’s movement. There are currently over 40 National Partners
          - one per country, including Bangladesh. We work collectively to
          advance a shift to impact-led economies at a local and global level.
          We develop, advocate, and implement innovative approaches and products
          that drive resources towards positive impact.
        </p>
        <p className="text-[16px] pb-4">
          GSG Impact supports the development and work of our National Partners,
          generates and shares differentiated policy insight and calls to
          action, leverages our collective voice in advocating for impact
          globally, and advances impact through innovation, knowledge sharing,
          convening, and collaboration. It is all about Impact through
          partnership and Impact at the heart of every decision.
        </p>
      </div>
    </div>
  );
}
