import React from "react";
import { Link } from "react-router-dom";
import news31 from "../../../../assets/images/news31.jpg";
import news31_details1 from "../../../../assets/images/news31_details1.jpg";
import news31_details2 from "../../../../assets/images/news31_details2.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function LaunchImpactInvestmentDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news31}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          November 10, 2016 marked a historic day for the investment ecosystem
          in Bangladesh. The launch of Build Bangladesh, the first impact
          enterprise under the Impress Group was the ‘big bang’ for the impact
          investment ecosystem in Bangladesh. Build Bangladesh is still leading
          the impact investment ecosystem, designed to deliver positive and
          measurable social, and environmental benefits along with financial
          gain. Build Bangladesh commenced its journey by hosting the first
          international conference on impact investment in Bangladesh gathering
          presence from over 100 local and global leaders in 2016.
        </p>

        <div className="text-center w-full">
          <img
            src={news31_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pt-2 pb-6">
          This conference was followed by the first international workshop on
          impact investment organised by Build Bangladesh in 2017, to guide the
          way forward together with partners like the UNDP.
        </p>

        <div className="text-center w-full">
          <img
            src={news31_details2}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pt-2 pb-6">
          However, Build Bangladesh still have a much steep terrain to climb
          than its predecessor innovators, managed the impacts of industrial
          revolution, the two world wars and post independent years of
          Bangladesh. Hence, its work lens looks through inclusiveness, gender
          equality, climate change, digital innovation resulting ‘people
          centric’ investment as the first Impact Enterprise to support the 8th
          Five Year Plan and the SDGs.
        </p>
        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
