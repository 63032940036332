import React from "react";
import Layout from "../../../shared/Layout/Layout";
import NewsHeader from "../../../shared/Components/NewsHeader";
import ConnectingCreativityDetails from "./component/ConnectingCreativityDetails";

export default function ConnectingCreativity() {
  return (
    <Layout>
      <NewsHeader>‘Connecting Creativity’ 2022</NewsHeader>
      <ConnectingCreativityDetails />
    </Layout>
  );
}
