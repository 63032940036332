import React from "react";
import { Link } from "react-router-dom";
import news1 from "../../../../assets/images/news1.jpg";
import impact2 from "../../../../assets/images/impact2.jpg";
import impact3 from "../../../../assets/images/impact3.jpg";
import impact4 from "../../../../assets/images/impact4.jpg";
import impact5 from "../../../../assets/images/impact5.jpg";
import impact6 from "../../../../assets/images/impact6.jpg";
import impact7 from "../../../../assets/images/impact7.jpg";
import impact8 from "../../../../assets/images/impact8.jpg";
import impact9 from "../../../../assets/images/impact9.jpg";
import impact10 from "../../../../assets/images/impact10.jpg";
import impact11 from "../../../../assets/images/impact11.jpg";
import impact12 from "../../../../assets/images/impact12.jpg";
import impact13 from "../../../../assets/images/impact13.jpg";
import impact14 from "../../../../assets/images/impact14.jpg";
import impact15 from "../../../../assets/images/impact15.jpg";
import impact16 from "../../../../assets/images/impact16.jpg";
import impact17 from "../../../../assets/images/impact17.jpg";
import impact18 from "../../../../assets/images/impact18.jpg";
import impact19 from "../../../../assets/images/impact19.jpg";
import impact20 from "../../../../assets/images/impact20.jpg";
import impact21 from "../../../../assets/images/impact21.jpg";
import impact22 from "../../../../assets/images/impact22.jpg";
import impact23 from "../../../../assets/images/impact23.jpg";
import impact24 from "../../../../assets/images/impact24.jpg";
import impact25 from "../../../../assets/images/impact25.jpg";
import impact26 from "../../../../assets/images/impact26.jpg";
import impact27 from "../../../../assets/images/impact27.jpg";
import impact28 from "../../../../assets/images/impact28.jpg";
import impact29 from "../../../../assets/images/impact29.jpg";
import impact30 from "../../../../assets/images/impact30.jpg";
import impact31 from "../../../../assets/images/impact31.jpg";
import impact32 from "../../../../assets/images/impact32.jpg";
import news11 from "../../../../assets/images/news11.jpg";
import Slider from "react-slick";

const impactImages = [
  impact11,
  impact12,
  impact13,
  impact14,
  impact15,
  impact16,
  impact17,
  impact18,
  impact19,
  impact20,
  impact21,
  impact22,
  impact23,
  impact24,
  impact25,
  impact26,
  impact27,
  impact28,
];

export default function ImpactInvestarDetails() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news1}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          It was Impact Investment festivity in Bangladesh between 23-25 June
          2023. Mr. Cliff Prior, CEO of the Global Steering Group (GSG) for
          Impact Investment illuminated our Impact Investment Ecosystem by
          visiting Bangladesh at the invitation of the National Advisory Board
          (NAB) of Impact Investment in Bangladesh.
        </p>

        <div className="py-20">
          <Slider {...settings}>
            <div>
              <img
                src={impact2}
                alt=""
                className="w-full h-auto rounded-md object-contain"
              />
            </div>
            <div>
              <img
                src={impact3}
                alt=""
                className="w-full h-auto rounded-md object-contain"
              />
            </div>
            <div>
              <img
                src={impact4}
                alt=""
                className="w-full h-auto rounded-md object-contain"
              />
            </div>
          </Slider>
        </div>

        <p className="pb-10">
          His three days visit was very eventful from day one till his
          departure. On Friday 23 June morning, he had an excellent firsthand
          but comprehensive introduction to our startup ecosystem from the
          Impact Entrepreneurs. In the afternoon he had a more robust and
          in-depth discussion with the intermediaries from across different
          sectors including fund manager, banker, accelerator, legal services
          provider, government VC Fund and development partners. The first day
          ended with formal welcome to Cliff Prior by the NAB Trust members over
          a dinner.
        </p>

        <div className="flex flex-col md:flex-row gap-6 py-10">
          <img
            src={impact5}
            alt=""
            className="w-full md:w-1/2 h-auto rounded-md  object-contain"
          />
          <img
            src={impact6}
            alt=""
            className="w-full md:w-1/2 h-auto rounded-md  object-contain"
          />
        </div>

        <p className="pb-10">
          Second day started at 7:30 in the morning! A trip to one of the
          largest readymade garments (RMG) and textile factory – Impress-Newtex
          Composite Textile Limited. Cliff expressed the experience as a
          ‘lifetime opportunity to see the engine room of the Bangladesh economy
          and the RMG workers in action’! He also experienced the pre-Eid
          traffic reconfirming our ‘density dividend’ and vibrant street markets
          on his rout. However, he had reached Dhaka to attend the evening
          programme, Impact InveSTAR on time. Thanks to the NAB Secretariat
          Executives.
        </p>

        <img
          src={impact7}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />
        <p className="pb-10">
          The programme (Impact InveSTAR) was hosted by the NAB Bangladesh Chair
          Mr Lokman Hossain Miah, Executive Chairman of the Bangladesh
          Investment Development Authority (BIDA) at the BIDA multipurpose hall.
        </p>
        <p className="pb-10">
          The Impact InveSTAR was designed is to acknowledge and show our
          respect to the ‘STARS’ shining on the Impact Investment sky over
          Bangladesh. This was our opportunity to share our appreciation for the
          organisations and people, who have been with the Impact Investment
          Ecosystem building movement of Bangladesh from day zero – Impact
          InveSTAR.
        </p>

        <img
          src={impact8}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />

        <p className="pb-10">
          We wanted to take this opportunity to recognise our Impact InveSTAR,
          while we had Cliff Prior, the CEO of the Global Steering Group for
          Impact Investment beside us.
        </p>
        <img
          src={impact9}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />

        <p className="pb-10">
          The evening commenced with live instrumental music followed by a BIDA
          documentary promoting Bangladesh as the investment destination. Cliff
          delivered his keynote speech at the glamorous evening while almost
          every segment of the Impact Investment stakeholders had shared their
          works and impacts to take the movement forward. In his speech, Cliff
          congratulated the Bangladesh NAB for leading this movement and the
          progress by having a National Strategy and Action Plan in place to
          implement. He also acknowledged the teamwork between all sectors of
          the Impact Investment Ecosystem and the positive cooperation among
          them. He had highlighted that such a group of Impact InveSTAR’s will
          be envy to a lot of emerging markets. He also assured the support
          needed from the GSG to ensure implementation of the National Strategy
          and Action Plan. Cliff concluded his speech by inviting all to attend
          the annual GSG Summit in Malaga, Spain on 2-3 October 2023.
        </p>

        <img
          src={impact10}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />
        <p className="pb-10">
          In addition, the first Impact Startup organisation was launched by
          Cliff Prior – 21st Startup.
        </p>

        <div className="py-10">
          <Slider {...settings}>
            {impactImages?.map((image) => (
              <div>
                <img
                  src={image}
                  alt=""
                  className="w-full h-auto rounded-md object-contain"
                />
              </div>
            ))}
          </Slider>
        </div>
        <p className="pb-10">
          There were 24 Impact InveSTAR recipients received the token of
          appreciation from the NAB Bangladesh. The acknowledgement was grouped
          under five categories: Government and Regulators; Investors;
          Investees; Intermediaries and Market Builders. And a special category
          for our Global STAR.
        </p>

        <img
          src={impact29}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />
        <p className="pb-10">
          The night ended with the concluding remarks from the NAB Trust Chair
          Mr. Arastoo Khan followed by a traditional Bangladeshi festive and
          formal dinner.
        </p>

        <img
          src={impact30}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />
        <p className="pb-10">
          The final day did not have any shortage of activities. With
          challenging Dhaka traffic our enthusiasm helped us to do three
          meetings. The day started with the morning meeting with Dr Mohammad
          Ayub Miah, CEO and General Secretary of the Center for Zakat
          Management (CZM) at their office. This was an eye-opening session for
          us. The CEO shared the impacts created among the people at the bottom
          of the pyramid by the CZM through their faith-based funding and
          activities across Bangladesh. The CZM has agreed to explore
          possibility of using the faith-based funds to complement the Impact
          Investment Ecosystem in Bangladesh.
        </p>

        <img
          src={impact31}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />
        <p className="pb-10">
          After lunch, the mission was to meet with the Government Regulator on
          the other side of the city. We reached there on time and met with Dr
          Shamsuddin Ahmed, a NAB Member and Commissioner of the Bangladesh
          Securities and Exchange Commission (BSEC). This meeting gave us the
          confidence for the ongoing support from the regulator to develop the
          Impact Investment Ecosystem in Bangladesh. The regulator in
          partnership with NAB has agreed to host an impact investment awareness
          session with the peak private sector bodies (e.g. FBBCCI, BGMEA etc.)
          this year.
        </p>

        <img
          src={impact32}
          alt=""
          className="w-full h-auto rounded-md object-contain"
        />

        <p className="pb-10">
          Our final meeting for the day/visit was with the Bangladesh Women
          Chamber of Commerce and Industries (BWCCI), again on another side of
          the city. This time we reached early. The BWCCI Treasurer Ms Sangita
          Khan welcomed us at their Board Room. The meeting was attended by few
          other BWCCI members including their Senior Vice President and a NAB
          Trust member Ms Sangita Ahmed. The meeting was chaired by the Founding
          President of the BWCCI Ms Selima Ahmad MP. The BWCCI agreed to
          continue their active cooperation to support the Impact Investment
          Movement in Bangladesh. The meeting ended with a very homely dinner
          sponsored by the Time Out restaurant.
        </p>
        <p className="pb-10">
          Finally, to summarise Cliff Prior three days visit, we realised that
          Bangladesh is a country still values ‘in person interactive meetings’
          extremely. In addition to the numerous benefits for the NAB
          Bangladesh, Cliff’s visit brought a five-fold benefit to the Impact
          Investment Movement in Bangladesh. The values it added to the tireless
          efforts in establishing the Impact Investment Ecosystem (since 2015)
          in Bangladesh and to support the 19th member country of the GSG are:
        </p>

        <div className="pl-6 pb-4">
          <p>
            <span className="font-bold">1. Overarching:</span> The interactive
            meetings and the Impact InveSTAR did boost up the ‘post-Covid’
            Impact Investment Ecosystem building process in Bangladesh.{" "}
          </p>
          <p>
            <span className="font-bold">2. Inspiration/Empowerment:</span> The
            inspiring speech and proactive discussion with various stakeholders
            including the NABG and NAB Trust members did empower these selfless
            knowledgeable/proactive stakeholders supporting us since 2018.
          </p>
          <p>
            <span className="font-bold">3. Sharing Intelligence:</span> Meetings
            with the regulators, faith-based organisation, peak business bodies
            and private sector leaders while sharing global scenario,
            opportunities, incentives (eg. use of untapped funds, role of
            government/private sectors, applicable impact instruments, IMM etc.)
            allowed Bangladesh to be on the same page as its peers in the impact
            movement.
          </p>
          <p>
            <span className="font-bold">4. Products/Events:</span> The launch
            the first token of acknowledgement to our Impact InveSTAR as the key
            Impact Investment Ecosystem builders, made all of us part of the
            impact investment history of Bangladesh.
          </p>
          <p>
            <span className="font-bold">5. General Awareness:</span> The
            presence and meetings as the GSG CEO with all stakeholders including
            young impact entrepreneurs, intermediaries, and development partners
            to share first-hand information; Press conference and electronic
            media exposure helped us to ensure/reinforce the continuity of the
            Impact Actions in Bangladesh.
          </p>
        </div>

        <p className="pb-10">
          The NAB is expecting that this visit and series of events will help us
          to reinforce the ongoing and outcome focused Impact Investment
          Movement in Bangladesh. We will be ‘one year faster’ then our planned
          speed with achievable goals due to the GSG’s active involvement and
          reconfirmation of strong message of alignment with the NAB Bangladesh
          through Cliff’s visit
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
