import React from "react";
import { Link } from "react-router-dom";
import news27 from "../../../../assets/images/news27.jpg";
import news27_details1 from "../../../../assets/images/news27_details1.jpg";
import news27_details2 from "../../../../assets/images/news27_details2.jpg";
import news27_details3 from "../../../../assets/images/news27_details3.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function NationalAdvisoryDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news27}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-6">
          In 2018, the National Advisory Board (NAB) for Impact Investment in
          Bangladesh was formed with the aim of creating an enabling environment
          for impact investment to thrive in the country. Build Bangladesh, the
          impact investment enterprise of the Impress Group played the lead role
          in spearheading the formation process by securing the consent from the
          External Resources Division (ERD) of the Ministry of Finance to head
          up the NAB. The organization also drew in memberships from the
          Government, Regulators, MDFIs, NGOs, Development Partners, Peak
          Business Associations, Legal Firm and Academics to reflect
          representation of key sectors and agreed to provide the secretariat
          support to the Advisory Board.
        </p>

        <div className="text-center w-full">
          <img
            src={news27_details1}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          The NAB Bangladesh officially commenced its journey in August 2018
          with its first meeting at the ERD. During this meeting, the Board
          unanimously decided to join the Global Steering Group (GSG) for impact
          investment as a member, with Build Bangladesh tasked with lodging the
          Membership submission. This recommendation from the NAB led to
          Bangladesh becoming the 19th member country of the GSG at its annual
          summit in New Delhi in October of the same year. This move provided
          NAB Bangladesh with access to a dynamic global ecosystem of impact
          investment knowledge and opportunities. Since then, the NAB Bangladesh
          has been instrumental in building impact investment momentum in
          Bangladesh and sensitizing the ecosystem to embrace impact investing.
        </p>
        <div className="text-center w-full">
          <img
            src={news27_details2}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <p className="pb-6">
          With the launching of Bangladesh Impact Investment Strategy and Action
          Plan (BIISAP) in 2022, the NAB Bangladesh is ready to carry out
          prioritized interventions in taking impact investment from margins to
          mainstream.
        </p>
        <div className="text-center w-full">
          <img
            src={news27_details3}
            alt=""
            className="w-full h-auto rounded-md shadow-xl object-contain"
          />
        </div>
        <div className="pt-4">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/0jI5525iLmI?si=fQezsT96lClg9Cl3"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="aspect-video"
          ></iframe>
        </div>
        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
