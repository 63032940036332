import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

export default function KeyPolicySecond() {
    let [showPolicy, setShowPolicy] = useState(false);
    return (
        <div className='px-8 py-4 border bg-white'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-primary'>Supply Side Players</p>
                <span>{showPolicy ? <FaMinusCircle onClick={() => setShowPolicy(false)} className='cursor-pointer fill-[#CCCCCC]' /> : <FaPlusCircle onClick={() => (setShowPolicy(true))} className='cursor-pointer fill-[#CCCCCC]' />}</span>
            </div>
            <div className={`${showPolicy ? "content show" : "content"}`}>
                <div className='table w-full border border-black'>
                    {/*table Heading */}
                    <div className='grid grid-cols-11 text-primary text-center font-bold border-b border-black'>
                        <div className='col-span-1 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder Category
                        </div>
                        <div className='col-span-2 border-r border-black flex justify-center items-center p-2'>
                            Stakeholder
                        </div>
                        <div className='col-span-8 border-black flex justify-center items-center p-2'>
                        Description of Activities/Web Links
                        </div>
                        
                    </div>

                    {/* Table Content */}
                    {/* First Row */}
                    <div className='grid grid-cols-11 text-center border-b border-black bg-[#FAFAFA] '>
                        <div className='col-span-1 border-r border-black flex items-center ' >
                          <p className='center'>-</p>    
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                        Bangladesh Angels Network (BAN)
                        </div>
                        <div className='col-span-8  border-black  text-left p-2'>
                        To bridge the early-stage financing gap and provide advisory support to startups, <a className='underline text-primary ' href="https://www.bdangels.co/" target='_blank'>Bangladesh Angels</a> created a platform for angel investing. The network also provides high quality mentoring and networking opportunities to portfolio companies. In addition to connecting individual and institutional investors with startups, BAN is also working to engage the Bangladeshi diaspora in the mainstream startup financing arena.
                        </div>
                       
                    </div>
                    {/* Second Row */}
                    <div className='grid grid-cols-11 text-center  bg-[#FAFAFA] '>
                        <div className='col-span-1 border-r border-black flex items-center'>
                        <p className='center'>-</p>  
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                        IDLC Venture Capital
                        </div>
                        <div className='col-span-8 border-black  text-left p-2'>
                        In 2020, <a className='underline text-primary ' href="https://idlc.com/aml/Venture-Capital/public/" target='_blank'>IDLC</a>  launched a venture capital fund worth BDT 450 Mn to invest in youth-led technology startups. The tenure of the fund is 7 years.
                        </div>
                       
                    </div>
                    
                    
                </div>
                <div className='grid grid-cols-11 text-center border-b border-black bg-[#FAFAFA] '>
                        <div className='col-span-1 border-x border-black flex items-center ' >
                          <p className='center'>-</p>    
                        </div>
                        <div className='col-span-2 border-r border-black flex items-center text-left p-2'>
                        Edge Asset Management
                        </div>
                        <div className='col-span-8 border-r border-black text-left p-2'>
                        <a className='underline text-primary ' href="https://edgeamc.com/" target='_blank'>The asset management company</a> employs a thorough fundamental research-based approach for identifying businesses with the good corporate governance culture, best management teams, strong and sustainable business models and attractive valuations. Its unique methodology for evaluating investment opportunities could be studied when preparing the impact investing due-diligence guideline for Bangladesh.                        </div>
                       
                    </div>
            </div>
        </div>
    )
}
