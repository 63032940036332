import React from "react";
import { Link } from "react-router-dom";

import news5 from "../../../../assets/images/news5.jpg";
import news11 from "../../../../assets/images/news11.jpg";

export default function NabMeetingDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={news5}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          The first meeting of the National Advisory Board (NAB) for Impact
          investment in Bangladesh was held on February 27, 2023 at the
          Bangladesh Investment Development Authority (BIDA) Board Room. The
          meeting was chaired by Lokman Hossain Miah, Chairman of National
          Advisory Board of Governance (NABG) and Executive Chairman of BIDA.
          The Meeting was moderated by Architect Farhadur Reza, Managing Trustee
          of NAB Bangladesh Trust and President of Build Bangladesh.
        </p>

        <p className="pb-10">
          The meeting was the 6th NAB meeting since the inception of the Board
          in 2018 and the first one since the launch of the Bangladesh Impact
          Investment Strategy and Action Plan (BIISAP) in August 2022.
        </p>
        <p className="pb-10">
          The Members discussed the two-tier approach of the NAB Bangladesh from
          2023. Where the NAB Bangladesh has been transitioned to the National
          Advisory Board for Governance (NABG) with the creation of the legal
          entity, NAB Trust, as requested by the NAB in their last meeting. The
          NAB Trust will now carry on the day-to-day function and the BIISAP
          Implementation Action Plans on behalf of the NABG.
        </p>
        <p className="pb-10">
          The members discussed the NABG and NAB Trust membership structure and
          considered to introduce an annual membership fee based on the category
          of the organisation. They also decided on the membership categories to
          ensure the membership diversification as guided by the Global Steering
          Group (GSG) for Impact Investment. The NABG also agreed to the
          inclusion of the Managing Director and CEO of the IFIC Bank Shah A
          Sarwar as a member of the NABG. The NAB Trust has been tasked to
          nominate new members for NABG to agree. Interested organisation can
          self-nominate for NABG Membership through NAB website
          (https://nabbangladesh.org/). NAB Trust will take actions to bring it
          to the attention of NABG for inclusion as applicable.
        </p>
        <p className="pb-10">
          NAB members decided that the “Impact InveStory” – proceedings from the
          BIISAP launch will remain as an electronic version. (QR Code). They
          have also agreed to share their views on the draft BIISAP
          Implementation Action Plans with recommendations for possible partners
          including their own contributions if applicable.
        </p>
        <p className="pb-10">
          The dedication of each member was demonstrated through their active
          involvement in the meeting to complement the way forward for building
          the Impact Investment Ecosystem in Bangladesh.
        </p>
        <p className="pb-10">
          The meeting was attended by Arastoo Khan – the Chairperson of the
          Trust and Chair of the Advisory Board of Build Bangladesh, Sangita
          Ahmed – NAB Trustee and Senior Vice President of Bangladesh Women
          Chamber of Commerce and Industries (BWCCI), Dr. Shaikh Shamsuddin
          Ahmed – Member of NABG and Commissioner of Bangladesh Securities and
          Exchange Commission (BSEC), Corinne Henchoz Pignani – Member of NABG
          and Deputy Head of Cooperation of Swiss Agency for Development and
          Cooperation (SDC), Stefan Liller – Member of NABG and Resident
          Representative of United Nations Development Programme (UNDP)
          Bangladesh, Ichiguchi Tomohide – Observer NABG and Chief
          Representative of Japan International Cooperation Agency (JICA)
          Bangladesh, and Sultana Afroz – Vice Chairperson NAB Trust and former
          CEO of PPPA.
        </p>
        <p className="pb-10">
          In addition, there were observers from the UNDP, PPA Authority, JICA
          and SDC.
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news11} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
