import React from "react";
import news1 from "../../../assets/images/news11.jpg";
import gsg from "../../../assets/images/gsgNews1.jpg";
import { Link } from "react-router-dom";

export default function GsgDetails() {
  return (
    <div className="max-w-6xl mx-auto py-20 px-4">
      <div className="text-center">
        <img
          src={gsg}
          alt=""
          className="w-full h-auto rounded-md shadow-xl object-contain"
        />
      </div>

      <div className="pt-20">
        <p className="pb-10">
          The 2021 GSG Global Impact Summit is all set to take place virtually
          from 6-8 October, 2021. The GSG annual summit is the most influential
          event for the global impact investing movement that focuses on
          delivering impact solutions for people and planet. This year four
          speakers from Bangladesh are invited to speak at this prestigious
          summit.
        </p>

        <p className="pb-10">
          Mr. Farhad Reza, President – Build Bangladesh and Ms. Corinne Henchoz
          Pignani, Deputy Head of Cooperation – Swiss Agency for Development and
          Cooperation (SDC), will be speaking at the GSG Asia Pacific Regional
          Session. Mr. Reza will narrate the story of NAB Bangladesh will Ms.
          Pignani will speak about the role of development partners in promoting
          impact investing.
        </p>

        <p className="pb-10">
          Ms. Tina Jabeen, Managing Director and CEO – Startup Bangladesh Ltd.
          will be speaking at the session titled ‘Social Entrepreneurship:
          lessons learned from the frontline.’
        </p>

        <p className="pb-10">
          Mohammad Anisur Rahman, Senior Director – BRAC Enterprises, will be
          sharing his knowledge and wisdom at the session titled ‘From Power
          Sharing to Power Shifting – Centering Communities in the Impact
          Economy.’
        </p>

        <Link to="/nab-bangladesh-launches-biisap">
          <p className="text-primary font-bold cursor-pointer text-right">
            Next: NAB Bangladesh launches BIISAP &#8594;
          </p>
        </Link>
      </div>

      <h2 className="pt-20 font-bold text-5xl">You May Also Like…</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 py-10">
        <Link to="/nab-bangladesh-launches-biisap">
          <div className="bg-white rounded-md overflow-hidden shadow-card">
            <img src={news1} className="w-full" />
            <div className="p-4">
              <h2 className="text-xl font-bold pb-2">
                NAB Bangladesh launches BIISAP
              </h2>
              <p className="text-primary pb-2">Sep 17, 2022</p>
              <p>
                The ‘seeding fertile ground’ for impact investment in Bangladesh
                gave the National Advisory Board (NAB) for Impact...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
